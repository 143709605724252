import { RiFacebookBoxLine, RiTwitterLine, RiWhatsappLine } from 'react-icons/ri';
import { Game } from '../../typescript/match';
import { normalizePotUrl } from '../../utils/normalize-name';
import { ChoosesType } from './Pot';
import CryptoJS from 'crypto-js';

export const POT_CHOICES_KEY = 'POT_CHOICES';
export const LINK_INVITE_GROUP_KEY = 'LINK_INVITE_GROUP';

export interface PotChoosePersisted {
  pot: Game;
  chooses: ChoosesType[];
}

export enum NetworkName {
  Whatsapp = 'Whatsapp',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export const NETWORKS = [
  {
    title: NetworkName.Whatsapp,
    icon: RiWhatsappLine,
  },

  {
    title: NetworkName.Facebook,
    icon: RiFacebookBoxLine,
  },

  {
    title: NetworkName.Twitter,
    icon: RiTwitterLine,
  },
];

interface SharePotLink {
  network: 'Whatsapp' | 'Facebook' | 'Twitter';

  potInfo: Game;
}

const shareText = 'Palpite na MoneyPot';

export const shareLinkToNetworks = ({ network, potInfo }: SharePotLink) => {
  if (window) {
    const potId = potInfo.id.toString();
    const encryptionKey = 'secrethero99';
    const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);

    const newUrl = `/pot/${eHex}`;

    const url =
      process.env.REACT_APP_ENVIRONMENT === 'PROD'
        ? `${process.env.REACT_APP_PROD_WEB_URL}${newUrl}`
        : `${process.env.REACT_APP_HML_WEB_URL}${newUrl}`;

    if (network === 'Facebook') {
      const link = `https://www.facebook.com/sharer.php?u=${url}`;
      return link;
    }

    if (network === 'Twitter') {
      const encodedText = encodeURIComponent('Faça já o seu palpite na MoneyPot');
      const encodedHashtags = encodeURIComponent('moneypot,game,money');
      const link = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${encodedText}&hashtags=${encodedHashtags}`;
      return link;
    }

    if (network === 'Whatsapp') {
      return `https://api.whatsapp.com/send?text=${url}`;
    }
  }

  return '';
};
