import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { GroupType } from '../../typescript/group';
import { setOffGroupWelcome } from '../../utils/group';
import { ReactComponent as CopyIcon } from '../../utils/icons/copy.svg';
import { shareLinkByClipBoard } from '../../utils/share';
import Button from '../Button/Button';
import {
  GroupInfoAvatar,
  GroupInfoContainer,
  GroupInfoHeader,
  LinkInvite,
  ModalOverlay,
  ShareLinkContainer,
} from './styles';

interface GroupInfoProps {
  group: GroupType;
  handleClose: () => void;
}

const WelcomeToGroup = ({ group, handleClose }: GroupInfoProps) => {
  const user = useSelector((state: RootState) => state.user);
  const [linkCopied, setLinkCopied] = useState(false);

  const sharePot = () => {
    const url = group.invite;
    shareLinkByClipBoard(url);
    setLinkCopied(true);
  };

  const handleCloseModal = () => {
    handleClose();
    setOffGroupWelcome(user.userInfo.id);
  };

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  }, [linkCopied]);

  return (
    <ModalOverlay>
      <GroupInfoContainer>
        <button className="close" onClick={handleCloseModal}>
          <MdClose size={18} />
        </button>

        <GroupInfoHeader>
          {!!group.avatar && (
            <GroupInfoAvatar>
              <img src={group.avatar} alt="Imagem do Grupo" />
            </GroupInfoAvatar>
          )}

          <ShareLinkContainer>
            <h2>Bem-vindo ao grupo</h2>
            <h2>{group.name}</h2>

            <p>
              <span>Convide seus amigos para entrar</span>
              <span>no grupo também!</span>
            </p>
          </ShareLinkContainer>

          <LinkInvite copied={linkCopied}>
            <p>{group.invite.replace('https://', '')}</p>

            <button onClick={sharePot}>
              <CopyIcon />
            </button>
          </LinkInvite>
        </GroupInfoHeader>

        <Button
          hierarchy="tertiary"
          label="Voltar"
          moreClasses="md:hidden mt-5 text-base text-[#1C1C27] font-bold"
          onClick={handleCloseModal}
        />
      </GroupInfoContainer>
    </ModalOverlay>
  );
};

export default WelcomeToGroup;
