import { Routes, Route, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import RequireAuth from './components/RequireAuth/RequireAuth';

//rotas criadas
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Faq from './pages/Faq/Faq';
import Network from './pages/Checkout/Checkout';
import Gains from './pages/Gains/Gains';
import Responsible from './pages/Responsible/Responsible';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';
import Coins from './pages/Coins';
import Guess from './pages/Guess/index';
import BuyCoins from './pages/ErrorCoinsPage/index';
import { PolicyAndPrivacy } from './pages/PolicyAndPrivacy';
import Group from './pages/Group/Group';
import JoinGroup from './pages/Group/JoinGroup';

//rotas autenticadas
import Profile from './pages/Profile/Profile';
import Account from './pages/Account';
import Withdraw from './pages/Withdraw';
import MyBets from './pages/MyBets';
import Pots from './pages/Pots/Pots';

import { LoginOpenMenuContext, LoginOpenMenuProvider } from './common/context/LoginOpenMenu';
import { UserProvider } from './common/context/UserContext';
import { NavBarMenuProvider } from './common/context/NavbarContext';
import { ModalMessageProvider } from './common/context/AlertsContext';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import Category from './pages/Category';
import SinglePotPage from './pages/Pot/SinglePotPage';
import ForgetPassword from './pages/ForgetPassword';
import CreateNewPassword from './pages/CreateNewPassword';
import RulesPage from './pages/Rules';
import AboutGroupPage from './pages/AbooutGroup';
import CreateGroupPage from './pages/CreateGroup';
import ParticipateGroupPage from './pages/ParticipateGroup';
import { useContext, useEffect, useState } from 'react';
import NavbarMenu from './components/NavbarMenu/NavbarMenu';
import TermsAndConditions from './pages/TermsAndConditions';
import Register from './pages/Register';
import BetSuccessPage from './pages/BetSuccess';
import ErrorCoinsPage from './pages/ErrorCoinsPage/index';
import { GoogleAuthInfoContextProvider } from './common/context/useGoogleAuthInfoContext';
import { AppMainContainer } from './layouts/PageContainer/styles';
import { handleBackToTop } from './utils/window-func';
import GroupPage from './pages/Group/group-page';
import SinglePotBetPage from './pages/PotBet/SinglePotBetPage';

function App() {
  const location = useLocation();
  const pathname = location.pathname


  useEffect(() => {
   handleBackToTop()
  }, [pathname]);

  const isChangeLayout  = pathname === "/login" || pathname === "/register" ||  pathname === "/forget-password"

  return (
    <Provider store={store}>
      <LoginOpenMenuProvider>
        <UserProvider>
          <GoogleAuthInfoContextProvider>
            <NavBarMenuProvider>
              <ModalMessageProvider>
                <AppMainContainer isChangeLayout={isChangeLayout}>
                  <NavbarMenu />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/create-group" element={<CreateGroupPage />} />
                    <Route path="/participate-group" element={<ParticipateGroupPage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/network" element={<Network />} />
                    <Route path="/gains" element={<Gains />} />
                    <Route path="/responsible" element={<Responsible />} />
                    <Route path="/recovery" element={<PasswordRecovery />} />
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    <Route path="/create-new-password/:token" element={<CreateNewPassword />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/rules" element={<RulesPage />} />
                    <Route path="/buyCoins" element={<BuyCoins />} />
                    <Route path="/category/:sport" element={<Category />} />
                    <Route path="/bets" element={<MyBets />} />
                    <Route path="/policy-and-privacy" element={<PolicyAndPrivacy />} />
                    <Route path="/group/:id" element={<Group />} />
                    <Route path="/:groupToken" element={<GroupPage />} />
                    <Route path="/about-group" element={<AboutGroupPage />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/bet-success" element={<BetSuccessPage />} />
                    <Route path="/coins-error" element={<ErrorCoinsPage />} />

                    <Route
                      path="/coins"
                      element={
                        <RequireAuth>
                          <Coins />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/account"
                      element={
                        <RequireAuth>
                          <Account />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <RequireAuth>
                          <Profile />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/profile/withdraw"
                      element={
                        <RequireAuth>
                          <Withdraw />
                        </RequireAuth>
                      }
                    />
                    {/* <Route path="/pots" element={<Pots />} />  */}
                    <Route path="/pot/:id" element={<SinglePotPage />} />
                    <Route path="/pot-bet/:login/:id" element={<SinglePotBetPage />} />

                    <Route
                      path="/guess/:id"
                      element={
                        <RequireAuth>
                          <Guess />
                        </RequireAuth>
                      }
                    />
                    <Route path="/pageNotFound" element={<PageNotFound />} />
                  </Routes>
                </AppMainContainer>
              </ModalMessageProvider>
            </NavBarMenuProvider>
          </GoogleAuthInfoContextProvider>
        </UserProvider>
      </LoginOpenMenuProvider>
    </Provider>
  );
}

export default App;
