import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PotAccumulatedPrice, PotChampionShipName, PotInfoContainer, PrizeText } from '../../components/Pot/styles';
import { potHandleIconIdentifier, potIdentifier } from '../../components/PotCard/helpers';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { getCmsHtml } from '../../service/cms/cms.service';
import { getPotBetById } from '../../service/pots/pots.service';
import { RootState } from '../../store';
import { Game } from '../../typescript/match';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { formatValue } from '../../utils/types';
import { ItemPotBet } from './item';
import { PotBanner } from './styles';

const SinglePotBetPage = () => {
  const [bannerContent, setBannerContent] = useState('');
  const [bannerPot, setBannerPot] = useState('');
  const [potInfo, setPotInfo] = useState<Game | null>(null);
  const [filteredPots, setFilteredPots] = useState<Game[]>([]);
  const navigate = useNavigate();
  const { id, login } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.user);

  const { MAIN_BANNER_MINI_TYPEID, POT_BANNER_TYPEID } = cmsPagesEnum;

  useEffect(() => {
    if (userInfo.login !== login) {
      return navigate('/pageNotFound');
    }
  }, [userInfo.login]);

  useMemo(async () => {
    const response = await getPotBetById(Number(id));

    const hasBetCorrect = response.data.betsSamePot.filter(
      (item: any) => potHandleIconIdentifier(item)?.legend === 'Você ganhou'
    );

    if (response.status === 500) {
      return navigate('/pageNotFound');
    }

    if (hasBetCorrect.length) {
      setPotInfo(hasBetCorrect[0]);
    }

    if (!hasBetCorrect.length) {
      setPotInfo(response.data.betsSamePot[0]);
    }

    return setFilteredPots(response.data.betsSamePot);
  }, [id]);

  useEffect(() => {
    getCmsHtml(MAIN_BANNER_MINI_TYPEID).then((res) => {
      setBannerContent(res);
    });

    getCmsHtml(POT_BANNER_TYPEID).then((res) => {
      setBannerPot(res);
    });
  }, []);

  return (
    <PageContainer className="relative">
      <div className="relative w-full overflow-hidden rounded-20 grid grid-rows-[auto_auto] gap-y-5 md:mt-16 md:mb-8 md:grid-cols-[64%_35%] md:gap-5 md:h-[200px]">
        <PotBanner
          dangerouslySetInnerHTML={{
            __html: bannerPot,
          }}
        />

        <PotBanner
          dangerouslySetInnerHTML={{
            __html: bannerContent,
          }}
        />
      </div>

      <div className="content-container flex flex-col items-center md:mb-10 mb-6 p-4 sm:p-7 max-w-full border-2 border-[#4B4C66] rounded-20">
        <div className="w-full">
          {(() => {
            if (potInfo) {
              const valueAccumulated = potInfo.valueAccumulated;
              const minimumValue = potInfo.minimumValue;

              return (
                <>
                  <PotInfoContainer>
                    <div>
                      <PotChampionShipName className="mb-2 text-2xl font-semibold leading-5 cursor-pointer">
                        {potInfo.championship}
                      </PotChampionShipName>

                      <p>{potInfo.name}</p>
                    </div>

                    <div className="flex flex-col items-end">
                      <PotAccumulatedPrice className="mb-2 gap-3 text-2xl font-semibold leading-5 flex items-center">
                        R$
                        {valueAccumulated >= minimumValue ? formatValue(valueAccumulated) : formatValue(minimumValue)}
                        {potIdentifier(potInfo).icon}
                      </PotAccumulatedPrice>

                      <PrizeText>Prêmio atual</PrizeText>
                    </div>
                  </PotInfoContainer>

                  <div className="w-full my-6 p-4 rounded-2xl bg-[#28293d80]">
                    <p className="text-[#C4C4C4]">{potInfo.description}</p>
                  </div>
                </>
              );
            }
          })()}
        </div>

        <div className="flex flex-col gap-6 w-full">
          {filteredPots.map((item, index) => (
            <ItemPotBet key={item.id} index={index + 1} pot={item} />
          ))}
        </div>
      </div>
    </PageContainer>
  );
};

export default SinglePotBetPage;
