import { Navigate } from 'react-router-dom';

import { RootState, useSelector } from '../../store';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const user = useSelector((state: RootState) => state.user);
  const logged = !!user.token;

  if (!logged) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default RequireAuth;
