const fireIcon = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.74023 5.48437C2.29648 7.65937 1.11523 10.1531 1.11523 12.4781C1.11523 14.5667 1.94492 16.5697 3.42177 18.0466C4.89862 19.5234 6.90165 20.3531 8.99023 20.3531C11.0788 20.3531 13.0819 19.5234 14.5587 18.0466C16.0355 16.5697 16.8652 14.5667 16.8652 12.4781C16.8652 7.60312 13.4902 3.85312 10.7527 1.125L7.49023 7.97812L3.74023 5.48437Z"
        stroke="#FF8E00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default fireIcon;
