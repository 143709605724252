import { encode } from 'base-64';
import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState } from 'react';
import { IoShareSocial } from 'react-icons/io5';
import { RiInformationFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import MatchSelector from '../../components/MatchSelector/MatchSelector';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useHideScrollWhenShowModal } from '../../hooks/useHideScrollWhenShowModal';
import { useUserAuth } from '../../hooks/user-auth';
import { checkDuplicatedBet, userBet } from '../../service/user/user.service';
import { Game, GroupOfMasterOptions, Match, TeamMatchPoint } from '../../typescript/match';
import { image_server_path } from '../../utils/group';
import { ReactComponent as ArrowRight } from '../../utils/icons/arrowR.svg';
import { ReactComponent as Champion } from '../../utils/icons/champion.svg';
import { ReactComponent as CoinsIcon } from '../../utils/icons/coins.svg';
import { ReactComponent as InfoIcon } from '../../utils/icons/info.svg';
import { ReactComponent as Master } from '../../utils/icons/master.svg';
import { ReactComponent as Target } from '../../utils/icons/target.svg';
import { normalizePotUrl } from '../../utils/normalize-name';
import { shareLinkByClipBoard } from '../../utils/share';
import { formatValue } from '../../utils/types';
import { handleBackToTop } from '../../utils/window-func';
import { BallonCoinsPot } from '../Ballon';
import Button from '../Button/Button';
import ExactMatchPoint from '../MatchSelector/ExactMatchPoint';
import ModalAuthentication from '../ModalAuthentication';
import ModalCoins, { ModalSessionExpired } from '../ModalCoins';
import ModalWarningSamePredict from '../ModalWarningSamePredict';
import { potHandleIconIdentifier } from '../PotCard/helpers';
import { Timer3 } from '../Timer';
import { SelectorTeam } from './../../components/MatchSelector/SelectorTeam';
import { ShareLinks } from './ShareLinks';
import {
  CoinsContainer,
  PotAccumulatedPrice,
  PotChampionShipName,
  PotContainer,
  PotInfoContainer,
  PrizeText,
} from './styles';
import moment from 'moment';
import { SessionControl } from '../../session/SessionControl';

interface propsInfo {
  setOpenRules: Function;
  openRules: boolean;
  setOpenModal: Function;
  openModal: boolean;
  potInfo: Game;
  isAlreadyBet: boolean;
  fullWidth?: boolean;
  isAtCategoryPage?: boolean;
  parsed?: string;
}

export interface ChoosesType {
  team: string;
  id: number;
}

const isAtLeastOneItemOnMatchOneVsManySelected = (matchOneVsMany: GroupOfMasterOptions[]) => {
  return matchOneVsMany.every((group) => group.options.some((option) => option.result !== null));
};

export const areAllTeamsWithPoints = ({ matchExactPoint }: { matchExactPoint: TeamMatchPoint[] }) => {
  const cpyMatchExactPoint = [...matchExactPoint];

  if (cpyMatchExactPoint.length === 0) {
    return false;
  } else {
    return cpyMatchExactPoint.every((match) => {
      return match.team1.point !== null && match.team2.point !== null;
    });
  }
};

export function formatDate(date: string) {
  const takeWithOutTime = date.split(' ')[0];
  const takeNumbers = takeWithOutTime.split('-');

  return takeNumbers[1] + '/' + takeNumbers[2];
}

const Pot = ({
  setOpenRules,
  setOpenModal,
  potInfo,
  fullWidth = false,
  isAtCategoryPage = false,
  isAlreadyBet,
}: propsInfo) => {
  const { userLogged, setUserLogged, updateAppState } = useUserContext();
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const windowSize = useGetWindowSize();
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | 'session' | ''>('');
  const [chooses, setChooses] = useState<ChoosesType[]>([]);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [fullBets, setFullBets] = useState(true);
  const [showModalAuth, setShowModalAuth] = useState(false);
  const [isSuccessBet, setIsSuccessBet] = useState(false);
  const isAlreadyDone = isAlreadyBet;
  const isMobile = windowSize.width <= 600;
  const isPotPage = window.location.pathname.startsWith('/pot/');
  const [isLoading, setIsLoading] = useState(false);
  const [infoBallonCoins, setInfoBallonCoins] = useState(false);
  const [showModalBetDuplicated, setShowModalBetDuplicated] = useState(false);
  const isHidePageContentWhenWarningAtMobile = isMobile && showModalBetDuplicated;
  useHideScrollWhenShowModal({ state: showModalAuth || isHidePageContentWhenWarningAtMobile, hidePageContent: false });
  const potOneVsMany = potInfo.onevsmany ? potInfo.onevsmany?.potOneVsMany : null;
  const pointSpread = potInfo.pointspread ? potInfo.pointspread?.pointSpread : null;

  const [matchExactPoint, setMatchExactPoint] = useState<TeamMatchPoint[]>([]);
  const [matchOneVsMany, setMatchOneVsMany] = useState<GroupOfMasterOptions[]>([]);

  const { isUserSessionFinished } = useUserAuth({});
  const hidePageContent = isMobile && statusRequest === 'session';

  function redirectToHome() {
    if (chooses.length < potInfo.match.length) {
      setFullBets(true);
      return;
    } else if (chooses.length) {
      SessionControl.setPotChoices(
        JSON.stringify({
          pot: potInfo,
          chooses,
        })
      );

      setShowModalAuth(true);
    }
  }

  const resetInputValue = () => {
    const emptyValue = NaN;

    setMatchExactPoint((prevMatchExactPoint) => {
      return prevMatchExactPoint.map((match) => {
        return {
          ...match,
          team1: {
            ...match.team1,
            point: emptyValue,
          },
          team2: {
            ...match.team2,
            point: emptyValue,
          },
        };
      });
    });
  };

  const handleUpdateMatchExactPoints = ({ id, team, value }: { id: string; team: string; value: number }) => {
    const updatedMatchExactPoint = matchExactPoint.map((match) => {
      if (match.id === id) {
        if (match.team1.name === team) {
          return {
            ...match,
            team1: {
              ...match.team1,
              point: value,
            },
          };
        } else if (match.team2.name === team) {
          return {
            ...match,
            team2: {
              ...match.team2,
              point: value,
            },
          };
        }
      }
      return match;
    });

    setMatchExactPoint(updatedMatchExactPoint);
    setFullBets(true);
  };

  const handleSelectedItem = ({ groupId, team }: { groupId: number; team: string }) => {
    const updatedMatchOneVsMany = matchOneVsMany.map((group) => {
      if (group.id === groupId) {
        const updatedOptions = group.options.map((option) => {
          if (option.name === team) {
            return {
              ...option,
              result: team,
            };
          } else {
            return {
              ...option,
              result: null,
            };
          }
        });

        return {
          ...group,
          options: updatedOptions,
        };
      } else {
        return group;
      }
    });

    if (updatedMatchOneVsMany.length) {
      setMatchOneVsMany(updatedMatchOneVsMany as GroupOfMasterOptions[]);
      setFullBets(true);
    }
  };

  const getSelectorDefaultValue = (id: number) => {
    const teamExist = chooses.find((team1) => team1.id === id);
    return teamExist ? teamExist.team : '';
  };

  const handleSetChooseTeams = (id: number, team: string) => {
    let newChooses: ChoosesType[] = structuredClone(chooses);

    if (newChooses.length === 0) {
      newChooses.push({
        id,
        team,
      });
    } else {
      const teamExist = newChooses.find((team1) => team1.id === id);

      if (teamExist) {
        if (teamExist.team === team) {
          newChooses = newChooses.filter((team1) => team1.id !== id);
        } else {
          newChooses = newChooses.filter((team1) => team1.id !== id);
          newChooses.push({
            id,
            team,
          });
        }
      } else {
        newChooses.push({ id, team });
      }
    }

    setChooses(newChooses);
  };

  const handleSelectedTeam = (id: number, team: string) => {
    handleSetChooseTeams(id, team);
    setFullBets(true);
  };

  const backToTop = () => {
    handleBackToTop();
  };

  const sharePot = (potInfo: Game) => {
    const { origin } = new URL(window.location.href);
    const championShip = normalizePotUrl(potInfo.championship);
    const title = normalizePotUrl(potInfo.name);
    const newUrl = `${origin}/pot/${championShip}/${title}`;

    try {
      shareLinkByClipBoard(newUrl);
    } catch (e) {
      console.error(e);
    } finally {
      setShowShareOptions(false);
    }
  };

  async function sendPot({ checkDuplicity }: { checkDuplicity: boolean }) {
    setFullBets(true);

    const sessionResult = await isUserSessionFinished();

    if (sessionResult) {
      setStatusRequest('session');
      return;
    }

    if (potInfo.bettypes) {
      if (potInfo.bettypes.includes('onevsone')) {
        if (chooses.length < potInfo.match.length) {
          setFullBets(false);
          return;
        }
      }

      if (potInfo.bettypes.includes('pointspread')) {
        const result = !areAllTeamsWithPoints({ matchExactPoint });
        if (result) {
          setFullBets(false);
          return;
        }
      }

      if (potInfo.bettypes.includes('onevsmany')) {
        const result = isAtLeastOneItemOnMatchOneVsManySelected(matchOneVsMany);
        if (!result) {
          setFullBets(false);
          return;
        }
      }
    }

    if (userLogged) {
      setStatusRequest('');

      try {
        let bets = '';

        if (potInfo.match.length) {
          const betsString = potInfo.match
            .map((m) => chooses.find((c) => c.id === m.id)?.team)
            .filter((v) => !!v)
            .join();

          bets = betsString;

          if (betsString.split(',').length !== potInfo.match.length) {
            setFullBets(false);
            return;
          }

          setFullBets(true);
          setIsLoading(true);
        }

        if (checkDuplicity) {
          const isDuplicated = await checkDuplicatedBet({
            bets,
            potId: potInfo.id,
            betmatchexactpoint: matchExactPoint.length ? matchExactPoint : null,
            betonevsmany: matchOneVsMany.length ? matchOneVsMany : null,
          });

          if (isDuplicated) {
            setShowModalBetDuplicated(true);
            setIsLoading(false);
            return;
          }
        }

        const bet = await userBet({
          login: userLogged?.login,
          bets,
          value: potInfo!.minimumBet,
          pot: potInfo!.id,
          id: userLogged?.id,
          betmatchexactpoint: matchExactPoint.length ? matchExactPoint : null,
          betonevsmany: matchOneVsMany.length ? matchOneVsMany : null,
        });

        if (bet.statusCode === 500) {
          setIsLoading(false);
          setStatusRequest('error');
          return;
        }

        if (bet.status === 406) {
          setIsLoading(false);

          return bet.status;
        }

        if (bet.status !== 200) {
          setIsLoading(false);
          setStatusRequest('error');
          return;
        }

        if (bet.status === 500 || bet.status === 400) {
          setIsLoading(false);
          setStatusRequest('error');
          return;
        }

        setUserLogged({
          ...userLogged,
          usersCoins: [
            {
              id: userLogged.usersCoins[0].id,
              status: userLogged.usersCoins[0].status,
              value: userLogged.usersCoins[0].value - potInfo!.minimumBet,
            },
          ],
        });

        setChooses([]);
        SessionControl.clearPotChoices();
        SessionControl.removePot(`pot${potInfo.id}matchExactPoint`);
        SessionControl.removePot(`pot${potInfo.id}potOneVsMany`);
        if (pointSpread) {
          setMatchExactPoint(pointSpread);
        }

        if (potOneVsMany) {
          setMatchOneVsMany(potOneVsMany);
        }

        // setStatusRequest('success');
        backToTop();

        if (isMobile) {
          setIsLoading(false);

          navigate('/bet-success');
        } else {
          setIsLoading(false);

          setIsSuccessBet(true);
        }

        setIsLoading(false);

        updateAppState('update users bets');
        resetInputValue();
        return bet;
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    }
  }

  const saveChooses = () => {
    backToTop();
    SessionControl.setPot(`pot${potInfo.id}`, JSON.stringify(matchExactPoint));
    SessionControl.setPotChoices(
      JSON.stringify({
        pot: potInfo,
        chooses,
      })
    );
  };

  function gotToPot() {
    // const championShip = normalizePotUrl(potInfo.championship);
    // const title = normalizePotUrl(potInfo.name);
    // const newUrl = `/pot/${championShip}/${title}`;

    const potId = potInfo.id.toString(); // Convertendo para string

    const encryptionKey = 'secrethero99';
    const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);

    const newUrl = `/pot/${eHex}`;

    if (chooses.length) {
      const encodedString = encode(JSON.stringify(chooses));
      saveChooses();
      navigate(`${newUrl}?chooses=${encodedString}`);
    } else {
      navigate(newUrl);
    }
  }
  const handleSendPot = async () => {
    const isAnyInputEmpty = matchExactPoint.some((match) => {
      const isEmpty =
        match.team1.point === null ||
        match.team1.point === undefined ||
        isNaN(match.team1.point) ||
        match.team2.point === null ||
        match.team2.point === undefined ||
        isNaN(match.team2.point);

      return isEmpty;
    });

    if (isAnyInputEmpty) {
      // Se algum campo de input estiver vazio, exibe a mensagem de erro e interrompe o envio
      setFullBets(false);
      return;
    }

    if (!userLogged) {
      saveChooses();
      //this creates a bug that once you log in it clear the bets you made while not logged
      // resetInputValue();
      setShowModalAuth(true);
      return redirectToHome();
    } else if (userLogged?.usersCoins[0].value < potInfo.minimumBet && window.innerWidth <= 1023) {
      saveChooses();
      resetInputValue();
      navigate('/buyCoins');
    } else if (userLogged?.usersCoins[0].value < potInfo.minimumBet) {
      saveChooses();
      resetInputValue();
      setOpenModal(true);
    } else {
      await sendPot({ checkDuplicity: true });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const finalBetDate = moment(potInfo!.dateFinalBet);
      const finalBetTimestamp = finalBetDate.valueOf();
      setTimerExpired(Boolean(moment().valueOf() > finalBetTimestamp));
    }, 100);

    return () => clearInterval(interval);
  }, [potInfo, timerExpired]);

  useEffect(() => {
    if (isAlreadyDone) {
      if (potInfo.anotherBet && potInfo.anotherBet.betmatchexactpoint && potInfo.bettypes?.includes('pointspread'))
        setMatchExactPoint(potInfo.anotherBet.betmatchexactpoint?.pointSpread);
      return;
    }

    if (pointSpread !== null) {
      const savedPotState = SessionControl.getPot(`pot${potInfo.id}matchExactPoint`);

      if (savedPotState) {
        const data = JSON.parse(savedPotState);
        if (data.length) {
          setMatchExactPoint(data);
        } else {
          setMatchExactPoint(pointSpread);
        }
      } else {
        setMatchExactPoint(pointSpread);
      }
    }
  }, []);

  useEffect(() => {
    if (matchExactPoint.length) {
      SessionControl.setPot(`pot${potInfo.id}matchExactPoint`, JSON.stringify(matchExactPoint));
    }
  }, [matchExactPoint]);

  useEffect(() => {
    if (isAlreadyDone) {
      if (potInfo.anotherBet && potInfo.anotherBet.betonevsmany && potInfo.bettypes?.includes('onevsmany')) {
        setMatchOneVsMany(potInfo.anotherBet.betonevsmany.potOneVsMany);
      }
      return;
    }

    if (potOneVsMany !== null) {
      const savedPotState = SessionControl.getPot(`pot${potInfo.id}potOneVsMany`);

      if (savedPotState) {
        const data = JSON.parse(savedPotState);
        if (data.length) {
          setMatchOneVsMany(data);
        } else {
          setMatchOneVsMany(potOneVsMany);
        }
      } else {
        setMatchOneVsMany(potOneVsMany);
      }
    }
  }, []);

  useEffect(() => {
    if (matchOneVsMany.length) {
      SessionControl.setPot(`pot${potInfo.id}potOneVsMany`, JSON.stringify(matchOneVsMany));
    }
  }, [matchOneVsMany]);

  useEffect(() => {
    return () => {
      SessionControl.removePot(`pot${potInfo.id}matchExactPoint`);
      SessionControl.removePot(`pot${potInfo.id}potOneVsMany`);
    };
  }, []);

  const potIndicatorIcon = potInfo.banner;
  const showContinueBetting = (potInfo?.match.length > 8 || isAtCategoryPage) && !isPotPage;
  const matches = showContinueBetting ? potInfo?.match.slice(0, isAtCategoryPage ? 2 : 8) : potInfo?.match;

  const potInfoDate = moment(potInfo.dateInitialBet).valueOf();
  const isDateValid = potInfoDate <= moment().valueOf();

  if (isDateValid) {
    return (
      <>
        <ModalCoins
          openAlert={isSuccessBet}
          title1="Parabéns!"
          title2="Seu palpite foi feito com sucesso!"
          text1="Agora é só ficar de olho no resultado e comemorar."
          text2="Boa sorte!"
          btnText="Ok, entendi"
          modalIcon="success"
          setOpenAlert={setIsSuccessBet}
        />

        {showModalBetDuplicated && (
          <ModalWarningSamePredict
            handleCancel={() => setShowModalBetDuplicated(false)}
            handleConfirm={() => {
              setShowModalBetDuplicated(false);
              sendPot({ checkDuplicity: false });
            }}
          />
        )}

        <ModalSessionExpired
          handleClose={() => setStatusRequest('')}
          handleConfirm={() => {
            setStatusRequest('');
            redirectToHome();
          }}
          open={statusRequest === 'session'}
        />

        {showModalAuth && (
          <ModalAuthentication isRedirect={potInfo?.match.length > 8} handleClose={() => setShowModalAuth(false)} />
        )}

        {!isHidePageContentWhenWarningAtMobile && !hidePageContent && (
          <PotContainer className="single-pot">
            <div
              className={`content-container flex flex-col items-center md:mb-10 max-[350px]:p-3 p-6 max-w-full border-2 border-[#4B4C66] rounded-20 ${
                fullWidth ? 'max-w-[100%]' : 'max-w-[1040px]'
              }`}
            >
              <div className="max-w-full w-full">
                <PotInfoContainer>
                  <PotChampionShipName
                    className="text-2xl font-semibold leading-5 cursor-pointer font-outfit"
                    onClick={gotToPot}
                  >
                    {potInfo?.championship || 'Campeonato'}
                  </PotChampionShipName>

                  <PotAccumulatedPrice className="hidden sm:flex gap-3 text-2xl font-semibold leading-5 font-outfit">
                    R$
                    {potInfo.valueAccumulated >= potInfo.minimumValue
                      ? formatValue(potInfo?.valueAccumulated)
                      : formatValue(potInfo?.minimumValue)}
                    <>
                      {potHandleIconIdentifier(potInfo)?.legend !== 'Aguardando resultado' ? (
                        potHandleIconIdentifier(potInfo)?.icon
                      ) : (
                        <>{!!potIndicatorIcon && <img src={`${image_server_path}/${potIndicatorIcon}`} alt="" />}</>
                      )}
                    </>
                  </PotAccumulatedPrice>
                </PotInfoContainer>

                <div className="flex justify-between mb-4 font-normal text-base text-[#C4C4C4] leading-xxl">
                  <p>{`${potInfo?.name}`}</p>
                  <PrizeText className="hidden sm:flex">Prêmio atual</PrizeText>
                </div>

                <div className="sm:hidden mb-6 flex flex-col items-end">
                  <PotAccumulatedPrice className="flex gap-3 text-2xl font-semibold leading-5">
                    R$
                    {potInfo.valueAccumulated >= potInfo.minimumValue
                      ? formatValue(potInfo?.valueAccumulated)
                      : formatValue(potInfo?.minimumValue)}
                    <>
                      {potHandleIconIdentifier(potInfo)?.legend !== 'Aguardando resultado' ? (
                        potHandleIconIdentifier(potInfo)?.icon
                      ) : (
                        <>{!!potIndicatorIcon && <img src={`${image_server_path}/${potIndicatorIcon}`} alt="" />}</>
                      )}
                    </>
                  </PotAccumulatedPrice>

                  <PrizeText className="flex">Prêmio atual</PrizeText>
                </div>

                {potInfo.description && potInfo.description !== '' && (
                  <div className="w-full rounded-16 mb-6 p-4 text-[#C4C4C4] bg-[#28293d80]">
                    <p>{potInfo.description}</p>
                  </div>
                )}

                <div className="flex flex-col gap-6">
                  {!!matches.length && (
                    <div>
                      <div className="flex items-center mb-4 gap-1 sm:gap-2">
                        <Champion className="w-5 h-4 sm:w-7 sm:h-6" />
                        <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Quem será o vencedor?</p>
                      </div>

                      <div
                        className={`w-full grid gap-4 ${isAtCategoryPage && '2xl:grid-cols-2'} ${
                          isPotPage && '2xl:grid-cols-2'
                        }`}
                      >
                        {matches.map((item: Match) => {
                          return (
                            <div className="bg-[#28293D] rounded-20 overflow-hidden p-4" key={item.id}>
                              {isAlreadyDone && (
                                <MatchSelector
                                  item={item}
                                  changeValue={handleSelectedTeam}
                                  disabled={isAlreadyDone}
                                  key={`${item.id}`}
                                  defaultValue={getSelectorDefaultValue(item.id)}
                                />
                              )}

                              {!isAlreadyDone && (
                                <MatchSelector
                                  changeValue={handleSelectedTeam}
                                  item={item}
                                  key={`${item.id}`}
                                  defaultValue={getSelectorDefaultValue(item.id)}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {!!matchOneVsMany.length && (
                    <div>
                      <div className="flex items-center mb-4 gap-1 sm:gap-2">
                        <Master className="w-5 h-4 sm:w-7 sm:h-6" />
                        <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Palpite de mestre</p>
                      </div>

                      <div className="flex flex-col gap-4">
                        {matchOneVsMany.map((item) => (
                          <div
                            className="flex flex-col justify-center items-center p-4 max-w-full bg-[#28293D] rounded-20"
                            key={item.id}
                          >
                            <h3 className="text-xl font-semibold mb-6 text-start w-full">{item.question}</h3>

                            <div className="grid sm:grid-cols-2 gap-3 w-full">
                              {item.options.map((option) => {
                                return (
                                  <div className="flex flex-row items-center" key={option.id}>
                                    <SelectorTeam
                                      handleSelect={() =>
                                        isAlreadyDone
                                          ? null
                                          : handleSelectedItem({ groupId: item.id, team: option.name })
                                      }
                                      fullWidth
                                      name={option.name}
                                      selected={option.value === option.result}
                                      teamImage={option.image ? option.image : ''}
                                      isLeftImage={true}
                                      disabled={isAlreadyDone}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {!!matchExactPoint.length && (
                    <div>
                      <div className="flex items-center mb-4 gap-1 sm:gap-2">
                        <Target className="w-5 h-5 sm:w-6 sm:h-6" />
                        <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Acerte na mosca</p>
                      </div>

                      <div
                        className={`w-full grid gap-4 ${isAtCategoryPage && '2xl:grid-cols-2'} ${
                          isPotPage && '2xl:grid-cols-2'
                        }`}
                      >
                        {matchExactPoint.map((item: TeamMatchPoint) => {
                          return (
                            <div className="bg-[#28293D] rounded-20 overflow-hidden p-4" key={item.id}>
                              {isAlreadyDone && (
                                <ExactMatchPoint
                                  item={item}
                                  updatePoint={(team, value) => {
                                    if (isAlreadyDone) return;
                                    handleUpdateMatchExactPoints({
                                      id: item.id,
                                      team,
                                      value,
                                    });
                                  }}
                                  disabled={isAlreadyDone}
                                  key={`${item.id}`}
                                />
                              )}

                              {!isAlreadyDone && (
                                <ExactMatchPoint
                                  updatePoint={(team, value) => {
                                    handleUpdateMatchExactPoints({
                                      id: item.id,
                                      team,
                                      value,
                                    });
                                  }}
                                  item={item}
                                  key={`${item.id}`}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex gap-2 sm:gap-6 items-center justify-between text-base relative mt-6">
                  <Timer3 finalAt={moment(potInfo?.dateFinalBet).toDate()} />

                  <div className="flex gap-6">
                    {windowSize.width > 1023 && (
                      <button
                        onClick={() => setOpenRules(true)}
                        className="font-normal grow basis-0 flex leading-xxl text-xs text-text-grayMenu"
                      >
                        <RiInformationFill className="w-[20px] text-white h-[30px] mr-[0.4em]" />
                        Regras
                      </button>
                    )}

                    {windowSize.width <= 1023 && (
                      <Link
                        to="/rules"
                        onClick={backToTop}
                        className="font-normal grow basis-0 flex leading-xxl text-xs text-text-grayMenu"
                      >
                        <RiInformationFill className="w-[20px] text-white h-[30px] mr-[0.4em]" />
                        Regras
                      </Link>
                    )}

                    <div className="relative">
                      <button
                        id="share"
                        onClick={() => setShowShareOptions(!showShareOptions)}
                        className="relative pl-4 grow basis-0 text-left text-xs leading-xxl"
                      >
                        <IoShareSocial className="absolute top-[50%] translate-y-[-50%] left-[-10px] w-[20px] h-[20px]" />
                        Compartilhar
                      </button>

                      {showShareOptions && (
                        <ShareLinks
                          popoverRef={popoverRef}
                          sharePot={() => sharePot(potInfo)}
                          potInfo={potInfo}
                          setShowShareOptions={setShowShareOptions}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {!fullBets && (
                  <span className="block text-xl text-state-fail w-full text-center">
                    Palpite em todos os cenários para poder confirmar seu pot
                  </span>
                )}
              </div>

              <div className="mt-6 w-full flex justify-between items-center">
                <CoinsContainer>
                  <div className="flex items-center gap-1 sm:gap-2">
                    <CoinsIcon />

                    <p className="text-sm sm:text-[1.125rem]">
                      {potInfo?.minimumBet === 1 ? `${potInfo?.minimumBet} Ficha` : `${potInfo?.minimumBet} Fichas`}
                    </p>

                    <button onClick={() => setInfoBallonCoins(!infoBallonCoins)} className="relative">
                      <InfoIcon />
                      {infoBallonCoins && <BallonCoinsPot />}
                    </button>
                  </div>
                </CoinsContainer>

                {!isAlreadyDone && (
                  <>
                    {!showContinueBetting && (
                      <Button
                        moreClasses="h-[52px] text-[#1C1C27] text-base"
                        size="sm"
                        label={timerExpired ? 'Encerrado' : isLoading ? 'jogando...' : 'Jogue agora'}
                        hierarchy="primary"
                        onClick={handleSendPot}
                        disabled={timerExpired || isLoading}
                      />
                    )}

                    {showContinueBetting && (
                      <button
                        className="max-w-full text-[#FF8E00] text-base font-semibold"
                        onClick={gotToPot}
                        disabled={timerExpired}
                      >
                        <p className="text-text-highlight uppercase flex items-center text-xs md:text-base">
                          {timerExpired ? 'Encerrado' : 'Continue jogando'}
                          {!timerExpired && <ArrowRight className="ml-4" />}
                        </p>
                      </button>
                    )}

                    {isAlreadyDone && (
                      <Button
                        moreClasses="h-[52px] text-[#1C1C27] text-base"
                        size="sm"
                        label={'Fazer novos palpites'}
                        hierarchy="primary"
                        onClick={redirectToHome}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </PotContainer>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default Pot;
