import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { AccountCreationContainer, AccountSidebarContainer, FormContainer, LoginBackgroundImage } from './styles';
import { RootState, useDispatch } from '../../store';
import { signInWithFacebook } from '../../store/reducers/user/userSlice';
import LoginForm from '../../components/AuthForms/LoginForm';
import { useSelector } from 'react-redux';
import { getCmsHtml } from '../../service/cms/cms.service';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { ErrorAlert, SuccessAlert } from '../../components/BasicAlerts';
import { ButtonAuthGoogle } from '../../components/ButtonAuthGoogle';
import BirthDateForm from '../../components/AuthForms/BirthDateForm';
import ButtonLoginSocial from '../../components/ButtonLoginSocial/ButtonLoginSocial';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SessionControl } from '../../session/SessionControl';

const Login = () => {
  const user = useSelector((state: RootState) => state.user);
  const [leftSideContent, setLeftSideContent] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [message, setMessage] = useState('');
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const [mode, setMode] = useState('login');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleCleanStatusRequest() {
    setMessage('');
    setStatusRequest('');
  }

  const responseFacebook = async (res: any) => {
    await dispatch(signInWithFacebook(res.accessToken));
    const token = SessionControl.getToken();
    if (token) navigate('/');
  };

  const handleChangeMode = (mode: any) => {
    navigate(`/${mode}`);
  };

  useEffect(() => {
    if (location.search.includes('register')) setMode('register');

    if (user.token) navigate('/');
  }, [navigate, user.token]);

  const { LOGIN_PAGE_TYPEID } = cmsPagesEnum;

  useEffect(() => {
    getCmsHtml(LOGIN_PAGE_TYPEID).then((res) => setLeftSideContent(res));
  });

  return (
    <AccountCreationContainer>
      <></>
      <LoginBackgroundImage
        className="bg-white hidden md:block"
        dangerouslySetInnerHTML={{ __html: leftSideContent }}
      />

      <AccountSidebarContainer isHeightAuto={isGoogleAuth} className={window.innerWidth < 600 ? 'overflow-hidden' : ''}>
        {!isGoogleAuth && (
          <FormContainer>
            <Link to="/">
              <img
                src="images/logo.svg"
                alt="moneypot"
                className={
                  window.innerWidth < 600 ? 'mb-[24px] max-w-[167px] w-full ' : 'mb-[48px] max-w-[167px] w-full'
                }
              />
            </Link>
            <h1 className="text-[28px] font-semibold text-white">Bem-vindo</h1>

            <div className="flex w-full gap-3 ">
              {/*<FacebookLogin*/}
              {/*  autoLoad={false}*/}
              {/*  appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}*/}
              {/*  fields="first_name,last_name,email,birthday,age_range,picture"*/}
              {/*  callback={responseFacebook}*/}
              {/*  render={(renderProps: any) => <ButtonLoginSocial provider="facebook" onClick={renderProps.onClick} />}*/}
              {/*/>*/}

              <ButtonAuthGoogle
                setMessage={(msg) => setMessage(msg)}
                setStatusRequest={(msg) => setStatusRequest(msg)}
                onSuccess={() => null}
                handleShowModalBirthDate={() => setIsGoogleAuth(true)}
              />
            </div>

            <LoginForm onChangeMode={handleChangeMode} />
          </FormContainer>
        )}

        {isGoogleAuth && <BirthDateForm handleCloseBirthForm={() => setIsGoogleAuth(false)} onSuccess={() => null} />}
      </AccountSidebarContainer>
    </AccountCreationContainer>
  );
};

export default Login;
