import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { getCmsHtml } from '../../service/cms/cms.service';
import { CMSModalContent } from './styles';

import { ReactComponent as IconRules } from '../../utils/icons/rulesIcon.svg';

interface IModalAlertProps {
  openRules: boolean;
  setOpenRules: Function;
}

const ModalRules = ({ setOpenRules, openRules }: IModalAlertProps) => {
  const [cmsContent, setCmsContent] = useState<string>('');
  const { RULES_CONTENT_TYPEID } = cmsPagesEnum;

  useEffect(() => {
    getCmsHtml(RULES_CONTENT_TYPEID).then((res) => {
      setCmsContent(res);
    });
  }, []);

  const handleClickToClose = (target: HTMLDivElement) => {
    if (target.id === 'background') {
      setOpenRules(false);
    }
  };
  return (
    <div
      className={
        openRules
          ? 'block w-full h-full z-50 bg-[#00000090] fixed flex justify-center items-center top-0 left-0'
          : 'hidden w-[400px] h-[300px] fixed'
      }
      id={'background'}
      onClick={(e) => handleClickToClose(e.target as HTMLDivElement)}
    >
      <CMSModalContent>
        <button className="text-white p-2 absolute right-3 top-2" onClick={() => setOpenRules(false)}>
          <MdClose size={18} />
        </button>

        <div>
          <IconRules />
          <h2>Regras do Pot</h2>
          <div>
            <h4>O que é?</h4>
            <p>
              O Pot funciona como um bolão. Quanto mais palpitadores, maior o prêmio. A MoneyPot garante um prêmio base
              independente do número de palpitadores.
            </p>

            <h4>Como os palpites funcionam?</h4>
            <p>
              Você só pode palpitar até o início do primeiro jogo da rodada do Pot. Aposte quantas vezes quiser, com
              quantos palpites quiser. Ganha quem acertar todos os resultados da rodada.
            </p>
            <h4>E se mais de uma pessoa acertar?</h4>
            <p>Havendo mais de um acertador, o prêmio será dividido igualmente entre eles.</p>
            <h4>Quando vou receber meu prêmio?</h4>
            <p>
              Acertou tudo? Sucesso! Sua recompensa será transferida automaticamente ao fim da rodada para sua carteira
              virtual. Com o dinheiro lá, você pode sacar quando quiser. Lembrando que o dinheiro pode demorar até 48h
              pra cair na conta dependendo dos prazos da instituição bancária.
            </p>
          </div>
          {/* <CMSPageContent dangerouslySetInnerHTML={{ __html: cmsContent }} /> */}
        </div>
      </CMSModalContent>
    </div>
  );
};

export default ModalRules;
