import { HTMLAttributes, SetStateAction, Dispatch } from 'react';
import { useLocation } from 'react-router-dom';

import Carrousel from '../../components/Carrousel/Carrousel';

import { sportOptions } from '../../utils/sportOptions';

interface ICategorySelector extends HTMLAttributes<HTMLDivElement> {
  setCategory: Dispatch<SetStateAction<string>>;
  selected: string;
  categoriesAvailable?: string[];
  thereIsHightLight?:boolean
}

const CategorySelector = ({
  setCategory,
  selected,
  categoriesAvailable = [],
  thereIsHightLight = true,}: ICategorySelector) => {
  const location = useLocation();

  const appPath = location.pathname;

  const isCategoryPage = appPath.includes('category');


  const todosIndex = sportOptions.findIndex((sport) => sport.name === 'todos');
  if (todosIndex !== -1) {
    const todosSport = sportOptions.splice(todosIndex, 1)[0];
    sportOptions.unshift(todosSport);
  }
  
  return (
    <Carrousel>
      {sportOptions.map((sport, index) => {
        if (!categoriesAvailable.includes(sport.name) && sport.name !== 'todos') return null;
        if (isCategoryPage && sport.name === 'todos') return null;
        return (
          <button
            key={index}
            disabled={sport.name === 'todos' && !thereIsHightLight}
            onClick={() => setCategory(sport.name)}
            className="flex flex-col items-center md:mt-[8px]"
          >
            <div
              className={`${selected !== sport.name ? 'bg-[#FF8E00]' : 'bg-background-highlight'}
              bg-background-400
              h-[78px] w-[78px] p-[23px] rounded-[32px] ${
                sport.name === 'todos' && !thereIsHightLight ? 'opacity-30' : ''
              }`}
            >
              <sport.icon
                className={`${selected === sport.name ? 'fill-text-blueBg' : 'fill-text-white'} h-[32px] w-[32px]`}
              />
            </div>
            <p
              className={`${
                selected === sport.name ? 'text-text-highlight' : 'text-text-white'
              } text-center font-outfit text-[16px] leading-5 capitalize mt-[10px] w-[80px] ${
                sport.name === 'todos' && !thereIsHightLight ? 'opacity-30' : ''
              }`}
            >
              {sport.title || sport.name}
            </p>
          </button>
        );
      })}
    </Carrousel>
  );
};

export default CategorySelector;
