import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { getCmsHtml } from '../../service/cms/cms.service';
import { rules } from '../Account/helper';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import Button from './../../components/Button/Button';

import Input from '../../components/Input/Input';
import { validateEmail } from '../../utils/validates';
import { recoverPassword } from '../../service/user/user.service';
import { ErrorAlert, SuccessAlert } from '../../components/BasicAlerts';
import { Link, useNavigate } from 'react-router-dom';
import {
  AccountCreationContainer,
  AccountSidebarContainer,
  FormContainer,
  LoginBackgroundImage,
} from '../Login/styles';
import { BiEnvelope } from 'react-icons/bi';
import { Container, ErrorMessage } from './styles';
import { SimpleFeedbackText } from '../../components/SimpleFeedBackText';

const ForgetPassword = () => {
  const [leftSideContent, setLeftSideContent] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [errorMessage, setErrorMessage] = useState('');

  const { LOGIN_PAGE_TYPEID } = cmsPagesEnum;

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validateEmail(email)) {
      setStatusRequest('');
      setMessage('');
      try {
        setIsLoading(true);
        const response = await recoverPassword(email);
        if (response.status === 200) {
          setStatusRequest('success');
          setMessage('Enviamos um email contendo as informações para recuperar sua senha!');
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
          setStatusRequest('error');
          return setErrorMessage('Ops! E-mail não cadastrado.');
        }
      } catch (e) {
        setIsLoading(false);
      }
    } else {
      // setErrorMessage(true);
    }
  };
  useEffect(() => {
    setErrorMessage('');
    // setMessage('')
  }, [email]);

  useEffect(() => {
    getCmsHtml(LOGIN_PAGE_TYPEID).then((res) => setLeftSideContent(res));
  });

  return (
    <AccountCreationContainer>
      <LoginBackgroundImage
        className="bg-white hidden md:block"
        dangerouslySetInnerHTML={{ __html: leftSideContent }}
      />
      <AccountSidebarContainer>
        <FormContainer>
          <Link to="/">
            <img src="images/logo.svg" alt="moneypot" className="mb-[48px] max-w-[167px] w-full" />
          </Link>

          {statusRequest === 'success' && (
            <SimpleFeedbackText text="Um e-mail foi enviado para você com os procedimentos para recuperação da senha." />
          )}
          <form className="flex flex-col w-full gap-[24px]">
            <h1 className="text-2xl font-semibold text-white">Esqueceu sua senha?</h1>

            <Container className="flex-col w-full">
              <Input
                isTextHelper={true}
                className="textClass inputBottom w-full"
                id="outlined-helperText"
                label="E-mail"
                name="email"
                placeholder="Email"
                type="email"
                error={!!errorMessage}
                onChange={(e: any) => setEmail(e.target.value)}
                rules={[rules.emailRule]}
                icon={BiEnvelope}
              />
              {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Container>

            <div className="text-[14px] text-left text-[#C4C4C4] w-[300px] max-w-[100%] pl-[24px] pr-[24px]">
              Você irá receber um e-mail no endereço informado acima contendo o procedimento para criar uma nova senha
              para esse usuário.
            </div>

            <div className="max-w-[327px]">
              <Button
                moreClasses="w-full h-[52px] font-semibold text-[16px] text-black"
                onClick={handleSubmit}
                disabled={!validateEmail(email) || !!errorMessage}
                label="Recuperar"
                hierarchy="primary"
              />
            </div>
            {/* <div className="flex justify-center align-center w-full">
              <p className="text-sm font-extralight text-center text-white">
                Não tem uma conta?
                <span
                  className="text-[#FF8E00] cursor-pointer hover:underline ml-1"
                  onClick={() => navigate('/register')}
                >
                  Criar nova conta
                </span>
              </p>
            </div> */}
            <button className="mt-[16px] w-[90%]  text-white" onClick={() => navigate('/login')}>
              Voltar
            </button>
          </form>
        </FormContainer>
      </AccountSidebarContainer>
    </AccountCreationContainer>
  );
};

export default ForgetPassword;
