import { HTMLAttributes } from 'react';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { PageLayoutChildrenContainer, PageLayoutContainer } from './styles';

type IPageContainerProps = HTMLAttributes<HTMLDivElement>;

const PageContainer = ({ children }: IPageContainerProps) => {
  return (
    <PageLayoutContainer>
      <Header />

      <PageLayoutChildrenContainer className="container-with-content">
        {children}

        <Footer logoUrl="/images/logo.png" />
      </PageLayoutChildrenContainer>
    </PageLayoutContainer>
  );
};

export default PageContainer;
