import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../components/Button/Button';
import { ReactComponent as BoxCoins } from '../../utils/icons/boxCoins.svg';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

const ErrorCoinsPage = () => {
  const navigate = useNavigate();
  const windowSize = useGetWindowSize()

  return (
    <PageContainer>
      
        <div className="w-full flex flex-col justify-center items-center min-h-[60vh] p-[8px] relative rounded-[20px]">
          <div>
            <BoxCoins className="w-[82px] h-[82px]" />
          </div>
          <div className="max-w-full  flex flex-col justify-center">
            <h2 className="mt-[16px] max-w-full text-center center text-white text-[24px] font-semibold">
              Você não tem fichas suficientes
            </h2>
            <p className="text-white text-center text-[16px] font-light">Deseja adicionar fichas à sua carteira para</p>
            <p className=" text-white text-center text-[16px] font-light">continuar jogando?</p>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            <Button
              hierarchy="primary"
              label="Comprar fichas"
              size="md"
              moreClasses=" mt-5 mb-3 text-[15px] text-[#1C1C27]  font-bold w-[196px] h-[49px]"
              onClick={() => {
                navigate('/coins');
              }}
            />

            <button className="mb-3" onClick={() => navigate(-1)}>
              Voltar
            </button>
          </div>
        </div>
      
    </PageContainer>
  );
};

export default ErrorCoinsPage;
