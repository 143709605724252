import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useHideScrollWhenShowModal } from '../../hooks/useHideScrollWhenShowModal';
import { getUserInfo } from '../../service/user/user.service';
import { RootState } from '../../store';
import { UserType } from '../../typescript/user';
import { normalizePotUrl } from '../../utils/normalize-name';
import BirthDateForm from '../AuthForms/BirthDateForm';
import LoginForm from '../AuthForms/LoginForm';
import RegisterForm from '../AuthForms/RegisterForm';
import { ErrorAlert } from '../BasicAlerts';
import { ButtonAuthGoogle } from '../ButtonAuthGoogle';
import ModalCoins from '../ModalCoins';
import { AccountSidebarContainer } from './../../pages/Login/styles';
import { ModalAuthOverlay, ModalAuthContainer, ModalAuthButtonGroupContainer, ModalAuthTitle } from './styles';
import CryptoJS from 'crypto-js';
import { SessionControl } from '../../session/SessionControl';

interface ModalAuthenticationProps {
  handleClose: () => void;
  isRedirect?: boolean;
}

const ModalAuthentication = ({ handleClose, isRedirect = true }: ModalAuthenticationProps) => {
  const [authMode, setAuthMode] = useState<'login' | 'register'>('login');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const { user } = useSelector((state: RootState) => state);
  const [showModalCoins, setShowModalCoins] = useState(false);
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const { updateAppState } = useUserContext();

  const windowSize = useGetWindowSize();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const isMobile = windowSize.width <= 600;

  const handleError = (msg: string) => {
    setStatusRequest('error');
    setMessage(msg);
  };

  const handleSuccess = async () => {
    const userInfo: UserType = await getUserInfo();
    const data = SessionControl.getPotChoices();
    const potInfo = data ? JSON.parse(data) : null;

    updateAppState('group');
    handleClose();

    if (potInfo) {
      if (userInfo.usersCoins[0].value >= potInfo.pot.minimumBet) {
        // const championShip = normalizePotUrl(potInfo.pot.championship);
        // const title = normalizePotUrl(potInfo.pot.name);
        //
        // const newUrl = `/pot/${championShip}/${title}`;

        const potId = potInfo.id.toString(); // Convertendo para string

        const encryptionKey = 'secrethero99';
        const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
        const e64 = CryptoJS.enc.Base64.parse(b64);
        const eHex = e64.toString(CryptoJS.enc.Hex);

        const newUrl = `/pot/${eHex}`;

        handleClose();
        if (isRedirect) {
          navigate(newUrl);
        }
      } else {
        if (isMobile) {
          handleClose();
          navigate('/coins-error');
        } else {
          setShowModalCoins(true);
        }
      }
    } else {
      handleClose();
    }
    setIsGoogleAuth(false);
  };

  const closeCoinsModal = (state: boolean) => {
    setShowModalCoins(state);
    handleClose();
  };

  useEffect(() => {
    if (user.token === '') return;
    handleSuccess();
  }, [user]);

  return (
    <>
      <ModalCoins openAlert={showModalCoins} setOpenAlert={closeCoinsModal} />

      {!showModalCoins && (
        <ModalAuthOverlay modeAuth={authMode}>
          {statusRequest === 'error' && <ErrorAlert title={'Erro'} message={message} onClose={() => null} autoClose />}
          <ModalAuthContainer modeAuth={authMode}>
            <div className="content-container">
              {!isGoogleAuth && (
                <>
                  <img src="/images/logo.svg" alt="moneypot" className="mb-[48px] max-w-[167px] w-full" />

                  <ModalAuthTitle>Bem-vindo</ModalAuthTitle>

                  <ModalAuthButtonGroupContainer>
                    <ButtonAuthGoogle
                      isAtModal
                      // isRegister={authMode === 'register'}
                      onSuccess={() => null}
                      handleShowModalBirthDate={() => setIsGoogleAuth(true)}
                      setMessage={(msg) => setMessage(msg)}
                      setStatusRequest={(msg) => setStatusRequest(msg)}
                    />
                  </ModalAuthButtonGroupContainer>

                  {authMode === 'login' && (
                    <LoginForm
                      onChangeMode={(mode) => setAuthMode(mode)}
                      onError={handleError}
                      isAtModal
                      onSuccess={() => null}
                      handleClose={handleClose}
                    />
                  )}

                  {authMode === 'register' && (
                    <>
                      {!isGoogleAuth && (
                        <RegisterForm
                          onChangeMode={(mode) => setAuthMode(mode)}
                          onError={handleError}
                          isAtModal
                          onSuccess={() => null}
                          handleClose={handleClose}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {isGoogleAuth && (
                <AccountSidebarContainer>
                  <BirthDateForm onSuccess={() => null} handleCloseBirthForm={() => setIsGoogleAuth(false)} isAtModal />
                </AccountSidebarContainer>
              )}
            </div>
          </ModalAuthContainer>
        </ModalAuthOverlay>
      )}
    </>
  );
};

export default ModalAuthentication;
