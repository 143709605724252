import { HTMLAttributes, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { ReactComponent as CoinsIcon } from '../../utils/icons/coins.svg';
import { formatValue } from '../../utils/types';
import Button from '../Button/Button';
import { Loading } from '../Loading/Loading';
import { ButtonContainer, CoinsAndCredit } from './styles';

type TopUserType = HTMLAttributes<HTMLDivElement>;

interface ITopUserInfoProps extends TopUserType {
  handleCloseMobileMenu?: () => void;
}

const TopUserInfo = ({ className, handleCloseMobileMenu = () => null }: ITopUserInfoProps) => {
  const { userLogged } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const windowSize = useGetWindowSize();

  useEffect(() => {
    if (userLogged?.login) setIsLoading(false);
  }, [userLogged]);

  return (
    <div
      className={`${className} relative mt-[40px] mb-[40px] lg:justify-end lg:gap-[100px] flex flex-row justify-between w-full text-text-grayMenu`}
    >
      <>{isLoading && <Loading isLoading={isLoading} />}</>
      <ButtonContainer className="btn-grou-wallet">
        {!!userLogged && (
          <CoinsAndCredit>
            <h1 className="label">
              <CoinsIcon />
              {userLogged.usersCoins[0].value > 0 ? userLogged.usersCoins[0].value : 0}{' '}
              {userLogged.usersCoins[0].value === 1 ? 'ficha' : 'fichas'}
            </h1>

            <h1 className="label">
              <span className="dollar-sign-icon">$</span>
              <span className="currency-l">R$</span>
              {userLogged?.wallet ? formatValue(userLogged?.wallet[0].value) : '0'}
            </h1>
          </CoinsAndCredit>
        )}

        {windowSize.width > 1024 && (
          <Button
            moreClasses="flex justify-center items-center text-center md:max-w-[150px] w-[131px] h-[32px] mt-3 font-semibold text-sm"
            hierarchy="primary"
            label="Comprar fichas"
            size="sm"
            onClick={() => {
              handleCloseMobileMenu();
              navigate('/coins');
            }}
          />
        )}

        {windowSize.width <= 1024 && (
          <Button
            moreClasses="flex justify-center items-center text-center text-[40px] max-w-[40px] w-full h-[40px] mt-3 font-thin"
            hierarchy="primary"
            label="+"
            size="sm"
            onClick={() => {
              handleCloseMobileMenu();
              navigate('/coins');
            }}
          />
        )}
      </ButtonContainer>
    </div>
  );
};

export default TopUserInfo;
