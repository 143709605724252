import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { GROUP_TOKEN_KEY } from '../../constants/local-storage-keys';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { checkGroupTokenAndParticipate, getGrouByUser } from '../../service/group/group';
import { getUserInfo } from '../../service/user/user.service';
import { RootState, useDispatch } from '../../store';
import { currentGroupParticipate, participateGroupWithLink } from '../../store/reducers/group/groupSlice';
import { IUserReducer } from '../../store/reducers/user/types';
import { normalizeName } from '../../utils/normalize-name';
import Group from './Group';
import JoinGroup from './JoinGroup';
import { ShareGroupContainer } from './styles';
import { SessionControl } from '../../session/SessionControl';

const GroupPage = () => {
  const { updateAppState } = useUserContext();
  const location = useLocation();
  const pathname = location.pathname;
  const [isItMyGroup, setIsItMyGroup] = useState<boolean | null>(null);
  const [groupDetails, setGroupDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { groupToken } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo }: IUserReducer = useSelector((state: RootState) => state.user);

  async function saveGroupInStore() {
    await dispatch(participateGroupWithLink({ url: pathname }));
    SessionControl.setGroupName(pathname);
    if (!userInfo.id) {
      navigate('/login');
    }
  }

  async function handleGroupInfo() {
    const userInfo = await getUserInfo();

    if (userInfo.id) {
      const response = await getGrouByUser(userInfo.id);

      if (normalizeName(response.data.name) === groupToken) {
        setGroupDetails(response.data);
        setIsItMyGroup(true);
        setIsLoading(false);
        return;
      }

      if (userInfo.userGroup.length) {
        const userGroupParticipatePathname = new URL(userInfo.userGroup[0].invite).pathname;
        const currentPathname = window.location.pathname;

        if (userGroupParticipatePathname === currentPathname) {
          setGroupDetails(userInfo.userGroup[0]);
          setIsItMyGroup(false);
          setIsLoading(false);
        } else {
          navigate('/pageNotFound');
        }
        return;
      }

      SessionControl.setGroupToken(JSON.stringify(groupToken));
      await checkGroupTokenAndParticipate(userInfo).then(async (response) => {
        if (response.data === 'grupo não encontrado') {
          return navigate('/pageNotFound');
        }
        if (response.status === 200) {
          const { userGroup } = await getUserInfo();
          dispatch(currentGroupParticipate(userGroup[0]));
          setGroupDetails(userGroup[0]);
          setIsItMyGroup(false);
          setIsLoading(false);
        }
      });
      SessionControl.clearGroupToken();
    }
  }

  useEffect(() => {
    saveGroupInStore();
    handleGroupInfo();
    updateAppState('group info updated');
  }, [pathname]);

  if (isItMyGroup === null || isLoading || !groupDetails) {
    return (
      <PageContainer>
        <ShareGroupContainer>
          <div className="container-title">
            <h2>Carregando informações</h2>
          </div>
        </ShareGroupContainer>
      </PageContainer>
    );
  }

  return isItMyGroup ? <Group groupDetails={groupDetails} /> : <JoinGroup groupDetails={groupDetails} />;
};

export default GroupPage;
