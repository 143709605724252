

export const removeCharsOnHtml = (content: string): string => {
    const result = content.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/code/g, "")
      .replace(/pre/g, "")
      .replace(/pre/g, "")
      .replace(/code/g, "")
      .replace(/class="language-markup"/g, "")
      .replace(/<>/g, "")
      .replace(/< >/g, "")

      return result
}