import styled from 'styled-components';

export const BannerCategory = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.5) 97.39%);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: cener;
  justify-content: flex-end;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 200px;
    margin-bottom: 0px;
  }

  .banner-category {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 600px) {
      margin-right: 0;
    }

    p {
      width: 100%;
      height: 100%;
    }

    picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 782px) {
        object-fit: fill;
      }
    }
  }

  .banner-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    right: 50px;
    display: none;

    @media (max-width: 600px) {
      right: 0;
    }
  }

  h1 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 120px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: 600px) {
      font-size: 70px;
    }
  }

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
`;

export const ChampionshipName = styled.h1`
  width: 100%;
  height: 60px;
  background: linear-gradient(90.52deg, #404160 31.54%, rgba(33, 33, 33, 0) 167.29%);
  border-radius: 8px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 32px;
  margin-bottom: 30px;
`;

export const IconCategoryContainer = styled.h1`
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  @media (max-width: 650px) {
    margin-top: 0;
    margin-bottom: 0px;
  }
`;
