import { getGrouByUser } from '../service/group/group';

export async function useGroupOwnershipCheck(userId: number, groupURL: string) {
  const { data } = await getGrouByUser(userId);

  if (data.name === groupURL) {
    return true;
  }

  return false;
}
