import {
  ModalAlertContainer,
  ModalAlertSaqueSeparator,
  ModalAlertSaqueSimpleText,
  ModalAlertSaqueTitle,
  ModalAlertSaqueTable,
  ModalAlertSaqueTableItem,
  ModalAlertSaqueTableItemValue,
} from './styles';
import { ModalOverlay, ButtonCloseModal, ButtonOutlined, ButtonContained, ModalButtonGroup } from './../styles';
import { MdClose } from 'react-icons/md';
import { CheckCircle } from './../../../utils/icons/checkCircle';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface SaqueData {
  title: string;
  data: string;
}

interface SaqueType {
  transationCode: string;
  solicitationDate: string | Date;
  time: string;
  recipient: string;
  cpf: string;
  bank: string;
  bancAgency: string;
  accountNumber: string;
}

interface ModalAlerSaqueProps {
  onClose: () => void;
  saqueInfo: SaqueType;
}

const ModalAlerSaque = ({ onClose, saqueInfo }: ModalAlerSaqueProps) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const navigate = useNavigate();

  const updateSize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  });

  const saqueData: SaqueData[] = [
    { title: 'Código da transação', data: saqueInfo.transationCode },
    { title: '', data: '' },
    { title: '', data: '' },
    {
      title: 'Data da solicitação',
      data: moment(saqueInfo.solicitationDate).date().toString()
    },
    { title: 'Horário', data: saqueInfo.time },
    { title: '', data: '' },
    { title: 'Favorecido', data: saqueInfo.recipient },
    { title: 'CPF', data: saqueInfo.cpf },
    { title: 'Banco', data: saqueInfo.bank },
    { title: 'Agência', data: saqueInfo.bancAgency },
    { title: 'Conta', data: '76352-1' },
  ];

  const isMobile = windowSize <= 600;

  const seeMorePots = () => {
    if (isMobile) {
      navigate('category/futebol');
    } else {
      navigate('category/futebol');
    }
  };

  const backHome = () => {
    if (isMobile) {
      onClose();
    } else {
      navigate('/');
    }
  };

  return (
    <ModalOverlay>
      <ModalAlertContainer>
        {!isMobile && (
          <ButtonCloseModal onClick={onClose}>
            <MdClose color="#fff" size="24" />
          </ButtonCloseModal>
        )}

        <CheckCircle />

        <ModalAlertSaqueTitle>Saque solicitado</ModalAlertSaqueTitle>
        <ModalAlertSaqueTitle>R$ 173,98</ModalAlertSaqueTitle>
        <ModalAlertSaqueSimpleText className="simple-text">
          Em até 48h você receberá em sua conta bancária
        </ModalAlertSaqueSimpleText>
        <ModalAlertSaqueSeparator />

        <ModalAlertSaqueTable>
          {saqueData.map((item: SaqueData) => (
            <ModalAlertSaqueTableItem key={item.title}>
              <ModalAlertSaqueSimpleText>{item.title}</ModalAlertSaqueSimpleText>
              <ModalAlertSaqueTableItemValue>{item.data}</ModalAlertSaqueTableItemValue>
            </ModalAlertSaqueTableItem>
          ))}
        </ModalAlertSaqueTable>

        <ModalButtonGroup>
          <ButtonOutlined onClick={backHome}>{isMobile ? 'voltar' : 'Ir para a home'}</ButtonOutlined>
          <ButtonContained onClick={seeMorePots}>{isMobile ? 'Comprar mais fichas' : 'Ver mais pots'}</ButtonContained>
        </ModalButtonGroup>
      </ModalAlertContainer>
    </ModalOverlay>
  );
};

export default ModalAlerSaque;
