import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';
import { Game } from '../../typescript/match';
import { NETWORKS, shareLinkToNetworks } from './helper';
import { ButtonCpyContainer } from './styles';
import CryptoJS from 'crypto-js';

interface ShareLinksProps {
  potInfo: Game;
  popoverRef: React.MutableRefObject<HTMLDivElement | null>;
  setShowShareOptions: Dispatch<SetStateAction<boolean>>;
  sharePot: () => void;
}

export const ShareLinks = ({ popoverRef, sharePot, potInfo, setShowShareOptions }: ShareLinksProps) => {
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  }, [linkCopied]);

  const copyPotUrlToClipboard = () => {
    const potId = potInfo.id.toString(); // Convertendo para string

    const encryptionKey = 'secrethero99';
    const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);

    const newUrl  = `/pot/${eHex}`;

    const url =
        process.env.REACT_APP_ENVIRONMENT === 'PROD'
            ? `${process.env.REACT_APP_PROD_WEB_URL}${newUrl}`
            : `${process.env.REACT_APP_HML_WEB_URL}${newUrl}`;

    navigator.clipboard.writeText(url)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Erro ao copiar para a área de transferência:', error));
  };



  return (
      <div
          ref={popoverRef}
          className="absolute flex flex-col items-center center w-[147px] bg-[#28293D] overflow-hidden top-10 right-0 height-auto rounded-20 z-50"
      >
        <ButtonCpyContainer
            copied={linkCopied}
            onClick={copyPotUrlToClipboard}
            className="w-[80%] h-10 cursor-pointer text-sm flex flex-row items-center flex-first border-b-2 border-solid border-[#1C1C27]"
        >
          <MdOutlineContentCopy className="w-[20px] text-white h-[30px] mr-[0.4em]" />
          Copiar link
        </ButtonCpyContainer>

        {NETWORKS.map((item) => (
            <a
                key={item.title}
                target="blank"
                rel="noopener noreferrer"
                href={shareLinkToNetworks({
                  network: item.title,
                  potInfo,
                })}
                className="w-[80%] h-10 cursor-pointer text-sm flex flex-row items-center flex-first border-b-2 border-solid border-[#1C1C27]"
                onClick={() => setShowShareOptions(false)}
            >
              <item.icon className="w-[20px] text-white h-[30px] mr-[0.4em]" />
              {item.title}
            </a>
        ))}
      </div>
  );
};
