import styled from '@emotion/styled';

export const WithdrawPageContainer = styled.div`
  max-width: 678px;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 24px;

  @media (max-width: 500px) {
    margin-bottom: 20px;
    margin-top: 0px;
  }
`;

export const WithdrawPageHeader = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

export const Title = styled.h2`
  margin-bottom: 18px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 25px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const WithdrawType = styled.div`
  max-width: 678px;
  height: 58px;
  width: 100%;
  background: linear-gradient(90.52deg, #404160 31.54%, rgba(33, 33, 33, 0) 167.29%);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  cursor: pointer;

  label {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

interface CustomRadioButtonProps {
  checked: boolean;
}

export const CustomRadioButton = styled.button<CustomRadioButtonProps>`
  outline: none;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  border: 1px solid ${({ checked }) => (checked ? '#ffc070' : '#c4c4c4')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;

  .radio-checked {
    background: #ffc070;
    height: 9px;
    width: 9px;
    border-radius: 100%;
  }
`;

export const WithdrawTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
`;

export const WithdrawSubTitle = styled.h3`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

export const WithdrawNotes = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  h1,
  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ff8e00;
    margin-bottom: 5px;
  }

  p {
    color: #fff;
    font-weight: 400;
  }
`;

export const WithdrawButton = styled.button`
  width: 130px;
  height: 49px;
  border: none;
  outline: none;
  background: #ff8e00;
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #1c1c27;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
