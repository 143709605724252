import { useEffect, useState } from 'react';
import CategoryMatch from '../../components/CategoryMatch';
import ErrorComponent404 from '../../components/ErrorComponent404';
import ModalCoins from '../../components/ModalCoins';
import ModalRules from '../../components/ModalRules';
import Pot from '../../components/Pot/Pot';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { getPublicPots } from '../../service/pots/pots.service';
import { Game } from '../../typescript/match';

const PageNotFound = () => {
  const [selectedCategory, setSelectedCategory] = useState('pots em alta');
  const [potList, setPotList] = useState<Game[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModalRules, setOpenModalRules] = useState(false);

  async function getPublic() {
    try {
      const response = await getPublicPots('?expire_in=48&&status=Em Andamento&take=4&highest_value=DESC');

      setPotList(response.data.data);
    } catch (error) {
      /* empty */
    }
  }

  useEffect(() => {
    getPublic();
  }, []);

  return (
    <div className="relative">
      <ModalCoins setOpenAlert={setOpenAlert} openAlert={openAlert} />
      <ModalRules openRules={openModalRules} setOpenRules={setOpenModalRules} />
      <PageContainer>
        <ErrorComponent404 />
        {
          <div className="mb-6">
            <CategoryMatch icon={'fireIcon'} category={'pots em alta'} />
          </div>
        }
        <div className="grid lg:grid-cols-1 xl:grid-cols-2 sm:gap-x-6 md:gap-x-2 lg:gap-x-6 xl:gap-x-2 2xl:gap-x-6 mb-20">
          {selectedCategory &&
            potList.map((pot) => (
              <Pot
                setOpenModal={setOpenAlert}
                openModal={false}
                openRules={openModalRules}
                setOpenRules={setOpenModalRules}
                potInfo={pot}
                key={pot.id}
                isAlreadyBet={false}
              />
            ))}
        </div>
      </PageContainer>
    </div>
  );
};

export default PageNotFound;
