import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { ModalAlertCheckout, ModalAlertCheckoutContentContainer } from '../../components/GenericModalAlert';
import { useGetGameMatches } from '../../hooks/useGetGameMatches';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { getCmsHtml } from '../../service/cms/cms.service';
import { getPublicBanner } from '../../service/pots/pots.service';
import { firstAccess, getUserInfo } from '../../service/user/user.service';
import { RootState } from '../../store';
import { MatchPotType } from '../../typescript/match';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { sportOptions } from '../../utils/sportOptions';
import CategoryMatch from '../CategoryMatch';
import CategorySelector from '../CategorySelector/CategorySelector';
import ModalCoins from '../ModalCoins';
import ModalRules from '../ModalRules';
import HomeGameInstruction from './HomeGameInstructions';
import HotPots from './HotPots';
import SelectedCategory, { ListPotFiltered } from './SelectedCategory';
import { CarouselContainer, HomeBanner, HomeLessPotList, HomePotList } from './styles';
import { SessionControl } from '../../session/SessionControl';

const LoggedHome = () => {
  const { userLogged } = useUserContext();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const { categoriesAvailable, isLoadingMatch, selectMatch } = useGetGameMatches();
  const [mainBanner, setMainBanner] = useState<string>('');
  const [isLoadinBanner, setIsLoadingBanner] = useState(false);
  const { token } = useSelector((state: RootState) => state.user);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModalRules, setOpenModalRules] = useState(false);
  const [bannerContent, setbannerContent] = useState('');
  const [statusfirstAccess, setStatusFirstAccess] = useState(false);
  const navigate = useNavigate();
  const selected = sportOptions.find((data) => data.name.toLowerCase() === selectedCategory.toLowerCase());
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const [thereIsHighlight, setThereIsHighligth] = useState(false);

  async function firstLogin() {
    if (userLogged) {
      try {
        const response = await getUserInfo();
        setStatusFirstAccess(response.firstAccess);
      } catch (error: any) {
        /* empty */
      }
    }
  }

  async function removeFirstAccess() {
    if (userLogged) {
      await firstAccess(userLogged?.id);
    }
    setStatusFirstAccess(false);
  }

  async function confirmFirstAccessBuyCoins() {
    if (userLogged) {
      await firstAccess(userLogged?.id);
    }
    navigate('/category/futebol');
    setStatusFirstAccess(false);
  }

  function handleCategory() {
    navigate('/category');
  }

  async function publicBanner() {
    const result = await getPublicBanner('principal=true');
    if (result.data.length) {
      setMainBanner(process.env.REACT_APP_API_BASE_IMAGE + result.data[0].name);
    }
  }

  function checkPotHighlighted(selectMatch: MatchPotType[]) {
    return selectMatch.some((item) => {
      if (item.games && Array.isArray(item.games)) {
        return item.games.some((game) => game.highlight === 1);
      }
      return false;
    });
  }

  const { MAIN_BANNER_TYPEID } = cmsPagesEnum;

  async function getBanner() {
    setIsLoadingBanner(true);
    await getCmsHtml(MAIN_BANNER_TYPEID).then((res) => {
      setbannerContent(res);
    });
    setIsLoadingBanner(false);
  }

  useEffect(() => {
    if (selectMatch.length) {
      const isPotHighlighted = checkPotHighlighted(selectMatch);

      setThereIsHighligth(isPotHighlighted);
      if (isPotHighlighted) {
        setSelectedCategory('todos');
      } else {
        // setSelectedCategory('todos');
        setSelectedCategory(selectMatch[0].name);
      }
    }
  }, [selectMatch]);

  useEffect(() => {
    firstLogin();
  }, [userLogged]);

  useEffect(() => {
    publicBanner();
  }, [mainBanner]);

  useEffect(() => {
    getBanner();
  }, []);

  const isPotBetStored = SessionControl.getPotChoices();

  const isShowFirstAccess = statusfirstAccess && !isPotBetStored;

  return (
    <div className="relative">
      <ModalCoins setOpenAlert={setOpenAlert} openAlert={openAlert} />
      <ModalRules openRules={openModalRules} setOpenRules={setOpenModalRules} />

      {isShowFirstAccess && (
        <>
          {isMobile ? (
            <ModalAlertCheckoutContentContainer
              onClose={removeFirstAccess}
              extraInfo="hot pots"
              isHome={true}
              handleConfirm={confirmFirstAccessBuyCoins}
              handleGotToHome={removeFirstAccess}
            />
          ) : (
            <ModalAlertCheckout
              onClose={removeFirstAccess}
              extraInfo="hot pots"
              isHome={true}
              handleGotToHome={removeFirstAccess}
              handleConfirm={confirmFirstAccessBuyCoins}
            />
          )}
        </>
      )}

      {!isShowFirstAccess && (
        <PageContainer>
          {isLoadinBanner ? (
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                borderRadius: '20px',
                marginTop: '56px',
                marginBottom: '40px',
                height: '300px',
              }}
            />
          ) : (
            <HomeBanner>
              <div
                className="banner-category"
                dangerouslySetInnerHTML={{
                  __html: bannerContent,
                }}
              />
            </HomeBanner>
          )}

          <HotPots />

          {isLoadingMatch ? (
            <div className="flex mb-[2rem] mt-[1em]">
              <Skeleton
                variant="rounded"
                style={{
                  background: '#4B4C66',
                  width: '80px',
                  marginRight: '2em',
                  borderRadius: '30px',
                  marginBottom: '1.5rem',
                  height: '80px',
                }}
              />
              <Skeleton
                variant="rounded"
                style={{
                  background: '#4B4C66',
                  width: '80px',
                  marginRight: '2em',
                  borderRadius: '30px',
                  marginBottom: '1.5rem',
                  height: '80px',
                }}
              />
              <Skeleton
                variant="rounded"
                style={{
                  background: '#4B4C66',
                  width: '80px',
                  borderRadius: '30px',
                  marginBottom: '1.5rem',
                  height: '80px',
                }}
              />
            </div>
          ) : (
            <CarouselContainer className="mb-[2rem]">
              <CategorySelector
                categoriesAvailable={categoriesAvailable}
                selected={selectedCategory}
                thereIsHightLight={thereIsHighlight}
                setCategory={setSelectedCategory}
              />
            </CarouselContainer>
          )}
          {thereIsHighlight && (
            <>
              {selectedCategory === 'todos' &&
                selectMatch.map((data: MatchPotType, index: number) => (
                  <>
                    {!!data.games.length && (
                      <div key={`div${data.games}${index}`} className="mb-0">
                        {!!data.games.length && (
                          <div className="mb-6">
                            <CategoryMatch icon={data.name} category={data.name} />
                          </div>
                        )}

                        {!!data.games.length && (
                          <div className="md:flex gap-6">
                            {data.games.length > 2 && (
                              <HomePotList className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 sm:gap-x-6 md:gap-x-2 lg:gap-x-6 xl:gap-x-2 2xl:gap-x-6">
                                <ListPotFiltered
                                  data={data.games}
                                  openAlert={openAlert}
                                  setOpenAlert={setOpenAlert}
                                  setOpenModalRules={setOpenModalRules}
                                />
                              </HomePotList>
                            )}

                            {data.games.length <= 2 && (
                              <HomeLessPotList>
                                <ListPotFiltered
                                  data={data.games.filter((item) => item.highlight === 1)}
                                  openAlert={openAlert}
                                  setOpenAlert={setOpenAlert}
                                  setOpenModalRules={setOpenModalRules}
                                />
                              </HomeLessPotList>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ))}
            </>
          )}

          {!!selected && (
            <SelectedCategory
              setOpenModalRules={setOpenModalRules}
              setOpenAlert={setOpenAlert}
              openAlert={openAlert}
              selected={selected}
              selectMatch={selectMatch}
              isCategory
            />
          )}

          <HomeGameInstruction />
        </PageContainer>
      )}
    </div>
  );
};

export default LoggedHome;
