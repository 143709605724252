import styled from '@emotion/styled';
import { CreateGroupButton } from './../../components/CreateGroup/styles';

export const AboutGroupContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 24px;
  padding-bottom: 80px;

  @media (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 40px;
  }
`;

export const AboutGroupBanner = styled.div`
  width: 100%;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.7) 88%);
  border-radius: 20px;
  height: 300px;
  overflow: hidden;
  margin-bottom: 80px;
  background-image: url('./banner-group.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 40px;

  div {
    padding: 51px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 730px) {
    background-position-x: 40%;
  }

  @media (max-width: 600px) {
    //group-image-mobile.png
    background-image: url('./group-image-mobile.png');
  }

  .title {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 88px;
    line-height: 106px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25); */
  }

  .text {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #ffffff;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.50); */
  }

  @media (max-width: 625px) {
    .title {
      font-size: 65px;
    }

    .text {
      font-size: 12pt;
    }
  }

  @media (max-width: 490px) {
    .title {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 16px;
    }

    .text {
      line-height: 19px;
    }
  }

  @media (max-width: 331px) {
    .title {
      font-size: 35px;
    }
  }
`;

export const BannerButtonCreate = styled(CreateGroupButton)`
  font-size: 14px;
  width: 106px;
  height: 34px;

  @media (max-width: 625px) {
    width: 70px;
  }
`;

export const AboutGroupCreateGroupSection = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1119px) {
    grid-template-columns: 100%;
    justify-content: center;
  }
`;

export const AboutGroupCreateGroupSectionTextContainer = styled.div`
  width: 65%;

  @media (max-width: 1276px) {
    width: 75%;
  }

  @media (max-width: 1152px) {
    width: 85%;
  }

  @media (max-width: 1119px) {
    justify-self: center;
  }

  h1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;

    @media (max-width: 1119px) {
      width: 100%;
      justify-content: center;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 8px;

    @media (max-width: 1119px) {
      width: 100%;
      text-align: center;
    }
  }

  div {
    max-width: 315px;
    width: 100%;

    @media (max-width: 1120px) {
      max-width: none;
    }
  }

  .text-legend {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #ff8e00;
    margin-top: 32px;
    text-align: left;

    @media (max-width: 1119px) {
      width: 100%;
      text-align: center;
    }
  }

  .text-legend-small {
    margin-top: 10px;
    font-size: 15px;
  }
`;
export const CreateGroupContainer = styled.div`
  background: #28293d;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 490px) {
    margin-top: 50px;
  }

  @media (max-width: 1120px) {
    margin-top: 30px;
  }
`;
