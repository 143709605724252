import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import CMSPageLayout from '../../components/CMSPageLayout';

const About = () => {
  const navigate = useNavigate();

  return (
    <CMSPageLayout contentName="ABOUT_PAGE_TYPEID">
      <Button
        moreClasses="max-w-[100%]  md:max-w-[173px] w-[100%] h-[49px] text-[16px] block my-[30px]"
        label="Quero Jogar"
        hierarchy="primary"
        size="sm"
        onClick={() => navigate('/')}
      />
    </CMSPageLayout>
  );
};

export default About;
