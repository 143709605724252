import { decode } from 'base-64';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useHideScrollWhenShowModal } from '../../hooks/useHideScrollWhenShowModal';
import { Game, GroupOfMasterOptions, Match, TeamMatchPoint } from '../../typescript/match';
import { ReactComponent as Champion } from '../../utils/icons/champion.svg';
import { ReactComponent as Master } from '../../utils/icons/master.svg';
import { ReactComponent as Target } from '../../utils/icons/target.svg';
import ExactMatchPoint from '../MatchSelector/ExactMatchPoint';
import MatchSelector from '../MatchSelector/MatchSelector';
import { SelectorTeam } from '../MatchSelector/SelectorTeam';
import moment from 'moment';
import { getCurrentTimestamp } from '../../utils/timestampDisplay';
import { SessionControl } from '../../session/SessionControl';

interface PotBetCardDataProps {
  potInfo: Game;
  isAlreadyBet: boolean;
  fullWidth?: boolean;
  isAtCategoryPage?: boolean;
}

export interface ChoosesType {
  team: string;
  id: number;
}

export const areAllTeamsWithPoints = ({ matchExactPoint }: { matchExactPoint: TeamMatchPoint[] }) => {
  const cpyMatchExactPoint = [...matchExactPoint];

  if (cpyMatchExactPoint.length === 0) {
    return false;
  } else {
    return cpyMatchExactPoint.every((match) => {
      return match.team1.point !== null && match.team2.point !== null;
    });
  }
};

export function PotBetCardData({
  potInfo,
  isAlreadyBet,
  fullWidth = false,
  isAtCategoryPage = false,
}: PotBetCardDataProps) {
  const { updateAppState } = useUserContext();
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const windowSize = useGetWindowSize();
  const [chooses, setChooses] = useState<ChoosesType[]>([]);
  const { id } = useParams();
  const { search } = useLocation();
  const [showModalAuth, setShowModalAuth] = useState(false);
  const location = useLocation();
  const isAlreadyDone = isAlreadyBet;
  const isMobile = windowSize.width <= 600;
  const isPotPage = location.pathname.split('/').includes('pot');
  const [showModalBetDuplicated, setShowModalBetDuplicated] = useState(false);
  const isHidePageContentWhenWarningAtMobile = isMobile && showModalBetDuplicated;
  useHideScrollWhenShowModal({ state: showModalAuth || isHidePageContentWhenWarningAtMobile, hidePageContent: false });
  const potOneVsMany = potInfo.onevsmany ? potInfo.onevsmany?.potOneVsMany : null;
  const pointSpread = potInfo.pointspread ? potInfo.pointspread?.pointSpread : null;

  const [matchExactPoint, setMatchExactPoint] = useState<TeamMatchPoint[]>([]);
  const [matchOneVsMany, setMatchOneVsMany] = useState<GroupOfMasterOptions[]>([]);

  const getSelectorDefaultValue = (id: number) => {
    const teamExist = chooses.find((team1) => team1.id === id);

    if (teamExist) {
      return teamExist.team;
    } else {
      return '';
    }
  };

  const handleSetChooseTeams = (id: number, team: string) => {
    let newChooses: ChoosesType[] = structuredClone(chooses);

    if (newChooses.length === 0) {
      newChooses.push({
        id,
        team,
      });
    } else {
      const teamExist = newChooses.find((team1) => team1.id === id);

      if (teamExist) {
        if (teamExist.team === team) {
          newChooses = newChooses.filter((team1) => team1.id !== id);
        } else {
          newChooses = newChooses.filter((team1) => team1.id !== id);
          newChooses.push({
            id,
            team,
          });
        }
      } else {
        newChooses.push({ id, team });
      }
    }

    setChooses(newChooses);
  };

  const handleSelectedTeam = (id: number, team: string) => {
    handleSetChooseTeams(id, team);
  };

  useEffect(() => {
    if (search) {
      if (search.includes('?chooses=')) {
        const queryParams = search.replace('?chooses=', '');

        if (queryParams) {
          const decodeString = decode(queryParams);
          const queryChooses = JSON.parse(decodeString);
          setChooses(queryChooses);
        }
      }
    } else {
      if (isAlreadyDone) {
        const matches = potInfo.match;

        const chooses: any[] = [];

        potInfo.bet.split(',').map((item) => {
          const teamFound = matches.find((team) => team.team1 === item || team.team2 === item);

          if (teamFound) {
            chooses.push({
              id: teamFound.id,
              team: item,
            });
          }
        });

        setChooses(chooses);
      }
    }
  }, [search]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerExpired(Boolean(getCurrentTimestamp() > moment(potInfo!.dateFinalBet).valueOf()));
    }, 100);

    return () => clearInterval(interval);
  }, [potInfo, timerExpired]);

  useEffect(() => {
    const data = SessionControl.getPotChoices();

    if (data) {
      const potInfo = JSON.parse(data);
      if (potInfo.pot.id === Number(id)) {
        setChooses(potInfo.chooses);
      }
    }
    updateAppState('update users bets');
  }, []);

  useEffect(() => {
    const data = SessionControl.getPotChoices();

    if (data) {
      if (chooses.length === 0) {
        SessionControl.clearPotChoices();
      }
    }
  }, [chooses]);

  useEffect(() => {
    if (isAlreadyDone) {
      if (potInfo.anotherBet && potInfo.anotherBet.betmatchexactpoint && potInfo.bettypes?.includes('pointspread'))
        setMatchExactPoint(potInfo.anotherBet.betmatchexactpoint?.pointSpread);
      return;
    }

    if (pointSpread !== null) {
      const savedPotState = SessionControl.getPot(`pot${potInfo.id}matchExactPoint`);

      if (savedPotState) {
        const data = JSON.parse(savedPotState);
        if (data.length) {
          setMatchExactPoint(data);
        } else {
          setMatchExactPoint(pointSpread);
        }
      } else {
        setMatchExactPoint(pointSpread);
      }
    }
  }, []);

  useEffect(() => {
    if (matchExactPoint.length) {
      SessionControl.setPot(`pot${potInfo.id}matchExactPoint`, JSON.stringify(matchExactPoint));
    }
  }, [matchExactPoint]);

  useEffect(() => {
    if (isAlreadyDone) {
      if (potInfo.anotherBet && potInfo.anotherBet.betonevsmany && potInfo.bettypes?.includes('onevsmany')) {
        setMatchOneVsMany(potInfo.anotherBet.betonevsmany.potOneVsMany);
      }
      return;
    }

    if (potOneVsMany !== null) {
      const savedPotState = SessionControl.getPot(`pot${potInfo.id}potOneVsMany`);

      if (savedPotState) {
        const data = JSON.parse(savedPotState);
        if (data.length) {
          setMatchOneVsMany(data);
        } else {
          setMatchOneVsMany(potOneVsMany);
        }
      } else {
        setMatchOneVsMany(potOneVsMany);
      }
    }
  }, []);

  useEffect(() => {
    if (matchOneVsMany.length) {
      SessionControl.setPot(`pot${potInfo.id}potOneVsMany`, JSON.stringify(matchOneVsMany));
    }
  }, [matchOneVsMany]);

  useEffect(() => {
    return () => {
      SessionControl.removePot(`pot${potInfo.id}matchExactPoint`);
      SessionControl.removePot(`pot${potInfo.id}potOneVsMany`);
    };
  }, []);

  const isPotWithColumn = isAtCategoryPage && potInfo?.match.length > 1;
  const showContinueBetting = (potInfo?.match.length > 8 || isAtCategoryPage) && !isPotPage;
  const matches = showContinueBetting ? potInfo?.match.slice(0, isAtCategoryPage ? 2 : 8) : potInfo?.match;

  return (
    <div className={`flex flex-col max-w-full ${fullWidth ? 'max-w-[100%]' : 'max-w-[1040px]'}`}>
      <div className="w-full flex flex-col gap-8">
        {!!matches.length && (
          <div>
            <div className="flex items-center mb-5 gap-1 sm:gap-2">
              <Champion className="w-5 h-4 sm:w-7 sm:h-6" />
              <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Quem será o vencedor?</p>
            </div>

            <div className={`w-full grid gap-4 ${isPotWithColumn && 'gap-2 sm:grid-cols-2 sm:gap-5'}`}>
              {matches.map((item: Match) => {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col justify-center items-center max-w-full bg-[#28293D] rounded-20"
                  >
                    {isAlreadyDone && (
                      <MatchSelector
                        item={item}
                        changeValue={handleSelectedTeam}
                        disabled={isAlreadyDone}
                        defaultValue={getSelectorDefaultValue(item.id)}
                      />
                    )}

                    {!isAlreadyDone && (
                      <MatchSelector
                        changeValue={handleSelectedTeam}
                        item={item}
                        defaultValue={getSelectorDefaultValue(item.id)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!!matchOneVsMany.length && (
          <div>
            <div className="flex items-center mb-5 gap-1 sm:gap-2">
              <Master className="w-5 h-4 sm:w-7 sm:h-6" />
              <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Palpite de mestre</p>
            </div>

            <div className="flex flex-col gap-8">
              {matchOneVsMany.map((item) => (
                <div
                  className="flex flex-col justify-center items-center max-w-full bg-[#28293D] rounded-20"
                  key={item.id}
                >
                  <h3 className="text-xl font-semibold mb-6 text-start w-full">{item.question}</h3>

                  <div className="grid sm:grid-cols-2 gap-3 w-full">
                    {item.options.map((option) => {
                      return (
                        <div className="flex flex-row items-center" key={option.id}>
                          <SelectorTeam
                            handleSelect={() => null}
                            fullWidth
                            name={option.name}
                            selected={option.value === option.result}
                            teamImage={option.image ? option.image : ''}
                            isLeftImage={true}
                            disabled={isAlreadyDone}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!!matchExactPoint.length && (
          <div>
            <div className="flex items-center mb-5 gap-1 sm:gap-2">
              <Target className="w-5 h-5 sm:w-6 sm:h-6" />
              <p className="text-[#FF8E00] text-xl sm:text-2xl font-semibold">Acerte na mosca</p>
            </div>

            <div className="w-full grid gap-12 2xl:grid-cols-2 sm:gap-5">
              {matchExactPoint.map((item: TeamMatchPoint) => {
                return (
                  <div className="w-full" key={item.id}>
                    <ExactMatchPoint
                      disabled={true}
                      item={item}
                      updatePoint={() => {
                        if (isAlreadyDone) return;
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
