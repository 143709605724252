import Alert from '@mui/material/Alert';
import { AlertModalContainer, AlertModalContent, ButtonCloseModal } from './styles';

import { ReactComponent as ErrorCircle } from '../../utils/icons/ErrorCircle.svg';
import { ReactComponent as Warning } from '../../utils/icons/Warning.svg';
import { ReactComponent as SuccessCircle } from '../../utils/icons/SuccessCircle.svg';
import { MdClose } from 'react-icons/md';
import { useEffect } from 'react';

interface ModalAlertProps {
  title: string;
  message: string;
  onClose: () => void;
  autoClose?: boolean;
}

const ErrorAlert = ({ title, message, onClose, autoClose = false }: ModalAlertProps) => {
  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, []);

  return (
    <AlertModalContainer>
      <AlertModalContent>
        <Alert iconMapping={{ error: <ErrorCircle /> }} className="errorAlert" variant="outlined" severity="error">
          <ButtonCloseModal onClick={onClose}>
            <MdClose color="#fff" size="18" />
          </ButtonCloseModal>
          <div>
            <p>{title}</p>
            <p className="messageAlert">{message}</p>
          </div>
        </Alert>
      </AlertModalContent>
    </AlertModalContainer>
  );
};

const WarningAlert = ({ title, message, onClose, autoClose = false }: ModalAlertProps) => {
  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, []);

  return (
    <AlertModalContainer>
      <Alert iconMapping={{ warning: <Warning /> }} className="warningAlert" variant="outlined" severity="warning">
        <ButtonCloseModal onClick={onClose}>
          <MdClose color="#fff" size="18" />
        </ButtonCloseModal>
        <div>
          <p>{title}</p>
          <p className="messageAlert">{message}</p>
        </div>
      </Alert>
    </AlertModalContainer>
  );
};

const SuccessAlert = ({ title, message, onClose, autoClose = false }: ModalAlertProps) => {
  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, []);

  return (
    <AlertModalContainer>
      <Alert
        iconMapping={{ success: <SuccessCircle /> }}
        className="successAlert"
        variant="outlined"
        severity="success"
      >
        <ButtonCloseModal onClick={onClose}>
          <MdClose color="#fff" size="18" />
        </ButtonCloseModal>
        <div>
          <p>{title}</p>
          <p className="messageAlert">{message}</p>
        </div>
      </Alert>
    </AlertModalContainer>
  );
};

export { ErrorAlert, SuccessAlert, WarningAlert };
