import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { firebaseAuth } from './../../service/firebase/firebase';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import 'react-toastify/dist/ReactToastify.css';
import {
  AccountCreationContainer,
  AccountSidebarContainer,
  FormContainer,
  LoginBackgroundImage,
} from './../Login/styles';
import { RootState, useDispatch } from '../../store';
import ButtonLoginSocial from '../../components/ButtonLoginSocial/ButtonLoginSocial';
import { signInWithFacebook, signInWithGoogle } from '../../store/reducers/user/userSlice';
import RegisterForm from '../../components/AuthForms/RegisterForm';
import { useSelector } from 'react-redux';
import { getCmsHtml } from '../../service/cms/cms.service';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import api from '../../service/api';
import { ErrorAlert, SuccessAlert } from '../../components/BasicAlerts';
import { signInWithPopup, GoogleAuthProvider, UserCredential } from 'firebase/auth';
import { ButtonAuthGoogle } from '../../components/ButtonAuthGoogle';
import BirthDateForm from '../../components/AuthForms/BirthDateForm';
import { SessionControl } from '../../session/SessionControl';

const Register = () => {
  const user = useSelector((state: RootState) => state.user);
  const [leftSideContent, setLeftSideContent] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('login');
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleCleanStatusRequest() {
    setMessage('');
    setStatusRequest('');
  }

  const responseFacebook = async (res: any) => {
    await dispatch(signInWithFacebook(res.accessToken));
    const token = SessionControl.getToken();
    if (token) navigate('/');
  };

  const handleChangeMode = () => {
    navigate('/login');
  };

  const showErrorToast = (message: string) => {
    setStatusRequest('error');
    setMessage(message);
    // toast.error(message);
  };

  const showSuccessToast = (message: string) => {
    setStatusRequest('success');
    setMessage(message);
    // toast.success(message);
  };

  useEffect(() => {
    if (location.search.includes('register')) setMode('register');

    if (user.token) navigate('/');
  }, [navigate, user.token]);

  const { LOGIN_PAGE_TYPEID } = cmsPagesEnum;

  useEffect(() => {
    getCmsHtml(LOGIN_PAGE_TYPEID).then((res) => {
      setLeftSideContent(res);
    });
  });

  return (
    <AccountCreationContainer style={{ alignItems: 'center' }}>
      <LoginBackgroundImage
        className="bg-white hidden md:block"
        dangerouslySetInnerHTML={{ __html: leftSideContent }}
      />
      <AccountSidebarContainer start={!isGoogleAuth}>
        {!isGoogleAuth && (
          <FormContainer>
            <Link to="/">
              <img src="images/logo.svg" alt="moneypot" className="mb-[24px] max-w-[167px] w-full" />
            </Link>
            <div className="flex items-center">
              <h1 className="text-2xl font-semibold text-white">Criar conta</h1>
            </div>
            <div className="flex w-full gap-3 mb-2">
              {/* <FacebookLogin
                autoLoad={false}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                fields="first_name,last_name,email,birthday,age_range,picture"
                callback={responseFacebook}
                render={(renderProps: any) => <ButtonLoginSocial provider="facebook" onClick={renderProps.onClick} />}
              /> */}
              <ButtonAuthGoogle
                setMessage={(msg: string) => setMessage(msg)}
                setStatusRequest={(msg: '' | 'error' | 'success') => setStatusRequest(msg)}
                // isRegister
                onSuccess={() => null}
                handleShowModalBirthDate={() => setIsGoogleAuth(true)}
              />
            </div>

            <RegisterForm onChangeMode={handleChangeMode} onSuccess={showSuccessToast} />
          </FormContainer>
        )}
        {isGoogleAuth && <BirthDateForm onSuccess={() => null} />}
      </AccountSidebarContainer>
    </AccountCreationContainer>
  );
};

export default Register;
