import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { getPotId } from '../../service/pots/pots.service';
import { RootState } from '../../store';
import ModalCoins from '../../components/ModalCoins';
import Pot from '../../components/Pot/Pot';
import ModalRules from '../../components/ModalRules';
import { Game } from '../../typescript/match';
import moment from 'moment';
import {getCurrentTimestamp} from "../../utils/timestampDisplay";


const Guess = () => {
  const [potSelected, setPotSelected] = useState<Game | null>();
  const [openAlert, setOpenAlert] = useState(false);
  const [openModalRules, setOpenModalRules] = useState(false);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);

  const { id } = useParams();

  const { token } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    async function getMatchOnLink() {
      const response = await getPotId(Number(id));
      setPotSelected(response.data);
    }
    getMatchOnLink();
  }, [token]);

  useEffect(() => {
    if (potSelected === null) return;

    const interval = setInterval(() => {
      setTimerExpired(Boolean(getCurrentTimestamp() > moment(potSelected!.dateFinalBet).valueOf()));
    }, 100);

    return () => clearInterval(interval);
  }, [potSelected, timerExpired]);

  return (
    <div className="relative">
      <ModalCoins setOpenAlert={setOpenAlert} openAlert={openAlert} />
      <ModalRules openRules={openModalRules} setOpenRules={setOpenModalRules} />
      <PageContainer>
        <div className="max-w-full max-w-[1040px] mt-[40px] mb-[32px] flex flex-col md:flex-row "></div>
        {potSelected && (
          <Pot
            setOpenRules={setOpenModalRules}
            openRules={false}
            setOpenModal={setOpenAlert}
            openModal={openAlert}
            potInfo={potSelected}
            isAlreadyBet={false}
          />
        )}
      </PageContainer>
    </div>
  );
};

export default Guess;
