import { useEffect, useState } from 'react';
import { getUsersByGroup } from '../../service/group/group';

interface UsersGroup {
  login: string;
  updatedAt: string;
}

export const useGetGroupUsers = ({ groupId }: {groupId: number}) => {
  const [groupUsers, setGroupUsers] = useState<UsersGroup[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const getNewUsersGroup = async () => {
    if (groupId) {
      setIsLoadingUsers(true);
      const response = await getUsersByGroup(groupId);
      setIsLoadingUsers(false);
      setGroupUsers(response.data.groupUser);
    }
  };

  useEffect(() => {
    if (groupId) {
      getNewUsersGroup();
    }
  }, [groupId]);

  return {
    groupUsers,
    isLoadingUsers
  };
};
