export const CoinsIcon = ({ bg, color }: {bg: string, color: string}) => {
  return (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7 7.00012C19.7 3.89575 17.2339 1.34032 14.1444 1.34033C11.055 1.34033 8.58887 3.89575 8.58887 7.00012C8.58887 10.1045 11.055 12.6599 14.1444 12.6599C17.2339 12.6599 19.7 10.1045 19.7 7.00012Z"
        fill={bg}
        stroke="#28293D"
        strokeWidth="2"
      />
      <path
        d="M12.4109 7.00012C12.4109 3.89575 9.94481 1.34032 6.85536 1.34033C3.7659 1.34033 1.2998 3.89575 1.2998 7.00012C1.2998 10.1045 3.7659 12.6599 6.85536 12.6599C9.94482 12.6599 12.4109 10.1045 12.4109 7.00012Z"
        fill={bg}
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
