import { BsStar } from 'react-icons/bs';
import Fire from './icons/fireIcon';

import { ReactComponent as Basket } from '../utils/icons/Basketicon.svg';
import { ReactComponent as Mma } from '../utils/icons/MmaIcon.svg';
import { ReactComponent as Beisebol } from '../utils/icons/baisebol.svg';
import { ReactComponent as eSport } from '../utils/icons/esports.svg';
import { ReactComponent as Soccer } from '../utils/icons/soccer.svg';
import { ReactComponent as Tenis } from '../utils/icons/tenis.svg';
import { ReactComponent as Volei } from '../utils/icons/volei.svg';
import { ReactComponent as Jiujstsu } from '../utils/icons/jiujstsu.svg';
import { ReactComponent as Kungfu } from '../utils/icons/KUNGFU.svg';
import { ReactComponent as Poker } from '../utils/icons/POKER.svg';
import { ReactComponent as Futsal } from '../utils/icons/FUTSAL.svg';

import { ReactComponent as Others } from '../utils/icons/outros.svg';

export type SportOptionsType = {
  name: string;
  // icon: IconType | (() => JSX.Element);
  icon: any;
  title?: string;
};

export const sportOptions: SportOptionsType[] = [
  { name: 'atletismo', icon: Others },
  { name: 'basquete', icon: Basket },
  { name: 'beisebol', icon: Beisebol },
  { name: 'boxe', icon: Others },
  { name: 'canoagem', icon: Others },
  { name: 'ciclismo', icon: Others },
  { name: 'todos', icon: BsStar },
  { name: 'esgrima', icon: Others },
  { name: 'esports', icon: eSport },
  { name: 'futebol', icon: Soccer },
  { name: 'kung-fu', icon: Kungfu },
  { name: 'poker', icon: Poker },
  { name: 'futsal', icon: Futsal },
  { name: 'ginastica', icon: Others },
  { name: 'golfe', icon: Others },
  { name: 'handebol', icon: Others },
  { name: 'hipismo', icon: Others },
  { name: 'hoquei', icon: Others },
  { name: 'judo', icon: Others },
  { name: 'karate', icon: Others },
  { name: 'mma', icon: Mma },
  { name: 'natacao', icon: Others },
  { name: 'Pots em alta', icon: Fire },
  { name: 'remo', icon: Others },
  { name: 'rugby', icon: Others },
  { name: 'surfe', icon: Others },
  { name: 'tenis', icon: Tenis, title: 'Tênis' },
  { name: 'triatlo', icon: Others },
  { name: 'vela', icon: Others },
  { name: 'volei', icon: Volei },
  { name: 'jiu-jitsu', icon: Jiujstsu },
  { name: 'outros', icon: Others },
];
