import { toast } from 'react-toastify';
import { ILoginFormData, IRegisterFormData } from '../../store/reducers/user/types';
import api from '../api';
import { GroupOfMasterOptions, TeamMatchPoint } from './../../typescript/match';

export const userRegister = async (user: IRegisterFormData) => {
  return await api
    .post(
      '/public/create-account',
      {
        login: user.username,
        password: user.password,
        email: user.email.toLowerCase(),
        birthDate: user.dob.toISOString().split('T')[0],
        token: user.token,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const firstAccess = async (id: number) => {
  return await api
    .post(`/accounts-users/first-access/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getUserInfo = async (): Promise<any> => {
  const response = await api
    .get(`/accounts-users`, {})
    .then((response) => response.data.data)
    .catch((response) => response.response.data);

  return response;
};

export const getByUsername = async (username: string): Promise<any> => {
  return await api
    .post(`/public/user/verify-user/`, { login: username })
    .then((response) => response.data.data)
    .catch((response) => response.response.data);
};

export const updateUserInfo = async (
  fullName: string,
  phoneNumber: string,
  pix: string,
  cpf: string,
  email: string,
  pixType: string
) => {
  const data = {
    name: fullName,
    phone: phoneNumber,
    pixKey: pix,
    cpf: cpf?.replaceAll('.', '').replaceAll('-', ''),
    email: email,
    pixType: pixType,
  };

  return await api
    .put(`/accounts-users`, data, {})
    .then((response) => response.data.data)
    .catch((response) => response.response.data);
};

export const updatecreateNewPassword = async (password: string, oldPassword: string, token: string) => {
  const data = {
    password: password,
    password_confirm: oldPassword,
    token,
  };

  return await api
    .post(`auth/reset`, data)
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const updateUserPassword = async (password: string, oldPassword: string, login: string) => {
  const data = {
    password: password,
    oldPassword: oldPassword,
    login: login,
  };

  return await api
    .put(`accounts-users/alter-password`, data, {})
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userLogin = async (userData: ILoginFormData) => {
  return await api
    .post('/auth/login', {
      login: userData.username,
      password: userData.password,
    })
    .then((response) => {
      return {
        token: response.data.data.token,
        status: response.data.status,
      };
    })
    .catch((response) => response);
};

export const userGoogleLogin = async (idToken: string, accessToken: string) => {
  return await api
    .post('/auth/google', {
      token: idToken,
      accessToken: accessToken,
    })
    .then((response) => {
      return {
        token: response.data.data.token,
        status: response.data.status,
      };
    })
    .catch((response) => {
      if (response.response.data.status === 406) {
        return toast.error(response.response.data.message);
      }
      return response;
    });
};

export const userFacebookLogin = async (accessToken: string) => {
  return await api
    .post('/auth/facebook?code=' + accessToken, {})
    .then((response) => {
      return {
        token: response.data.data.token,
        status: response.data.status,
      };
    })
    .catch((response) => {
      if (response.response.data.status === 406) {
        return toast.error(response.response.data.message);
      }
      return response;
    });
};

export const recoverPass = async (email: string) => {
  return await api
    .post(
      '/emails/forgout-password',
      {
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const recoverPassword = async (email: string) => {
  return await api
    .post('/auth/changePassword', {
      email: email,
    })
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

//users-coins

export const userBuyCoins = async (login: string, value: number, id: number) => {
  return await api
    .post(
      `/accounts-users/wallet-transaction/confirm`,
      {
        status: 'COMPRA',
        description: 'Compra de Coins',
        value: value,
        loginTransaction: login,
        userId: id,
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userAddMoney = async (
  login: string,
  value: number,
  coins: number,
  id: number,
  card_number: string,
  exp_month: string,
  exp_year: string,
  security_code: string,
  holder_name: string,
  currency: string,
  payment_method: number,
  discount: boolean
) => {
  const cardNumberFormatted = card_number.replaceAll(' ', '');

  const { PagSeguro } = window as any;
  const { encryptedCard } = PagSeguro.encryptCard({
    publicKey: process.env.REACT_APP_PAGSEGURO_PUBLIC_KEY,
    holder: holder_name,
    number: cardNumberFormatted,
    expMonth: exp_month,
    expYear: exp_year,
    securityCode: security_code,
  });

  return await api
    .post(
      `/accounts-users/payment-transaction`,
      {
        value: value,
        loginTransaction: login,
        userId: id,
        coins: coins,
        encrypted: encryptedCard,
        currency: currency,
        payment_method: payment_method,
        discount,
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userAddMoneyPix = async (
  login: string,
  value: number,
  coins: number,
  id: number,
  currency: string,
  payment_method: number,
  discount: boolean
) => {
  return await api
    .post(
      `/accounts-users/payment-transaction`,
      {
        value: value,
        loginTransaction: login,
        userId: id,
        coins: coins,
        encrypted: 'Pix',
        currency: currency,
        payment_method: payment_method,
        discount,
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const getAcceptPayment = async (id_transaction: string) => {
  return await api
    .get(`/accounts-users/payment-transaction/${id_transaction}`, {})
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userAddCoins = async (value: number, id: number) => {
  return await api
    .put(
      `/accounts-users/users-coins/${id}`,
      {
        value: value,
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

interface UserWithdrawProps {
  login: string;
  value: number;
  numberWallet: number;
  name?: string;
  typekey?: string;
  keypix?: string;
  cpf?: string;
}

export const userWithdraw = async (data: UserWithdrawProps) => {
  return await api
    .post(
      `/accounts-users/wallet-historic`,
      {
        ...data,
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

interface UserBetProps {
  login: string;
  bets: string;
  value: number;
  pot: number;
  id: number;
  betonevsmany: GroupOfMasterOptions[] | null;
  betmatchexactpoint: TeamMatchPoint[] | null;
}

export const userBet = async ({ login, bets, value, pot, id, betmatchexactpoint, betonevsmany }: UserBetProps) => {
  return await api
    .post(
      `/accounts-users/pot-bets`,
      {
        bet: bets,
        login: login,
        value: value,
        pot: pot,
        users: id,
        betmatchexactpoint: {
          pointSpread: betmatchexactpoint,
        },
        betonevsmany: {
          potOneVsMany: betonevsmany,
        },
      },
      {}
    )
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userGetPots = async (filter?: string) => {
  return await api
    .get(`/accounts-users/pot-bets-user${'?' + filter}`, {})
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const userTransactionsHistory = async (filter: 'coin' | 'user') => {
  return await api
    .get(`/accounts-users/historic-${filter}`, {})
    .then((response) => response.data.data)
    .catch((response) => response.response.data);
};
export const alreadyBet = async (id: number) => {
  const response = await api.get(`/accounts-users/users-pot`, {
    params: `potId=${id}`,
  });

  const quantityBet = response.data.data.filter((e: any) => e.numberPot === id);
  const total = quantityBet.length > 0 ? 'Palpitar novamente' : 'Palpitar';
  return total;
};

interface checkDuplicatedBetProps {
  betonevsmany: GroupOfMasterOptions[] | null;
  betmatchexactpoint: TeamMatchPoint[] | null;
  potId: number;
  bets: string;
}

export const checkDuplicatedBet = async ({
  betmatchexactpoint,
  betonevsmany,
  potId,
  bets,
}: checkDuplicatedBetProps): Promise<Boolean> => {
  const {
    data: { data },
  } = await api.post('/accounts-users/check-pot-bet-duplicity', {
    bet: bets,
    betmatchexactpoint,
    betonevsmany,
    potId,
  });
  return data;
};
