import styled from 'styled-components';

export const BallonContainer = styled.div`
  max-width: 146px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: absolute;
  top: 15%;
  left: 29%;
`;

export const BallonContainerPot = styled.div`
  max-width: 146px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -95px;
  left: -65px;
`;

export const BallonArea = styled.div`
  width: 146px;
  height: auto;
  margin: 0 auto;
  background: #ffc070;
  display: inline-block;
  padding: 8px;
  border-radius: 20px;
  position: relative;

  ::after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    top: 80px;
    left: 66px;
    background-color: #ffc070;
    transform: rotate(45deg);
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #1c1c27;
  }
`;

export const BallonContainerMoney = styled.div`
  max-width: 146px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: absolute;
`;

export const BallonAreaMoney = styled.div`
  width: 146px;
  height: auto;
  margin: 0 auto;
  background: #ffc070;
  display: inline-block;
  padding: 8px;
  border-radius: 20px;
  position: relative;

  ::after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    top: 97px;
    left: 66px;
    background-color: #ffc070;
    transform: rotate(45deg);
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #1c1c27;
  }
`;
