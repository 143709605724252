import Button from '../Button/Button';

interface SelectorTeamProps {
  handleSelect: () => void;
  name: string;
  selected: boolean;
  teamImage: string;
  disabled?: boolean;
  isLeftImage?: boolean;
  fullWidth?: boolean;
}

export const SelectorTeam = ({
  handleSelect,
  name,
  selected,
  teamImage,
  isLeftImage = false,
  disabled,
  fullWidth,
}: SelectorTeamProps) => {
  return (
    <Button
      moreClasses={`flex flex-col gap-2 text-center ${
        isLeftImage
          ? 'sm:flex-row md:flex-col lg:flex-row xl:flex-col 2xl:flex-row'
          : 'sm:flex-row-reverse md:flex-col lg:flex-row-reverse xl:flex-col 2xl:flex-row-reverse'
      } ${!fullWidth && 'max-w-[165px]'}
      ${
        fullWidth && !isLeftImage && 'text-center sm:text-end md:text-center lg:text-end xl:text-center 2xl:text-end'
      } ${
        disabled && 'cursor-default'
      } justify-center sm:justify-between md:justify-center lg:justify-between xl:justify-center 2xl:justify-between items-center min-h-[56px] h-full text-white text-sm font-normal w-full py-2 px-1 sm:p-2 rounded-lg sm:min-w-0 overflow-hidden`}
      onClick={handleSelect}
      hierarchy={selected ? 'primary' : 'secondary'}
      style={{ background: disabled && selected ? '#4B4C66' : '', border: '2px solid #4B4C66' }}
      size="sm"
    >
      {!!teamImage && (
        <div className="w-full h-full max-w-[32px] max-h-[32px] rounded-full bg-white overflow-hidden flex items-center justify-center">
          {!!teamImage && <img className="w-full h-full object-cover" src={teamImage} alt={`Imagem de ${name}`} />}
        </div>
      )}

      <span className="text-xs sm:text-sm text-center font-normal">{name}</span>

      <div className="hidden sm:block md:hidden lg:block xl:hidden 2xl:block w-px" />
    </Button>
  );
};
