import { useEffect, useState } from 'react';

import PageContainer from '../../layouts/PageContainer/PageContainer';

import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { ErrorAlert } from '../../components/BasicAlerts';
import ModalCoins, { ModalSessionExpired } from '../../components/ModalCoins';
import PixWithdraw from '../../components/PixWithdraw';
import { useUserAuth } from '../../hooks/user-auth';
import { getUserInfo, updateUserInfo, userWithdraw } from '../../service/user/user.service';
import { PixDataType } from '../../typescript/pix-withdraw';
import { formatMoney, formatValue } from '../../utils/types';
import { Title } from '../Account/styles';
import {
  CustomRadioButton,
  WithdrawPageContainer,
  WithdrawPageHeader,
  WithdrawSubTitle,
  WithdrawTitle,
  WithdrawType,
} from './styles';

const Withdraw = () => {
  const { userLogged, setUserLogged } = useUserContext();
  const [select, setSelect] = useState('pix');
  const [quantity, setQuantity] = useState('');
  const [pix, setPix] = useState('');
  const [pixType, setPixType] = useState('');
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | 'session' | ''>('');
  useUserAuth({ isPrivatePage: true });
  const navigate = useNavigate();

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const handleWithdrawPix = async (data: PixDataType) => {
    handleCleanStatusRequest();

    if (!userLogged) {
      return;
    }

    const NumberWithoutMask = Number(formatMoney(data.value).replace(',', '.'));
    const isValid = NumberWithoutMask <= userLogged?.wallet[0].value;

    if (!isValid) {
      setStatusRequest('error');
      setMessage('Saldo insuficiente!');
      return;
    }

    try {
      const getWithdraw = await userWithdraw({
        login: userLogged?.login,
        value: NumberWithoutMask,
        numberWallet: userLogged?.wallet[0].id,
        cpf: data.cpf,
        keypix: data.pixKey,
        name: data.fullName,
        typekey: data.pixType as string,
      });

      if (getWithdraw.status === 400) {
        setStatusRequest('error');
        return setMessage(getWithdraw.data);
      }

      const apiData = await updateUserInfo(
        userLogged.name,
        userLogged.phone,
        pix,
        userLogged.cpf,
        userLogged.email,
        pixType
      );

      if ([400, 406].includes(apiData.status)) {
        setStatusRequest('error');
        return setMessage(apiData.data);
      }

      setQuantity('');

      setStatusRequest('success');
      setMessage('Pedido de saque realizado!\nEm menos de 3 dias você receberá o valor em sua conta.');

      const { wallet, usersCoins } = await getUserInfo();
      setUserLogged({
        ...userLogged,
        wallet,
        usersCoins,
      });

      return 'success';
    } catch (error) {
      setStatusRequest('error');
      setMessage('Ocorreu um erro, tente novamente mais tarde!');
    }
  };

  useEffect(() => {
    if (userLogged) {
      setPix(userLogged.pixKey);
      setPixType(userLogged.pixType);
    }
  }, [userLogged, quantity]);

  return (
    <PageContainer>
      <WithdrawPageContainer>
        <ModalSessionExpired
          handleClose={() => setStatusRequest('')}
          handleConfirm={() => {
            setStatusRequest('');
            window.location.reload();
            navigate('/login');
          }}
          open={statusRequest === 'session'}
        />

        <ModalCoins
          openAlert={statusRequest === 'success'}
          setOpenAlert={() => setStatusRequest('')}
          title1="Pedido de saque realizado!"
          text1="Em menos de 3 dias úteis você receberá o valor na sua conta."
          btnText="Ir para home"
          handleConfirm={() => navigate('/')}
        />

        {statusRequest === 'error' && (
          <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} />
        )}

        <WithdrawPageHeader>
          <Title>Sacar</Title>
          <WithdrawType>
            <CustomRadioButton checked={select === 'pix'}>
              {select === 'pix' && <div className="radio-checked" />}
            </CustomRadioButton>
            <h1 className="label">PIX</h1>
          </WithdrawType>
        </WithdrawPageHeader>

        <WithdrawTitle>Realizar pedido de saque</WithdrawTitle>
        <WithdrawSubTitle>Quanto você quer sacar? Informe abaixo.</WithdrawSubTitle>

        <PixWithdraw
          handleWithdraw={handleWithdrawPix}
          totalCredit={userLogged ? formatValue(userLogged.wallet[0].value) : 0}
        />
      </WithdrawPageContainer>
    </PageContainer>
  );
};

export default Withdraw;
