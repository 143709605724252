import { HTMLAttributes } from 'react';

interface IMenuOpenIconProps extends HTMLAttributes<HTMLDivElement> {
  setMenuOpen: () => void;
}

const MenuOpenIcon = ({ setMenuOpen }: IMenuOpenIconProps) => {
  return (
    <div onClick={setMenuOpen} className="btn-nvb px-[10px] flex flex-col  items-left gap-[6px] h-[54px] w-[54px]">
      <div className="w-full bg-text-white h-[4px] rounded-full"></div>
      <div className="w-[50%] bg-text-white h-[4px] rounded-full"></div>
    </div>
  );
};

export default MenuOpenIcon;
