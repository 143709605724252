import { MatchPotType, Game } from '../../typescript/match';
import { sortByFinalDate } from '../../utils/home';
import CategoryMatch from '../CategoryMatch';
import Pot from '../Pot/Pot';
import { SportOptionsType } from '../../utils/sportOptions';
import { HomePotList, HomeLessPotList } from './styles';
import { useEffect } from 'react';

interface SelectedCategoryProps {
  setOpenModalRules: Function;
  setOpenAlert: Function;
  openAlert: boolean;
  selected: SportOptionsType;
  selectMatch: MatchPotType[];
  isCategory?: boolean;
}

interface FilterSelectMatchesProps {
  setOpenModalRules: Function;
  setOpenAlert: Function;
  openAlert: boolean;
  selectMatch: MatchPotType[];
  byName: string;
  isCategory?: boolean;
}

interface ListPotFilteredProps {
  setOpenModalRules: Function;
  setOpenAlert: Function;
  openAlert: boolean;
  data: Game[];
}

export const ListPotFiltered = ({ openAlert, data, setOpenAlert, setOpenModalRules }: ListPotFilteredProps) => {
  return (
    <>
      {data.map((pot: any) => (
        <Pot
          setOpenRules={setOpenModalRules}
          openRules={false}
          setOpenModal={setOpenAlert}
          openModal={openAlert}
          potInfo={pot}
          key={pot.id}
          isAlreadyBet={false}
        />
      ))}
    </>
  );
};

const FilterSelectMatches = ({
  selectMatch,
  byName,
  openAlert,
  setOpenAlert,
  setOpenModalRules,
  isCategory,
}: FilterSelectMatchesProps) => {
  const result = selectMatch
    .filter((match: MatchPotType) => match.name === byName)
    .map((obj: MatchPotType) => obj.games.sort(sortByFinalDate));

  const resultFiltered = isCategory ? result[0] : result[0];

  return (
    <ListPotFiltered
      data={resultFiltered}
      openAlert={openAlert}
      setOpenAlert={setOpenAlert}
      setOpenModalRules={setOpenModalRules}
    />
  );
};

const SelectedCategory = ({
  selectMatch,
  setOpenModalRules,
  setOpenAlert,
  openAlert,
  selected,
  isCategory,
}: SelectedCategoryProps) => {
  const isGamesAvailable =
    selected && selectMatch.filter((s: MatchPotType) => s.name === selected.name)[0]?.games.length;

  const gamesLength = selectMatch.filter((s: MatchPotType) => s.name === selected.name)[0]?.games.length > 2;

  return (
    <div>
      {!!isGamesAvailable && (
        <div className="mb-6">
          <CategoryMatch icon={selected.name} category={selected.name} />
        </div>
      )}

      {!!isGamesAvailable && (
        <div className="md:flex gap-5 mb-1">
          {gamesLength && (
            <HomePotList>
              <FilterSelectMatches
                isCategory={isCategory}
                byName={selected.name}
                selectMatch={selectMatch}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                setOpenModalRules={setOpenModalRules}
              />
            </HomePotList>
          )}

          {!gamesLength && (
            <HomeLessPotList>
              <FilterSelectMatches
                isCategory={isCategory}
                byName={selected.name}
                selectMatch={selectMatch}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                setOpenModalRules={setOpenModalRules}
              />
            </HomeLessPotList>
          )}
        </div>
      )}

      {!isGamesAvailable && selected.name !== 'todos' && <p className="mb-12">Não há partidas disponíveis.</p>}
    </div>
  );
};

export default SelectedCategory;
