import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../utils/icons/arrowR.svg';
import { sportOptions } from '../../utils/sportOptions';
import { CategoryMatchContainer } from './style';

interface ICategoryMatch {
  category: string;
  icon: string;
}

const CategoryMatch = ({ category, icon }: ICategoryMatch) => {
  const sport = sportOptions.find((sport) => sport.name === icon);

  return (
    <CategoryMatchContainer className="flex items-center justify-between rounded-lg p-2 sm:p-4 md:px-8 md:py-[1.125rem] bg-gradient-to-r from-[#404160] to-[#212121]">
      <div className="flex items-center gap-2">
        {sport && (
          <sport.icon className="fill-text-highlight w-[24px] h-[24px] max-[382px]:w-[16px] max-[382px]:h-[16px]" />
        )}

        <h1 className="category-name uppercase font-semibold sm:text-[18px]">{category}</h1>
      </div>

      <Link to={`category/${category}`}>
        <p className="match-legend pointer text-text-highlight uppercase flex flex-row items-center text-xs md:text-base">
          ver todos os pots <ArrowRight className="ml-2" />
        </p>
      </Link>
    </CategoryMatchContainer>
  );
};

export default CategoryMatch;
