import { FcGoogle } from 'react-icons/fc';
import { FaFacebookSquare } from 'react-icons/fa';

interface IButtonLoginSocialProps {
  onClick: any;
  provider: string;
  disabled?: boolean;
}

const ButtonLoginSocial = ({ provider, onClick, disabled }: IButtonLoginSocialProps) => {
  const renderProviderIcon = () => {
    switch (provider) {
      case 'google':
        return (
          <>
            <FcGoogle size={20} className="mr-[5px]" /> Google
          </>
        );
      case 'facebook':
        return (
          <>
            <FaFacebookSquare size={20} color="#395185" className="mr-[5px]" /> Facebook
          </>
        );
    }
  };

  const disabledClasses = () => {
    if (disabled) return 'opacity-40 cursor-not-allowed';
  };

  return (
    <button
      onClick={!disabled && onClick}
      className={`
                flex
                items-center 
                justify-center 
                w-full 
                bg-[#181829] 
                text-white
                font-medium
                py-4
                px-2.5
                rounded-full
                border-[1px] 
                border-white
                ${disabledClasses()}
            `}
    >
      {renderProviderIcon()}
    </button>
  );
};

export default ButtonLoginSocial;
