import styled from '@emotion/styled';

export const PageLayoutContainer = styled.div`
  width: 100%;
  height: auto;
  overflow-y: hidden;
  background: #18182a;
  min-height: 100vh;
  height: auto;
  position: relative;
  color: #fff;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

interface MainContainerProps {
  isChangeLayout?: boolean;
}

export const AppMainContainer = styled.div<MainContainerProps>`
  display: grid;
  grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px auto')};
  background: ${({ isChangeLayout }) => (isChangeLayout ? 'transparent' : '#18182a')};

  @media (max-width: 1395px) {
    grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px 80%')};
  }

  @media (max-width: 1320px) {
    grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px 79%')};
  }

  @media (max-width: 1250px) {
    grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px 78%')};
  }

  @media (max-width: 1200px) {
    grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px 77%')};
  }

  @media (max-width: 1110px) {
    grid-template-columns: ${({ isChangeLayout }) => (isChangeLayout ? '100%' : '290px 75%')};
  }

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;

export const PageLayoutChildrenContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  height: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  gap: 20px;

  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
