import { useEffect, useState } from 'react';

import Button from '../../components/Button/Button';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { Link, useNavigate } from 'react-router-dom';

import { useUserContext } from '../../common/context/UserContext';
import { userTransactionsHistory } from '../../service/user/user.service';
import { formatDay, formatValue } from '../../utils/types';
import {
  ButtonContainer,
  Column,
  ContainerLine,
  InfoContainer,
  MainContainer,
  ProfileTitle,
 
} from './styles';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

import { ReactComponent as InfoIcon } from '../../utils/icons/info.svg';
import { BallonCoins, BallonMoney } from '../../components/Ballon';
import { useUserAuth } from '../../hooks/user-auth';
import { UserTransactions } from './user-transactions';
import { CoinsTransactions } from './coins-transactions';

interface CoinsProps {
  result: infoCoins[];
  buy: infoCoins[];
}

interface infoCoins {
  status: string;
  dateBet: string;
  value: number;
}

const filterValue = {
  user: "",
  coin: ""
}

const Profile = () => {
  const { userLogged } = useUserContext();
  const [filter, setFilter] = useState<keyof typeof filterValue>("coin");
  const windowsSize = useGetWindowSize();
  const [infoBallonCoins, setInfoBallonCoins] = useState(false);
  const [infoBallonMoney, setInfoBallonMoney] = useState(false);
  useUserAuth({ isPrivatePage: true });

  const history = useNavigate();
 
 

 

  return (
    <PageContainer>
      <div>
        <ProfileTitle>Meus Saldos</ProfileTitle>
        <MainContainer>
          <InfoContainer>
            <div className="alignBox">
              <p>Saldo em fichas</p>
              <div className="titleInfo">
                <InfoIcon onClick={() => setInfoBallonCoins(!infoBallonCoins)} />
                {infoBallonCoins && <BallonCoins />}
              </div>
            </div>
            <ContainerLine
              hasToBreak={userLogged?.usersCoins[0] && userLogged?.usersCoins[0].value.toString().length > 4}
            >
              <h2>
                {userLogged?.usersCoins[0].value}
                {userLogged?.usersCoins[0].value === 1 ? ' ficha' : ' fichas'}
              </h2>
              <Button
                hierarchy="primary"
                label={windowsSize.width < 700 ? 'Comprar' : 'Comprar mais fichas'}
                size={windowsSize.width < 700 ? 'sm' : 'lg'}
                weight="thin"
                onClick={() => history('/coins')}
              />
            </ContainerLine>
          </InfoContainer>

          <InfoContainer>
            <div className="alignBox">
              <p>Saldo em reais</p>
              <div className="titleInfo">
                <InfoIcon onClick={() => setInfoBallonMoney(!infoBallonMoney)} />
                {infoBallonMoney && <BallonMoney />}
              </div>
            </div>
            <ContainerLine
              hasToBreak={(userLogged?.wallet[0]?.value ? formatValue(userLogged?.wallet[0].value) : '0,00').length > 5}
            >
              <h2>R${userLogged?.wallet[0]?.value ? formatValue(userLogged?.wallet[0].value) : '0,00'}</h2>
              <Link
                to="/profile/withdraw"
                className={window.innerWidth < 700 ? 'max-w-[224px]' : 'w-full max-w-[224px]'}
              >
                <Button
                  hierarchy="secondary"
                  label="Sacar"
                  size={windowsSize.width < 700 ? 'sm' : 'lg'}
                  weight="thin"
                  disabled={`${userLogged?.wallet[0]?.value}` === '0.00'}
                  moreClasses=" text-white border-white"
                />
              </Link>
            </ContainerLine>
          </InfoContainer>

          <Column>
            <div>
              <p>Histórico de transações</p>
            </div>
            <ButtonContainer>
              <Button
                hierarchy={filter !== 'coin' ? 'secondary' : 'primary'}
                label="Fichas"
                size={'sm'}
                weight="thin"
                moreClasses={filter !== 'coin' ? ' text-white border-white' : ''}
                onClick={() => setFilter('coin')}
              />
              <Button
                hierarchy={filter !== 'user' ? 'secondary' : 'primary'}
                label="Reais"
                size={'sm'}
                weight="thin"
                moreClasses={filter !== 'user' ? ' text-white border-white' : ''}
                onClick={() => setFilter('user')}
              />
            </ButtonContainer>
            {filter === 'coin' && <CoinsTransactions />}
            {filter === 'user' && <UserTransactions />}
          </Column>
        </MainContainer>
      </div>
    </PageContainer>
  );
};

export default Profile;
