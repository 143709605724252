import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: auto;
  //padding-top: 150px;
  //padding-bottom: 50px;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    position: static;
    height: auto;
    padding-top: 20px;
    background: #28293d;
    overflow: hidden;
  }
`;

export const ModalAlertContainerStyle = styled.div`
  background: #28293d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 51px 68px;
  position: relative;

  @media (max-width: 600px) {
    padding: 30px 10px;
    background: transparent;
  }
`;

export const ModalButtonGroup = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 32px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const ButtonContained = styled.button`
  width: 182px;
  height: 49px;
  background: #ff8e00;
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #1c1c27;
  margin-top: 20px;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const ButtonOutlined = styled(ButtonContained)`
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: transparent;
  justify-content: center;
  margin-right: 16px;

  @media (max-width: 600px) {
    border: 1px solid transparent;
    margin-right: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
`;
