import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import Input from '../../components/Input/Input';
import { useUserAuth } from '../../hooks/user-auth';

const Checkout = () => {
  const navigate = useNavigate();
   useUserAuth({ isPrivatePage: true })

  return (
    <PageContainer className="w-[700px]">
      <div className="pt-[20px]">
        <h1>Adicionando Fichas</h1>
        <form>
          <div className="md:max-w-[400px] md:mr-[25px] mr-[18px] my-[10px]">
            <label>E-mail</label>
            <Input
              name="E-mail"
              placeholder="E-mail"
              type="text"
              moreClasses="max-w-[380px] max-h-[48px] !bg-[#252935]"
            />
          </div>
          <div>
            <label>Dados do Cartão</label>
            <Input
              name="Dados do Cartão"
              placeholder="Dados do Cartão"
              type="password"
              moreClasses="max-w-[380px] max-h-[48px] !bg-[#252935]"
            />
            <div className=" flex flex-row ">
              <Input
                name="MM / AA"
                placeholder="Dados do Cartão"
                type="date"
                moreClasses="max-w-[190px] max-h-[48px] !bg-[#252935]"
              />
              <Input
                name="CVC"
                placeholder="Dados do Cartão"
                type="number"
                moreClasses="max-w-[190px] max-h-[48px] !bg-[#252935]"
              />
            </div>
          </div>
          <div>
            <label>Nome no cartão</label>
            <Input
              name="Nome no cartão"
              placeholder="Nome no cartão"
              type="text"
              moreClasses="max-w-[380px] max-h-[48px] !bg-[#252935]"
            />
          </div>
          <div>
            <label>Região</label>
            <Input
              name="Região"
              placeholder="Região"
              type="text"
              moreClasses="max-w-[380px] max-h-[48px] mx-auto !bg-[#252935]"
            />
          </div>
        </form>
        <Button
          moreClasses="max-w-[380px] block mx-auto my-[30px]"
          label="Pagar"
          hierarchy="primary"
          size="lg"
          onClick={() => navigate('/')}
        />
      </div>
    </PageContainer>
  );
};

export default Checkout;
