import { removeCharsOnHtml } from '../../utils/banner-helper';
import api from '../api';

export const getCmsHtml = async (typeId: string | number) => {
  return await api
    .get(`/public/cms-pages?typeId=${typeId}`)
    .then((response) => {
      

      const result  = response.data.data.data[0].text

      const htmlText = removeCharsOnHtml(result)

      return htmlText;
    })
    .catch((response) => {
      return response.response.data;
    });
};
