import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingProps {
  isLoading: boolean;
}

const Loading = ({ isLoading }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <SkeletonTheme baseColor="#4B4C66">
      <div className="circle-right flex ">
        <div className="mr-[15px] grow">
          <Skeleton width={40} height={20} />
          <Skeleton width={40} height={20} />
        </div>
        <div>
          <Skeleton circle width={20} height={20} />
          <Skeleton width={40} height={20} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

const LoadingPot = ({ isLoading }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <SkeletonTheme height="40px" borderRadius="20px" baseColor="#4B4C66">
      <Skeleton className="p-[0.5rem] max-w-[543px] circle-right flex w-full mr-[15px] grow mb-[1rem]" />
      <Skeleton className="p-[0.5rem] max-w-[543px] circle-right flex w-full mr-[15px] grow mb-[1rem]" />
      <Skeleton className="p-[0.5rem] max-w-[543px] circle-right flex w-full mr-[15px] grow mb-[1rem]" />
      <Skeleton className="p-[0.5rem] max-w-[543px] circle-right flex w-full mr-[15px] grow mb-[1rem]" />
      <Skeleton className="p-[0.5rem] max-w-[543px] circle-right flex w-full mr-[15px] grow mb-[1rem]" />
    </SkeletonTheme>
  );
};

const LoadingBet = ({ isLoading }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <SkeletonTheme height="100px" width="168px" borderRadius="14px" baseColor="#4B4C66">
      <div>
        <div className="flex mb-2">
          <Skeleton height={20} width={20} className="mr-2" />
          <Skeleton height={20} width={20} />
        </div>
        <div className="mr-[15px] grow mb-[1rem] flex">
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
        </div>
      </div>
    </SkeletonTheme>
  );
};

const LoadingHightlights = ({ isLoading }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <SkeletonTheme height="100px" width="168px" borderRadius="14px" baseColor="#4B4C66">
      <div>
        <div className="mr-[15px] grow mb-[1rem] flex">
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
          <Skeleton className="rounded-15 w-[168px] min-w-[168px] max-h-[130px] mr-2" />
        </div>
      </div>
    </SkeletonTheme>
  );
};

const LoadingMyPot = ({ isLoading }: LoadingProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <SkeletonTheme height="100px" width="168px" borderRadius="14px" baseColor="#4B4C66">
      <div className="flex flex-row gap-[10px] w-full relative">
        <div className="mr-[15px] grow mb-[1rem]">
          <Skeleton className="rounded-15 w-full max-w-[100px] max-h-[32px]" />
        </div>
        <div className="mr-[15px] grow mb-[1rem]">
          <Skeleton className="rounded-15 w-full max-w-[100px] max-h-[32px]" />
        </div>
        <div className="mr-[15px] grow mb-[1rem]">
          <Skeleton className="rounded-15 w-full max-w-[100px] max-h-[32px]" />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export { Loading, LoadingPot, LoadingBet, LoadingMyPot, LoadingHightlights };
