import { useState } from 'react';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { NewUserContainer, SeparationLine, TableContainer } from './styles';
import { useGetGroupUsers } from './use-get-group-users';
import moment from 'moment';

export const GroupUsers = ({ groupId }: { groupId: number }) => {
  const { groupUsers } = useGetGroupUsers({ groupId });
  const totalItems = groupUsers.length;
  const [limit, setLimit] = useState(10);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;

  const filteredUsers = isMobile ? groupUsers.slice(0, limit) : groupUsers

  const handlePagination = () => {
    if (limit === totalItems) {
      return null;
    }

    const sum = limit + 10;

    if (sum <= totalItems) {
      setLimit(sum);
    } else {
      setLimit(totalItems);
    }
  };

  return (
    <NewUserContainer>
      <h2>Novos Usuários</h2>
      <SeparationLine />
      <TableContainer className="boxContainer">
        <div className="table-header">
          <p>Nome</p>
          <p>Data de entrada</p>
        </div>
        <div className="users-table-list">
          {filteredUsers &&
            filteredUsers.map((item, index) => (
              <div className="table-list-item" key={index}>
                <span>{item.login}</span>
                {moment(item.updatedAt).format('DD/MM/YYYY')}
              </div>
            ))}
        </div>
      </TableContainer>
      {limit !== totalItems && <>{isMobile && <button onClick={handlePagination}>Ver mais</button>}</>}
    </NewUserContainer>
  );
};
