import { useEffect, useState } from 'react';
import { getPots } from '../service/pots/pots.service';
import { Game, MatchPotType } from '../typescript/match';
import { sportOptions } from '../utils/sportOptions';
import { isToday, parseISO, isBefore } from 'date-fns';
import moment from 'moment';
import {getCurrentTimestamp} from "../utils/timestampDisplay";


interface UseGetGameMatchesProps {
  isLoadingMatch: boolean;
  selectMatch: MatchPotType[];
  categoriesAvailable: string[];
}

export const isExpired = (potDate: string) => getCurrentTimestamp() > new Date(potDate).getTime();

export const useGetGameMatches = (): UseGetGameMatchesProps => {
  const [selectMatch, setSelectMatch] = useState<MatchPotType[]>([]);
  const [isLoadingMatch, setIsLoadingMatch] = useState(false);
  const categoriesAvailable = selectMatch.filter((match) => match.games.length).map((match) => match.name);
  const today = getCurrentTimestamp()

  async function getAllPots() {

    setIsLoadingMatch(true)
    const response = await getPots(`/?expire_in=48&take=100&status=Em Andamento`);
    function filterMatchs(sport: string) {
      return response?.data?.data.filter((pot: Game) => {
        const dateInitialBet = moment(pot.dateInitialBet, 'YYYY-MM-DD');

        return pot.sports === sport && 
              !isExpired(pot.dateFinalBet) &&   
              dateInitialBet.isValid() &&
              dateInitialBet.valueOf() <= today;
      });
    }

  
    const result = sportOptions.slice(2).map((sport)  => ({
      name: sport.name,
      icon: sport.icon,
      games: filterMatchs(sport.name),
    }));

    setSelectMatch(result);
    setIsLoadingMatch(false)
  }

  useEffect(() => {
    getAllPots();
  }, []);

  return { selectMatch, categoriesAvailable, isLoadingMatch };
};
