import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavBarMenuContext } from '../../common/context/NavbarContext';
import { useGetGameMatches } from '../../hooks/useGetGameMatches';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useHideScrollWhenShowModal } from '../../hooks/useHideScrollWhenShowModal';
import { getGrouByUser } from '../../service/group/group';
import { getUserInfo } from '../../service/user/user.service';
import { RootState } from '../../store';
import { slice } from '../../store/reducers/user/userSlice';
import { UserGroup } from '../../typescript/group';
import { ReactComponent as EditIcon } from '../../utils/icons/edit.svg';
import { ReactComponent as LoggoutIcon } from '../../utils/icons/loggout.svg';
import { normalizeName } from '../../utils/normalize-name';
import { sportOptions } from '../../utils/sportOptions';
import { handleBackToTop } from '../../utils/window-func';
import Button from '../Button/Button';
import CreateGroup from '../CreateGroup';
import ParticipateGroup from '../ParticipateGroup';
import TopUserInfo from '../TopUserInfo/TopUserInfo';
import { firebaseAuth } from './../../service/firebase/firebase';
import {
  ButtonRow,
  MenuCategoryItem,
  NavbarButtonGroupLoggout,
  NavbarContainer,
  NavbarMenuHeader,
  NavbarMenuList,
  NavbarMenuListContainer,
  NavbarMenuListItem,
  NavbarMenuTitle,
  OverlayModalCreateGroup,
  TextSkeleton,
} from './styles';
import { SessionControl } from '../../session/SessionControl';

const MENU_ITEMS = {
  home: [
    { title: 'Home', route: '/' },
    { title: 'Grupo dos Parças', route: '/about-group' },
    { title: 'Quem Somos', route: '/about' },
    { title: 'Regras', route: '/rules' },
  ],
  myAccount: [
    { title: 'Meus Saldos', route: '/profile' },
    { title: 'Configurações', route: '/account' },
  ],
};

const NavbarMenu = () => {
  const { userInfo, token } = useSelector((state: RootState) => state.user);
  const { currentGroupParticipate } = useSelector((state: RootState) => state.linkGroupInvited);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModalGroup, setOpenModalGroup] = useState(false);
  const [openModalParticipate, setOpenModalParticipate] = useState(false);
  const windowSize = useGetWindowSize();
  const [userGroup, setUserGroup] = useState<UserGroup | null>(null);
  const [groupParticipation, setGroupParticipation] = useState<UserGroup | null>(null);
  const [loadingMyGroup, setLoadingMyGroup] = useState(true);
  const [loadingOurGroup, setLoadingOurGroup] = useState(true);
  const { openMenu, setOpenMenu } = useContext(NavBarMenuContext);
  useHideScrollWhenShowModal({ state: openMenu });
  const { categoriesAvailable, isLoadingMatch } = useGetGameMatches();

  const location = useLocation();
  const appPath = location.pathname;
  const isStillPotBet = appPath.split('/').includes('pot-bet');

  function handleLogout() {
    dispatch(slice.actions.logout());
    firebaseAuth.signOut();
    navigate('/');
    SessionControl.clearSession();
    return window.location.reload();
  }

  const backToTop = () => {
    handleBackToTop();
  };

  async function handleGetUserGroup() {
    const response = await getGrouByUser(userInfo.id);

    if (response.status === 200) {
      setUserGroup(response.data);
    }

    setLoadingMyGroup(false);
  }

  async function handleGetUserGroupParticipate() {
    const userInfo = await getUserInfo();
    let group = null;

    if (userInfo.id) {
      if (userInfo.userGroup?.length) {
        group = userInfo.userGroup[0];
        setGroupParticipation(group);
      }
    }

    setLoadingOurGroup(false);
    return group;
  }

  async function handleClickGroupParticipation() {
    setOpenMenu(false);

    //added check for if the group name has changed while static on page
    const group = await handleGetUserGroupParticipate();

    if (group) {
      return navigate(`/${normalizeName(group.name)}`);
    }

    if (windowSize.width < 750) {
      return navigate('/participate-group');
    }

    return setOpenModalParticipate(true);
  }

  function handleClickCreateGroup() {
    setOpenMenu(false);

    if (userGroup) {
      return navigate(`/${normalizeName(userGroup.name)}`);
    }

    if (windowSize.width < 750) {
      return navigate('/create-group');
    }

    return setOpenModalGroup(true);
  }

  useEffect(() => {
    handleGetUserGroupParticipate();
  }, [currentGroupParticipate, token]);

  useEffect(() => {
    if (appPath === '/login' || !token) {
      return;
    }

    handleGetUserGroup();
  }, [userInfo.userGroup?.length, appPath, userInfo, token]);

  function handleNavigate(route: string) {
    backToTop();
    setOpenMenu(false);
    navigate(route);
  }

  const isPasswordPage = appPath.includes('password');

  const hideNavbar = (appPath === '/login' || appPath === '/register' || isPasswordPage) && windowSize.width >= 1024;

  if (hideNavbar) {
    return null;
  }

  return (
    <>
      {openModalGroup && (
        <OverlayModalCreateGroup>
          <CreateGroup rulesGroup={true} hasBtnBack handleCloseModal={() => setOpenModalGroup(false)} />
        </OverlayModalCreateGroup>
      )}

      {openModalParticipate && (
        <OverlayModalCreateGroup>
          <ParticipateGroup
            handleBack={() => (windowSize.width < 750 ? navigate(-1) : setOpenModalParticipate(false))}
          />
        </OverlayModalCreateGroup>
      )}

      <NavbarContainer open={openMenu}>
        {openMenu && (
          <NavbarMenuHeader>
            <button onClick={() => setOpenMenu(false)}>
              <img src="/close-icon.svg" alt="close menu" />
            </button>
            <Link to="/" className="logo mb-4" onClick={() => setOpenMenu(false)}>
              <img src="/logo-rodape.svg" alt="Money Pot logo" />
            </Link>
          </NavbarMenuHeader>
        )}

        <div className="container-list">
          <NavbarMenuListContainer>
            {!openMenu && (
              <Link to="/" className="logo mb-4" onClick={() => setOpenMenu(false)}>
                <img src="/logo-rodape.svg" alt="Money Pot logo" />
              </Link>
            )}

            <NavbarMenuList>
              {!!token && openMenu && (
                <TopUserInfo
                  handleCloseMobileMenu={() => setOpenMenu(false)}
                  className="wallet-info-mobile mb-5 mt-5"
                />
              )}

              {!token && openMenu && (
                <ButtonRow>
                  <Button
                    hierarchy="tertiary"
                    label="Criar conta"
                    size="sm"
                    moreClasses="text-base text-[#fff]"
                    weight="thin"
                    onClick={() => handleNavigate('/register')}
                  />
                  <Button
                    hierarchy="primary"
                    label="Entrar"
                    size="sm"
                    weight="thin"
                    onClick={() => handleNavigate('/login')}
                  />
                </ButtonRow>
              )}

              {MENU_ITEMS.home.map((item) => (
                <NavbarMenuListItem
                  active={location.pathname === item.route}
                  onClick={() => handleNavigate(item.route)}
                  key={item.route}
                >
                  {item.title}
                </NavbarMenuListItem>
              ))}
            </NavbarMenuList>

            <NavbarMenuList>
              <NavbarMenuTitle>Categoria</NavbarMenuTitle>
              {isLoadingMatch ? (
                <div className="pl-4">
                  <TextSkeleton />
                  <TextSkeleton className="mt-4" />
                </div>
              ) : (
                sportOptions.map((item) => {
                  if (!categoriesAvailable.includes(item.name)) {
                    return null;
                  }

                  return (
                    <MenuCategoryItem
                      active={location.pathname === `/category/${item.name.toLowerCase()}`}
                      onClick={() => handleNavigate(`category/${item.name.toLowerCase()}`)}
                      key={item.name}
                    >
                      <item.icon className={'sport-icon '} />
                      {item.title ?? item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                    </MenuCategoryItem>
                  );
                })
              )}
            </NavbarMenuList>

            {token && (
              <>
                <NavbarMenuList>
                  <NavbarMenuTitle>Minha Conta</NavbarMenuTitle>
                  <NavbarMenuListItem
                    active={location.pathname === '/bets' || isStillPotBet}
                    onClick={() => handleNavigate('/bets')}
                  >
                    Meus Palpites
                  </NavbarMenuListItem>
                  {MENU_ITEMS.myAccount.map((item) => (
                    <NavbarMenuListItem
                      active={location.pathname === item.route}
                      onClick={() => handleNavigate(item.route)}
                      key={item.route}
                    >
                      {item.title}
                    </NavbarMenuListItem>
                  ))}
                </NavbarMenuList>

                <NavbarMenuList>
                  <NavbarMenuTitle>Meus grupos</NavbarMenuTitle>
                  <NavbarMenuListItem
                    onClick={handleClickGroupParticipation}
                    active={
                      location.pathname === `/${normalizeName(groupParticipation?.name as string)}` &&
                      location.pathname !== '/'
                    }
                  >
                    <>
                      {loadingMyGroup ? (
                        <TextSkeleton />
                      ) : (
                        <>{groupParticipation ? groupParticipation?.name : 'Participar de um Grupo'}</>
                      )}
                    </>
                  </NavbarMenuListItem>

                  <NavbarMenuListItem
                    onClick={handleClickCreateGroup}
                    active={
                      location.pathname === `/${normalizeName(userGroup?.name as string)}` && location.pathname !== '/'
                    }
                  >
                    {loadingOurGroup ? (
                      <TextSkeleton />
                    ) : (
                      <>
                        {userGroup ? (
                          <div className="flex items-center">
                            <p className="truncate">{userGroup.name}</p>
                            <div className="ml-5">
                              <EditIcon />
                            </div>
                          </div>
                        ) : (
                          'Criar Grupo'
                        )}
                      </>
                    )}
                  </NavbarMenuListItem>
                </NavbarMenuList>
              </>
            )}

            <NavbarButtonGroupLoggout>
              {token ? (
                <NavbarMenuListItem onClick={() => handleLogout()} active={false} className="flex gap-2">
                  <LoggoutIcon></LoggoutIcon>
                  Sair
                </NavbarMenuListItem>
              ) : (
                <>
                  {!openMenu && (
                    <ButtonRow>
                      <Button
                        hierarchy="tertiary"
                        label="Criar conta"
                        size="sm"
                        moreClasses="text-base"
                        weight="thin"
                        onClick={() => handleNavigate('/register')}
                      />
                      <Button
                        hierarchy="primary"
                        label="Entrar"
                        size="sm"
                        weight="thin"
                        onClick={() => handleNavigate('/login')}
                      />
                    </ButtonRow>
                  )}
                </>
              )}
            </NavbarButtonGroupLoggout>
          </NavbarMenuListContainer>
        </div>
      </NavbarContainer>
    </>
  );
};

export default NavbarMenu;
