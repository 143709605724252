import PageContainer from '../../layouts/PageContainer/PageContainer';
import {
  AboutGroupInstrutionButton,
  AboutGroupInstrutionContainer,
  AboutGroupInstrutionItem,
  AboutGroupInstrutionItemDescription,
  AboutGroupInstrutionItemIllustration,
  AboutGroupInstrutionItemTitle,
} from './styles';
import { ReactComponent as RightArrow } from '../../utils/icons/rightArrow.svg';
import { ReactComponent as LeftArrow } from '../../utils/icons/leftArrow.svg';
import { ReactComponent as Winner } from '../../utils/icons/troph.svg';
import { ReactComponent as GrouIcon } from '../../utils/icons/group.svg';
import { ReactComponent as MoneyPotMessageIcon } from '../../utils/icons/moneypot-message.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';

interface InstructionItemType {
  icon: React.ReactNode;
  title: string;
  description: string;
  descStrong?: string;
}

interface AboutInstructionsProps {
  hideButton?: boolean;
}

const AboutInstructions = ({ hideButton }: AboutInstructionsProps) => {
  const instructions: InstructionItemType[] = [
    {
      icon: <GrouIcon />,
      title: 'Monte seu grupo',
      description: 'Clique em criar e pense num nome bacana e fácil pra todo mundo identificar.',
      descStrong: '',
    },
    {
      icon: <MoneyPotMessageIcon />,
      title: 'Compartilhe',
      description: 'Divulgue seu link pra seguidores, amigos, família…',
      descStrong: 'É hora de chamar a galera!',
    },

    {
      icon: <Winner />,
      title: 'Ganhe',
      description: 'Toda vez que um membro do seu grupo comprar fichas ou acertar um palpite, você ganha junto!',
      descStrong: 'Agora é lucrar!',
    },
  ];

  return (
    <AboutGroupInstrutionContainer>
      <div className="mobile-slider">
        <Swiper
          pagination={{ dynamicBullets: true }}
          modules={[Pagination]}
          className="mySwiper"
          spaceBetween={50}
          slidesPerView={1}
        >
          {instructions.map((item, index) => (
            <SwiperSlide key={item.title}>
              <AboutGroupInstrutionItem>
                <AboutGroupInstrutionItemIllustration>{item.icon}</AboutGroupInstrutionItemIllustration>
                <AboutGroupInstrutionItemTitle>{item.title}</AboutGroupInstrutionItemTitle>
                <AboutGroupInstrutionItemDescription>
                  {' '}
                  {!!item.descStrong && <span>{item.descStrong}</span>} {item.description}
                </AboutGroupInstrutionItemDescription>
                {index === 1 && <LeftArrow className="svg-icon arrow-icon-slider" />}
                {index === 0 && <RightArrow className="svg-icon arrow-icon-slider" />}
              </AboutGroupInstrutionItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="about-instruction-container">
        <AboutGroupInstrutionItem>
          <AboutGroupInstrutionItemIllustration>{instructions[0].icon}</AboutGroupInstrutionItemIllustration>
          <AboutGroupInstrutionItemTitle>{instructions[0].title}</AboutGroupInstrutionItemTitle>
          <AboutGroupInstrutionItemDescription>
            {!!instructions[0].descStrong && <span>{instructions[0].descStrong}</span>} {instructions[0].description}
          </AboutGroupInstrutionItemDescription>
        </AboutGroupInstrutionItem>
        <RightArrow className="svg-icon" />
        <AboutGroupInstrutionItem>
          <AboutGroupInstrutionItemIllustration>{instructions[1].icon}</AboutGroupInstrutionItemIllustration>
          <AboutGroupInstrutionItemTitle>{instructions[1].title}</AboutGroupInstrutionItemTitle>
          <AboutGroupInstrutionItemDescription>
            {!!instructions[1].descStrong && <span>{instructions[1].descStrong}</span>} {instructions[0].description}
          </AboutGroupInstrutionItemDescription>
        </AboutGroupInstrutionItem>
        <LeftArrow className="svg-icon" />
        <AboutGroupInstrutionItem>
          <AboutGroupInstrutionItemIllustration>{instructions[2].icon}</AboutGroupInstrutionItemIllustration>
          <AboutGroupInstrutionItemTitle>{instructions[2].title}</AboutGroupInstrutionItemTitle>
          <AboutGroupInstrutionItemDescription>
            {!!instructions[2].descStrong && <span>{instructions[2].descStrong}</span>} {instructions[2].description}
          </AboutGroupInstrutionItemDescription>
        </AboutGroupInstrutionItem>
      </div>
      {!hideButton && (
        <a href="#create-group-section">
          <AboutGroupInstrutionButton>Criar agora!</AboutGroupInstrutionButton>
        </a>
      )}
    </AboutGroupInstrutionContainer>
  );
};

export default AboutInstructions;
