import styled from '@emotion/styled';

export const ModalWarningSamePredictOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    height: 100%;
    background: #28293d;
  }
`;

export const ModalWarningSameContainer = styled.div`
  width: 500px;
  height: 412px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 51px 96px;
  background: #28293d;
  border-radius: 20px;
  position: relative;

  @media (max-width: 600px) {
    height: 100%;
    border-radius: 0;
  }

  .btn-close-modal {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .text-warning {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;

    @media (max-height: 400px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .btn-group {
    width: auto;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    justify-content: space-between;
    column-gap: 16px;

    @media (max-width: 600px) {
      justify-content: center;
      align-items: center;
      column-gap: 0px;
      flex-direction: column;
      row-gap: 15px;
    }
  }
`;
