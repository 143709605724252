import styled, { keyframes } from 'styled-components';

const SkeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
`;

export const TextSkeleton = styled.div`
  width: 70%;
  height: 0.5rem;
  border-radius: 0.25rem;
  animation: ${SkeletonLoading} 1s linear infinite alternate;
`;

export const ButtonOpenNavbar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  z-index: 50;
  width: auto;
  height: auto;
  padding-top: 5px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const navbarClasses = (open: boolean) => `${open ? 'translate-x-0' : 'translate-x-[-100%]'}
      overflow-x-hidden ease-in-out duration-300 z-50
    lg:w-[290px] lg:rounded-none lg:fixed lg:z-0
    absolute top-0 left-0 h-[100vh] w-[100vw] bg-background-100`;

interface NavbarProps {
  open: boolean;
}

export const NavbarMenuHeader = styled.div`
  border-bottom: 1px solid #4b4c66;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 10px 26px;

  button {
    margin-right: 22px;
    background-color: transparent;
    outline: none;
    border: none;
  }
`;

export const NavbarContainer = styled.div<NavbarProps>`
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  z-index: 50;
  width: 290px;
  max-height: 100%;
  top: 0;
  left: 0;

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
    background-color: #4b4c66;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 100vh;
    background: #1c1c27;
    position: fixed;
    display: ${({ open }) => (open ? 'block' : 'none')};
  }

  @media (max-width: 802px) {
    z-index: 100;

    .container-list {
      height: 85vh;
      overflow: auto;
      padding-bottom: 20px;
    }
  }
`;

export const NavbarButtonGroup = styled.div`
  width: 100%;
  height: 54px;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const NavbarMenuListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  padding-top: 20px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
`;

export const NavbarMenuList = styled.ul`
  padding-right: 20px;
  list-style: none;
  box-sizing: border-box;

  @media (max-width: 405px) {
    .wallet-info-mobile .btn-grou-wallet {
      left: 0;
    }
  }
`;

interface ListItemProps {
  active: boolean;
}

export const NavbarMenuListItem = styled.li<ListItemProps>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ active }) => (active ? '#FFFFFF' : '#c4c4c4')};
  background: ${({ active }) => (active ? '#28293D' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  min-height: 34px;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  &:hover {
    color: #ff8e00;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const NavbarMenuTitle = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 18px;
  color: #e4e4e6;
  padding: 0;
  width: 90%;
`;

export const OverlayModalCreateGroup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 750px) {
    position: static;
    height: auto;
    background: transparent;
    z-index: 0;
    padding-bottom: 30px;
  } ;
`;

export const NavbarButtonGroupLoggout = styled.div`
  flex-direction: row;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const MenuCategoryItem = styled(NavbarMenuListItem)`
  width: 100%;
  display: flex;
  flex-direction: row;

  .sport-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    path {
      fill: ${({ active }) => (active ? '#FF8E00' : 'white')};
    }
  }
`;
