import { Navigate, useNavigate } from 'react-router-dom';
import CreateGroup from '../../components/CreateGroup';
import { useUserAuth } from '../../hooks/user-auth';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { OverlayModalCreateGroup } from './../../components/NavbarMenu/styles';

const CreateGroupPage = () => {
  const navigate = useNavigate();
  useUserAuth({ isPrivatePage: true })


  return (
    <PageContainer>
      <OverlayModalCreateGroup>
        <CreateGroup isPage rulesGroup={true} hasBtnBack handleBack={() => navigate(-1)} />
      </OverlayModalCreateGroup>
    </PageContainer>
  );
};

export default CreateGroupPage;
