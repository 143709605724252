import styled from 'styled-components';

export const PotBanner = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.5) 97.39%);
  overflow: hidden;
  border-radius: 30px;

  @media (max-width: 800px) {
    background: transparent;
    /* overflow: auto; */
    height: auto;

    &:first-child {
      margin-top: 30px;
    }
  }

  p {
    width: 100%;
    height: 100%;
    border-radius: 30px;

    @media (max-width: 800px) {
      height: auto;
    }

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      border-radius: 30px;

      @media (max-width: 800px) {
        object-fit: scale-down;
      }
    }
  }
`;

export const ItemPotBetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px;
  background: #28293d;
  width: 100%;
  padding: 16px;
  @media (min-width: 650px) {
    padding: 24px;
  }
`;

export const ItemPotBetHeaderInfoContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
