import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { getCmsHtml } from '../../service/cms/cms.service';

import { cmsPagesEnum } from '../../utils/cmsPagesEnum';

import Input from '../../components/Input/Input';
import { passwordLabels, rules } from '../Account/helper';
import { updatecreateNewPassword } from '../../service/user/user.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../components/BasicAlerts';
import {
  AccountCreationContainer,
  AccountSidebarContainer,
  Container,
  FormContainer,
  LoginBackgroundImage,
} from '../Login/styles';
import { SimpleFeedbackText } from '../../components/SimpleFeedBackText';
import { BiKey } from 'react-icons/bi';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import Button from '../../components/Button/Button';
import { SpanAlert } from '../Account/styles';

const CreateNewPassword = () => {
  const [leftSideContent, setLeftSideContent] = useState('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [formValidity, setFormValidity] = useState({
    password: false,
    newPassword: false,
    repeatNewPassword: false,
    differentPasswords: false,
  });
  const [errorMessageRepeat, setErrorMessageRepeat] = useState<boolean>();
  const { LOGIN_PAGE_TYPEID } = cmsPagesEnum;

  const { token } = useParams();

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!token) return;

    try {
      setIsLoading(true);
      const response = await updatecreateNewPassword(password, repeatPassword, token);

      if (response.status === 400) {
        setIsLoading(false);
        setErrorMsg('Aconteceu algum erro');
      }

      if (response.status === 200) {
        setIsLoading(false);
        setStatusRequest('success');
        setMessage('Senha alterada com sucesso!');

        setTimeout(() => navigate('/'), 500);
      }
    } catch (error) {
      setIsLoading(false);

      setStatusRequest('error');
      setMessage('Ocorreu um erro! Tente novamente mais tarde.');
    }
  };

  useEffect(() => {
    setFormValidity({
      ...formValidity,
      differentPasswords: repeatPassword !== password,
    });
    if (password.length > 0 && repeatPassword.length > 0) {
      setErrorMessageRepeat(repeatPassword !== password && repeatPassword.length > 7 && password.length > 7);
    }
  }, [password, repeatPassword]);
  useEffect(() => {
    getCmsHtml(LOGIN_PAGE_TYPEID).then((res) => setLeftSideContent(res));
  }, []);

  return (
    <AccountCreationContainer>
      <LoginBackgroundImage
        className="bg-white hidden md:block"
        dangerouslySetInnerHTML={{ __html: leftSideContent }}
      />
      <AccountSidebarContainer>
        {statusRequest === 'error' && (
          <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} autoClose />
        )}
        <FormContainer>
          <Link to="/">
            <img src="/images/logo.svg" alt="moneypot" className="mb-[48px] max-w-[167px] w-full" />
          </Link>

          {statusRequest === 'success' && <SimpleFeedbackText text="Sua senha foi alterada com sucesso!" />}

          <h1 className="text-2xl font-semibold text-white">Criar nova senha</h1>
          <form onSubmit={(e: any) => e.preventDefault()} className="register-form w-full gap-[24px] flex flex-col">
            <Container>
              <Input
                name="password"
                placeholder="Senha"
                type="password"
                onChange={(e: any) => setPassword(e.target.value)}
                onSuccess={(v: any) => setFormValidity({ ...formValidity, password: v })}
                rules={[
                  rules.passwordMinLengthRule,
                  rules.passwordNumberRule,
                  rules.passwordUppercaseRule,
                  rules.passwordSymbolRule,
                ]}
                disabled={isLoading}
                icon={BiKey}
              />
              {password.length ? (
                <div className="ml-4">
                  {passwordLabels.map((item) => (
                    <span
                      key={item.label}
                      className={`
                            flex
                            items-center
                            ${item.rule(password) ? 'text-[#00DC6E]' : 'text-[#FF4444]'}
                        `}
                    >
                      {' '}
                      {item.rule(password) ? <IoIosCheckmark className="mr-1" /> : <IoIosClose className="mr-1" />}
                      {item.label}
                    </span>
                  ))}
                </div>
              ) : (
                ''
              )}
            </Container>
            <Container>
              <Input
                name="password"
                placeholder="Repetir Senha"
                type="password"
                onChange={(e: any) => setRepeatPassword(e.target.value)}
                disabled={isLoading}
                forceInvalid={errorMessageRepeat}
                icon={BiKey}
              />
              {!!errorMessageRepeat && <SpanAlert>Senhas não conferem</SpanAlert>}
            </Container>

            <div className="max-w-[327px]">
              <Button
                moreClasses="w-full h-[52px] font-semibold text-[16px] text-black"
                onClick={handleSubmit}
                label={isLoading ? 'loading...' : 'Alterar senha'}
                hierarchy="primary"
              />
            </div>
          </form>
          {/* <div className="flex justify-center align-center w-full">
            <p className="text-sm font-extralight text-center text-white">
              Não tem uma conta?
              <span
                className="text-[#FF8E00] cursor-pointer hover:underline ml-1"
                onClick={() => navigate('/register')}
              >
                Criar nova conta
              </span>
            </p>
          </div> */}
          <button className="mt-[16px] text-white w-full" onClick={() => navigate(-1)}>
            Voltar
          </button>
        </FormContainer>
      </AccountSidebarContainer>
    </AccountCreationContainer>
  );
};

export default CreateNewPassword;
