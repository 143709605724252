import styled from 'styled-components';
import { CreateGroupButton } from './../../components/CreateGroup/styles';

export const TopContainer = styled.div`
  padding-top: 20px;
`;

export const MainContainer = styled.div`
  max-width: 678px;
  width: 100%;
  margin-bottom: 30px;

  .simple-feedback-text  {
    width: 100%;
  }

  .simple-feedback-text  .content-container {
    display: grid;
    grid-template-columns:5% 95%;
    grid-gap: 5px;

    @media  (max-width: 750px){
      grid-template-columns:5% 95%;
      grid-gap: 15px;

    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 18px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Outfit';
  max-width: 678px;
  margin: 0.625rem 0 0.625rem 0;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

 

  label {
    font-size: 16px;
    font-weight: 400;
    color: #c4c4c4;
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4b4c66;
  }

  & label.Mui-focused {
    color: #c4c4c4;
  }

  & .MuiOutlinedInput-root {
    border: 1px;
    border-radius: 20px;
    border-color: white;

    &.Mui-focused fieldset {
      border-radius: 20px;
      border-color: #4b4c66;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      border: 1px;
      border-color: #4b4c66;
      border-radius: 20px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .textClass {
    border: 2px #4b4c66;
    border-color: #4b4c66;
    background-color: #202030;
    border-radius: 20px;
    width: 678px;
    height: 56px;

    @media (max-width: 767px) {
      max-width: 366px;
      width: 100%;
      height: 56px;
    }
  }

  .MuiSvgIcon-root {
    color: #fff;
  }

  .selectClass {
    border: 2px #4b4c66;
    border-color: #4b4c66;
    background-color: #202030;
    border-radius: 20px;
    width: 226px;
    height: 56px;
    color: #fff;
    margin-right: 16px;

    @media (max-width: 767px) {
      max-width: 366px;
      width: 100%;
      height: 56px;
    }
    @media (max-width: 500px) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  .textEmail {
    margin-right: 16px;
    @media (max-width: 500px) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  .textWidth {
    width: 100%;
    height: 56px;

    @media (max-width: 500px) {
      max-width: 366px;
      width: 100%;
      height: 56px;
    }
  }

  #outlined-helperText {
    color: white;
    padding-left: 24px;
  }
`;

export const SpanAlert = styled.span`
  display: block;
  margin-left: 19px;
  margin-top: 8px;
  font-size: 14px;
  color: #ff4444;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 55px;
  text-align: left;
`;

export const WarningRules = styled.div`
  margin-left: 20px;

  span {
    display: flex;
    justify-items: center;
    font-size: 14px;
  }
`;

export const GroupSettingsContainer = styled.div`
  max-width: 678px;
  height: auto;
  margin-bottom: 50px;
`;

export const GroupSettingsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .group-logo {
    width: 24px;
    height: 24px;
    border-radius: 10px;
    background: gray;
  }

  .group-name {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    margin-left: 10px;
  }
`;

export const GroupSettingsItemButton = styled(CreateGroupButton)`
  @media (max-width: 750px) {
    height: 45px;
    font-size: 14px;
  }
`;
