import styled from 'styled-components';
import { ModalAlertContainerStyle } from '../styles';

interface ModalProps {
  isWithPots?: boolean;
}

const cStyle = ModalAlertContainerStyle;

export const ModalCheckoutContainer = styled(cStyle)<ModalProps>`
  width: 836px;
  height: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  align-items: center;
  padding: 36px 68px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
    background-color: #4b4c66;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  @media (max-width: 906px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    box-shadow: 0px transparent;
    overflow: auto;
    border-radius: 0px;
    margin-top: 0;
    padding: 36px 30px;
  }

  .simple-text {
    margin-top: 10px;
  }
`;

export const ModalAlertCheckoutTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;

  span {
    font-weight: 400;
  }
`;

export const ModalAlertCheckoutSimpleText = styled.p`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const ModalCheckoutExtraIngoContainer = styled.div`
  width: 100%;
  height: auto;

  .pots-list {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-gap: 15px;
    height: 300px;
    max-height: auto;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 5px;
      height: 100%;
      background-color: #4b4c66;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }

    @media (min-width: 1024px) and (max-height: 725px) {
      height: 200px;
    }

    @media (max-width: 906px) {
      display: flex;
      flex-direction: column;

      .card-game-image {
        width: 100%;
      }

      .img-team {
        width: 150px;
        height: 150px;
      }
    }
  }

  .extra-info .pots-list {
    @media (max-width: 600px) {
      height: auto;
      max-height: auto;
      overflow: hidden;
    }
  }
`;

export const ModalExtraInfoTitle = styled.h1`
  margin-top: 24px;
  width: 100%;
  height: 50px;
  background: linear-gradient(90.52deg, #404160 31.54%, rgba(33, 33, 33, 0) 167.29%);
  border-radius: 8px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 32px;
  margin-bottom: 30px;

  svg {
    margin-right: 10px;
  }
`;

export const ModalAlertCheckoutBanner = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.5) 97.39%);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 80px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  @media (max-width: 782px) {
    height: 200px;
  }

  @media (max-width: 422px) {
    height: 150px;
  }

  .banner-category {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 600px) {
      margin-right: 0;
    }

    p {
      width: 100%;
      height: 100%;
    }

    p img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 782px) {
        object-fit: fill;
      }
    }
  }
`;
