import { CountdownTimer, CountdownTimer3 } from './CountdownTimer';

import { CountdownTimer2 } from './CountdownTimer';

interface DateProps {
  finalAt: Date;
}

export const Timer = ({ finalAt }: DateProps) => <CountdownTimer finalAt={finalAt} />;
export const Timer2 = ({ finalAt }: DateProps) => <CountdownTimer2 finalAt={finalAt} />;
export const Timer3 = ({ finalAt }: DateProps) => <CountdownTimer3 finalAt={finalAt} />;
