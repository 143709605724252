import styled from '@emotion/styled';

export const AboutGroupInstrutionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 449px;
  margin-bottom: 80px;
  padding-left: 70px;
  padding-right: 70px;
  border: 2px solid #ffffff;
  border-radius: 20px;

  @media (max-width: 1340px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 1080px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 730px) {
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0px;
  }

  .mobile-slider {
    width: 100%;
    align-items: center;
    justify-content: center;

    .mySwiper {
      .swiper-pagination {
        padding-right: 16px;
        position: absolute;
        bottom: 0;
      }
    }

    @media (min-width: 730px) {
      display: none;
    }

    .arrow-icon-slider {
      position: absolute;
      right: 0;
      margin-top: -48px;

      @media (max-width: 400px) {
        right: -48px;
      }

      @media (max-width: 300px) {
        right: -56px;
      }
    }
  }

  .about-instruction-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto 15% auto 15% auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: 730px) {
      display: none;
    }
  }

  .svg-icon {
    margin-top: -100px;

    @media (max-width: 1300px) {
      transform: scale(0.8);
    }

    @media (max-width: 1160px) {
      transform: scale(0.7);
    }

    @media (max-width: 180px) {
      transform: scale(0.6);
    }

    @media (max-width: 800px) {
      transform: scale(0.5);
    }
  }
`;

export const AboutGroupInstrutionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 730px) {
    margin-bottom: 30px;
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const AboutGroupInstrutionItemIllustration = styled.div`
  margin-bottom: 16px;

  @media (max-width: 1340px) {
    margin-bottom: 5px;

    svg {
      transform: scale(0.8);
    }
  }

  @media (max-width: 1080px) {
    svg {
      transform: scale(0.7);
    }
  }

  @media (max-width: 800px) {
    svg {
      transform: scale(1);
      margin-bottom: 16px;
    }
  }
`;

export const AboutGroupInstrutionItemTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;

  @media (max-width: 1080px) {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const AboutGroupInstrutionItemDescription = styled.p`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;

  span {
    font-weight: 600;
  }

  @media (max-width: 1080px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 13px;
  }

  @media (max-width: 730px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 70%;
  }
`;

export const AboutGroupInstrutionButton = styled.button`
  width: 170px;
  height: 52px;
  background: #ff8e00;
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c1c27;
  margin-top: 32px;
  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 16px;
    width: 292px;
  }
`;
