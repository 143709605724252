import styled from 'styled-components';

export const PotBanner = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.5) 97.39%);
  overflow: hidden;
  border-radius: 30px;

  @media (max-width: 800px) {
    background: transparent;
    /* overflow: auto; */
    height: auto;

    &:first-child {
      margin-top: 30px;
    }
  }

  p {
    width: 100%;
    height: 100%;
    border-radius: 30px;

    @media (max-width: 800px) {
      height: auto;
    }

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      border-radius: 30px;

      @media (max-width: 800px) {
        object-fit: scale-down;
      }
    }
  }
`;
