import styled from '@emotion/styled';

export const CMSPageContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
  padding-top: 50px;

  .btn-back {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const CMSPageContentHeader = styled.header`
  width: 100%;
  background: #1c1c27;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
  }
`;

export const PageContentCardContainer = styled.div`
  border-radius: 20px;
  height: auto;
  width: 100%;
  padding: 40px;
  position: relative;
  background: #222232;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const CMSPageContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin-top: 10px;

  h1,
  h2,
  h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 700px) {
      font-size: 28px;
    }

    @media (max-width: 470px) {
      font-size: 25px;
    }

    span {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: #fff;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  h2 {
    text-align: left;
    font-size: 20px;
    /* margin-top: 20px; */
    margin-bottom: 0;
    font-weight: 400;
    color: #fff;

    @media (max-width: 700px) {
      font-size: 18px;
    }

    @media (max-width: 470px) {
      font-size: 16px;
    }
  }

  h3 {
    text-align: left;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;

    @media (max-width: 470px) {
      font-size: 15px;
    }
  }

  ul,
  ol {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  ul li,
  ol li {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    width: auto;
    list-style: disc;
    margin-bottom: 10px;
    margin-left: 20px;

    @media (max-width: 470px) {
      font-size: 14px;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 40px;
  }

  ol li {
    font-weight: 600;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 10px;

    @media (max-width: 470px) {
      font-size: 14px;
    }
  }

  span {
    color: #fff;
  }
`;
