import styled from 'styled-components';

export const BetsPageContainer = styled.div`
  min-height: 500px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const BetsContainerHeader = styled.div`
  width: 100%;
  height: auto;
  margin-top: 60px;

  @media (max-width: 600px) {
    margin-top: 0px;
  }

  h1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #ffffff;

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  .btn-group-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 18px 0;
  }
`;

interface ButtonProps {
  active: boolean;
}

export const BetsButtonFilter = styled.button<ButtonProps>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 18px;
  color: ${({ active }) => (active ? '#1c1c27' : '#fff')};
  width: auto;
  height: 34px;
  background: ${({ active }) => (active ? '#ff8e00' : 'transparent')};
  border: 1px solid ${({ active }) => (active ? '#ff8e00' : '#fff')};
  border-radius: 100px;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  text-wrap: nowrap;

  @media (max-width: 600px) {
    font-size: 0.875rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 12px;
    margin-bottom: 0px;
  }

  @media (max-width: 350px) {
    font-size: 0.625rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 7px;
  }
`;

export const BetsList = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 48.4%);
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 22px;
  grid-gap: 30px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
`;
