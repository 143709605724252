export interface MatchPotType {
  name: string;
  games: Game[];
}

export enum PotStatus {
  Finalizado = 'Finalizado',
  AguardandoFinalizacao = 'Aguardando Finalizacao',
  EmAndamento = 'Em Andamento',
  Cancelado = 'Cancelado',
}

export interface GroupOfMasterOptions {
  id: number;
  question: string;
  options: OneVsManyTypeOption[];
  result?: string;
}

export interface OneVsManyTypeOption {
  id: number;
  name: string;
  value: string;
  result?: string;
  label: string;
  image?: string;
}

export interface PotOneVsMany {
  potOneVsMany: GroupOfMasterOptions[];
}

export interface TeamMatchPoint {
  id: string;
  name: string;
  team1: {
    image?: string;
    name: string;
    point: number;
  };
  team2: {
    image?: string;
    name: string;
    point: number;
  };
}

export interface PointSpread {
  pointSpread: TeamMatchPoint[];
}

export interface Game {
  banner: string | null;
  bet: string;
  betmatchexactpoint?: PointSpread;
  bettypes?: string;
  betonevsmany?: PotOneVsMany;
  category: any;
  championship: string;
  collectionPercentage: number;
  createdAt: string;
  dateCreated: string;
  dateFinalBet: string;
  dateFinalExtend: any;
  dateInitialBet: string;
  dateInitialExtend: any;
  dateStart: string;
  description: string;
  highlight: number;
  id: number;
  match: Match[];
  minimumBet: number;
  minimumValue: number;
  name: string;
  onevsmany?: PotOneVsMany;
  pointspread?: PointSpread;
  potBetId?: number;
  potId: number;
  resultPot: string | null;
  repeated?: number;
  sameBets?: number;
  sports: string;
  status: 'Finalizado' | 'Aguardando Finalizacao' | 'Em Andamento' | 'Cancelado';
  statusPot?: PotStatus;
  updatedAt: string;
  valueAccumulated: any;
  anotherBet?: {
    betmatchexactpoint?: PointSpread;
    betonevsmany?: PotOneVsMany;
  };
}

export interface Match {
  id: number;
  draw: boolean;
  team1: string;
  team2: string;
  result: string;
  winner: any;
  numberPot: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  imageTeam1: ImageTeam;
  imageTeam2: ImageTeam;
  highlight: boolean;
}

export interface ImageTeam {
  image: string;
}
