import styled from '@emotion/styled';

interface MStyleProps {
  modeAuth: string;
}

export const ModalAuthOverlay = styled.div<MStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 665px) {
    background: #28293d;
  }

  @media (max-height: 820px) and (max-width: 665px) {
    /* padding-bottom: ${({ modeAuth }) => (modeAuth === 'login' ? 'auto' : '70px')}; */
    background: #28293d;
  }
`;

export const ModalAuthContainer = styled.div<MStyleProps>`
  width: auto;
  height: auto;
  background: #28293d;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .content-container {
    width: 552px;
    height: auto;
    max-height: 90vh;
    padding: 20px;
    background: red;
    overflow-y: auto;
    background: #28293d;

    ::-webkit-scrollbar {
      width: 5px;
      height: 100%;
      background-color: #4b4c66;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }

    @media (max-height: 820px) and (max-width: 665px) {
      padding-bottom: ${({ modeAuth }) => (modeAuth === 'login' ? 'auto' : '70px')};
    }
  }

  /* @media (max-height: 820px) and (max-width: 665px) {
    margin-bottom: 50px;
  } */

  @media (max-width: 665px) {
    padding: 0;
    border-radius: 0;
    width: 100%;
    height: 100vh;

    .content-container {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;
      padding: 40px;
    }
  }
`;

export const ModalAuthTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
`;

export const ModalAuthButtonGroupContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;
