import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';
import * as yup from 'yup';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';

import { MdClose } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserContext } from '../../common/context/UserContext';
import { SuccessAlert, WarningAlert } from '../../components/BasicAlerts';
import Button from '../../components/Button/Button';
import { ModalAlertCheckout, ModalAlertCheckoutContentContainer } from '../../components/GenericModalAlert';
import Input from '../../components/Input/Input';
import { ModalPaymentInfo, ModalSessionExpired } from '../../components/ModalCoins';
import ModalPix from '../../components/ModalPix';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useUserAuth } from '../../hooks/user-auth';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { getUserInfo, userAddMoney, userAddMoneyPix } from '../../service/user/user.service';
import { IPaymentForm } from '../../store/reducers/user/types';
import { ReactComponent as PromoPlus } from '../../utils/icons/promoPlus.svg';
import { ReactComponent as PromoTop } from '../../utils/icons/promoTop.svg';
import { normalizePotUrl } from '../../utils/normalize-name';
import { formatCreditCard, formatValue, maskCvc, maskDate } from '../../utils/types';
import { handleBackToTop } from '../../utils/window-func';
import { Container, CustomRadio } from './styles';
import { SessionControl } from '../../session/SessionControl';

const Coins = () => {
  const { userLogged, setUserLogged, updateAppState } = useUserContext();
  const [packetOne, setPacketOne] = useState(0);
  const [packetTwo, setPacketTwo] = useState(0);
  const [packetThree, setPacketThree] = useState(0);
  const [packetFour, setPacketFour] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('stepOne');
  const [selectPay, setSelectPay] = useState('');
  const [isAlertPayment, setIsAlertPayment] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [acceptPayment, setAcceptPayment] = useState('');
  const [openModalPix, setOpenModalPix] = useState(false);
  const [balance, setBalance] = useState(false);
  const windowSize = useGetWindowSize();
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | 'warn' | 'session' | ''>('');
  const isMobile = windowSize.width <= 600;
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserSessionFinished } = useUserAuth({ isPrivatePage: true });

  const [total, setTotal] = useState({
    coins: 0,
    money: 0,
  });

  const [success, setSuccess] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPotBetStored, setIsPotBetStored] = useState(false);
  const isShowModalHotPots = isAlertPayment && !isPotBetStored;
  const isHidePageContent = !isShowModalHotPots || !isMobile;
  const isHidePageContentWhenModalOpen = statusRequest === 'error' && isMobile;
  const isHidePageOnPixModalOpen = isMobile && openModalPix;
  const zeroBalance = Number(userLogged!.wallet[0].value) === 0;

  const payment_method = {
    creditcard: 1,
    pix: 2,
    balance: 3,
  };

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const goToTop = () => {
    handleBackToTop();
  };

  const handleBackModal = () => {
    goToTop();
    setIsAlertPayment(false);
  };

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        cardNumber: yup.string().required('Numéro do cartão é obrigatório'),
        dateCard: yup
          .string()
          .typeError('Por favor selecione data de vencimento do cartão')
          .min(7, 'Data inválida. Exemplo: 06/2027')
          .max(7, 'Data inválida. Exemplo: 06/2027')
          .required('Obrigatório data do cartão'),
        cvc: yup.string().required('Obrigatório código').min(3, 'Deve ter pelo menos 3 caracteres'),
        name: yup.string().required('Nome presente no cartão é obrigatório'),
        currency: yup.string(),
      }),
    []
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      cardNumber: '',
      dateCard: '',
      cvc: '',
      name: '',
      address: '',
      currency: '',
    },
  });

  const showModalError = (msg: string) => {
    setStatusRequest('error');
    setMessage(msg);
    goToTop();
  };

  const checkIfGotPotBetStored = () => {
    const data = SessionControl.getPotChoices();

    if (data) {
      const potInfo = JSON.parse(data);
      const championShip = normalizePotUrl(potInfo.championship);
      const title = normalizePotUrl(potInfo.name);
      const newUrl = `pot/${championShip}/${title}`;
      navigate(newUrl);
    }
  };

  const sendBuyWithPix = async () => {
    if (await isUserSessionFinished()) {
      setStatusRequest('session');
      return;
    }

    try {
      setSuccess(false);
      setResponseError(false);
      setErrorMessage('');
      setLoading(true);

      if (total.coins === 0) {
        setLoading(false);
        setStatusRequest('error');
        setMessage('Compra inválida');
      }

      if (userLogged) {
        const result = await userAddMoneyPix(
          userLogged.login,
          total.money,
          total.coins,
          userLogged.id,
          'BRL',
          payment_method.pix,
          balance
        );

        setQrCode(result.data.qr_code);
        setQrCodeImage(result.data.qr_code_base64);
        setAcceptPayment(result.data.id);
        goToTop();

        if (result.status !== 200) {
          setLoading(false);
          setOpenModalPix(false);
          return showModalError(
            'Desculpe, não foi possível gerar o QRcode, por favor tente novamente ou use outra forma de pagamento.'
          );
        }
      }
    } catch (e) {
      setLoading(false);
      setSuccess(false);
    }
  };

  const onSubmit = async (data: IPaymentForm) => {
    const expMonth = data.dateCard.slice(0, 2);
    const expYear = data.dateCard.slice(3, 7);

    if (await isUserSessionFinished()) {
      return;
    }

    try {
      setSuccess(false);
      setResponseError(false);
      setErrorMessage('');
      setLoading(true);

      if (total.coins === 0) {
        setLoading(false);
        showModalError('Compra inválida');
      }

      if (userLogged) {
        const result = await userAddMoney(
          userLogged.login,
          total.money,
          total.coins,
          userLogged.id,
          data.cardNumber,
          expMonth,
          expYear,
          data.cvc,
          data.name,
          'BRL',
          payment_method.creditcard,
          balance
        );

        if (result.status !== 200) {
          setLoading(false);
          showModalError(result.data);
          return;
        }

        const { wallet, usersCoins } = await getUserInfo();
        if (total.money > 0) {
          setUserLogged({
            ...userLogged,
            wallet,
            usersCoins,
          });
        }

        setStep('stepOne');
        setLoading(false);
        setIsAlertPayment(true);
        goToTop();
        setIsPotBetStored(false);
        setPacketOne(0);
        setPacketTwo(0);
        setPacketThree(0);
        setPacketFour(0);
      }
    } catch (e) {
      setLoading(false);
      setSuccess(false);
    }
  };

  const errorClassName = 'text-sm font-outfit text-state-fail text-left';
  const successClassName = 'text-sm font-outfit text-state-success text-center';

  function handleAdd(e: MouseEvent) {
    const packetTarget = e.target as HTMLInputElement;
    if (packetTarget.dataset.packet === '1') {
      if (packetOne >= 0) {
        setPacketOne(packetOne + 1);
      }
    }
    if (packetTarget.dataset.packet === '2') {
      if (packetTwo >= 0) {
        setPacketTwo(packetTwo + 1);
      }
    }
    if (packetTarget.dataset.packet === '3') {
      if (packetThree >= 0) {
        setPacketThree(packetThree + 1);
      }
    }
    if (packetTarget.dataset.packet === '4') {
      if (packetFour >= 0) {
        setPacketFour(packetFour + 1);
      }
    }
  }

  function handleRemove(e: MouseEvent) {
    const packetTarget = e.target as HTMLInputElement;
    if (packetTarget.dataset.packet === '1') {
      if (packetOne > 0) {
        setPacketOne(packetOne - 1);
      }
    }
    if (packetTarget.dataset.packet === '2') {
      if (packetTwo > 0) {
        setPacketTwo(packetTwo - 1);
      }
    }
    if (packetTarget.dataset.packet === '3') {
      if (packetThree > 0) {
        setPacketThree(packetThree - 1);
      }
    }
    if (packetTarget.dataset.packet === '4') {
      if (packetFour > 0) {
        setPacketFour(packetFour - 1);
      }
    }
  }

  async function handleBalanceBuy() {
    if (await isUserSessionFinished()) {
      setStatusRequest('session');
      return;
    }

    try {
      setLoading(true);

      if (userLogged) {
        const result = await userAddMoneyPix(
          userLogged.login,
          total.money,
          total.coins,
          userLogged.id,
          'BRL',
          payment_method.balance,
          balance
        );

        const { wallet, usersCoins } = await getUserInfo();

        if (total.money > 0) {
          setUserLogged({
            ...userLogged,
            wallet,
            usersCoins,
          });
        }

        if (result.status !== 200) {
          setLoading(false);
          showModalError('Ocorreu algum erro inesperado, tente novamente');
          return;
        }

        setPacketOne(0);
        setPacketTwo(0);
        setPacketThree(0);
        setPacketFour(0);
        setLoading(false);
        setIsPotBetStored(false);
        goToTop();
        setIsAlertPayment(true);

        return result;
      } else {
        setLoading(false);

        showModalError('Ocorreu um erro! Usuário não está logado!');
      }
    } catch (error) {
      setLoading(false);

      setStatusRequest('error');
      setMessage('Ocorreu um erro! Tente novamente mais tarde.');
    }
  }

  async function handleContinueToBuy() {
    goToTop();
    try {
      if (userLogged) {
        return setStep('stepTwo');
      }

      showModalError('Ocorreu um erro! Usuário não está logado!');
    } catch (error) {
      setStatusRequest('error');
      setMessage('Ocorreu um erro! Tente novamente mais tarde.');
    }
  }

  useMemo(() => {
    if (packetOne >= 0 && packetTwo >= 0 && packetThree >= 0 && packetFour >= 0) {
      const totalCoinsOne = packetOne * 4;
      const totalCoinsTwo = packetTwo * 10;
      const totalCoinsThree = packetThree * 50;
      const totalCoinsFour = packetFour * 1;

      const totalMoneyOne = packetOne * 20;
      const totalMoneyTwo = packetTwo * 47.5;
      const totalMoneyThree = packetThree * 225;
      const totalMoneyFour = packetFour * 1;

      const totalCoinsResume = totalCoinsOne + totalCoinsTwo + totalCoinsThree + totalCoinsFour;
      const totalMoneyResume = totalMoneyOne + totalMoneyTwo + totalMoneyThree + totalMoneyFour;

      setTotal({ money: totalMoneyResume, coins: totalCoinsResume });
    }
  }, [packetOne, packetTwo, packetThree, packetFour]);

  useEffect(() => {
    function messageBuy() {
      if (location.search.startsWith('?success=true')) {
        setStatusRequest('success');
        return setMessage('Pagamento efetuado com sucesso!');
      }
      if (location.search.startsWith('?success=false')) {
        setStatusRequest('error');
        setMessage('Erro ao realizar o pagamento!');
      }
    }

    messageBuy();
  }, [location.search]);

  useEffect(() => {
    const bets = SessionControl.getPotChoices();
    setIsPotBetStored(!!bets);
  }, []);

  useEffect(() => {
    reset();
    setSelectPay('');
  }, [step]);

  return (
    <>
      {isHidePageContent && (
        <div
          className={
            openPopUp
              ? 'visible w-full h-full bg-[#00000090] fixed flex justify-center items-center z-[10] left-0 '
              : 'invisible  max-w-[400px] w-full h-[300px] fixed'
          }
        >
          <div className="bg-[#222232] p-4 relative rounded-md">
            <button className="text-white absolute right-3 top-2" onClick={() => setOpenPopUp(false)}>
              <MdClose size={18} />
            </button>
            <p className="text-white text-center">Verificamos que seu saldo é insuficiente!</p>
            <p className="text-white">Para continuar as compras clique no botão abaixo</p>
            <div className="flex justify-center w-full">
              <Button
                hierarchy="primary"
                label="Comprar"
                size="md"
                moreClasses="mt-5 mb-3 w-full"
                onClick={() => {
                  goToTop();
                  alert('got to');
                  setStep('stepTwo');
                  setOpenPopUp(false);
                }}
                // handleAddMoney
              />
            </div>
          </div>
        </div>
      )}

      <ModalPix
        qrcode={qrCode}
        qrcodeImage={qrCodeImage}
        id_transaction={acceptPayment}
        openAlert={openModalPix}
        setOpenAlert={(value: boolean) => {
          setOpenModalPix(value);
          updateAppState('update user info wallet');
        }}
        handleSuccess={() => {
          setOpenModalPix(false);
          setIsAlertPayment(true);
          updateAppState('update user info wallet');
          // checkIfGotPotBetStored();
        }}
        setPacketFour={setPacketFour}
        setPacketOne={setPacketOne}
        setPacketThree={setPacketThree}
        setPacketTwo={setPacketTwo}
        setStep={setStep}
        setLoading={setLoading}
      />

      <ModalSessionExpired
        handleClose={() => setStatusRequest('')}
        handleConfirm={() => {
          setStatusRequest('');
          navigate('/login');
        }}
        open={statusRequest === 'session'}
      />

      {isShowModalHotPots && (
        <>
          {isMobile ? (
            <ModalAlertCheckoutContentContainer
              onClose={handleBackModal}
              handleConfirm={handleBackModal}
              extraInfo="hot pots"
            />
          ) : (
            <ModalAlertCheckout
              onClose={() => {
                setIsAlertPayment(false);
              }}
              handleGotToHome={() => {
                navigate('/');
              }}
              handleConfirm={() => {
                navigate('/category/futebol');
              }}
              extraInfo="hot pots"
            />
          )}
        </>
      )}

      {!isHidePageOnPixModalOpen && (
        <>
          {isHidePageContent && (
            <PageContainer className="relative">
              {statusRequest === 'success' && (
                <SuccessAlert
                  title={'Compra Efetuada'}
                  message={message}
                  onClose={handleCleanStatusRequest}
                  autoClose
                />
              )}

              {statusRequest === 'warn' && (
                <WarningAlert
                  title={'Sessão expirada'}
                  message={message}
                  onClose={handleCleanStatusRequest}
                  autoClose
                />
              )}

              <ModalPaymentInfo
                btnText="fechar"
                openAlert={statusRequest === 'error'}
                setOpenAlert={() => {
                  setStatusRequest('');
                  setMessage('');
                }}
                modalIcon={'card-info'}
                title1={message}
                text2=" "
                text1=" "
              />

              <ToastContainer
                toastStyle={{ background: '#222232', color: 'white' }}
                progressStyle={{ background: '#FF8E00' }}
                position="top-right"
                autoClose={3500}
              />

              {!isHidePageContentWhenModalOpen && (
                <>
                  {step === 'stepOne' ? (
                    <div className="md:mx-0 md:pt-[20px] max-w-[543px] ">
                      <h2 className="mb-[10px] md:mt-[24px] text-[28px] font-semibold fz-[14px] leading-[35px]">
                        Adicionar Fichas
                      </h2>
                      <p className="text-[14px]">Selecione um pacote de fichas</p>

                      <div className=" grid grid-rows-3 md:grid-rows-1 grid-flow-col max-w-[543px] ml-[18px] md:ml-0 mt-[24px] md:mt-[54px]">
                        <div className="md:max-w-[230px] md:w-[230px] w-[90%] h-[292px] bg-[#28293D] md:mr-[25px] rounded-[20px] p-[1rem] mb-[24px]">
                          <div className="mt-[20px] text-center">
                            <p className="text-center text-[32px] font-gothic text-[#FF8E00] py-[0.5rem] font-normal">
                              PACOTE BÁSICO
                            </p>
                          </div>
                          <div className=" flex flex-col justify-center items-center text-center">
                            <h2 className="text-[20px]">4 FICHAS</h2>
                            <div className="flex justify-center items-center text-center w-[240px] md:w-[158px] h-[68px] bg-[#4B4C66] mt-[18px] rounded-20">
                              <p className="text-[20px] font-normal">R$ 20,00</p>
                            </div>
                          </div>
                          <div className="flex justify-center items-center pt-[1rem] pb-[0.5rem]">
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={handleRemove}
                              data-packet="1"
                            >
                              -
                            </button>
                            <input
                              className="w-[80px] bg-transparent border-none text-center text-[24px] font-semibold"
                              type="text"
                              maxLength={3}
                              disabled
                              onChange={(event) => setPacketOne(parseInt(event.target.value))}
                              value={packetOne}
                            />
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={(e: MouseEvent) => handleAdd(e)}
                              data-packet="1"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="relative md:max-w-[230px] md:w-[230px] w-[90%] h-[325px] top-[-32px] left-0 bg-[#28293D] md:mr-[25px] rounded-[20px] p-[1rem] mt-[12px] border-[3px] border-[#FF8E00]">
                          <div className="flex justify-center items-center absolute top-0 left-0 w-full h-[32px] text-center rounded-tl-[14px] rounded-tr-[14px] bg-[#FF8E00] ">
                            <p className=" text-center text-[14px] text-[#1C1C27] font-bold ">MAIS INDICADO</p>
                          </div>
                          <div className=" mt-[16px] text-center">
                            <p className="text-center text-[32px] font-gothic text-[#FF8E00] py-[0.5rem] font-normal">
                              PACOTE PLUS
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center text-center">
                            <h2 className="text-[20px]">10 FICHAS</h2>
                            <div className="flex flex-col justify-center items-center text-center w-[240px] md:w-[158px] h-[68px] bg-[#4B4C66] mt-[18px] rounded-20">
                              <PromoPlus className="absolute left-[83.89%] md:left-[78.89%] top-[43.7%] right-[8.34%] bottom-[49.32%]" />
                              <p className=" text-[#FF8E00] text-[14px] font-normal">de R$ 50,00</p>
                              <p className="text-[20px] font-normal">por R$ 47,50</p>
                            </div>
                          </div>
                          <div className="flex justify-center items-center pt-[1rem] pb-[0.5rem]">
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={handleRemove}
                              data-packet="2"
                            >
                              -
                            </button>
                            <input
                              className="w-[80px] bg-transparent border-none text-center text-[24px] font-semibold"
                              type="text"
                              maxLength={3}
                              disabled
                              onChange={(event) => setPacketTwo(parseInt(event.target.value))}
                              value={packetTwo}
                            />
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={(e: MouseEvent) => handleAdd(e)}
                              data-packet="2"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="relative md:max-w-[230px] md:w-[230px] w-[90%] h-[290px] bg-[#28293D] md:mr-[25px] mr-[18px] mb-[24px] rounded-[20px] p-[1rem]">
                          <div className="mt-[16px] text-center">
                            <p className="text-center text-[32px] font-gothic text-[#FF8E00] py-[0.5rem] font-normal">
                              PACOTE TOP
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center text-center">
                            <h2 className="text-[20px]">50 FICHAS</h2>
                            <div className="flex flex-col justify-center items-center text-center w-[240px] md:w-[158px] h-[68px] bg-[#4B4C66] mt-[18px] rounded-20">
                              <PromoTop className="absolute left-[83.89%] md:left-[78.89%] top-[43.7%] right-[8.34%] bottom-[49.32%]" />
                              <p className=" text-[#FF8E00] text-[14px] font-normal">de R$ 250,00</p>
                              <p className="text-[20px] font-normal">por R$ 225,00</p>
                            </div>
                          </div>
                          <div className="flex justify-center items-center pt-[1rem] pb-[0.5rem]">
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={handleRemove}
                              data-packet="3"
                            >
                              -
                            </button>
                            <input
                              className="w-[80px] bg-transparent border-none text-center text-[24px] font-semibold"
                              type="text"
                              maxLength={3}
                              disabled
                              onChange={(event) => setPacketThree(parseInt(event.target.value))}
                              value={packetThree}
                            />
                            <button
                              className="border border-[#FF8E00] rounded-full h-[32px] w-[32px]"
                              onClick={(e: MouseEvent) => handleAdd(e)}
                              data-packet="3"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <p>Total</p>
                      <div>
                        <p className="text-[#FF8E00] text-[24px] flex items-center">
                          {total.coins} {total.coins === 1 ? 'ficha' : 'fichas'}
                          <span className="text-white text-[14px] pl-[8px]">
                            (
                            {Intl.NumberFormat('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            }).format(total.money)}
                            )
                          </span>
                        </p>
                      </div>
                      <Button
                        hierarchy="primary"
                        label="Comprar"
                        size="sm"
                        moreClasses="flex justify-center items-center text-center max-w-[366px] w-full h-[52px] md:max-w-[152px] md:w-[152px] md:h-[49px] text-[16px] font-semibold my-[30px]"
                        disabled={total.coins === 0}
                        onClick={() => handleContinueToBuy()}
                      />
                    </div>
                  ) : (
                    <div className="pt-[20px] mb-[24px] max-w-[543px] w-full">
                      <div className="flex flex-row max-w-[640px] md:w-[640px] justify-between mb-[32px]">
                        <h1 className=" text-[28px] font-semibold">Checkout</h1>
                        <button className="" onClick={() => setStep('stepOne')}>
                          Voltar
                        </button>
                      </div>

                      <div className="flex flex-col-reverse md:flex-row">
                        <div className="flex flex-col">
                          <div
                            className={`flex flex-row items-center w-full max-w-[366px] h-[80px] text-white rounded-[8px] pl-3 pr-4 py-4 bg-gradient-to-r from-[#FF8E00] to-[rgba(33, 33, 33, 0) 167.29%)] ${
                              zeroBalance && 'opacity-[0.5] cursor-not-allowed'
                            }`}
                          >
                            <div>
                              {balance && total.money - Number(userLogged?.wallet[0].value) <= 0 ? (
                                <input
                                  className={`ml-[16px] bg-transparent rounded-[4px] focus:!bg-transparent checked:!bg-transparent checked:!border-2  checked:!border-white`}
                                  type="checkbox"
                                  name="coin"
                                  value="coins"
                                  onClick={() => {
                                    setBalance(!balance);
                                    setSelectPay('balance');
                                  }}
                                  checked={selectPay === 'balance' && balance}
                                  disabled={zeroBalance}
                                />
                              ) : (
                                <input
                                  className={`ml-[16px] bg-transparent rounded-[4px] focus:!bg-transparent checked:!bg-transparent checked:!border-2  checked:!border-white`}
                                  type="checkbox"
                                  name="coin"
                                  value="coins"
                                  onClick={() => {
                                    setBalance(!balance);
                                    setSelectPay('balance');
                                  }}
                                  checked={balance}
                                  disabled={zeroBalance}
                                />
                              )}
                            </div>
                            <div
                              className="ml-[16px] cursor-pointer"
                              onClick={() => {
                                !zeroBalance && setBalance((prevState) => !prevState);
                                setSelectPay('balance');
                              }}
                            >
                              <p className="uppercase text-[16px] font-semibold">Saldo MoneyPot</p>
                              <p className="text-[14px]">Total: R$ {formatValue(userLogged!.wallet[0].value)}</p>
                            </div>
                          </div>

                          {balance && total.money - Number(userLogged?.wallet[0].value) <= 0 ? (
                            <div>
                              <div>
                                <div className="flex flex-row items-center mt-[24px] max-w-[366px] md:w-[303px] h-[58px] text-white rounded-[8px] pl-8 pr-4 py-4 bg-gradient-to-r from-[#404160] to-[rgba(33, 33, 33, 0) 167.29%)]">
                                  <CustomRadio
                                    active={selectPay === 'creditCard'}
                                    onClick={() => {
                                      setSelectPay('creditCard');
                                      setBalance(false);
                                    }}
                                  >
                                    {selectPay === 'creditCard' && <div className="circle" />}
                                  </CustomRadio>

                                  <p
                                    onClick={() => {
                                      setSelectPay('creditCard');
                                      setBalance(false);
                                    }}
                                    className="uppercase ml-[16px] text-[16px] font-semibold cursor-pointer"
                                  >
                                    Cartão de Crédito
                                  </p>
                                </div>
                                {selectPay === 'creditCard' && (
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className=" pt-4">
                                      <Controller
                                        control={control}
                                        name="cardNumber"
                                        render={({ field }) => (
                                          <Container>
                                            <Input
                                              isTextHelper={true}
                                              className="textClass inputBottom"
                                              id="outlined-helperText"
                                              label="Número"
                                              name="cardNumber"
                                              type="tel"
                                              placeholder="1234 1234 1234 1234"
                                              autoComplete="cc-number"
                                              x-autocompletetype="cc-number"
                                              value={formatCreditCard(field.value)}
                                              // moreClasses="max-w-[366px] md:w-[303px] max-h-[46px] placeholder:text-[14px] placeholder:font-normal  placeholder:leading-[18px] rounded-[16px] border-b-background-100 border-b-2 !bg-[#28293D]"
                                              onChange={(value: ChangeEvent<HTMLInputElement>) => {
                                                field.onChange(value.target.value);
                                              }}
                                            />
                                          </Container>
                                        )}
                                      />
                                      {errors.cardNumber && (
                                        <span className={errorClassName}>{errors.cardNumber.message}</span>
                                      )}

                                      <div className=" max-w-[366px] md:w-[303px] mt-[24px] flex flex-row ">
                                        <div className="flex flex-col">
                                          <Controller
                                            control={control}
                                            name="dateCard"
                                            render={({ field }) => (
                                              <Container>
                                                <Input
                                                  isTextHelper={true}
                                                  className="textClass inputBottom"
                                                  id="outlined-helperText"
                                                  label="Validade"
                                                  name="dateCard"
                                                  placeholder="00/0000"
                                                  type="text"
                                                  autoComplete="cc-exp"
                                                  x-autocompletetype="cc-exp"
                                                  value={maskDate(field.value)}
                                                  onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                                    field.onChange(value.target.value)
                                                  }
                                                />
                                              </Container>
                                            )}
                                          />
                                          {errors.dateCard && (
                                            <span className={errorClassName}>{errors.dateCard.message}</span>
                                          )}
                                        </div>
                                        <div className="flex flex-col">
                                          <Controller
                                            control={control}
                                            name="cvc"
                                            render={({ field }) => (
                                              <Container className="ipt">
                                                <Input
                                                  isTextHelper={true}
                                                  className="textClass inputBottom "
                                                  id="outlined-helperText"
                                                  label="CVC"
                                                  name="cvc"
                                                  placeholder="000"
                                                  type="text"
                                                  value={maskCvc(field.value)}
                                                  autoComplete="cc-csc"
                                                  onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                                    field.onChange(value.target.value)
                                                  }
                                                />
                                              </Container>
                                            )}
                                          />
                                          {errors.cvc && <span className={errorClassName}>{errors.cvc.message}</span>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pt-4">
                                      <Controller
                                        control={control}
                                        name="name"
                                        render={({ field }) => (
                                          <Container>
                                            <Input
                                              isTextHelper={true}
                                              className="textClass inputBottom"
                                              id="outlined-helperText"
                                              label="Nome no Cartão"
                                              name="name"
                                              placeholder="Nome no cartão"
                                              type="text"
                                              onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                                field.onChange(value.target.value)
                                              }
                                            />
                                          </Container>
                                        )}
                                      />
                                      {errors.name && <span className={errorClassName}>{errors.name.message}</span>}
                                    </div>
                                  </form>
                                )}

                                <div className="flex flex-row items-center mt-[24px] max-w-[366px] md:w-[303px] h-[58px] text-white rounded-[8px] pl-8 pr-4 py-4 bg-gradient-to-r from-[#404160] to-[rgba(33, 33, 33, 0) 167.29%)]">
                                  <CustomRadio
                                    active={selectPay === 'pix'}
                                    onClick={() => {
                                      setSelectPay('pix');
                                      setBalance(false);
                                    }}
                                  >
                                    {selectPay === 'pix' && <div className="circle" />}
                                  </CustomRadio>
                                  <p
                                    onClick={() => {
                                      setSelectPay('pix');
                                      setBalance(false);
                                    }}
                                    className="uppercase ml-[16px] text-[16px] font-semibold cursor-pointer"
                                  >
                                    Pix
                                  </p>
                                </div>
                                {selectPay === 'pix' && (
                                  <div>
                                    <Button
                                      moreClasses=" max-w-[366px]  md:w-[187px] h-[52px] text-[16px] font-semibold block my-[30px]"
                                      label="Comprar com PIX"
                                      hierarchy="primary"
                                      size="sm"
                                      disabled={loading}
                                      onClick={() => {
                                        goToTop();

                                        sendBuyWithPix();
                                        setOpenModalPix(true);
                                      }}
                                    />
                                  </div>
                                )}

                                {selectPay === 'creditCard' && (
                                  <>
                                    {loading ? (
                                      <button
                                        className="tracking-[1.25px] font-outfit rounded-full outline-none ease-out transition-all duration-150 focus:outline-none w-[146px] h-[56px] block my-[30px] text-md font-semibold px-[3em] py-4 w-fit border-[1px] border-transparent bg-background-highlight text-text-white disabled:!bg-toast-300 disabled:!text-onPrimary-100"
                                        disabled={loading}
                                        onClick={handleSubmit(onSubmit)}
                                      >
                                        <BeatLoader
                                          color={'#FFFFFF'}
                                          loading={loading}
                                          cssOverride={{
                                            display: 'block',
                                          }}
                                          speedMultiplier={0.6}
                                          size={12}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      </button>
                                    ) : (
                                      <Button
                                        moreClasses="flex justify-center items-center text-center max-w-[366px] w-full md:max-w-[146px] h-[56px]  text-[16px] font-semibold my-[30px]"
                                        label="Comprar"
                                        hierarchy="primary"
                                        size="sm"
                                        disabled={loading}
                                        onClick={handleSubmit(onSubmit)}
                                      />
                                    )}
                                    {responseError && <span className={errorClassName}>{errorMessage}</span>}
                                    {success && <span className={successClassName}>Compra efetuada com sucesso!</span>}
                                  </>
                                )}

                                {selectPay === 'balance' && (
                                  <div>
                                    <Button
                                      moreClasses="w-[366px] md:w-[303px] h-[52px] text-[16px] font-semibold block my-[30px]"
                                      label="Comprar com Saldo"
                                      hierarchy="primary"
                                      size="sm"
                                      disabled={loading}
                                      onClick={() => handleBalanceBuy()}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="flex flex-row items-center mt-[24px] max-w-[366px] md:w-[303px] h-[58px] text-white rounded-[8px] pl-8 pr-4 py-4 bg-gradient-to-r from-[#404160] to-[rgba(33, 33, 33, 0) 167.29%)]">
                                <CustomRadio
                                  active={selectPay === 'creditCard'}
                                  onClick={() => setSelectPay('creditCard')}
                                >
                                  {selectPay === 'creditCard' && <div className="circle" />}
                                </CustomRadio>

                                <p
                                  onClick={() => setSelectPay('creditCard')}
                                  className="uppercase ml-[16px] text-[16px] font-semibold cursor-pointer"
                                >
                                  Cartão de Crédito
                                </p>
                              </div>
                              {selectPay === 'creditCard' && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className=" pt-4">
                                    <Controller
                                      control={control}
                                      name="cardNumber"
                                      render={({ field }) => (
                                        <Container>
                                          <Input
                                            isTextHelper={true}
                                            className="textClass inputBottom"
                                            id="outlined-helperText"
                                            label="Número"
                                            name="cardNumber"
                                            type="tel"
                                            placeholder="1234 1234 1234 1234"
                                            autoComplete="cc-number"
                                            x-autocompletetype="cc-number"
                                            value={formatCreditCard(field.value)}
                                            // moreClasses="max-w-[366px] md:w-[303px] max-h-[46px] placeholder:text-[14px] placeholder:font-normal  placeholder:leading-[18px] rounded-[16px] border-b-background-100 border-b-2 !bg-[#28293D]"
                                            onChange={(value: ChangeEvent<HTMLInputElement>) => {
                                              field.onChange(value.target.value);
                                            }}
                                          />
                                        </Container>
                                      )}
                                    />
                                    {errors.cardNumber && (
                                      <span className={errorClassName}>{errors.cardNumber.message}</span>
                                    )}

                                    <div className=" max-w-[366px] md:w-[303px] mt-[24px] flex flex-row ">
                                      <div className="flex flex-col">
                                        <Controller
                                          control={control}
                                          name="dateCard"
                                          render={({ field }) => (
                                            <Container>
                                              <Input
                                                isTextHelper={true}
                                                className="textClass inputBottom"
                                                id="outlined-helperText"
                                                label="Validade"
                                                name="dateCard"
                                                placeholder="00/0000"
                                                type="text"
                                                autoComplete="cc-exp"
                                                x-autocompletetype="cc-exp"
                                                value={maskDate(field.value)}
                                                onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                                  field.onChange(value.target.value)
                                                }
                                              />
                                            </Container>
                                          )}
                                        />
                                        {errors.dateCard && (
                                          <span className={errorClassName}>{errors.dateCard.message}</span>
                                        )}
                                      </div>
                                      <div className="flex flex-col">
                                        <Controller
                                          control={control}
                                          name="cvc"
                                          render={({ field }) => (
                                            <Container className="ipt">
                                              <Input
                                                isTextHelper={true}
                                                className="textClass inputBottom "
                                                id="outlined-helperText"
                                                label="CVC"
                                                name="cvc"
                                                placeholder="000"
                                                type="text"
                                                value={maskCvc(field.value)}
                                                autoComplete="cc-csc"
                                                onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                                  field.onChange(value.target.value)
                                                }
                                              />
                                            </Container>
                                          )}
                                        />
                                        {errors.cvc && <span className={errorClassName}>{errors.cvc.message}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-4">
                                    <Controller
                                      control={control}
                                      name="name"
                                      render={({ field }) => (
                                        <Container>
                                          <Input
                                            isTextHelper={true}
                                            className="textClass inputBottom"
                                            id="outlined-helperText"
                                            label="Nome no Cartão"
                                            name="name"
                                            placeholder="Nome no cartão"
                                            type="text"
                                            onChange={(value: ChangeEvent<HTMLInputElement>) =>
                                              field.onChange(value.target.value)
                                            }
                                          />
                                        </Container>
                                      )}
                                    />
                                    {errors.name && <span className={errorClassName}>{errors.name.message}</span>}
                                  </div>
                                </form>
                              )}

                              <div className="flex flex-row items-center mt-[24px] max-w-[366px] md:w-[303px] h-[58px] text-white rounded-[8px] pl-8 pr-4 py-4 bg-gradient-to-r from-[#404160] to-[rgba(33, 33, 33, 0) 167.29%)]">
                                <CustomRadio active={selectPay === 'pix'} onClick={() => setSelectPay('pix')}>
                                  {selectPay === 'pix' && <div className="circle" />}
                                </CustomRadio>
                                <p
                                  onClick={() => setSelectPay('pix')}
                                  className="uppercase ml-[16px] text-[16px] font-semibold cursor-pointer"
                                >
                                  Pix
                                </p>
                              </div>
                              {selectPay === 'pix' && (
                                <div>
                                  <Button
                                    moreClasses=" max-w-[366px]  md:w-[187px] h-[52px] text-[16px] font-semibold block my-[30px]"
                                    label="Comprar com PIX"
                                    hierarchy="primary"
                                    size="sm"
                                    disabled={loading}
                                    onClick={() => {
                                      goToTop();

                                      sendBuyWithPix();
                                      setOpenModalPix(true);
                                    }}
                                  />
                                </div>
                              )}

                              {selectPay === 'creditCard' && (
                                <>
                                  {loading ? (
                                    <button
                                      className="tracking-[1.25px] font-outfit rounded-full outline-none ease-out transition-all duration-150 focus:outline-none w-[146px] h-[56px] block my-[30px] text-md font-semibold px-[3em] py-4 w-fit border-[1px] border-transparent bg-background-highlight text-text-white disabled:!bg-toast-300 disabled:!text-onPrimary-100"
                                      disabled={loading}
                                      onClick={handleSubmit(onSubmit)}
                                    >
                                      <BeatLoader
                                        color={'#FFFFFF'}
                                        loading={loading}
                                        cssOverride={{
                                          display: 'block',
                                        }}
                                        speedMultiplier={0.6}
                                        size={12}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                      />
                                    </button>
                                  ) : (
                                    <Button
                                      moreClasses="flex justify-center items-center text-center max-w-[366px] w-full md:max-w-[146px] h-[56px]  text-[16px] font-semibold my-[30px]"
                                      label="Comprar"
                                      hierarchy="primary"
                                      size="sm"
                                      disabled={loading}
                                      onClick={handleSubmit(onSubmit)}
                                    />
                                  )}
                                  {responseError && <span className={errorClassName}>{errorMessage}</span>}
                                  {success && <span className={successClassName}>Compra efetuada com sucesso!</span>}
                                </>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="flex flex-col mb-[24px] md:ml-[35px] py-[16px] px-[32px] max-w-[366px] md:w-[303px] h-[248px] rounded-[8px] bg-[#28293D]">
                          <h2 className=" mb-[24px] text-[20px] font-semibold">Detalhes da sua compra</h2>
                          <div className="mx-auto my-0 w-[248px] border-[1px] border-dashed border-[#ffffff20]" />
                          <div className="flex flex-row justify-between mt-[8px] mb-[8px]">
                            <p>
                              {total.coins} {total.coins === 1 ? 'Ficha' : 'Fichas'}
                            </p>
                            <p>R${formatValue(total.money)}</p>
                          </div>
                          <div className="mx-auto my-0 w-[248px] border-[1px] border-dashed border-[#ffffff20]" />
                          <div className="flex flex-row justify-between mt-[8px]">
                            <p>Total</p>
                            <p>R${formatValue(total.money)}</p>
                          </div>
                          <div className="flex flex-row justify-between text-[#FF8E00] mt-[8px]">
                            <p className="font-light">Desconto (saldo)</p>
                            <p className="font-normal">
                              {balance
                                ? `R$ ${formatValue(Math.min(userLogged!.wallet[0].value, total.money))}`
                                : 'R$ 0,00'}
                            </p>
                          </div>
                          <div className="flex flex-row justify-between  mt-[8px]">
                            <p>Subtotal</p>
                            <p>
                              {balance
                                ? total.money - Number(userLogged?.wallet[0].value) < 0
                                  ? 'R$ 0,00'
                                  : `R$ ${formatValue(total.money - Number(userLogged?.wallet[0].value))}`
                                : `R$ ${formatValue(total.money)}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </PageContainer>
          )}
        </>
      )}
    </>
  );
};

export default Coins;
