import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { ReactComponent as LeftArrow } from '../../utils/icons/leftArrow.svg';
import { ReactComponent as RightArrow } from '../../utils/icons/rightArrow.svg';
import { ArrowPositionLeft, ArrowPositionRight, GameInstructionContainer, IconContainer, IconSection } from './styles';

const HomeGameInstruction = () => {
  const windowSize = useGetWindowSize();

  return (
    <div>
      {windowSize.width >= 1024 ? (
        <GameInstructionContainer>
          <h2>Rápido, fácil, lucrativo e seguro.</h2>

          <IconSection>
            <IconContainer>
              <img src="/images/poker-chip.png" alt="logomara pot" />

              <p>
                Escolha um <b>POT</b>.
              </p>

              <p className="messageIcon">
                Em quais <b>jogos</b> você quer palpitar?
              </p>
            </IconContainer>

            <ArrowPositionLeft>
              <RightArrow className="svg-icon" />
            </ArrowPositionLeft>

            <IconContainer>
              <img src="/images/cards.png" alt="logomara pot" />

              <p>
                Manda ver nos <b>palpites!</b>
              </p>

              <p className="messageIcon">Mais palpites, mais chances de ganhar!</p>
            </IconContainer>

            <ArrowPositionRight>
              <LeftArrow className=" svg-icon" />
            </ArrowPositionRight>

            <IconContainer>
              <img src="/images/troph.png" alt="logomara pot" />

              <p>
                Gabaritou os <b>resultados?</b>
              </p>

              <p className="messageIcon">
                O <b>prêmio</b> é seu!
              </p>
            </IconContainer>
          </IconSection>
        </GameInstructionContainer>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomeGameInstruction;
