import { GoogleAuthProvider, signInWithPopup, UserCredential } from 'firebase/auth';
import api from '../../service/api';
import { firebaseAuth } from '../../service/firebase/firebase';
import ButtonLoginSocial from '../ButtonLoginSocial/ButtonLoginSocial';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../../store/reducers/user/userSlice';
// import { useDispatch } from 'react-redux';
import { useDispatch } from '../../store';
import { googleAuthInfoContext } from './../../common/context/useGoogleAuthInfoContext';
import { useContext } from 'react';
import { SessionControl } from '../../session/SessionControl';
interface ButtonAuthGoogleProps {
  setStatusRequest: (msg: '' | 'error' | 'success') => void;
  setMessage: (msg: string) => void;
  isAtModal?: boolean;
  onSuccess?: () => void;
  isRegister?: boolean;
  handleShowModalBirthDate?: () => void;
}

export const ButtonAuthGoogle = ({
  setStatusRequest,
  setMessage,
  isAtModal = false,
  onSuccess = () => null,
  isRegister = false,
  handleShowModalBirthDate = () => null,
}: ButtonAuthGoogleProps) => {
  const { updateInfo } = useContext(googleAuthInfoContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const responseGoogle = async (res: UserCredential) => {
    const tokenId = await res.user.getIdToken();
    const accessToken = (res as any)._tokenResponse.oauthAccessToken;

    try {
      if (isRegister) {
        updateInfo({ tokenId, accessToken });
        onSuccess();
      } else {
        await api.post('/auth/google', {
          token: tokenId,
          accessToken: accessToken,
          birthdate: null,
        });
        await dispatch(signInWithGoogle(tokenId, accessToken));
        const token = SessionControl.getToken();
        if (token) {
          if (isAtModal) {
            onSuccess();
          } else {
            const path = SessionControl.getGroupName();
            navigate(path ? path : '/');
          }
        }
      }
    } catch (e: any) {
      if (e.response.status == 406) {
        if (e.response.data.data === 'menor_de_idade') {
          setStatusRequest('error');
          setMessage('Usuário menor de idade');
        }

        if (e.response.data.data === 'user_not_found') {
          updateInfo({ tokenId, accessToken });
          handleShowModalBirthDate();
        }
      } else if (e.response.status == 404) {
        if (e.response.data.data === 'user_not_found') {
          updateInfo({ tokenId, accessToken });
          handleShowModalBirthDate();
        }
      } else {
        setStatusRequest('error');
        setMessage('Erro inesperado');
      }
    }
  };

  return (
    <ButtonLoginSocial
      provider="google"
      onClick={() => {
        const provider = new GoogleAuthProvider();

        provider.setCustomParameters({
          prompt: 'select_account',
        });

        provider.addScope('profile');
        signInWithPopup(firebaseAuth, provider).then(function (result: UserCredential) {
          responseGoogle(result);
        });
      }}
    />
  );
};
