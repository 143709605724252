export const linksList = [
  {
    name: 'Vantagens e Ganhos',
    linkUrl: '/gains',
  },
  {
    name: 'Tire suas Dúvidas',
    linkUrl: '/faq',
  },
  {
    name: 'Jogo Responsável',
    linkUrl: '/responsible',
  },
  {
    name: 'Termos e Condições',
    linkUrl: '/terms',
  },
  // {
  //   name: 'Termos e Condições',
  //   linkUrl: '/terms',
  // },
];

export const contactList = [
  // {
  //   name: '41 98888-8888',
  //   linkUrl: '',
  // },
  // {
  //   name: 'Rua Nome da Rua, 00',
  //   linkUrl: '',
  // },
  // {
  //   name: 'Curitiba/PR - Brasil',
  //   linkUrl: '',
  // },
  {
    name: 'Política de Privacidade',
    linkUrl: '/policy-and-privacy',
  },
];
