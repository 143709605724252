import styled from '@emotion/styled';

interface AccountSidebarContainer {
  start?: boolean;
  isHeightAuto?: boolean;
}

export const AccountSidebarContainer = styled.div<AccountSidebarContainer>`
  padding: 24px 64px;
  display: flex;
  justify-content: center;
  align-items: ${({ start }) => (start ? 'start' : 'center')};
  height: ${({ isHeightAuto }) => (isHeightAuto ? 'auto' : '100')};
  max-height: 100vh;
  overflow-y: auto;
  min-width: 458px;
  overflow-x: hidden;
  @media (max-width: 750px) {
    min-width: auto;
    max-height: 100vh;
    padding: 42px;
    overflow-y: ${({ start }) => (start ? 'auto' : 'hidden')};
  }
`;
export const AccountCreationContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  @media (max-width: 750px) {
    display: grid;
    grid-template-columns: 100%;
  }
`;

export const LoginBackgroundImage = styled.div`
  width: 100%;
  height: 100%;

  p {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 750px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 330px;
  padding: 0.5rem;
  width: 100%;
  height: auto;
  gap: 24px;

  @media (max-height: 550px) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Outfit';
  width: 100%;
  flex-direction: column;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    padding-left: 16px;
    line-height: 20.16px;
  }
  .MuiInputLabel-shrink {
    padding: 0 !important;
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4b4c66;
  }

  & label.Mui-focused {
    color: #c4c4c4;
  }

  & .MuiOutlinedInput-root {
    border: 1px;
    border-radius: 20px;
    border-color: white;

    &.Mui-focused fieldset {
      border-radius: 20px;
      border-color: #4b4c66;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      border: 1px;
      border-color: #4b4c66;
      border-radius: 20px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .textClass {
    border: 2px #4b4c66;
    border-color: #4b4c66;
    background-color: #202030;
    border-radius: 20px;
    width: 100%;
    height: 56px;

    @media (max-width: 767px) {
      width: 100%;
      height: 56px;
    }

    @media (max-width: 280px) {
      max-width: 327px;
      width: 100%;
      height: 56px;
    }
  }

  .inputBottom {
    margin-bottom: 0;
  }

  #outlined-helperText {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    line-height: 20.16px;
  }
`;
