import styled from 'styled-components';

export const ProfileTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  margin-top: 55px;
  margin-bottom: 35px;

  @media (max-width: 500px) {
    margin-top: 1rem;
    font-size: 20px;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(2, 150px);
  grid-gap: 25px;

  div:nth-child(2) {
    .alignBox {
      .titleInfo {
        div {
          top: -31px;
        }
      }
    }
  }

  @media (max-width: 1292px) {
    grid-template-rows: repeat(2, auto);
  }

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: #28293d;
  border-radius: 20px;
  padding: 32px;

  .alignBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    > p {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin-right: 16px;
    }

    .titleInfo {
      position: relative;

      svg {
        cursor: pointer;
      }

      div {
        content: '';
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  img {
    padding: 1rem 0 1rem 0;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-family: 'League Gothic';

    font-size: 44px;
    line-height: 53px;
    text-align: center;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 0.3rem;
  }
`;

export const Column = styled(InfoContainer)`
  grid-row: 1/3;
  grid-column: 2/2;

  @media (max-width: 760px) {
    min-height: auto;
    height: auto;
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 10px;

  @media (max-width: 700px) {
    overflow: auto;
    height: auto;
    max-height: 100%;
    padding-right: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
    background-color: #4b4c66;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  .statusMessage {
    max-width: auto;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin-right: 10px;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    white-space: nowrap;
  }

  span {
    margin-left: 12px;
    margin-right: 12px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const ContainerLine = styled.div<{ hasToBreak?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1292px) {
    flex-direction: column;
    align-items: start;

    h2 {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 760px) {
    h2 {
      margin-bottom: 0px;
    }

    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: ${({ hasToBreak }) => (hasToBreak ? 'column' : 'row')};
    align-items: ${({ hasToBreak }) => (hasToBreak ? 'start' : 'center')};

    h2 {
      margin-bottom: ${({ hasToBreak }) => (hasToBreak ? '20px' : '0')};
    }
  }
`;

export const ButtonSeeMore = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;
  border: 1px solid #ffffff;
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 24px;
`;
