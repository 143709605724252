import { twMerge } from 'tailwind-merge';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hierarchy: 'primary' | 'secondary' | 'tertiary' | 'login';
  label?: string;
  moreClasses?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  weight?: 'thin' | 'fat';
}

const Button = ({
  moreClasses,
  onClick,
  hierarchy,
  size,
  type,
  disabled,
  label,
  children,
  weight,
  ...rest
}: IButtonProps) => {
  const disabledClasses = () => {
    if (disabled) {
      return 'opacity-40 cursor-not-allowed bg-[#4B4C66]';
    }
  };

  if (!weight) {
    weight = 'thin';
  }

  const hierarchyClasses = () => {
    if (hierarchy === 'primary') {
      return 'border-[1px] border-transparent text-[#1C1C27] disabled:!bg-toast-300 disabled:!text-onPrimary-100 bg-background-highlight';
    }
    if (hierarchy === 'secondary') {
      return `bg-background-transparent border-text-lightGray border-[1px] text-text-grayMenu disabled:!border-onPrimary-100 disabled:!text-onPrimary-100 ${
        !disabled && 'hover:bg-onPrimary-25'
      }`;
    }
    if (hierarchy === 'tertiary') {
      return `border-[1px] border-transparent !duration-300 text-text-grayMenu disable:text-onPrimary-100 ${
        !disabled && 'hover:!text-primary-500 hover:!bg-primary-50'
      }`;
    }

    return 'bg-background-login text-text-white disabled:!bg-toast-300 disabled:!text-onPrimary-100';
  };

  const sizeClasses = () => {
    if (size === 'sm') {
      return 'leading-6 tracking-tightest w-fit px-4';
    }
    if (size === 'md') {
      return 'w-[150px] h-[34px] py-2 border-box';
    }
    if (size === 'lg') {
      return 'w-full max-w-[224px]';
    }

    return 'w-fit px-[46px]';
  };

  return (
    <button
      className={twMerge(
        `tracking-[1.25px] font-outfit rounded-full outline-none ease-out transition-all duration-150 focus:outline-none font-semibold text-base flex items-center justify-center ${hierarchyClasses()} ${sizeClasses()} ${disabledClasses()} ${
          weight !== 'thin' ? 'py-4' : 'py-2'
        }`,
        moreClasses
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
      {...rest}
    >
      {children}
      {label}
    </button>
  );
};

export default Button;
