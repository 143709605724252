import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Outfit';
  max-width: 327px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    padding-left: 16px;
    line-height: 20.16px;
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4b4c66;
  }

  & label.Mui-focused {
    color: #c4c4c4;
  }

  & .MuiOutlinedInput-root {
    border: 1px;
    border-radius: 20px;
    border-color: white;

    &.Mui-focused fieldset {
      border-radius: 20px;
      border-color: #4b4c66;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      border: 1px;
      border-color: #4b4c66;
      border-radius: 20px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .textClass {
    border: 2px #4b4c66;
    border-color: #4b4c66;
    background-color: #202030;
    border-radius: 20px;
    width: 100%;
    height: 56px;

    @media (max-width: 767px) {
      max-width: 327px;
      width: 100%;
      height: 56px;
    }

    @media (max-width: 280px) {
      max-width: 327px;
      width: 100%;
      height: 56px;
    }
  }

  .inputBottom {
    margin-bottom: 0;
  }

  #outlined-helperText {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    line-height: 20.16px;
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Outfit';
  color: #ff4444;
  font-size: 14px;
  margin-left: 24px;
  margin-top: 8px; //text-[] text-[] ml-5 mt-1.5
`;
