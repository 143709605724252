import { TeamMatchPoint } from '../../typescript/match';

interface IMatchSelectorProps {
  updatePoint: (team: string, value: number) => void;
  disabled?: boolean;
  item: TeamMatchPoint;
}

const ExactMatchPoint = ({ item, updatePoint, disabled = false }: IMatchSelectorProps) => {
  const handleUpdatePointValue = ({ team, value }: { team: string; value: number }) => {
    updatePoint(team, value);
  };

  return (
    <div className="flex items-center justify-start gap-2">
      <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row xl:flex-col 2xl:flex-row gap-4 w-full min-h-[100px]">
        <div className="h-100 min-h-[80px] w-full border-2 border-background-400 rounded-lg flex flex-col gap-1 justify-center items-center p-2 sm:p-5 md:p-2 lg:p-5 xl:p-2 2xl:p-5 sm:min-w-[150px] md:min-w-fit lg:min-w-[150px] xl:min-w-fit">
          <div className="w-full h-full max-w-[32px] max-h-[32px] rounded-full bg-white overflow-hidden flex items-center justify-center">
            <img
              src={item.team1.image}
              alt={`Logo do time ${item.team1.name}`}
              className="w-full h-full object-cover"
            />
          </div>

          <span className="text-sm text-center truncate">{item.team1.name}</span>
        </div>

        <div className="h-100">
          <input
            min={0}
            max={1000}
            type="number"
            value={item.team1.point}
            disabled={disabled}
            defaultValue={item.team1.point}
            className={`focus:outline-none focus:ring-0 focus:border-[#FF8E00] min-w-[85px] w-full h-full text-white text-center font-[400] p-2 sm:p-0 border-2 border-[#4B4C66] rounded-lg text-xl truncate min-h-[40px] ${
              disabled ? 'bg-[#4B4C66]' : 'bg-transparent'
            }`}
            onChange={(event) => handleUpdatePointValue({ team: item.team1.name, value: event.target.valueAsNumber })}
          />
        </div>
      </div>

      <span className="text-xl text-text-grayMenu">X</span>

      <div className="flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row xl:flex-col-reverse 2xl:flex-row gap-4 w-full min-h-[100px]">
        <div className="h-100">
          <input
            min={0}
            max={1000}
            type="number"
            disabled={disabled}
            defaultValue={item.team2.point}
            value={item.team2.point}
            className={`focus:outline-none focus:ring-0 focus:border-[#FF8E00] min-w-[85px] w-full h-full text-white text-center font-[400] p-2 sm:p-0 border-2 border-[#4B4C66] rounded-lg text-xl truncate min-h-[40px] ${
              disabled ? 'bg-[#4B4C66]' : 'bg-transparent'
            }`}
            onChange={(event) => handleUpdatePointValue({ team: item.team2.name, value: event.target.valueAsNumber })}
          />
        </div>

        <div className="h-100 min-h-[80px] w-full border-2 border-background-400 rounded-lg flex flex-col gap-1 justify-center items-center p-2 sm:p-5 md:p-2 lg:p-5 xl:p-2 2xl:p-5 sm:min-w-[150px] md:min-w-fit lg:min-w-[150px] xl:min-w-fit">
          <div className="w-full h-full max-w-[32px] max-h-[32px] rounded-full bg-white flex items-center justify-center">
            <img src={item.team2.image} alt={`Imagem de ${item.team2.name}`} className="w-full h-full object-cover" />
          </div>

          <span className="text-sm text-center truncate">{item.team2.name}</span>
        </div>
      </div>
    </div>
  );
};

export default ExactMatchPoint;
