import styled from '@emotion/styled';
import { TextFieldContainer } from './../PixWithdraw/styles';

export const CreateGroupContainer = styled.div`
  width: 515px;
  height: auto;
  background: #28293d;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1214px) {
    width: 450px;
  }

  @media (max-width: 1119px) {
    justify-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 750px) {
    background: transparent;
    width: 100%;
    padding: 0;
  }

  .avatar-error-msg {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ff4444;
    margin-bottom: 50px;
    margin-top: 10px;
  }

  .button-div-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-content: center;

    @media (max-width: 350px) {
      flex-wrap: wrap-reverse;
    }
  }
`;

export const CreateGroupTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;

  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

export const CreateGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* @media (max-width: 350px) {
    flex-direction: column;
    justify-content: center;
  } */
`;

export const CreateGroupHeaderAvatar = styled.div`
  background: #4b4c66;
  width: 80px;
  height: 80px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 369px) {
    width: 60px;
    height: 60px;
  }
`;

export const CreateGroupDropZone = styled.button`
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 100px;
  background: transparent;
  padding: 8px 16px;
  margin-left: 30px;
  cursor: pointer;

  @media (max-width: 550px) {
    font-size: 12px;
  }

  @media (max-width: 369px) {
    padding: 5px 10px;
    margin-left: 10px;
  }
`;

export const CreateGroupInput = styled(TextFieldContainer)`
  width: 100%;
  height: auto;
  margin-top: -24px;
`;

interface GroupButtonProps {
  isOutlined?: boolean;
}

export const CreateGroupButton = styled.button<GroupButtonProps>`
  margin-top: 24px;
  min-width: 150px;
  width: auto;
  height: 52px;
  background: ${({ isOutlined }) => (isOutlined ? 'transparent' : '#ff8e00')};
  border: ${({ isOutlined }) => (isOutlined ? '1px solid #fff' : 'none')};
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isOutlined }) => (isOutlined ? '#fff' : '#1c1c27')};
  margin-right: ${({ isOutlined }) => (isOutlined ? '16px' : '0')};
  outline: none;
  cursor: pointer;

  @media (max-width: 369px) {
    width: 60px;
    height: 45px;
    font-size: 13px;
  }

  @media (max-width: 730px) {
    min-width: 100px;
    width: 150px;
  }
  @media (max-width: 350px) {
    width: 100%;

    margin-right: 0;
  }
`;

export const RulesCreateGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  p {
    display: flex;
    flex-direction: column;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
`;

export const RulesGroupTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
`;
