import { HTMLAttributes, useEffect, useState } from 'react';
import { Game } from '../../typescript/match';
import { formatValue } from '../../utils/types';

import Button from '../Button/Button';
import { Timer2 } from '../Timer';
import { CardImgBg } from './CardImgBg';
import { CardImgContentContainer } from './styles';
import moment from 'moment';

export interface ICardImgProps extends HTMLAttributes<HTMLDivElement> {
  colorSchema: 'light' | 'dark';
  title?: string;
  value: number;
  currency?: 'USD' | 'BRL';
  image?: string;
  imagetwo?: string;
  imageAlt?: string;
  text?: string;
  timeLeft: string;
  buttonText?: string;
  pot?: Game;
  accumulated: number;
  championship?: string;
  openMenu?: () => void;
}

const CardImg = ({
  colorSchema,
  title,
  value,
  currency,
  image,
  imagetwo,
  imageAlt,
  text,
  timeLeft,
  buttonText,
  openMenu,
  accumulated,
  championship,
}: ICardImgProps) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const updateSize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  });

  return (
    <CardImgContentContainer
      onClick={openMenu}
      className={`card-game-image  ${colorSchema === 'light' ? 'bg-background-light' : 'bg-background-200'}`}
    >
      <div className="z-20 text-[16px] font-semibold">
        <h2 className={`${colorSchema === 'light' ? 'text-text-black' : 'text-text-white'} champioshipName`}>
          {championship}
        </h2>
        <h2
          className={`font-outfit leading-[18px] text-[14px] font-normal
                    ${colorSchema === 'light' ? 'text-text-black' : 'text-text-white'}`}
        >
          {title}
        </h2>
        <h3
          className={`text-[44px] font-gothic leading-[53px]
                    ${colorSchema === 'light' ? 'text-text-gray' : 'text-text-white'}`}
        >
          {currency === 'USD'
            ? `$ ${value}`
            : `${accumulated >= value ? `R$${formatValue(accumulated)}` : `R$${formatValue(value)}`}`}
        </h3>
        <div className="py-3 hidden md:block">
          <p
            className={`text-xs font-outfit leading-same
                        ${colorSchema === 'light' ? 'text-text-black' : 'text-text-white'}`}
          >
            {text}
          </p>
          <div
            className={`text-md font-outfit leading-same hidden md:block
                        ${colorSchema === 'light' ? '!text-text-black' : '!text-text-highlight'}`}
          >
            <Timer2 finalAt={moment(timeLeft).toDate()} />
          </div>
        </div>

        {windowSize >= 768 && buttonText && (
          <Button
            label={buttonText}
            moreClasses=" max-w-[112px] w-full h-[34px] font-semibold text-[14px] leading-[18px]"
            onClick={openMenu}
            hierarchy="primary"
            size="sm"
          />
        )}
      </div>
      <CardImgBg />
      {image && (
        <img
          className="img-team absolute rounded-full  object-contain md:max-h-[150px] max-h-[100px] max-w-[30%] w-full bottom-[8%] right-[15%] md:bottom-[22%] md:right-[15%] z-[10]"
          src={image}
          alt={imageAlt || ''}
        />
      )}
      {imagetwo && (
        <img
          className={`img-team absolute rounded-full ${imagetwo.includes("atleta") || imagetwo.includes("team") ? "opacity-90": ""}  object-contain md:max-h-[150px] max-h-[100px] max-w-[30%] w-full bottom-[8%] right-0.5 md:bottom-[22%] z-[9]`}
          src={imagetwo}
          alt={imageAlt || ''}
        />
      )}
    </CardImgContentContainer>
  );
};

export default CardImg;
