export const shareLinkByClipBoard = (link: string) => {
  const el: HTMLTextAreaElement = document.createElement('textarea');

  if (el) {
    el.value = link;
    el.setAttribute('readonly', '');
    (el as any).style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
  }

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    const editable = el.contentEditable;
    const readOnly = el.readOnly;

    // convert to editable with readonly to stop iOS keyboard opening
    if (el) {
      (el as any).contentEditable = true;
      el.readOnly = true;
    }

    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(el);

    // select the range
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      el.setSelectionRange(0, 999999);
    }

    // restore contentEditable/readOnly to original state
    el.contentEditable = editable;
    el.readOnly = readOnly;
  } else {
    el.select();
  }

  document.execCommand('copy');
  document.body.removeChild(el);
};
