import { getPots } from '../service/pots/pots.service';
import { Game } from '../typescript/match';

export const usePotListing = async () => {
  const response = await getPots('/?expire_in=9999&status=Em%20Andamento');

  if (!response) return;

  const result = response.data?.data.sort((a: Game, b: Game) => {
    const dateSorted = a.dateFinalBet.localeCompare(b.dateFinalBet);

    if (dateSorted === 0) {
      const valueANormalized = a.valueAccumulated === null ? Number.NEGATIVE_INFINITY : a.valueAccumulated;
      const valueBNormalized = b.valueAccumulated === null ? Number.NEGATIVE_INFINITY : b.valueAccumulated;

      return valueBNormalized - valueANormalized;
    }

    return dateSorted;
  });

  return result;
};
