import { WELCOME_TO_GROUP } from '../constants/local-storage-keys';
import { SessionControl } from '../session/SessionControl';

const urlVar = process.env.REACT_APP_API_BASE_URL;
export const image_server_path = urlVar ? urlVar.replace('/api/v1', '') : '';

export const checkGroupVisit = (userId: number) => {
  const data = SessionControl.getWelcomeToGroup(userId);
  if (data) {
    return JSON.parse(data);
  }
  SessionControl.setWelcomeToGroup(userId, true);
  return !!data;
};

export const setOffGroupWelcome = (userId: number) => {
  SessionControl.setWelcomeToGroup(userId, false);
};
