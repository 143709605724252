export function normalizeName(name: string) {
  if (name) {
    const normalized = name.replace(/[^\w\s]/gi, '');

    const hyphenated = normalized.replace(/\s+/g, '-');

    return hyphenated.toLowerCase();
  } else {
    return '';
  }
}

export const normalizePotUrl = (name: string) => {
  if (!name) return '';

  const hyphenated = encodeURIComponent(name)
 
  return hyphenated;
};

export const serializePotName = (name: string) => {
  if (!name) return '';

  const normalized = name.replace(/-/g, ' ').replace(/[^\w\s]/gi, '');
  return normalized;
};
