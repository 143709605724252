import styled from '@emotion/styled';

export const CardImgContentContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
  padding-top: 0.375rem /* 6px */;
  width: 100%;
  height: 120px;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 225px;
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    .champioshipName {
      max-width: 160px;
    }
  }
`;

export const CardImageContainer = styled.div`
  display: grid;
  grid-template-columns: 14px 14px;
  grid-template-rows: 34px 34px;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  position: absolute;
  top: -20px;
  right: 20px;

  .div-shape {
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    border-radius: 20px;
  }

  .div1 {
    background: #e4e4e6;
  }

  .div2 {
    background: #ff8e00;
    opacity: 0.5;
  }

  .div3 {
    background: #e4e4e6;
    opacity: 0.2;
  }

  .div4 {
    background: #ff8e00;
    opacity: 0.7;
  }
`;
