import { Navigate, useNavigate } from 'react-router-dom';
import ParticipateGroup from '../../components/ParticipateGroup';
import { useUserAuth } from '../../hooks/user-auth';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { OverlayModalCreateGroup } from './../../components/NavbarMenu/styles';

const ParticipateGroupPage = () => {
  const navigate = useNavigate();
  useUserAuth({ isPrivatePage: true })

  return (
    <PageContainer>
      <OverlayModalCreateGroup>
        <ParticipateGroup handleBack={() => navigate(-1)} />
      </OverlayModalCreateGroup>
    </PageContainer>
  );
};

export default ParticipateGroupPage;
