import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 685px) {
    margin-top: -24px;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% repeat(4, 20%);
  background: #28293d;
  border-radius: 20px;
  padding: 63px;
  margin-bottom: 26px;

  @media (max-width: 1335px) {
    grid-template-columns: 200px repeat(2, auto);
    grid-template-rows: auto auto;
  }

  @media (max-width: 685px) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    justify-content: center;

    .networks-menu {
      grid-row: 2/2;
      grid-column: 1/3;
    }

    .security-menu {
      grid-column: 1/3;
    }

    .about-menu {
      margin-right: 20px;
    }
  }

  @media (max-width: 451px) {
    grid-template-columns: auto;

    .contact-menu {
      grid-row: 4/4;
    }
    .about-menu {
      margin-right: 0;
    }
  }
`;

export const FooterLogo = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-top: -12px;

  @media (max-width: 685px) {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/3;
  }
`;

export const FooterMenu = styled.ul`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  .security-div {
    display: flex;
    flex-direction: column;

    img {
      object-fit: scale-down;
      width: 83.61px;
      height: 40px;

      &:last-child {
        width: 142px;
        height: 44.25px;
      }
    }
  }

  .networks-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      margin-right: 10px;
    }

    @media (max-width: 685px) {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 685px) {
    margin-top: 20px;
    width: auto;
    align-items: center;

    .mb-no-title {
      display: none;
    }

    li {
      width: 100%;
    }

    .networks-div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }

    .security-div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      img {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 451px) {
    .security-div {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const FooterMenuTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 16px;
  width: 100%;

  @media (max-width: 451px) {
    width: auto;
  }
`;

export const FooterMenuItem = styled.li`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;
  cursor: pointer;
  margin-bottom: 12px;
  width: auto;

  @media (max-width: 451px) {
    text-align: center;
  }
`;
