import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiMail } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { recoverPass } from '../../service/user/user.service';

import PageContainer from '../../layouts/PageContainer/PageContainer';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

import 'react-toastify/dist/ReactToastify.css';
import { ErrorAlert, SuccessAlert } from '../../components/BasicAlerts';

const text = {
  title: 'Digite seu email para recuperação de senha',
  sendButtonLabel: 'Recuperar senha',
  returnButtonLabel: 'Voltar',
  returnMessage: 'Você receberá um e-mail para recuperar sua senha. Certifique-se de olhar a caixa de spam.',
};

interface IRecoveryFormData {
  email: string;
}

const PasswordRecovery = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().required('E-mail é obrigatório').email('E-mail inválido. Por favor tente novamente.'),
      }),
    []
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IRecoveryFormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: IRecoveryFormData) => {
    setStatusRequest('');
    setMessage('');
    try {
      setError(false);
      const response = await recoverPass(data.email);
      if (response.status === 200) {
        setSuccess(true);
        setStatusRequest('success');
        setMessage('Enviamos um email contendo as informações para recuperar sua senha!');
        return;
      }
      setSuccess(false);
      setError(true);
      if (response.status === 400) {
        setStatusRequest('error');
        return setMessage(response.data);
      }
    } catch (e) { /* empty */ }
  };

  const navigate = useNavigate();

  const errorClassName = 'text-sm font-outfit text-state-fail text-center';
  const successClassName = 'text-sm font-outfit text-center';

  return (
    <PageContainer>
      {statusRequest === 'success' && (
        <SuccessAlert title={'Sucesso'} message={message} onClose={handleCleanStatusRequest} autoClose />
      )}
      {statusRequest === 'error' && (
        <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} autoClose />
      )}
      <div className="p-[20px] bg-background-200 my-[20px] rounded-20">
        <h2 className="mb-[1em] text-2xl font-semibold leading-5">{text.title}</h2>
        <div className="flex flex-col items-center gap-[1em] lg:max-w-[500px]">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                className="lg:max-w-[350px]"
                name="email"
                placeholder="Email"
                type="text"
                icon={FiMail}
                onBlur={field.onBlur}
                onChange={(value: string) => field.onChange(value)}
              />
            )}
          />
          {errors.email && <span className={errorClassName}>{errors.email.message}</span>}
          {error && (
            <span className={errorClassName}>
              Algo deu errado, por favor tente novamente mais tarde. Caso o erro persista, entre em contato
            </span>
          )}
          {success && <span className={successClassName}>{text.returnMessage}</span>}
          <Button
            moreClasses="block"
            label={text.sendButtonLabel}
            hierarchy="primary"
            size="lg"
            onClick={handleSubmit(onSubmit)}
          />
          <Button
            moreClasses="block "
            label={text.returnButtonLabel}
            hierarchy="primary"
            size="lg"
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default PasswordRecovery;
