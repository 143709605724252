import { useEffect } from 'react';
import { useGetWindowSize } from './useGetWindowSize';

interface useHideScrollWhenShowModalProps {
  state: boolean;
  hidePageContent?: boolean;
}

export const useHideScrollWhenShowModal = ({
  state = false,
  hidePageContent = true,
}: useHideScrollWhenShowModalProps) => {
  const windowSize = useGetWindowSize();

  const hideScroll = () => {
    const bodyElement = document.querySelector('body');
    const htmlElement = document.querySelector('html');
    const container = document.querySelector('.container-with-content');

    bodyElement?.style.setProperty('overflow-y', 'hidden');
    htmlElement?.style.setProperty('overflow-y', 'hidden');

    if (windowSize.width <= 600 && hidePageContent) {
      (container as HTMLHtmlElement)?.style.setProperty('display', 'none');
    }
  };

  const showScroll = () => {
    const bodyElement = document.querySelector('body');
    const htmlElement = document.querySelector('html');
    const container = document.querySelector('.container-with-content');

    bodyElement?.style.setProperty('overflow-y', 'auto');
    htmlElement?.style.setProperty('overflow-y', 'auto');

    if (windowSize.width <= 600 && hidePageContent) {
      (container as HTMLHtmlElement)?.style.setProperty('display', 'flex');
    }
  };

  useEffect(() => {
    if (state) {
      hideScroll();
    } else {
      showScroll();
    }

    return ()=> showScroll();
  }, [state, windowSize]);

  return { isHided: state };
};
