import { HTMLAttributes } from 'react';

interface ICheckboxProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  value: string;
  link?: string;
  linkUrl?: string;
}

const Checkbox = ({ name, label, value, link, linkUrl, onChange, className }: ICheckboxProps) => {
  const spanClasses = 'text-text-placeholder font-outfit text-sm font-[200]';
  return (
    <label className={`flex flex-row gap-[12px] ${className}`}>
      <input
        className="mt-[2px] w-[16px] h-[16px] rounded bg-transparent border-text-placeholder border-[1.2px] min-w-[16px] min-h-[16px] w-[16px] h-[16px]"
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className={spanClasses}>
        {label}
        {link && (
          <a href={linkUrl} target="_blank" rel="noreferrer" className={`underline inline ${spanClasses}`}>
            {link}
          </a>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
