import styled from 'styled-components';
import { ModalAlertContainerStyle } from './../styles';

export const ModalAlertContainer = styled(ModalAlertContainerStyle)`
  width: 552.48px;
  height: 753px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .simple-text {
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

export const ModalAlertSaqueTitle = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

export const ModalAlertSaqueSimpleText = styled.p`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const ModalAlertSaqueSeparator = styled.div`
  border: 2px dashed #ffffff;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const ModalAlertSaqueTable = styled.div`
  width: 80%;
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(11, 25px);

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ModalAlertSaqueTableItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;

export const ModalAlertSaqueTableItemValue = styled.h1`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  color: #ffffff;
`;
