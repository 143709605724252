import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
`;

export const GroupInfoContainer = styled.div`
  position: relative;

  height: auto;
  background: #28293d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 36px;
  width: 38vw;
  min-width: 300px;

  @media (min-width: 640px) {
    padding: 48px;
    min-width: 547px;
  }

  .close {
    color: #f4f4f4;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const GroupInfoAvatar = styled.div`
  max-width: 110px;
  max-height: 110px;
  border-radius: 100%;
  margin-bottom: 18px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const GroupInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShareLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #fff;

    @media (min-width: 960px) {
      font-size: 1.75rem;
    }
  }

  p {
    margin-top: 18px;
    margin-bottom: 24px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
  }
`;

interface LinkInviteProps {
  copied?: boolean;
}

export const LinkInvite = styled.p<LinkInviteProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 267px;
  width: 100%;

  p {
    margin: 0;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Outfit';
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    color: ${({ copied }) => (copied ? ' #00DC6E' : ' #c4c4c4')};
  }

  width: 100%;
  height: auto;
  padding: 8px;
  border: ${({ copied }) => (copied ? '1px solid #00DC6E' : '1px solid #4b4c66')};
  border-radius: 8px;
  background: #28293d;

  button {
    margin-left: 8px;

    svg {
      g path {
        fill: ${({ copied }) => (copied ? '#00DC6E' : '#C4C4C4')};
      }
    }
  }

  @media (max-width: 760px) {
    height: auto;

    p {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
