import React, { useEffect, useState } from 'react';
import { BiCalendar, BiEnvelope, BiKey, BiUser } from 'react-icons/bi';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { passwordLabels } from '../../pages/Account/helper';
import { Container } from '../../pages/Login/styles';
import { getByUsername, userRegister } from '../../service/user/user.service';
import { useDispatch } from '../../store';
import { GroupLinkInviteData } from '../../store/reducers/group/types';
import { login } from '../../store/reducers/user/userSlice';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Input from '../Input/Input';
import { rules } from './helpers';
import moment from 'moment/moment';
import { getCurrentDate } from '../../utils/timestampDisplay';
import { SessionControl } from '../../session/SessionControl';

interface RegisterFormProps {
  onChangeMode: (mode: 'login' | 'register') => void;
  onError?: (mode: string) => void;
  onSuccess: (msg: string) => void;
  isAtModal?: boolean;
  handleClose?: () => void;
}

const RegisterForm = ({ onChangeMode, onError, isAtModal, onSuccess, handleClose = () => null }: RegisterFormProps) => {
  const { linkGroupInvited } = useSelector((state: GroupLinkInviteData) => state);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setBirthdate] = useState<Date>(getCurrentDate());
  const [ageAndTermsCheck, setAgeAndTermsCheck] = useState(false);
  const [acceptMails, setAcceptMails] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [formValidity, setFormValidity] = useState({
    username: false,
    email: false,
    dob: false,
    password: false,
    ageAndTermsCheck: false,
  });
  const [forceInvalid, setForceInvalid] = useState<boolean>();

  const dispatch = useDispatch();
  const token = SessionControl.getPartner();

  const [isLoading, setIsLoading] = useState(false);

  const formatDate = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const dateInput = target.valueAsDate;

    if (dateInput) {
      const date = moment(dateInput).toDate();
      setFormValidity({ ...formValidity, dob: rules.birthdateRule(date) });
      setBirthdate(date);
    }
  };

  const handleChangeMode = () => {
    onChangeMode('login');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await userRegister({
        username,
        email,
        password,
        dob,
        ageAndTermsCheck,
        acceptMails,
        token,
      });

      SessionControl.clearPartner();

      if (response.error) {
        throw new Error(response.message[0]);
      }
      if (response.status === 500) {
        return toast.error('Falha ao enviar o email, certifique que o endereço está correto');
      }
      if (response.status === 400 && response.data === 'E-mail já cadastrado. Por favor, tente novamente') {
        setEmailErrorMsg('E-mail já cadastrado');
        toast.error('E-mail já cadastrado');
        return;
      }

      await dispatch(login({ username, password, remember: false }));

      onSuccess('Usuário criado com sucesso!');

      try {
        const token = SessionControl.getToken();
        if (token) {
          if (linkGroupInvited.url !== '') {
            navigate(`${linkGroupInvited.url}`);
          } else if (isAtModal) {
            onSuccess('sucess');
          } else {
            navigate('/');
          }
        } else {
          throw new Error('Usuário ou senha incorretos!');
        }
      } catch (err: any) {
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const buttonBack = () => {
    if (isAtModal) {
      handleClose();
    } else {
      navigate('/');
    }
  };

  const isAllFieldsValid = () => Object.values(formValidity).every((v) => v);

  useEffect(() => {
    const isValidPassword = [
      rules.passwordMinLengthRule,
      rules.passwordNumberRule,
      rules.passwordUppercaseRule,
      rules.passwordSymbolRule,
    ].every((r: any) => r(password));
    setFormValidity({ ...formValidity, password: isValidPassword });
    setErrorMessage('');
  }, [password, dob, username, email, ageAndTermsCheck]);

  const customUntakenUsernameRule = async () => {
    try {
      const res = await getByUsername(username);
      setForceInvalid(!!res.data);

      if (res.data) {
        setFormValidity({ ...formValidity, username: !!res.data });
        setErrorMessage(res.data);
      }
      return !res.data;
    } catch (e) {
      setForceInvalid(false);
      return false;
    }
  };

  return (
    <form className="flex flex-col w-full gap-[16px]" autoComplete="new-password">
      <Container>
        <Input
          name=""
          placeholder="Usuário (sem espaço)"
          type="text"
          id="nome"
          value={username}
          autoComplete="new-password"
          autoCapitalize="none"
          onChange={(e: any) => {
            setUsername(e.target.value.trim());
          }}
          onSuccess={(v: any) => setFormValidity({ ...formValidity, username: v })}
          rules={[rules.alphanumericRule, rules.usernameLengthRule]}
          forceInvalid={forceInvalid}
          onBlur={customUntakenUsernameRule}
          errorMessage={forceInvalid ? 'Usuário já cadastrado.' : 'Usuário inválido.'}
          disabled={isLoading}
          icon={BiUser}
        />
      </Container>

      <Input
        name="email"
        placeholder="Email"
        autoComplete="new-password"
        type="email"
        value={email}
        onChange={(e: any) => {
          setEmailErrorMsg('');
          setEmail(e.target.value.trim());
        }}
        onSuccess={(v: any) => setFormValidity({ ...formValidity, email: v })}
        rules={[rules.emailRule]}
        errorMessage={'E-mail inválido.'}
        disabled={isLoading}
        icon={BiEnvelope}
      />

      {!!emailErrorMsg && <p className="text-[#FF4444] text-[14px] ml-5">{emailErrorMsg}</p>}

      <Input
        name=""
        placeholder="Senha"
        autoComplete="new-password"
        type="password"
        value={password}
        onChange={(e: any) => setPassword(e.target.value.trim())}
        onSuccess={(v: any) => setFormValidity({ ...formValidity, password: v })}
        rules={[
          rules.passwordMinLengthRule,
          rules.passwordNumberRule,
          rules.passwordUppercaseRule,
          rules.passwordSymbolRule,
        ]}
        disabled={isLoading}
        icon={BiKey}
      />

      {password.length ? (
        <div className="ml-4">
          {passwordLabels.map((item) => (
            <span
              key={item.label}
              className={`
                            flex
                            items-center
                            ${item.rule(password) ? 'text-[#00DC6E]' : 'text-[#FF4444]'}
                        `}
            >
              {item.rule(password) ? <IoIosCheckmark className="mr-1" /> : <IoIosClose className="mr-1" />}
              {item.label}
            </span>
          ))}
        </div>
      ) : (
        ''
      )}
      <Input
        name="birthdate"
        placeholder="Data de Nascimento"
        type="date"
        onChange={formatDate}
        id="dataNasc"
        onSuccess={(v: any) => setFormValidity({ ...formValidity, dob: v })}
        rules={[rules.birthdateRule]}
        errorMessage="Apenas maiores de 18 anos."
        disabled={isLoading}
        icon={BiCalendar}
      />

      <Checkbox
        className="mt-2"
        label="Confirmo que tenho mais de 18 anos de idade e aceito os "
        link="Termos e Condições."
        linkUrl="/terms"
        name="ageAndTermsCheck"
        value="agreeAgeAndTerms"
        onChange={(e) => {
          setAgeAndTermsCheck(e.currentTarget.checked);
          setFormValidity({
            ...formValidity,
            ageAndTermsCheck: e.currentTarget.checked,
          });
        }}
      />

      <Checkbox
        className="mb-2"
        label="Aceito receber emails com recompensas e ofertas"
        name="mailListCheck"
        value="acceptMails"
        onChange={(e) => setAcceptMails(e.currentTarget.checked)}
      />

      <Button
        moreClasses="w-full h-[52px] bg-[#FF8E00] text-black"
        disabled={!isAllFieldsValid()}
        onClick={handleSubmit}
        label="Criar conta"
        hierarchy="primary"
        size="md"
      />

      {isLoading ? (
        <div className="flex justify-center">
          <BeatLoader
            color={'#FF8E00'}
            speedMultiplier={0.8}
            size={12}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <p className="text-sm font-extralight text-center text-white">
            Já tem uma conta?
            <span className="text-[#FF8E00] cursor-pointer hover:underline ml-1" onClick={handleChangeMode}>
              Entrar
            </span>
          </p>
          <button className="mt-[16px] text-white" onClick={buttonBack}>
            Voltar
          </button>
        </>
      )}
    </form>
  );
};

export default RegisterForm;
