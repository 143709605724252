import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { useUserContext } from '../../common/context/UserContext';
import { getAcceptPayment } from '../../service/user/user.service';
import { RootState } from '../../store';
import { ReactComponent as IconPix } from '../../utils/icons/iconePix.svg';
import Button from '../Button/Button';
import { ModalPixContentContainer, ModalPixOverlayContainer } from './styles';

interface IModalAlertProps {
  qrcode: string;
  qrcodeImage: string;
  id_transaction: string;
  openAlert: boolean;
  setOpenAlert: Function;
  setPacketOne: Function;
  setPacketTwo: Function;
  setPacketThree: Function;
  setPacketFour: Function;
  setStep: Function;
  setLoading: Function;
  handleSuccess: () => void;
}

const ModalPix = ({
  qrcode,
  qrcodeImage,
  id_transaction,
  setOpenAlert,
  openAlert,
  setPacketFour,
  setPacketOne,
  setPacketThree,
  setPacketTwo,
  setStep,
  setLoading,
  handleSuccess,
}: IModalAlertProps) => {
  const { token } = useSelector((state: RootState) => state.user);
  const { userLogged } = useUserContext();
  const [copy, setCopy] = useState(false);
  const [statusPayment, setStatusPayment] = useState('');

  const initialTimer = 10 * 60;
  const [timer, setTimer] = useState(initialTimer);

  const minutes = Math.floor(timer / 60);
  const secunds = timer % 60;

  const sharePot = () => {
    const textArea = document.createElement('textarea');
    textArea.value = qrcode;
    textArea.className = 'absolute top-0 left-0';
    document.body.appendChild(textArea);
    try {
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      // toast.success("Link copiado com sucesso!");
      setCopy(true);
    } catch (e) {
      console.error(e);
    }

    document.body.removeChild(textArea);
  };

  function redirect() {
    setOpenAlert(false);
    window.location.reload();
  }

  function redirectCancel() {
    // if (timer)
    setPacketOne(0);
    setPacketTwo(0);
    setPacketThree(0);
    setPacketFour(0);
    setOpenAlert(false);
    setLoading(false);
    setStep('stepOne');
  }

  const pollPaymentStatus = async () => {
    const result = await getAcceptPayment(id_transaction);
    const statusPayment = result.data.status;

    if (statusPayment === 'approved') {
      handleSuccess();
      setStatusPayment(statusPayment);
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 25000));

    pollPaymentStatus();
  };

  useEffect(() => {
    if (openAlert) {
      if (timer === 0) {
        // REGENERATE
        return;
      }

      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setTimer(initialTimer);
    }
  }, [timer, openAlert]);

  useEffect(() => {
    if (userLogged && openAlert) {
      pollPaymentStatus();
    }
  }, [id_transaction, statusPayment, token, userLogged]);

  useEffect(() => {
    if (statusPayment === 'approved') {
      setOpenAlert(false);
    }
  }, [statusPayment]);

  return (
    <ModalPixOverlayContainer open={openAlert}>
      <ModalPixContentContainer>
        <button
          className="text-white p-2 absolute right-3 top-2"
          onClick={() => {
            statusPayment === 'approved' ? redirect() : redirectCancel();
          }}
        >
          <MdClose size={18} />
        </button>

        <div className="mt-8">
          <IconPix />
        </div>
        <div>
          <h2 className="mt-4 text-white text-center text-[1.5rem] font-semibold">Pix gerado com sucesso</h2>
        </div>
        <div className="mt-2">
          <p className="text-white text-base text-center font-light">Pague pelo seu app de pagamentos preferido ou</p>
          <p className=" text-white text-center text-base font-light">pelo Internet Banking.</p>
        </div>

        <div className="mt-2">
          <p className="text-white text-base text-center font-light">
            Você pode escanear este código QR com o celular:
          </p>
        </div>

        <div className="mt-2">
          <div className="ticket__qrcode-image">
            {!qrcodeImage ? (
              <div className="flex items-center justify-center w-[186px] h-[188px]">
                <BeatLoader
                  color={'#FFFFFF'}
                  loading={!qrcodeImage}
                  cssOverride={{
                    display: 'block',
                  }}
                  speedMultiplier={0.6}
                  size={12}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <img alt="qrcode" className="w-[186px] h-[188px]" src={`data:image/jpeg;base64,${qrcodeImage}`} />
            )}
          </div>
        </div>

        <div className="mt-2">
          <p className="text-white text-base text-center">Ou copiar e colar o código abaixo:</p>
        </div>

        <div className="ipt-container flex flex-row mt-2">
          <input
            value={qrcode}
            disabled
            className="ipt p-2 w-[284px] h-[39px] bg-transparent border-px rounded-lg border-[#C4C4C4] text-sm text-[#C4C4C4]"
          />
          <Button
            hierarchy="secondary"
            label={copy === true ? 'Copiado' : 'Copiar'}
            size="sm"
            moreClasses="ml-1 border-[#FF8E00] w-[69px] h-[40px] rounded-lg text-[#FF8E00]"
            onClick={sharePot}
          />
        </div>

        <div className="flex flex-col mt-4 mb-6">
          {/* <InfoPix className="relative" /> */}
          <p className="text-white text-sm text-center font-normal">O pagamento deve ser feito dentro dos próximos</p>
          {statusPayment === 'approved' ? (
            <p className="text-center text-base font-semibold text-[#00DC6E]">Pagamento efetuado com sucesso!</p>
          ) : timer === 0 ? (
            <div className="text-center text-base font-semibold text-white">
              <span>Tempo encerrado</span>
            </div>
          ) : (
            <div className="text-center text-2xl font-semibold text-white">
              <span>{minutes.toString().padStart(2, '0')}</span>
              <span>:</span>
              <span>{secunds.toString().padStart(2, '0')}</span>
            </div>
          )}
        </div>
      </ModalPixContentContainer>
    </ModalPixOverlayContainer>
  );
};

export default ModalPix;
