import { TextField } from '@mui/material';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { IconType } from 'react-icons/lib';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  icon?: IconType;
  type: string;
  placeholder?: string;
  name: string;
  rules?: any;
  onChange?: any;
  onBlur?: any;
  onSuccess?: any;
  moreClasses?: string;
  value?: string;
  id?: string;
  forceInvalid?: any;
  disabled?: boolean;
  maxLength?: number;
  errorMessage?: string;
  autoComplete?: string;
  isTextHelper?: boolean;
  label?: string;
  error?: boolean;
}

const Input = ({
  icon,
  type = "text",
  placeholder,
  name,
  rules,
  onChange,
  onSuccess,
  className,
  moreClasses,
  value,
  id,
  disabled,
  errorMessage,
  maxLength,
  autoComplete,
  isTextHelper,
  forceInvalid,
  label,
  onBlur,
  error,
}: IInputProps) => {
  const [visible, setVisible] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  const windowSize = useGetWindowSize();

  useEffect(() => onSuccess && onSuccess(isValid), [isValid]);

  const visibleIconClass =
    'w-[20px] h-[20px] absolute right-[18px] top-[31px] translate-y-[-50%] text-text-placeholder';

  const verifyRules = async (e: any) => {
    if (rules) {
      setIsValid(rules.every((r: any) => r(e.target.value)));
    }
  };
  useEffect(() => {
    if (forceInvalid !== undefined) {
      setTimeout(() => setIsValid(!forceInvalid), 1);
    }
  }, [forceInvalid]);

  const showErrorMessage = () => {
    if (isValid === false) return <div className="text-[#FF4444] text-[14px] ml-5 mt-1.5">{errorMessage}</div>;
  };

  const validityClasses = () => {
    if (isValid === false) return 'border border-[#FF4444]';
    else if (isValid === true) return 'border border-[#00DC6E]';
    else return 'border-0';
  };

  const disabledClasses = () => {
    if (disabled) return 'opacity-40 cursor-not-allowed';
  };

  

  const isMobileDatePicker = type === 'date';

  return (
    <div className={`relative w-[100%] ${className}`}>
      {icon &&
        React.createElement(icon, {
          className: 'w-[20px] h-[20px] absolute left-[18px] top-[31px] translate-y-[-50%] text-text-placeholder',
        })}

      {isMobileDatePicker && (
        <>
          <input
            type="date"
            name={name}
            max="2999-12-31"
            onChange={onChange}
            placeholder={placeholder}
            className={`${icon ? 'pl-[48px]' : 'pl-[19px]'} ${moreClasses}
             placeholder:text-text-placeholder placeholder:text-sm placeholder:text-sm placeholder:font-semibold placeholder:font-outfit
             bg-background-100 shadow rounded-16 w-full h-[64px] font-outfit text-text-white text-sm text-semibold
             placeholder:text-text-placeholder ${validityClasses()} ${disabledClasses()}`}
            onBlur={(e) => {
              if (onBlur) {
                onBlur();
              }
              verifyRules(e);
            }}
            value={value}
            disabled={disabled}
          />
        </>
      )}

      {!isMobileDatePicker && (
        <>
          {isTextHelper ? (
            <TextField
              className={className}
              error={error}
              id="outlined-helperText"
              label={label}
              autoComplete={autoComplete}
              style={{ boxShadow: 'none' }}
              type={visible ? 'text': type}
              name={name}
              onChange={onChange}
              onBlur={verifyRules}
              value={value}

              disabled={disabled}
              // maxLength={maxLength}
              // onFocus={type === 'date' ? (e) => (e.target.type = 'date') : undefined}
            />
          ) : (
            <input
              autoComplete={autoComplete}
              style={{ boxShadow: 'none' }}
              className={`${icon ? 'pl-[48px]' : 'pl-[19px]'} ${moreClasses}
             placeholder:text-text-placeholder placeholder:text-sm placeholder:text-sm placeholder:font-semibold placeholder:font-outfit
             bg-background-100 shadow rounded-16 w-full h-[64px] font-outfit text-text-white text-sm text-semibold
              ${validityClasses()} ${disabledClasses()}`}
              type={visible || type === 'date' ? 'text' : type}
              placeholder={placeholder}
              name={name}
              id={id}

              onChange={onChange}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur();
                }
                verifyRules(e);
              }}
              value={value}
              disabled={disabled}
              maxLength={maxLength}
              onFocus={type === 'date' ? (e) => (e.target.type = 'date') : undefined}
            />
          )}
        </>
      )}

      {type === 'password' ? (
        <i onClick={() => setVisible(!visible)}>
          {visible ? (
            <BiShow style={{ color: 'c4c4c4' }} className={visibleIconClass} />
          ) : (
            <BiHide style={{ color: 'c4c4c4' }} className={visibleIconClass} />
          )}
        </i>
      ) : null}
      {showErrorMessage()}
    </div>
  );
};

export default Input;
