import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../..';
import { UserGroup } from '../../../typescript/group';
import { IGroupLinkInvite } from './types';

export const slice = createSlice({
  name: 'group',
  initialState: {
    url: '',
    currentGroupParticipate: {},
  },
  reducers: {
    groupInviteURL(state: any, action: PayloadAction<any>): void {
      state.url = action.payload.url;
    },
    currentGroupParticipate(state: any, action: PayloadAction<any>): void {
      state.currentGroupParticipate = action.payload;
    },
  },
});

export const { reducer } = slice;

export const participateGroupWithLink =
  (URL: IGroupLinkInvite): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.groupInviteURL(URL));
  };

export const currentGroupParticipate =
  (group: UserGroup): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.currentGroupParticipate(group));
  };
