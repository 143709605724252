import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

interface ChildrenProps {
  children: ReactNode;
}

interface Context {
  message: string | undefined;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
}

const initialContext: Context = {
  message: '',
  setMessage: () => '',
};

export const AlertModal = createContext<Context>(initialContext);

export const ModalMessageProvider = ({ children }: ChildrenProps) => {
  const [message, setMessage] = useState<string>();

  return <AlertModal.Provider value={{ message, setMessage }}>{children}</AlertModal.Provider>;
};

export const useAlertMessageContext = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [alerts, setAlerts] = useState<string[]>([]);

  const { message, setMessage } = useContext(AlertModal);

  useEffect(() => {
    function getTimer(alerts: string[]) {
      const timer = setTimeout(() => {
        alerts.splice(-1, 1);
        setIsOpen(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
    getTimer(alerts);
  }, [alerts]);

  return {
    message,
    setMessage,
    isOpen,
    setIsOpen,
    alerts,
    setAlerts,
  };
};
