import { CardImageContainer } from './styles';

const CardImgBg = () => {
  return (
    <div className="flex flex-col items-center gap-[8px] absolute md:top-[15px] md:right-[10px] right-[50px] rotate-[-45deg] origin-center  mt-[16px]">
      <div className="opacity-15 bg-[#C4C4C4] rounded-full md:w-[75px] w-[45px] md:h-[30px] h-[15px]"></div>
      <div className="flex flex-row gap-[8px]">
        <div className="opacity-30 bg-[#C4C4C4] rounded-full md:w-[75px] w-[45px] md:h-[30px] h-[15px]"></div>
        <div className="opacity-50 bg-[#4B5A66] rounded-full md:w-[75px] w-[45px] md:h-[30px] h-[15px]"></div>
      </div>
      <div className="opacity-70 bg-[#C4C4C4] rounded-full md:w-[75px] w-[45px] md:h-[30px] h-[15px]"></div>
    </div>
  );
};

const CardImgInfo = () => {
  return (
    <div className="">
      <div className="absolute right-[5%] top-[-5%] bottom-[37.37%] rotate-[45deg]  bg-[#E4E4E6] rounded-full w-[14px] h-[34px]" />
      <div className="absolute right-[9%] top-[-5%] bottom-[33.37%] rotate-[45deg] opacity-[0.5] bg-[#FF8E00] rounded-full w-[14px] h-[34px]" />
      <div className="absolute right-[9%] top-[4%] bottom-[33.37%] rotate-[45deg] opacity-[0.2] bg-[#E4E4E6] rounded-full w-[14px] h-[34px]" />
      <div className="absolute right-[5%] top-[4%] bottom-[33.37%] rotate-[45deg] opacity-[0.7] bg-[#FF8E00] rounded-full w-[14px] h-[34px]" />
    </div>
  );
};

const CardImgCMSPage = () => {
  return (
    <CardImageContainer>
      <div className="div-shape div1" />
      <div className="div-shape div2" />
      <div className="div-shape div3" />
      <div className="div-shape div4" />
    </CardImageContainer>
  );
};

export { CardImgBg, CardImgInfo, CardImgCMSPage };
