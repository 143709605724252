import moment from "moment/moment";
import 'moment-timezone';

const getCurrentDate = (): Date => {
    return moment.tz('America/Sao_Paulo').toDate();
};

const getCurrentTimestamp = (): number => {
    return moment.tz('America/Sao_Paulo').valueOf();
};

export { getCurrentDate, getCurrentTimestamp };
