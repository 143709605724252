import { ChangeEvent, useEffect, useState } from 'react';
import { ButtonContainer, Container, MainContainer, SpanAlert, WarningRules } from './styles';
import { passwordLabels, rules } from './helper';
import Input from '../../components/Input/Input';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import Button from '../../components/Button/Button';
import { updateUserPassword } from '../../service/user/user.service';
import { SimpleFeedbackText } from '../../components/SimpleFeedBackText';
import { IUser } from '../../common/context/UserContext';

interface PasswordSettingProps {
  handleSetOpenPassword: () => void;
  userLogged: IUser | undefined;
}

const PasswordSetting = ({ handleSetOpenPassword, userLogged }: PasswordSettingProps) => {
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageRepeat, setErrorMessageRepeat] = useState(false);
  const [message, setMessage] = useState('');
  const [formValidity, setFormValidity] = useState({
    oldPassword: false,
    newPassword: false,
    repeatNewPassword: false,
    differentPasswords: false,
  });
  const isAllFieldsValid = () => Object.values(formValidity).every((v) => v);
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setErrorMessageRepeat(repeatPassword !== password && repeatPassword.length > 7 && password.length > 7);
    const functs = [
      rules.passwordMinLengthRule,
      rules.passwordNumberRule,
      rules.passwordUppercaseRule,
      rules.passwordSymbolRule,
    ];
    functs.every((r: any) => r(oldPassword));
    setFormValidity({
      newPassword: functs.every((r: any) => r(password)),
      repeatNewPassword: functs.every((r: any) => r(repeatPassword)),
      oldPassword: functs.every((r: any) => r(oldPassword)),
      differentPasswords: oldPassword !== password,
    });
  }, [oldPassword, password, repeatPassword]);

  const handleUpdatePassword = async () => {
    try {
      if (repeatPassword !== password) {
       
        return setErrorMessageRepeat(true);
      }

      setIsLoading(true)

      setStatusRequest('');
      setMessage('');
      setErrorMessageRepeat(false);

      if (userLogged) {
        const apiData = await updateUserPassword(password, oldPassword, userLogged.login);

        if (apiData.status === 400) {
        // setStatusRequest('error');
          setErrorMessage(true);
          setMessage(apiData.data);
        }

        if (apiData.status === 200) {
          setErrorMessage(false);
          setOldPassword('');
          setPassword('');
          setRepeatPassword('');
          //   handleSetOpenPassword();
          setStatusRequest('success');
          setMessage('Sua senha foi alterada com sucesso! Mantenha sua senha em um lugar seguro.');
          return;
        }
        setIsLoading(false)

        return apiData;
      }
      setIsLoading(false)

    } catch (error) {
      setStatusRequest('error');
      setMessage('Ocorreu um erro! Tente novamente mais tarde.');
    }
  };

  useEffect(() => {
    if (statusRequest === 'success') {
      setTimeout(() => {
        setStatusRequest('');
        setMessage('');
      },3000);
    }
  }, [statusRequest]);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(false);
    }
  }, [oldPassword]);

  return (
    <>
      <MainContainer>
        <Container>
          <Input
            isTextHelper={true}
            error={errorMessage}
            className="textClass"
            id="outlined-helperText"
            label="Digite sua senha atual"
            name="Digite a sua senha atual"
            type="password"
            value={oldPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
            onSuccess={(v: any) => setFormValidity({ ...formValidity, oldPassword: v })}
            rules={[
              rules.passwordMinLengthRule,
              rules.passwordNumberRule,
              rules.passwordUppercaseRule,
              rules.passwordSymbolRule,
            ]}
          />
        </Container>

        {errorMessage && (
          <div className="mb-6">
            <SpanAlert>Senha incorreta </SpanAlert>
          </div>
        )}

        <Container className="boxContainer gap-2.5">
          <Input
            isTextHelper={true}
            error={errorMessageRepeat}
            className="textClass textWidth textEmail"
            id="outlined-helperText"
            label="Digite a sua nova senha"
            name="Digite a sua nova senha"
            type="password"
            onSuccess={(v: any) => setFormValidity({ ...formValidity, newPassword: v })}
            value={password}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
            rules={[
              rules.passwordMinLengthRule,
              rules.passwordNumberRule,
              rules.passwordUppercaseRule,
              rules.passwordSymbolRule,
            ]}
          />

          <Input
            isTextHelper={true}
            error={false}
            className="textClass textWidth"
            id="outlined-helperText"
            label="Repetir Senha"
            name="Repetir senha"
            type="password"
            value={repeatPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setRepeatPassword(event.target.value);
            }}
            onSuccess={(v: any) => setFormValidity({ ...formValidity, repeatNewPassword: v })}
            rules={[
              rules.passwordMinLengthRule,
              rules.passwordNumberRule,
              rules.passwordUppercaseRule,
              rules.passwordSymbolRule,
            ]}
          />
        </Container>

        {!!password.length && (
          <WarningRules>
            {passwordLabels.map((item) => (
              <span
                key={item.label}
                className={`
                        ${item.rule(password) ? 'text-[#00DC6E]' : 'text-[#FF4444]'}
                    `}
              >
                {item.rule(password) ? <IoIosCheckmark className="mr-1 mt-1" /> : <IoIosClose className="mr-1 mt-1" />}
                {item.label}
              </span>
            ))}
          </WarningRules>
        )}

        {!!errorMessageRepeat && <SpanAlert>Senhas não conferem</SpanAlert>}
        {!formValidity.differentPasswords && password !== '' && (
          <SpanAlert>Escolha uma senha diferente da atual</SpanAlert>
        )}

        {!!statusRequest && (
          <SimpleFeedbackText isError={statusRequest === 'error'} style={{ width: '100%' }} text={message} />
        )}

        <ButtonContainer>
          <Button
            hierarchy="primary"
            label={ isLoading ? "alterando...": "Alterar senha"}
            size="lg"
            disabled={!isAllFieldsValid() || isLoading}
            moreClasses=" max-w-[366px] w-full md:max-w-[140px] font-semibold text-[14px] h-[40px]"
            onClick={handleUpdatePassword}
          />
        </ButtonContainer>
      </MainContainer>
    </>
  );
};

export default PasswordSetting;
