import styled from 'styled-components';

export const MainHeaderContainer = styled.div`
  width: 95%;
  transition: 0.5s;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    position: fixed;
    z-index: 99;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  background: #1c1c27;
  border-bottom: 1px solid #4b4c66;
`;

export const AlignContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderMainInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  gap: 76px;
`;

export const HeaderUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const HeaderButtonContainer = styled.div`
  position: relative;
  left: 16%;
  margin-top: 24px;

  @media (max-width: 415px) {
    left: 36%;
  }

  @media (max-width: 405px) {
    left: 16%;
  }

  @media (max-width: 350px) {
    left: 7%;
  }
`;

export const ButtonNavBar = styled.div`
  margin-top: 40px;
  margin-left: 8px;
`;

export const NavBarOffContainer = styled.div`
  width: auto;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;

  .navOff {
    margin: 80px 12px 10px 0px;
  }
`;
