import styled from 'styled-components';

export const HomeBanner = styled.div`
  width: 100%;
  background: linear-gradient(271.68deg, rgba(13, 13, 19, 0) 39.38%, rgba(13, 13, 19, 0.5) 97.39%);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  margin-top: 56px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }

  .banner-category {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @media (max-width: 600px) {
      margin-right: 0;
    }

    p {
      width: 100%;
      height: 100%;
    }

    picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 600px) {
        object-fit: scale-down;
      }
    }
  }

  .banner-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    right: 50px;

    @media (max-width: 600px) {
      right: 0;
      width: 120px;
    }
  }

  h1 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 120px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: 600px) {
      font-size: 45px;
      line-height: 40px;
      margin: 0;
      margin-bottom: 10px;
    }
  }

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
`;

export const CarouselContainer = styled.div`
  margin-bottom: 2rem;

  .container-carousel {
    &::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border: none;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      display: none;
    }
  }
`;

export const HomeLessPotList = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: flex-start;
  grid-gap: 40px;

  @media (max-width: 1536px) {
    grid-gap: 20px;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 100%;
  }

  @media (max-width: 994px) {
    display: grid;
    grid-template-columns: 100%;
  }
`;

export const HomePotList = styled.div`
  width: 100%;
  height: auto;

  .single-pot {
    width: 100%;
    margin-bottom: 40px;
    height: auto;
    display: inline-block; /* stops block being split across columns. Could use break-inside: avoid for modern browsers; */
    break-inside: avoid;
  }

  .single-pot .content-container {
    margin-bottom: 0;
  }
`;

export const GameInstructionContainer = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid #ff8e00;
  border-radius: 20px;
  padding: 2.5rem 4rem 4rem 4rem;
  margin-bottom: 24px;

  h2 {
    text-align: center;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 3.5rem;
  }
`;

export const IconSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 294px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  img {
    margin-bottom: 26px;
  }

  p {
    width: 100%;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 89.5%;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .messageIcon {
    font-size: 16px;
  }
`;

export const ArrowPositionLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 15.5%;

  .svg-icon {
    max-width: 121px;
    width: 100%;
    height: 37px;
    margin-top: 24px;

    @media (max-width: 1300px) {
      transform: scale(0.8);
      position: absolute;
      left: 24.5%;
    }

    @media (max-width: 1160px) {
      transform: scale(0.6);
      position: absolute;
      left: 23.5%;
    }

    @media (max-width: 1100px) {
      transform: scale(0.6);
      position: absolute;
      left: 18.5%;
    }
  }
`;

export const ArrowPositionRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 15.5%;

  .svg-icon {
    max-width: 121px;
    width: 100%;
    height: 37px;
    margin-top: 24px;

    @media (max-width: 1300px) {
      transform: scale(0.8);
      position: absolute;
      right: 24.5%;
    }

    @media (max-width: 1160px) {
      transform: scale(0.6);
      position: absolute;
      right: 23.5%;
    }

    @media (max-width: 1100px) {
      transform: scale(0.6);
      position: absolute;
      right: 18.5%;
    }
  }
`;
