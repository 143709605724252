import { Game, PointSpread, PotOneVsMany, PotStatus } from '../../typescript/match';

import { ReactComponent as PotWaiting } from '../../utils/icons/access_time.svg';
import { ReactComponent as PotCorrectIcon } from '../../utils/icons/check_circle.svg';
import { ReactComponent as PotCanceledIcon } from '../../utils/icons/transaction-icon.svg';
import { ReactComponent as PotLostIcon } from '../../utils/icons/wrong-bet.svg';

interface BetsAvailableType {
  onevsmany: boolean | null;
  onevsone: boolean | null;
  pointspread: boolean | null;
}

function compareResultsOneVsMany(potOneVsmany: PotOneVsMany, betsOneVsMany: PotOneVsMany): boolean | null {
  if (potOneVsmany.potOneVsMany && betsOneVsMany.potOneVsMany) {
    for (const group of potOneVsmany.potOneVsMany) {
      const result = group.result;
      const groupFound = betsOneVsMany.potOneVsMany.find((item) => item.id === group.id);

      if (groupFound) {
        for (const groupOption of groupFound.options) {
          if (groupOption.result !== null && groupOption.result !== result) {
            return false;
          }
        }
      }
    }

    return true;
  } else {
    return null;
  }
}

function compareResultsPointSpread(betmatchexactpoint: PointSpread, pointspread: PointSpread): boolean | null {
  if (betmatchexactpoint && pointspread && pointspread?.pointSpread.length && betmatchexactpoint?.pointSpread.length) {
    if (pointspread?.pointSpread.length !== betmatchexactpoint?.pointSpread.length) {
      return false;
    } else {
      for (let i = 0; i < pointspread?.pointSpread.length; i++) {
        const resultGame = pointspread?.pointSpread[i];
        const betGame = betmatchexactpoint?.pointSpread[i];

        if (resultGame.team1.point !== betGame.team1.point || resultGame.team2.point !== betGame.team2.point) {
          return false;
        }
      }
    }

    return true;
  } else {
    return null;
  }
}

export const checkPotResult = (game: Game): boolean => {
  const pot = { ...game };

  if (pot.anotherBet?.betmatchexactpoint) {
    pot.betmatchexactpoint = pot.anotherBet.betmatchexactpoint;
  }

  if (pot.anotherBet?.betonevsmany) {
    pot.betonevsmany = pot.anotherBet.betonevsmany;
  }

  const bets: BetsAvailableType = {
    onevsmany: null,
    onevsone: null,
    pointspread: null,
  };

  if (pot.bettypes) {
    if (pot.bettypes?.includes('onevsone')) {
      const resultPotArray = pot?.bet ? pot?.bet?.split(',') : [];
      const winnerBetArray = pot?.resultPot ? pot?.resultPot?.split(',') : [];

      resultPotArray.sort();
      winnerBetArray.sort();

      const resultPotArrayStr = JSON.stringify(resultPotArray);
      const winnerBetArrayStr = JSON.stringify(winnerBetArray);

      bets.onevsone = resultPotArrayStr === winnerBetArrayStr;
    }

    if (pot.bettypes?.includes('onevsmany') && pot.onevsmany?.potOneVsMany && pot.betonevsmany) {
      bets.onevsmany = compareResultsOneVsMany(pot.onevsmany, pot.betonevsmany);
    }

    if (pot.bettypes?.includes('pointspread') && pot.betmatchexactpoint && pot.pointspread) {
      bets.pointspread = compareResultsPointSpread(pot.betmatchexactpoint, pot.pointspread);
    }
  } else {
    const resultPotArray = pot?.bet ? pot?.bet?.split(',') : [];
    const winnerBetArray = pot?.resultPot ? pot?.resultPot?.split(',') : [];

    resultPotArray.sort();
    winnerBetArray.sort();

    const resultPotArrayStr = JSON.stringify(resultPotArray);
    const winnerBetArrayStr = JSON.stringify(winnerBetArray);

    return resultPotArrayStr === winnerBetArrayStr;
  }

  const resultValues = Object.values(bets).filter((item) => item !== null);
  const finalResult = resultValues.filter((item) => item === false);

  if (resultValues.length <= 0) {
    return false;
  } else {
    return !finalResult.length;
  }
};

export const compareBetPot = ({
  bet1,
  bet2,
  bettypes,
}: {
  bet1: { bet: string; betonevsmany: any; betmatchexactpoint: any };
  bet2: { bet: string; betonevsmany: any; betmatchexactpoint: any };
  bettypes: string;
}): boolean => {
  const bets: BetsAvailableType = {
    onevsmany: null,
    onevsone: null,
    pointspread: null,
  };

  if (bettypes) {
    if (bettypes?.includes('onevsone')) {
      const resultPotArray = bet1.bet ? bet1.bet?.split(',') : [];
      const winnerBetArray = bet2.bet ? bet2.bet?.split(',') : [];

      resultPotArray.sort();
      winnerBetArray.sort();

      const resultPotArrayStr = JSON.stringify(resultPotArray);
      const winnerBetArrayStr = JSON.stringify(winnerBetArray);

      bets.onevsone = resultPotArrayStr === winnerBetArrayStr;
    }

    if (bettypes?.includes('onevsmany')) {
      bets.onevsmany = bet1.betonevsmany === bet2.betonevsmany;
    }

    if (bettypes.includes('pointspread')) {
      bets.pointspread = bet1.betmatchexactpoint === bet2.betmatchexactpoint;
    }
  } else {
    const resultPotArray = bet1.bet ? bet1.bet.split(',') : [];
    const winnerBetArray = bet2.bet ? bet2.bet.split(',') : [];

    resultPotArray.sort();
    winnerBetArray.sort();

    const resultPotArrayStr = JSON.stringify(resultPotArray);
    const winnerBetArrayStr = JSON.stringify(winnerBetArray);

    return resultPotArrayStr === winnerBetArrayStr;
  }

  const resultValues = Object.values(bets).filter((item) => item !== null);
  const finalResult = resultValues.filter((item) => item === false);

  if (resultValues.length <= 0) {
    return false;
  } else {
    return !finalResult.length;
  }
};

export interface PotHandleIconIdentifierType {
  icon: JSX.Element;
  legend: string;
  colors: {
    border: string;
    coin: string;
    button: {
      bg: string;
    };
  };
}

export const StatusCardData = {
  'Você ganhou': {
    icon: <PotCorrectIcon />,
    legend: 'Você ganhou',
    colors: {
      border: '#00DC6E',
      icon: '#00DC6E',
      coin: '#00DC6E',
      button: {
        bg: '#00DC6E',
      },
    },
  },
  'Tente outra vez': {
    icon: <PotLostIcon />,
    legend: 'Tente outra vez',
    colors: {
      border: '#FF444433',
      icon: '#FF4444',
      coin: '#FF4444',
      button: {
        bg: '#FF4444',
      },
    },
  },
  'Aguardando resultado': {
    icon: <PotWaiting />,
    legend: 'Aguardando resultado',
    colors: {
      border: '#EFAC3033',
      icon: '#FF8E00',
      coin: '#FF8E00',
      button: {
        bg: '#FFC070',
      },
    },
  },
  'Pot Cancelado': {
    icon: <PotCanceledIcon />,
    legend: 'Pot Cancelado',
    colors: {
      border: '#4B4C66',
      icon: '#C4C4C4',
      coin: '#C4C4C4',
      button: {
        bg: '#C4C4C4',
      },
    },
  },
};

export const potIdentifier = (pot: Game) => {
  if (pot.status === 'Finalizado') {
    if (checkPotResult(pot)) {
      return StatusCardData['Você ganhou'];
    }

    return StatusCardData['Tente outra vez'];
  }

  if (pot.status === 'Cancelado') {
    return StatusCardData['Pot Cancelado'];
  }

  return StatusCardData['Aguardando resultado'];
};

export const potHandleIconIdentifier = (pot: Game): PotHandleIconIdentifierType | null => {
  switch (pot.statusPot || pot.status) {
    case PotStatus.Finalizado:
      if (checkPotResult(pot)) {
        return StatusCardData['Você ganhou'];
      } else {
        return StatusCardData['Tente outra vez'];
      }
    case PotStatus.AguardandoFinalizacao:
    case PotStatus.EmAndamento:
      return StatusCardData['Aguardando resultado'];
    case PotStatus.Cancelado:
      return StatusCardData['Pot Cancelado'];
    default:
      return null;
  }
};
