import { ReactComponent as ArrowRight } from '../../utils/icons/arrowRight.svg';
import { formatValue } from '../../utils/types';
import { ArrowContainer, InfoContainer, MainCointainer, TextContainer } from './styles';

interface ButtonLinerProps {
  isOpen: boolean;
  handleOpen: () => void;
  label: string;
  balance?: number;
  coins?: number | string;
}

const ButtonLiner = ({ isOpen, handleOpen, label, balance, coins }: ButtonLinerProps) => {
  return (
    <MainCointainer onClick={handleOpen}>
      <TextContainer>
        <p>{label}</p>
        {isOpen && balance && (
          <InfoContainer>
            <h2>
              {balance ? formatValue(balance) : '0,00'} <span>BRL</span>
            </h2>
            <h2>
              {coins ? coins : 0} <span> {coins === 1 ? 'Ficha' : 'Fichas'}</span>
            </h2>
          </InfoContainer>
        )}
      </TextContainer>
      <ArrowContainer onClick={handleOpen}>
        {!isOpen ? (
          <ArrowRight className="w-[15.66px] h-[13.78px] transition duration-150 ease-out" onClick={handleOpen} />
        ) : (
          <ArrowRight
            className="w-[15.66px] h-[13.78px] rotate-90 transition duration-150 ease-out"
            onClick={handleOpen}
          />
        )}
      </ArrowContainer>
    </MainCointainer>
  );
};

export default ButtonLiner;
