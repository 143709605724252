import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { ILoginFormData, ILoginPayload, IRegisterFormData, IUser, IUserReducer } from './types';
import { userLogin, userRegister, userGoogleLogin, userFacebookLogin } from '../../../service/user/user.service';
import { AppThunk } from '../..';
import { SessionControl } from '../../../session/SessionControl';

const checkInitialState = () => {
  const token = SessionControl.getToken();

  if (token) {
    return {
      token: token,
      userInfo: jwt_decode(token) as IUser,
    };
  }

  return { token: '', userInfo: {} as IUser };
};

const initialState: IUserReducer = checkInitialState();

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state: IUserReducer, action: PayloadAction<ILoginPayload>): void {
      if (action.payload.token) {
        state.status = action.payload.status;
        state.token = action.payload.token;
        state.userInfo = jwt_decode(state.token);
        SessionControl.setToken(state.token);
      }
    },
    register: (state, action: PayloadAction<IUser>) => {
      state.message = '';
      state.status = 200;
      if (typeof action.payload === 'string') {
        state.status = 400;
        state.message = action.payload;
      }
      return state;
    },
    logout: () => {
      SessionControl.clearSession();
      //CLEARITEM LOCAL STORAGE
    },
  },
});

export const { reducer } = slice;

export const register =
  (userData: IRegisterFormData): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await userRegister(userData);
    dispatch(slice.actions.register(data));
  };

export const login =
  (userData: ILoginFormData): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await userLogin(userData);
    dispatch(slice.actions.login(data));
  };

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.logout());
  };

export const signInWithGoogle =
  (idToken: string, accessToken: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await userGoogleLogin(idToken, accessToken);
    dispatch(slice.actions.login(data));
  };

export const signInWithFacebook =
  (accessToken: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await userFacebookLogin(accessToken);
    dispatch(slice.actions.login(data));
  };
