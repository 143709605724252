import styled from 'styled-components';

export const AlertModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

export const AlertModalContainer = styled.div`
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  margin: 30px;

  .errorAlert {
    display: flex;
    align-items: center;
    width: 366px;
    height: 96px;
    padding-left: 32px;
    position: relative;
    color: #ff0449;
    border: 2px solid #ff0449;
    border-radius: 14px;
    background: rgba(94, 55, 59, 1);

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .warningAlert {
    display: flex;
    align-items: center;
    width: 366px;
    height: 96px;
    padding-left: 32px;
    position: relative;
    color: #ff8e00;
    border: 2px solid #ff8e00;
    border-radius: 14px;
    background: #5c401c;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .successAlert {
    display: flex;
    align-items: center;
    width: 366px;
    height: 96px;
    padding-left: 32px;
    position: relative;
    color: #00dc6e;
    border: 2px solid #00dc6e;
    border-radius: 14px;
    background: #04582e;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  div {
    font-family: 'outfit';
    font-style: normal;
    font-size: 14px;
  }

  p.messageAlert {
    color: white;
  }
`;

export const ButtonCloseModal = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
`;
