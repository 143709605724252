import { useNavigate } from 'react-router';
import Button from '../../components/Button/Button';
import CMSPageLayout from '../../components/CMSPageLayout';

const RulesPage = () => {
  const navigate = useNavigate();

  return (
    <CMSPageLayout contentName="RULES_CONTENT_TYPEID">
      <Button
        moreClasses="max-w-[100%] md:max-w-[173px] w-full h-[49px] text-[16px] text-[#1C1C27] block my-[30px]"
        label="Voltar"
        hierarchy="primary"
        size="sm"
        onClick={() => navigate('/')}
      />
    </CMSPageLayout>
  );
};

export default RulesPage;
