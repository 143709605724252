import { useEffect, useState } from 'react';
import PotCard from '../../components/PotCard';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { userGetPots } from '../../service/user/user.service';
import { BetsList, BetsContainerHeader, BetsButtonFilter, BetsPageContainer } from './styles';

import Pagination from '../../components/Pagination';
import { LoadingMyPot } from '../../components/Loading/Loading';
import { rangeNumbers } from '../../utils/numbers';
import { Game, MatchPotType } from '../../typescript/match';
import { useUserAuth } from '../../hooks/user-auth';
import { checkPotResult, compareBetPot } from '../../components/PotCard/helpers';

const FILTERS = [
  {
    title: 'Todos',
    value: '',
    filter: 'status[]=Em Andamento&status[]=Aguardando Finalizacao&status[]=Finalizado&status[]=Cancelado',
  },
  {
    title: 'Aguardando resultado',
    value: 'Em Andamento',
    filter: 'status[]=Em Andamento&status[]=Aguardando Finalizacao',
  },
  { title: 'Encerrado', value: 'Finalizado', filter: 'status[]=Finalizado' },
  { title: 'Cancelado', value: 'Cancelado', filter: 'status[]=Cancelado' },
];

interface GetAllTheSameBetInTheSame {
  pots: Game[];
  rest: number;
}

export const getAllTheSameBetInTheSame = (data: Game[]): GetAllTheSameBetInTheSame => {

  const bets: Game[] = [];

  for (const bet of data) {
    if (bets.length === 0) {
      bets.push({ ...bet, repeated: 1 });
    } else {
      const itemIndex = bets.findIndex((item) => item.potId === bet.potId);

      if (itemIndex >= 0) {
        const foundItem = bets[itemIndex];

        const otherBetmatchexactpoint = JSON.stringify(
          foundItem.betmatchexactpoint ? foundItem.betmatchexactpoint.pointSpread : null
        );

        const otherBetonevsmany = JSON.stringify(foundItem.betonevsmany ? foundItem.betonevsmany.potOneVsMany : null);

        const otherBet = foundItem.bet;

        const userBetmatchexactpoint = JSON.stringify(
          bet.betmatchexactpoint ? bet.betmatchexactpoint?.pointSpread : null
        );

        const userBetonevsmany = JSON.stringify(bet.betonevsmany ? bet.betonevsmany?.potOneVsMany : null);

        const userBet = bet.bet;

        const result = compareBetPot({
          bettypes: foundItem.bettypes + '',
          bet1: { betmatchexactpoint: otherBetmatchexactpoint, bet: otherBet, betonevsmany: otherBetonevsmany },
          bet2: { betmatchexactpoint: userBetmatchexactpoint, bet: userBet, betonevsmany: userBetonevsmany },
        });

        if (result) {
          const sameBets1 = foundItem.sameBets ? foundItem.sameBets : 0;
          const sameBets2 = bet.sameBets ? bet.sameBets : 0;
          const repeated = foundItem.repeated ? foundItem.repeated + 1 : 0;

          const updatedBet = {
            ...foundItem,
            sameBets: Number(sameBets1) + Number(sameBets2),
            repeated,
          };

          bets[itemIndex] = updatedBet;
        } else {
          bets.push({ ...bet, repeated: 1 });
        }
      } else {
        bets.push({ ...bet, repeated: 1 });
      }
    }
  }

  return { pots: bets, rest: data.length - bets.length };
};

const MyBets = () => {
  const [pots, setPots] = useState<Game[]>([]);
  const [filterStatus, setFilterStatus] = useState(FILTERS[0].filter);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBets, setaTotalBets] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageLimit = 10;
  useUserAuth({ isPrivatePage: true });

  const filterByStatus = (potStatus: string) => {
    setFilterStatus(potStatus);
  };

  async function getCircularBets() {
    setLoading(true);
    const status = filterStatus;
    const page = activePage
    const limit = pageLimit; // Alterado para const

    try {
      const userData = await userGetPots(`${status}&page=${page}&limit=${limit}&created_at=DESC`);

      const data = userData.data.items;
      const metaData = userData.data.meta;

      setPots(data);
      setaTotalBets(metaData.totalItems);
      setTotalPages(metaData.totalPages);

    } catch (error) {
      console.error('Error fetching pots:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCircularBets();
  }, []);


  const calculatePages = (): number[] => {
    return rangeNumbers(1, totalPages);
  };

  useEffect(() => {
    calculatePages();
    getCircularBets();
  }, [activePage, pageLimit]);

  useEffect(() => {
    setActivePage(1);
    getCircularBets();
    calculatePages();
  }, [filterStatus]);

  const goNextPage = () => {
    const nextPage = activePage + 1;
    if (nextPage <= totalPages) {
      setActivePage(nextPage);
    }
  };

  const goPrevPage = () => {
    const nextPage = activePage - 1;
    if (nextPage >= 1) {
      setActivePage(nextPage);
    }
  };

  return (
    <PageContainer>
      <BetsPageContainer>
        <BetsContainerHeader>
          <h1>Meus Palpites</h1>
          <div className="btn-group-header">
            {FILTERS.map((item) => (
              <BetsButtonFilter
                key={item.value}
                onClick={() => filterByStatus(item.filter)}
                active={filterStatus === item.filter}
              >
                {item.title}
              </BetsButtonFilter>
            ))}
          </div>
        </BetsContainerHeader>

        {loading && <LoadingMyPot isLoading />}

        {!!pots.length && !loading && (
            <BetsList>
              {pots.map((pot: any) => {
                return (
                    <PotCard pot={pot} key={`${pot.potId}_${pot.dateInitialBet}_${pot.dateInitialBet}`} />
                );
              })}
            </BetsList>
        )}

        {pots.length === 0 && !loading && <h1>Sem palpites</h1>}

        {totalBets > pageLimit && (
          <Pagination
            handleActivePage={(page) => setActivePage(page)}
            handleNextPage={goNextPage}
            activePage={activePage}
            handlePrevPage={goPrevPage}
            pages={calculatePages()}
          />
        )}
      </BetsPageContainer>
    </PageContainer>
  );
};

export default MyBets;
