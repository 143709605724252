import { createContext, useState } from 'react';

interface UpdateInfoProps {
  tokenId: string;
  accessToken: string;
}

interface GoogleAuthInfoContextType {
  authInfo: UpdateInfoProps;
  isAtModal?: boolean;
  updateInfo: (data: UpdateInfoProps | null) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const googleAuthInfoDefaultState: GoogleAuthInfoContextType = {
  authInfo: { accessToken: '', tokenId: '' },
  isAtModal: false,
  updateInfo: () => null,
};

export const googleAuthInfoContext = createContext<GoogleAuthInfoContextType>(googleAuthInfoDefaultState);

export const GoogleAuthInfoContextProvider = ({ children }: ProviderProps) => {
  const [isAtModal, setIsAtModal] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<UpdateInfoProps>(googleAuthInfoDefaultState.authInfo);

  const updateInfo = (data: UpdateInfoProps | null) => {
    if (data === null) return;
    setAuthInfo(data);
  };

  return (
    <googleAuthInfoContext.Provider value={{ updateInfo, authInfo, isAtModal }}>
      {children}
    </googleAuthInfoContext.Provider>
  );
};
