export const CheckCircle = () => {
  return (
    <svg width="110" height="109" viewBox="0 0 110 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.4761 44.2812L48.4827 68.125L36.0073 56.2031"
        stroke="#00DC6E"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.7417 95.375C77.3163 95.375 95.6167 77.0746 95.6167 54.5C95.6167 31.9254 77.3163 13.625 54.7417 13.625C32.1671 13.625 13.8667 31.9254 13.8667 54.5C13.8667 77.0746 32.1671 95.375 54.7417 95.375Z"
        stroke="#00DC6E"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
