import { useEffect, useState } from 'react';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { userTransactionsHistory } from '../../service/user/user.service';
import { Transaction } from '../../typescript/transaction';
import { formatValue } from '../../utils/types';
import { TransactionItem, TransactionContainer, ButtonSeeMore } from './styles';
import moment from 'moment';

export const UserTransactions = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [limit, setLimit] = useState(5);
  const [isLoading, setLoading] = useState(false);

  const totalItems = transactions.length;
  const isThereNoMoTransactions = transactions.length > limit;
  const windowsSize = useGetWindowSize();
  const isMobileTypeDevice = windowsSize.width < 700;
  const filteredTransactions = isMobileTypeDevice ? transactions.slice(0, limit) : transactions;
  const isShowButton = isMobileTypeDevice && transactions.length > 4 && isThereNoMoTransactions;

  const handleGetTransactions = async () => {
    setLoading(true);
    const response = await userTransactionsHistory('user');
    setLoading(false);
    setTransactions(response);
  };

  const handlePagination = () => {
    if (limit === totalItems) {
      return null;
    }

    const sum = limit + 5;

    if (sum <= totalItems) {
      setLimit(sum);
    } else {
      setLimit(totalItems);
    }
  };

  useEffect(() => {
    handleGetTransactions();
  }, []);


  return (
    <>
      <TransactionContainer>
        {isLoading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {filteredTransactions
              .filter((item) => item.status !== 'Devolução de fichas')
              .map((transaction) => (
                <TransactionItem key={transaction.id}>
                  <>
                    {transaction.status === 'Compra' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>
                        {transaction.status} com fichas
                      </p>
                    )}
                    {transaction.status === 'Compra com saldo' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>
                        {transaction.status} moneypot
                      </p>
                    )}
                    {transaction.status === 'Pedido Saque' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>Solicitação de saque em andamento
                      </p>
                    )}
                    {transaction.status === 'Ganho Pot' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>Saldo recebido pelo palpite
                      </p>
                    )}
{/* 
                    {transaction.status === 'Devolução de fichas' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>Devolução em Fichas Cancelados em{' '}
                        {Number(transaction.value) > 1 ? 'fichas' : 'ficha'}
                      </p>
                    )} */}

                    {transaction.status === 'Saque' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>Solicitação de saque realizada
                      </p>
                    )}

                    {transaction.status === 'Comissão do grupo' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>
                        {transaction.status}
                      </p>
                    )}

                    {transaction.status === 'Comissão do grupo por palpite' && (
                      <p className="statusMessage">
                        {moment(transaction.dateTransaction).format('DD/MM/YYYY')}
                        <span>•</span>
                        {transaction.status}
                      </p>
                    )}

                    {Number(transaction.value) < 0 && <p>- R${formatValue(-transaction.value)}</p>}
                    {Number(transaction.value) > 0 && (
                      <p>
                        {transaction.status === 'Saque' ? '-' : transaction.status === 'Pedido Saque' ? '' : '+'} R$
                        {formatValue(Number(transaction.value))}
                      </p>
                    )}
                  </>
                </TransactionItem>
              ))}
          </>
        )}
      </TransactionContainer>
      {isShowButton && <ButtonSeeMore onClick={handlePagination}>ver mais</ButtonSeeMore>}
    </>
  );
};
