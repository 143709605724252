import React, { FormEvent, useContext, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { rules } from './helpers';
import { useDispatch } from '../../store';

import Button from '../Button/Button';
import Input from '../Input/Input';
import { Container } from '../../pages/Login/styles';
import { ErrorMessage } from '../../pages/ForgetPassword/styles';
import { FormContainer } from './../../pages/Login/styles';
import { BiCalendar } from 'react-icons/bi';
import Checkbox from '../Checkbox/Checkbox';
import { signInWithGoogle } from '../../store/reducers/user/userSlice';
import api from '../../service/api';
import { googleAuthInfoContext } from '../../common/context/useGoogleAuthInfoContext';
import moment from 'moment';
import { SessionControl } from '../../session/SessionControl';

interface BirthDateFormProps {
  isAtModal?: boolean;
  onSuccess: () => void;
  handleCloseBirthForm?: () => void;
}

const BirthDateForm = ({ isAtModal, onSuccess, handleCloseBirthForm = () => null }: BirthDateFormProps) => {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [ageAndTermsCheck, setAgeAndTermsCheck] = useState(false);
  const [acceptMails, setAcceptMails] = useState(false);
  const [formValidity, setFormValidity] = useState({
    birthdate: false,
    ageAndTermsCheck: false,
  });

  const isAllFieldsValid = () => Object.values(formValidity).every((v) => v);
  const { authInfo } = useContext(googleAuthInfoContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await api.post('/auth/google', {
      token: authInfo.tokenId,
      accessToken: authInfo.accessToken,
      birthdate,
    });

    await dispatch(signInWithGoogle(authInfo.tokenId, authInfo.accessToken));
    const token = SessionControl.getToken();
    setLoading(false);

    if (token) {
      if (isAtModal) {
        onSuccess();
      } else {
        navigate('/');
      }
    }
  };

  const formatDate = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const dateInput = target.valueAsDate;

    if (dateInput) {
      const date = moment(dateInput).toDate();
      setFormValidity({ ...formValidity, birthdate: rules.birthdateRule(date) });
      setBirthdate(date);
    }
  };

  const goToLogin = () => {
    if (isAtModal) {
      handleCloseBirthForm();
    } else {
      handleCloseBirthForm();
      navigate('/login');
    }
  };

  return (
    <FormContainer style={{ justifyContent: 'center' }}>
      <Link to="/">
        <img src="images/logo.svg" alt="moneypot" className="mb-[24px] max-w-[167px] w-full" />
      </Link>
      <div className="flex items-center">
        <h1 className="text-2xl font-semibold text-white">Estamos quase lá!</h1>
      </div>

      <form className="flex flex-col w-full gap-[24px]">
        <Container>
          <Input
            name="birthdate"
            placeholder="Data de Nascimento"
            type="date"
            onChange={formatDate}
            id="dataNasc"
            onSuccess={(v: any) => setFormValidity({ ...formValidity, birthdate: v })}
            rules={[rules.birthdateRule]}
            errorMessage="Apenas maiores de 18 anos."
            disabled={isLoading}
            icon={BiCalendar}
          />
          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Container>

        <Checkbox
          className="mt-2"
          label="Confirmo que tenho mais de 18 anos de idade e aceito os "
          link="Termos e Condições."
          linkUrl="/terms"
          name="ageAndTermsCheck"
          value="agreeAgeAndTerms"
          onChange={(e) => {
            setAgeAndTermsCheck(e.currentTarget.checked);
            setFormValidity({
              ...formValidity,
              ageAndTermsCheck: e.currentTarget.checked,
            });
          }}
        />

        <Checkbox
          className="mb-2"
          label="Aceito receber emails com recompensas e ofertas"
          name="mailListCheck"
          value="acceptMails"
          onChange={(e) => setAcceptMails(e.currentTarget.checked)}
        />

        <Button
          moreClasses="w-full h-[52px] font-semibold text-[16px] text-black"
          onClick={handleSubmit}
          disabled={!isAllFieldsValid()}
          label="Criar conta"
          hierarchy="primary"
        />

        {isLoading ? (
          <div className="flex justify-center">
            <BeatLoader
              color={'#FF8E00'}
              speedMultiplier={0.8}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <p className="text-sm font-normal text-center text-white">
              Já tem uma conta?
              <span className="text-[#FF8E00] cursor-pointer hover:underline ml-1" onClick={goToLogin}>
                Entrar
              </span>
            </p>
          </>
        )}
      </form>
    </FormContainer>
  );
};

export default BirthDateForm;
