import { useState } from 'react';
import { BiKey, BiUser } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { useDispatch } from '../../store';
import { login } from '../../store/reducers/user/userSlice';

import { useSelector } from 'react-redux';
import { ErrorMessage } from '../../pages/ForgetPassword/styles';
import { Container } from '../../pages/Login/styles';
import { GroupLinkInviteData } from '../../store/reducers/group/types';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { SessionControl } from '../../session/SessionControl';

interface LoginFormProps {
  onChangeMode: (mode: 'login' | 'register') => void;
  onError?: (mode: string) => void;
  onSuccess?: () => void;
  isAtModal?: boolean;
  handleClose?: () => void;
}

const LoginForm = ({
  onChangeMode,
  onError,
  isAtModal,
  onSuccess = () => null,
  handleClose = () => null,
}: LoginFormProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { linkGroupInvited } = useSelector((state: GroupLinkInviteData) => state);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(login({ username, password, remember }));
      const token = SessionControl.getToken();

      if (token) {
        if (isAtModal) {
          onSuccess();
        } else if (linkGroupInvited.url !== '') {
          navigate(`${linkGroupInvited.url}`);
        } else {
          navigate('/');
        }
      } else {
        throw new Error('Usuário ou senha incorretos!');
      }
    } catch (err: any) {
      // onError(err.message);
      setErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const emitChangeMode = () => {
    onChangeMode('register');
  };

  const formButtonBack = () => {
    if (isAtModal) {
      handleClose();
    } else {
      navigate('/');
    }
  };

  return (
    <form className="flex flex-col w-full gap-6" autoComplete="new-password">
      <Container>
        <Input
          isTextHelper={true}
          className="textClass"
          id="outlined-helperText"
          label="Usuário ou email"
          autoCapitalize="none"
          name=""
          autoComplete="new-password"
          placeholder="Usuário ou email"
          type="text"
          onChange={(e: any) => setUsername(e.target.value)}
          disabled={isLoading}
          error={!!errorMessage}
          icon={BiUser}
          moreClasses="border-[1px] border-white"
        />
        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
      <Container>
        <Input
          isTextHelper={true}
          className="textClass inputBottom"
          id="outlined-helperText"
          label="Senha"
          name=""
          placeholder="Senha"
          type="password"
          autoComplete="new-password"
          error={!!errorMessage}
          onChange={(e: any) => setPassword(e.target.value)}
          disabled={isLoading}
          icon={BiKey}
        />
      </Container>

      <div className="flex justify-between">
        <div />
        <p
          onClick={() => navigate('/forget-password')}
          className="text-sm font-extralight text-[#FF8E00] cursor-pointer hover:underline"
        >
          Esqueci a senha
        </p>
      </div>

      <Button
        moreClasses="w-full h-[52px] max-w-[100%] font-semibold text-[16px] text-black"
        style={{ width: '100%' }}
        onClick={handleSubmit}
        disabled={isLoading}
        label="Entrar"
        hierarchy="primary"
      />

      {isLoading ? (
        <div className="flex justify-center">
          <BeatLoader
            color={'#FF8E00'}
            speedMultiplier={0.8}
            size={12}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <p className="text-sm font-normal text-center text-white">
            Não tem uma conta?
            <span className="text-[#FF8E00] cursor-pointer hover:underline ml-1" onClick={emitChangeMode}>
              Criar nova conta
            </span>
          </p>
          <button className="mt-[16px] text-white" onClick={formButtonBack}>
            Voltar
          </button>
        </>
      )}
    </form>
  );
};

export default LoginForm;
