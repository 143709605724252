import styled from '@emotion/styled';
import { PaperProps } from '@mui/material';

export const SelectMenuProps: Partial<PaperProps<'div'>> | undefined = {
  sx: {
    bgcolor: '#4B4C66',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '20px',
    '& .MuiMenuItem-root': {
      height: '45px',
      color: '#FFFFFF',
      fontsize: '14px',
      borderBottom: '1px solid #1C1C27',
    },
    '& .MuiMenuItem-root:last-child': {
      border: 'none',
    },
  },
};

export const PixWithdrawContainer = styled.div`
  width: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const PixWithdrawFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(5, auto);
  grid-gap: 25px;
  margin-top: 50px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .text-field-name,
  .text-field-cpf {
    grid-column: 1/3;
  }

  .ipt-value {
    margin-top: 16px;
  }
`;

export const TextFieldContainer = styled.div`
  width: 100%;
  height: 100%;

  .ipt {
    width: 100%;

    .MuiInputBase-root {
      border: none;
      border-radius: 20px;
      background: #202030;
      border-radius: 20px;

      &:focus {
        border: none;
        outline: none;
      }
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-color: #4b4c66;
    }

    & .Mui-focused {
      overflow: hidden;
      outline: 0;
    }

    .MuiFormLabel-root {
      color: #c4c4c4;
    }

    input,
    .MuiSelect-select {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      padding-left: 16px;

      &:placeholder {
        color: #c4c4c4;
      }
    }

    .select-label {
      color: #c4c4c4;
    }

    svg {
      fill: #fff;
    }
  }

  .select-paper {
    background-color: red;
  }

  & label.Mui-focused {
    color: white;
  }
`;

export const TotalCredit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 27px;
  background: linear-gradient(90.52deg, rgba(255, 142, 0, 0.65) 31.54%, rgba(33, 33, 33, 0) 167.29%);
  border-radius: 8px;
  height: 85px;

  .title {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  .value {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
`;
