import { Match } from './../typescript/match';

export const sortByFinalDate = (a: any, b: any) => {
  if (a.dateFinalBet < b.dateFinalBet) {
    return -1;
  }
  if (a.dateFinalBet > b.dateFinalBet) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

export const sortMatchArrayById = (dataA: Match, dataB: Match) => {
  return dataA.id - dataB.id;
};
