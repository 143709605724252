import styled from '@emotion/styled';
import { CreateGroupContainer, CreateGroupHeader } from './../CreateGroup/styles';

export const GroupInfoContainer = styled(CreateGroupContainer)`
  padding: 15px;
  width: 100%;
`;

export const GroupInfoHeader = styled(CreateGroupHeader)`
  @media (max-width: 550px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ShareLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: auto;
  padding-left: 15px;

  @media (max-width: 1250px) {
    width: 300px;
  }

  @media (max-width: 1110px) {
    width: 400px;
  }

  @media (max-width: 730px) {
    width: 350px;
  }

  @media (max-width: 600px) {
    width: 250px;
  }

  @media (max-width: 550px) {
    padding-left: 0;
    margin-top: 20px;
  }

  @media (max-width: 550px) {
    width: 80%;
  }

  .container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 300px; */
  }

  h2 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 760px) {
    margin: 0;
    grid-column-gap: 4px;

    h2 {
      font-size: 36px;
    }
  }
`;

interface LinkInviteProps {
  copied?: boolean;
}

export const LinkInvite = styled.p<LinkInviteProps>`
  display: flex;
  flex-direction: row;
  grid-row: 2/2;
  grid-column: 2/2;
  align-items: center;

  p {
    margin: 0;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Outfit';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: ${({ copied }) => (copied ? ' #00DC6E' : ' #c4c4c4')};
  }

  width: 100%;
  height: auto;
  padding: 8px;
  border: ${({ copied }) => (copied ? '1px solid #00DC6E' : '1px solid #4b4c66')};
  border-radius: 8px;
  background: #28293d;

  button {
    margin-left: 8px;

    svg {
      g path {
        fill: ${({ copied }) => (copied ? '#00DC6E' : '#C4C4C4')};
      }
    }
  }

  @media (max-width: 760px) {
    height: auto;

    p {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      margin-left: 8px;
    }
  }
`;
