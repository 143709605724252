import styled from 'styled-components';

export const MainCointainer = styled.button`
  display: flex;
  justify-content: space-between;
  max-width: 678px;
  width: 100%;
  height: 60px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: #28293d;
  border-radius: 20px;

  @media (max-width: 500px) {
    max-width: 366px;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 10px 8px 0 0;
  }
  //text-white text-[16px] font-normal mt-[10px] ml-[8px]
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 5px 0;

  h2 {
    font-size: 25px;
    color: white;
  }

  span {
    font-size: 14px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  width: 35px;
  height: 30px;
  margin-top: 10px;
  /* 
  svg {
    width: 15.66px;
    height: 13.78px;

    &:hover {
      transform: rotate(90deg);
      transition: transition-duration 150ms ease-out;
    }
  } */
`;
