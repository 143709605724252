import {
  CreateGroupButton,
  CreateGroupHeaderAvatar,
  CreateGroupTitle,
  RulesCreateGroup,
} from './../CreateGroup/styles';
import { GroupInfoContainer, ShareLinkContainer, LinkInvite, GroupInfoHeader } from './styes';
import { ReactComponent as CopyIcon } from '../../utils/icons/copy.svg';
import { UserGroup } from '../../typescript/group';
import { useEffect, useState } from 'react';
import { shareLinkByClipBoard } from '../../utils/share';

interface GroupInfoProps {
  group: UserGroup;
}

const GroupInfo = ({ group }: GroupInfoProps) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const sharePot = () => {
    const url = group.invite;
    shareLinkByClipBoard(url);
    setLinkCopied(true);
  };

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  }, [linkCopied]);

  return (
    <GroupInfoContainer>
      <CreateGroupTitle>Você já tem um grupo!</CreateGroupTitle>
      <RulesCreateGroup>
        <p>Compartilhe com seus amigos e comece a lucrar agora!</p>
      </RulesCreateGroup>
      <GroupInfoHeader>
        <CreateGroupHeaderAvatar>{!!group.urlFile && <img src={group.urlFile} alt="" />}</CreateGroupHeaderAvatar>
        <ShareLinkContainer>
          <div className="container-title">
            <h2>{group.name}</h2>
          </div>
          <LinkInvite copied={linkCopied}>
            <p>{group.invite}</p>
            <button onClick={sharePot}>
              <CopyIcon />
            </button>
          </LinkInvite>
        </ShareLinkContainer>
      </GroupInfoHeader>
      <CreateGroupButton onClick={sharePot}>Copia Link</CreateGroupButton>
    </GroupInfoContainer>
  );
};

export default GroupInfo;
