import validator from 'validator';
import moment from 'moment';

export const passwordDefaultRules = {
  minLength: 0,
  minLowercase: 0,
  minUppercase: 0,
  minNumbers: 0,
  minSymbols: 0,
};

export const rules = {
  alphanumericRule: function (str: string) {
    return validator.isAlphanumeric(str);
  },
  usernameLengthRule: function (str: string) {
    return validator.isByteLength(str, { min: 4, max: 24 });
  },
  nameLengthRule: function (str: string) {
    return validator.isByteLength(str, { min: 6 });
  },
  emailRule: function (email: string) {
    return validator.isEmail(email);
  },
  passwordMinLengthRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minLength: 8,
    });
  },
  passwordNumberRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minNumbers: 1,
    });
  },
  passwordUppercaseRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minUppercase: 1,
    });
  },
  passwordSymbolRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minSymbols: 1,
    });
  },
  birthdateRule: function (str: any) {
    const eighteenYearsAgo = moment().subtract(18, 'years');
    const userBirthdate = moment(str, 'YYYY-MM-DD');
    return userBirthdate.isSameOrBefore(eighteenYearsAgo, 'day');
  },
};

export const passwordLabels = [
  { label: '8 dígitos ou mais', rule: rules.passwordMinLengthRule },
  { label: 'Pelo menos 1 número', rule: rules.passwordNumberRule },
  {
    label: 'Pelo menos 1 letra maiúscula',
    rule: rules.passwordUppercaseRule,
  },
  {
    label: 'Pelo menos 1 caractere especial',
    rule: rules.passwordSymbolRule,
  },
];
