import { useEffect, useMemo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../common/context/UserContext';
import { useGetWindowSize } from '../../../hooks/useGetWindowSize';
import { usePotListing } from '../../../hooks/usePotListing';
import { getCmsHtml } from '../../../service/cms/cms.service';
import { firstAccess } from '../../../service/user/user.service';
import { RootState } from '../../../store';
import { Game } from '../../../typescript/match';
import { cmsPagesEnum } from '../../../utils/cmsPagesEnum';
import { FootBallIcon } from '../../../utils/icons/ballIcon';
import { normalizePotUrl } from '../../../utils/normalize-name';
import CardImg from '../../CardImg/CardImg';
import { IconSmile } from './../../../utils/icons/IconSmile';
import { ButtonCloseModal, ButtonContained, ButtonOutlined, ModalButtonGroup, ModalOverlay } from './../styles';
import {
  ModalAlertCheckoutBanner,
  ModalAlertCheckoutSimpleText,
  ModalAlertCheckoutTitle,
  ModalCheckoutContainer,
  ModalCheckoutExtraIngoContainer,
  ModalExtraInfoTitle,
} from './styles';
import { isToday, isBefore, parseISO } from 'date-fns';
import CryptoJS from "crypto-js";
import moment from 'moment';
import {getCurrentTimestamp} from "../../../utils/timestampDisplay";

interface ModalAlertCheckoutProps {
  extraInfo?: 'hot pots' | 'hottest pot banner' | 'none';
  onClose: () => void;
  handleConfirm?: () => void;
  isHome?: boolean;
  handleGotToHome?: () => void;
}

export const ModalAlertCheckoutContentContainer = ({
  extraInfo = 'none',
  onClose,
  handleConfirm,
  handleGotToHome = () => null,
  isHome = false,
}: ModalAlertCheckoutProps) => {
  const { userLogged } = useUserContext();
  const [potList, setPotList] = useState([] as Array<Game>);
  const { token } = useSelector((state: RootState) => state.user);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const [bannerContent, setBannerContent] = useState('');
  const { BANNER_CATEGORY_TYPEID } = cmsPagesEnum;
  const navigate = useNavigate();

  async function removeFirstAccess() {
    if (userLogged) {
      await firstAccess(userLogged?.id);
    }
  }

  useMemo(async () => {
    const result = await usePotListing();

    setPotList(result);
  }, [token]);

  function gotToPot(potInfo: Game) {
    // const championShip = normalizePotUrl(potInfo.championship);
    // const title = normalizePotUrl(potInfo.name);
    // const newUrl = `/pot/${championShip}/${title}`;

    const potId = potInfo.id.toString(); // Convertendo para string

    const encryptionKey = 'secrethero99';
    const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);

    const newUrl  = `/pot/${eHex}`;

    navigate(newUrl);
  }

  const seeMorePots = () => {
    if (isMobile) {
      if (handleConfirm) {
        handleConfirm();
      }
      removeFirstAccess();
    } else {
      navigate('/category');
      removeFirstAccess();
    }
  };

  useEffect(() => {
    getCmsHtml(BANNER_CATEGORY_TYPEID).then((res) => {
      setBannerContent(res);
    });
  }, []);

  const userName = userLogged?.name || userLogged?.login;

  const handleOpenMenu = (pot: Game) => {
    if (isHome) {
      removeFirstAccess();
    }

    gotToPot(pot);
  };

  const today = getCurrentTimestamp()

  return (
    <ModalCheckoutContainer isWithPots={extraInfo === 'hot pots'}>
      {!isMobile && (
        <ButtonCloseModal onClick={onClose}>
          <MdClose color="#fff" size="24" />
        </ButtonCloseModal>
      )}
      <IconSmile />
      <div style={{ marginTop: '0px' }} />
      <ModalAlertCheckoutTitle>
        <span>Aí sim, {userName}!</span>
      </ModalAlertCheckoutTitle>
      <ModalAlertCheckoutTitle>A Money Pot fica melhor com você</ModalAlertCheckoutTitle>
      <ModalAlertCheckoutSimpleText>Aqui uma seleção dos Pots mais interessantes</ModalAlertCheckoutSimpleText>
      <ModalAlertCheckoutSimpleText>do momento. É hora de ganhar!</ModalAlertCheckoutSimpleText>

      {extraInfo !== 'none' && (
        <ModalCheckoutExtraIngoContainer>
          {extraInfo === 'hot pots' && (
            <div className="extra-info">
              <ModalExtraInfoTitle>
                {' '}
                <FootBallIcon /> POTS EM ALTA
              </ModalExtraInfoTitle>
              <div className="pots-list">
                {potList
                .filter((pot) => {
                  const potDate = moment(pot.dateInitialBet, 'YYYY-MM-DD');
                  return potDate.valueOf() <= today;
                })
                .slice(0, 4).map((pot) => (
                  <CardImg
                    openMenu={() => handleOpenMenu(pot)}
                    key={pot.id}
                    colorSchema={'light'}
                    championship={pot?.championship}
                    title={'Ganhe até:'}
                    timeLeft={pot?.dateFinalBet}
                    image={pot?.match[0]?.imageTeam1?.image}
                    imagetwo={pot?.match[0]?.imageTeam2?.image}
                    imageAlt="match"
                    accumulated={pot?.valueAccumulated}
                    value={pot?.minimumValue}
                    buttonText={'jogue agora'}
                  />
                ))}
              </div>
            </div>
          )}

          {extraInfo === 'hottest pot banner' && (
            <div>
              <ModalExtraInfoTitle>
                <FootBallIcon /> ESGOTA AMANHÃ
              </ModalExtraInfoTitle>
              <ModalAlertCheckoutBanner>
                <div
                  className="banner-category"
                  dangerouslySetInnerHTML={{
                    __html: bannerContent,
                  }}
                />
              </ModalAlertCheckoutBanner>
            </div>
          )}
        </ModalCheckoutExtraIngoContainer>
      )}

      <ModalButtonGroup>
        <ButtonOutlined onClick={isMobile ? onClose : handleGotToHome}>
          {isMobile ? 'voltar' : 'Ir para a home'}
        </ButtonOutlined>
        <ButtonContained onClick={isMobile ? seeMorePots : handleConfirm}>
          {isMobile ? 'Comprar mais fichas' : 'Ver mais pots'}
        </ButtonContained>
      </ModalButtonGroup>
    </ModalCheckoutContainer>
  );
};

const ModalAlertCheckout = ({
  extraInfo = 'none',
  onClose,
  handleConfirm,
  handleGotToHome,
}: ModalAlertCheckoutProps) => {
  return (
    <ModalOverlay style={{ paddingTop: '0' }}>
      <ModalAlertCheckoutContentContainer
        extraInfo={extraInfo}
        onClose={onClose}
        handleConfirm={handleConfirm}
        handleGotToHome={handleGotToHome}
      />
    </ModalOverlay>
  );
};

export default ModalAlertCheckout;
