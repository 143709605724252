import { useEffect, useRef, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';
import { RiFacebookBoxLine, RiTwitterLine, RiWhatsappLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { ErrorAlert } from '../../components/BasicAlerts';
import Button from '../../components/Button/Button';
import WelcomeToGroup from '../../components/WelcomeToGroup';
import { GROUP_TOKEN_KEY } from '../../constants/local-storage-keys';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useUserAuth } from '../../hooks/user-auth';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { checkGroupVisit } from '../../utils/group';
import { ReactComponent as CopyIcon } from '../../utils/icons/copy.svg';
import { shareLinkByClipBoard } from '../../utils/share';
import { ImageContainer, MainContainer, ModalShareContainer, ShareGroupContainer, ShareLinkContainer } from './styles';
import { SessionControl } from '../../session/SessionControl';

enum NetworkName {
  Whatsapp = 'Whatsapp',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

const NETWORKS = [
  {
    title: NetworkName.Whatsapp,
    icon: RiWhatsappLine,
  },

  {
    title: NetworkName.Facebook,
    icon: RiFacebookBoxLine,
  },

  {
    title: NetworkName.Twitter,
    icon: RiTwitterLine,
  },
];

export default function JoinGroup({ groupDetails }: any) {
  const { userLogged } = useUserContext();
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | 'warn' | ''>('');
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  useUserAuth({ isPrivatePage: true });
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const { groupToken } = useParams();
  const navigate = useNavigate();

  const hideContentPage = isFirstVisit && isMobile;
  const popoverRef = useRef<HTMLDivElement | null>(null);

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  const sharePot = () => {
    const url = groupDetails.invite;
    shareLinkByClipBoard(url);
    setLinkCopied(true);
  };

  const shareLinkToNetworks = ({ network }: any) => {
    if (window) {
      const url = groupDetails.invite;

      if (network === 'Facebook') {
        const link = `https://www.facebook.com/sharer.php?u=${url}`;
        return link;
      }

      if (network === 'Twitter') {
        const link = `https://twitter.com/intent/tweet?url=${url}&text=Fa%C3%A7a%20j%C3%A1%20o%20seu%20palpite%20na%20MoneyPot&hashtags=moneypot,game,money`;
        return link;
      }

      if (network === 'Whatsapp') {
        const link = `https://api.whatsapp.com/send?text=${url}`;
        return link;
      }
    }

    return '';
  };

  const handleSaveToken = () => {
    if (!userLogged) {
      if (groupToken) {
        SessionControl.setGroupToken(JSON.stringify(groupToken));
      }
    }
  };

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  }, [linkCopied]);

  useEffect(() => {
    //CLOSE SHARE OPTION WHEN CLICK OUTSIDE
    function closeOptions(event: Event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as HTMLDivElement)) {
        setShowShareOptions(false);
      }
    }

    document.addEventListener('mousedown', closeOptions);
    return () => document.removeEventListener('mousedown', closeOptions);
  }, [popoverRef]);

  useEffect(() => {
    if (!userLogged?.id) {
      return navigate('/');
    }
    if (groupDetails.name) {
      const result = checkGroupVisit(userLogged.id);
      if (result) {
        setIsFirstVisit(result);
      }
    }
  });

  useEffect(() => {
    handleSaveToken();
  }, []);

  return (
    <>
      {isFirstVisit && <WelcomeToGroup handleClose={() => setIsFirstVisit(false)} group={groupDetails} />}

      {!hideContentPage && (
        <PageContainer>
          {statusRequest === 'error' && (
            <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} autoClose />
          )}

          <MainContainer>
            <ShareGroupContainer>
              <ShareLinkContainer copied={linkCopied}>
                <ImageContainer>
                  <img src={groupDetails?.urlFile} alt="" />
                </ImageContainer>

                <div className="container-title">
                  <h2>{groupDetails.name}</h2>
                </div>

                <div className="container-invite">
                  <p>{groupDetails.invite.replace('https://', '')}</p>

                  <button>
                    <CopyIcon onClick={sharePot} />
                  </button>
                </div>
              </ShareLinkContainer>

              <Button
                hierarchy="primary"
                label="Convidar"
                size="lg"
                moreClasses="flex justify-center max-w-[334px] md:max-w-[147px] w-[90%] h-[52px]"
                onClick={() => setShowShareOptions(!showShareOptions)}
              />

              {showShareOptions && (
                <ModalShareContainer ref={popoverRef}>
                  <button type="button" onClick={() => sharePot()} className="link-Container">
                    <MdOutlineContentCopy />
                    Copiar link
                  </button>

                  {NETWORKS.map((item) => (
                    <a
                      key={item.title}
                      target="blank"
                      rel="noopener noreferrer"
                      href={shareLinkToNetworks({ network: item.title })}
                      className="link-Container"
                    >
                      <item.icon />
                      {item.title}
                    </a>
                  ))}
                </ModalShareContainer>
              )}
            </ShareGroupContainer>
          </MainContainer>
        </PageContainer>
      )}
    </>
  );
}
