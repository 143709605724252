import styled from 'styled-components';

export const CoinsAndCredit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 22px;
  width: auto;
  height: 40px;
  background: #28293d;
  border-radius: 10px;
  column-gap: 20px;
  margin-top: 10px;

  @media (max-width: 750px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 213px;
    padding: 0px;
    gap: 10px;
    /* display: none; */
  }

  @media (max-width: 350px) {
    width: 160px;
  }

  svg {
    margin-right: 5px;
  }

  .dollar-sign-icon {
    color: #ff8e00;
    margin-right: 5px;

    @media (max-width: 350px) {
      margin-right: 0;
    }
  }

  .currency-l {
    font-weight: 300;
    margin-right: 5px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #ffffff;

    @media (max-width: 350px) {
      font-size: 12px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  gap: 8px;

  @media (max-width: 415px) {
    position: relative;
    left: 0%;
  }

  @media (max-width: 405px) {
    left: -24%;
  }

  @media (max-width: 350px) {
    left: -32%;
  }
`;
