import { CMSPageContent, CMSPageContentContainer, PageContentCardContainer } from './styles';
import { useGetCmsContent } from '../../hooks/useGetCmsContent';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { CardImgCMSPage } from '../CardImg/CardImgBg';

interface CMSPageLayoutProps {
  contentName: keyof typeof cmsPagesEnum;
  children?: React.ReactNode;
}

const CMSPageLayout = ({ contentName, children }: CMSPageLayoutProps) => {
  const { content, loading } = useGetCmsContent({ id: cmsPagesEnum[contentName] });

  const noData = !loading && !content;

  return (
    <PageContainer>
      <CMSPageContentContainer>
        <PageContentCardContainer>
          <CardImgCMSPage />
          {noData && <CMSPageContent dangerouslySetInnerHTML={{ __html: '<h3>Sem informação</h3>' }} />}
          {loading && <CMSPageContent dangerouslySetInnerHTML={{ __html: '<h3>Carregando informações...</h3>' }} />}
          {!loading && <CMSPageContent dangerouslySetInnerHTML={{ __html: content }} />}
        </PageContentCardContainer>
        {children}
      </CMSPageContentContainer>
    </PageContainer>
  );
};

export default CMSPageLayout;
