import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Outfit';
  width: 100%;
  height: 56px;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 8px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    padding-left: 8px;
    line-height: 20.16px;
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4b4c66;
  }

  & label.Mui-focused {
    color: #c4c4c4;
  }

  & .MuiOutlinedInput-root {
    border: 1px;
    border-radius: 20px;
    border-color: white;

    &.Mui-focused fieldset {
      border-radius: 20px;
      border-color: #4b4c66;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      border: 1px;
      border-color: #4b4c66;
      border-radius: 20px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .textClass {
    border: 2px #4b4c66;
    border-color: #4b4c66;
    background-color: #202030;
    border-radius: 20px;
    width: 100%;
    height: 56px;

    @media (max-width: 767px) {
      width: 100%;
      height: 56px;
    }

    @media (max-width: 280px) {
      max-width: 327px;
      width: 100%;
      height: 56px;
    }
  }

  .inputBottom {
    margin-bottom: 0;
  }

  #outlined-helperText {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #c4c4c4;
    line-height: 20.16px;
  }
`;

interface CustomRadioProps {
  active: boolean;
}

export const CustomRadio = styled.div<CustomRadioProps>`
  width: 18px;
  height: 18px;
  border: ${({ active }) => (active ? '2px solid #FF8E00' : '2px solid #C4C4C4')};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .circle {
    background-color: #ffc070;
    width: 9px;
    height: 9px;
    border-radius: 100%;
  }
`;
