import validator from 'validator';
import moment from 'moment';

const passwordDefaultRules = {
  minLength: 0,
  minLowercase: 0,
  minUppercase: 0,
  minNumbers: 0,
  minSymbols: 0,
};

export const rules = {
  alphanumericRule: function (str: string) {
    return validator.isAlphanumeric(str);
  },
  usernameLengthRule: function (str: string) {
    return validator.isByteLength(str, { min: 4, max: 24 });
  },
  emailRule: function (email: string) {
    return validator.isEmail(email);
  },
  passwordMinLengthRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minLength: 8,
    });
  },
  passwordNumberRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minNumbers: 1,
    });
  },
  passwordUppercaseRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minUppercase: 1,
    });
  },
  passwordSymbolRule: function (str: string) {
    return validator.isStrongPassword(str, {
      ...passwordDefaultRules,
      minSymbols: 1,
    });
  },
  birthdateRule: function (str: any) {
    const eighteenYearsAgo = moment();
    const userBirthdate = moment(str);
    const result = eighteenYearsAgo.year() - userBirthdate.year();

    return result >= 18;
  },
};
