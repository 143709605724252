import axios from 'axios';
import { SessionControl } from '../session/SessionControl';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(function (config) {
  const token = SessionControl.getToken();

  config.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (config && config.headers) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

export default api;
