import { useEffect, useState } from 'react';

import football from '../../utils/icons/football.svg';
import soccer from '../../utils/icons/soccer.svg';
import vooley from '../../utils/icons/vooley.svg';
import mma from '../../utils/icons/mma.svg';
import tenis from '../../utils/icons/tenis.svg';
import four from '../../utils/icons/four.svg';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

const ErrorComponent404 = () => {
  const navigate = useNavigate();

  function redirectToHome() {
    navigate('/login');
  }

  const images = [football, soccer, vooley, mma, tenis];

  const [index, setIndex] = useState(0);
  const func = () => {
    if (index === images.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      func();
    }, 1000);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-row items-center justify-center h-full mb-[13px] mt-[80px]">
        <img src={four} alt="four" className="mr-[22px] w-full md:max-w-[122px] max-w-[50px]" />
        <img
          src={images[index]}
          alt="error"
          className="w-full max-w-[200px] md:min-h-[205px] min-h-[85px] md:max-w-[200px] max-w-[82px]"
        />
        <img src={four} alt="four" className="ml-[22px] w-full md:max-w-[122px] max-w-[50px]" />
      </div>

      <h1 className="text-[32px] md:text-[48px] text-center font-bold">Oops! Página não encontrada :(</h1>
      <div className="mb-[13px]" />
      <h1 className="text-[14px] md:text-[24px] text-center">
        Desculpe, a página que você esta procurando
        <br /> não foi encontrada por aqui.
      </h1>

      <div className=" mb-[40px]" />

      <Button hierarchy="primary" label="Ir para a home" size="lg" onClick={() => redirectToHome()} />
      <div className=" mb-[80px]" />
    </div>
  );
};

export default ErrorComponent404;
