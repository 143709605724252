import { format, intervalToDuration } from 'date-fns';
import { pt } from 'date-fns/locale';
import { ReactNode } from 'react';
import moment from "moment";
import {getCurrentDate} from "./timestampDisplay";

export interface ChildrenProps {
  children: ReactNode;
}

export interface IWallet {
  id: number;
  status: string;
  value: number;
  typeCurrency: string;
}

export interface IUserCoins {
  id: number;
  status: string;
  value: number;
}

export const formatValue = (value: number) => {
  return Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  })
    .format(value)
    .replace('R$', '');
};

export function formatCPF(value: string, limited = true) {
  if (!value) return '';
  const cpfWithOutMax = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2');

  if (limited) {
    return cpfWithOutMax.replace(/(-\d{2})\d+?$/, '$1');
  }
  return cpfWithOutMax;
}

export function formatCNPJ(value: string) {
  if (!value) return '';
  const cleanedCNPJ = value.replace(/\D/g, '');

  return cleanedCNPJ
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function formatCPForCNPJ(value: string) {
  if (!value) return '';

  if (value.length > 15) {
    return formatCNPJ(value);
  }

  return formatCPF(value, false);
}

export function formatPhoneNumber(value: string) {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

export function formatCep(value: string) {
  if (!value) return value;

  const cep = value.replace(/[^\d]/g, '');

  const cepLength = cep.length;

  if (cepLength < 4) {
    return `${cep.slice(0, 5)}-`;
  }

  return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
}

export function temp(end: string) {
  const now = getCurrentDate();
  const endDate = moment(end).toDate();

  if (endDate >= now) {
    return intervalToDuration({
      start: now,
      end: endDate,
    });
  }
  return 0;
}

export function validateCPF(strCPF: string) {
  let Soma;
  let Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function formatDay(day: string) {
  return moment(day).format('DD/MM/YYYY');
}

export const formatMoney = (i: string): string => {
  let v = i?.replace(/\D/g, '');
  v = (Number(v) / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return (i = v);
};

export const formatCreditCard = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .slice(0, 19);
};

export const maskDate = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
};

export const maskCvc = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1');
};
