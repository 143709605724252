import { HTMLAttributes } from 'react';

interface ICarrouselProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  carrouselClassName?: string;
}

const Carrousel = ({ title, carrouselClassName, className, children }: ICarrouselProps) => {
  return (
    <div className={className + ' container-carousel '}>
      <h2 className="text-sm font-semibold leading-6 mb-[15px]">{title}</h2>
      <div className={`${carrouselClassName} pb-[5px] flex flex-row gap-[30px] overflow-x-auto container-carousel`}>
        {children}
      </div>
    </div>
  );
};

export default Carrousel;
