import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo, userGetPots } from '../../service/user/user.service';
import { RootState } from '../../store';
import { slice } from './../../store/reducers/user/userSlice';
import { UserGroup } from '../../typescript/group';
import { ChildrenProps, IUserCoins, IWallet } from '../../utils/types';
import { firebaseAuth } from './../../service/firebase/firebase';
import { Game } from '../../typescript/match';

export interface IUser {
  id: number;
  name: string;
  login: string;
  CEP: string;
  address: string;
  birthDate: string;
  checkEmails: boolean;
  city: string;
  cpf: string;
  pixKey: string;
  pixType: string;
  email: string;
  phone: string;
  firstAccess?: boolean,
  typeaccount: "google'" | 'facebook' | 'default';
  usersCoins: IUserCoins[];
  userGroup?: UserGroup[];
  wallet: IWallet[];
}

interface UserContextData {
  userLogged: IUser | undefined;
  setUserLogged: Dispatch<SetStateAction<IUser | undefined>> | any;
  updateAppState: (s: string) => void;
  appStateUpdatedCtx: string;
  userBets: Game[];
}

const initialContext: UserContextData = {
  userLogged: undefined,
  setUserLogged: () => null,
  updateAppState: () => null,
  appStateUpdatedCtx: '',
  userBets: [],
};

export const UserContext = createContext<UserContextData>(initialContext);

export const UserProvider = ({ children }: ChildrenProps) => {
  const [userLogged, setUserLogged] = useState<IUser>();
  const [appStateUpdatedCtx, setAppStateUpdatedCtx] = useState('');
  const { token } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bets, setBets] = useState<Game[]>([]);
  const location = useLocation();

  function handleLogout() {
    dispatch(slice.actions.logout());
    firebaseAuth.signOut();
    navigate('/');
    return window.location.reload();
  }

  //FUNCTION TO TRIGGER AN UI UPDATE FOR OTHER
  //STATE THAT ARE NOT RELATED TO THE USER INFO
  //stateContextName Basically you just set anything to update the state
  const updateAppState = (stateContextName: string) => {
    setAppStateUpdatedCtx(`${stateContextName}+${Math.random() * 1000}`);
  };

  const handleGetUserInfo = async () => {
    if (token) {
      try {
        const response = await getUserInfo();

        if (response.statusCode === 401) {
          handleLogout();
          return;
        }

        setUserLogged(response);
      } catch (error: any) {
        //  handleLogout();
      }
    } else {
      // handleLogout();
    }
  };

  const handleGetBets = async () => {
    const response = await userGetPots(
      `status[]=Em Andamento&status[]=Aguardando Finalizacao&page=1&limit=100&created_at=DESC`
    );
    const data = response.items;
    setBets(data);
  };

  useEffect(() => {
    handleGetUserInfo();
  }, [token]);

  useEffect(() => {
    if (appStateUpdatedCtx.includes('wallet')) {
      handleGetUserInfo();
    }
  }, [appStateUpdatedCtx]);

  useEffect(() => {
    if (appStateUpdatedCtx.includes('group')) {
      handleGetUserInfo();
    }
  }, [appStateUpdatedCtx]);

  useEffect(() => {
    if (token) {
      if (appStateUpdatedCtx.includes('bets')) {
        handleGetBets();
      }
    }
  }, [appStateUpdatedCtx]);

  return (
    <UserContext.Provider value={{ userBets: bets, userLogged, setUserLogged, appStateUpdatedCtx, updateAppState}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};
