import styled from 'styled-components';

export const BetsListPaginationContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-left {
    transform: rotate(180deg);
  }
`;

export const PaginationButton = styled.button`
  border: none;
  outline: none;
`;

export const PaginationPageList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
`;

interface PageItemProps {
  active: boolean;
}

export const PaginationPageListItem = styled.li<PageItemProps>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? '#FF8E00' : '#C4C4C4')};
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;
`;
