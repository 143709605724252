import { MenuItem, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { ButtonContainer, Container, MainContainer, Title, TopContainer } from './styles';

import { useUserContext } from '../../common/context/UserContext';
import Button from '../../components/Button/Button';
import ButtonLiner from '../../components/ButtonLiner';
import { SimpleFeedbackText } from '../../components/SimpleFeedBackText';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { updateUserInfo } from '../../service/user/user.service';
import { pixTypeEnum } from '../../utils/pixTypeEnum';
import { formatCPF, formatPhoneNumber } from '../../utils/types';
import PasswordSetting from './password-setting';

const Account = () => {
  const { userLogged, setUserLogged } = useUserContext();
  const [openPassword, setOpenPassword] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openGroupInfo, setOpenGroupInfo] = useState(false);
  const [fullName, setFullName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [pix, setPix] = useState<string>('');
  const [pixType, setPixType] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState<'Senha Alterada' | 'Cadastro Alterado' | ''>('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | ''>('');
  const [isLoading, setIsLoading] = useState(false);

  function handleCleanStatusRequest() {
    setStatusRequest('');
    setMessage('');
  }

  function handleOpenPassword() {
    setOpenPassword((openPassword) => !openPassword);
  }

  function handleOpenInformation() {
    setOpenInformation((openInformation) => !openInformation);
  }

  const handleCorfirm = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      setStatusRequest('');
      setMessage('');
      const apiData = await updateUserInfo(fullName, phoneNumber, pix, cpf, email, pixType);
      setUserLogged({ ...userLogged, ...apiData });
      setStatusRequest('success');
      setTitle('Cadastro Alterado');
      setMessage('Cadastro alterado com sucesso! ');
      setIsLoading(false);
      return apiData;
    } catch (error) {
      setIsLoading(false);
      setStatusRequest('error');
      setMessage('Ocorreu um erro! Tente novamente mais tarde.');
    }
  };

  function handleFormatPhone(e: ChangeEvent<HTMLInputElement>) {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  }

  function handleFormatCpf(e: ChangeEvent<HTMLInputElement>) {
    const formattedCpf = formatCPF(e.target.value);
    setCpf(formattedCpf);
  }

  async function getUser() {
    if (userLogged) {
      const { name, phone, pixKey, cpf, email, pixType } = userLogged;
      setFullName(name);
      setPhoneNumber(phone);
      setPix(pixKey);
      setPixType(pixType);
      setCpf(formatCPF(cpf));
      setEmail(email);
    }
  }

  useEffect(() => {
    getUser();
  }, [userLogged]);

  useEffect(() => {
    if (statusRequest === 'success' && !isLoading) {
      getUser();
    }
  }, [statusRequest, isLoading]);

  return (
    <PageContainer>
      {/* {statusRequest === 'success' && (
        <SuccessAlert title={title} message={message} onClose={handleCleanStatusRequest} autoClose />
      )}
      {statusRequest === 'error' && (
        <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} autoClose />
      )} */}

      <TopContainer>
        <Title>Configurações</Title>

        <ButtonLiner label={'Dados pessoais'} isOpen={openInformation} handleOpen={handleOpenInformation} />
        {openInformation && (
          <>
            <MainContainer>
              <Container>
                <TextField
                  className="textClass"
                  id="outlined-helperText"
                  label="Nome Completo"
                  type="text"
                  defaultValue={fullName}
                  value={fullName || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setFullName(event.target.value)}
                />
              </Container>

              <Container>
                <TextField
                  className="textClass"
                  id="outlined-helperText"
                  label="CPF"
                  type="text"
                  defaultValue={cpf}
                  value={cpf || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormatCpf(event)}
                />
              </Container>

              <Container>
                <TextField
                  className="selectClass"
                  id="outlined-helperText"
                  select
                  label="Tipo Pix"
                  defaultValue={pixType}
                  value={pixType || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPixType(event.target.value)}
                >
                  {pixTypeEnum.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textClass"
                  id="outlined-helperText"
                  label="PIX"
                  type="text"
                  defaultValue={pix}
                  value={pix || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPix(event.target.value)}
                />
              </Container>

              <Container className="boxContainer">
                <TextField
                  className="textClass textEmail"
                  id="outlined-helperText"
                  label="E-mail"
                  type="text"
                  defaultValue={email}
                  value={email || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                />

                <TextField
                  className="textClass"
                  id="outlined-helperText"
                  label="Telefone"
                  type="phone"
                  defaultValue={phoneNumber}
                  value={phoneNumber || ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormatPhone(event)}
                />
              </Container>

              {!!statusRequest && <SimpleFeedbackText isError={statusRequest === 'error'} text={message} />}

              <ButtonContainer>
                <Button
                  onClick={handleCorfirm}
                  hierarchy="primary"
                  disabled={isLoading}
                  label={isLoading ? 'salvando...' : 'Salvar alterações'}
                  size="lg"
                  moreClasses=" max-w-[366px] md:max-w-[170px] w-full font-semibold text-[14px] h-[40px] "
                />
              </ButtonContainer>
            </MainContainer>
          </>
        )}

        {userLogged?.typeaccount === 'default' && (
          <ButtonLiner label={'Alterar Senha'} isOpen={openPassword} handleOpen={handleOpenPassword} />
        )}
        {openPassword && (
          <PasswordSetting handleSetOpenPassword={() => setOpenPassword(false)} userLogged={userLogged} />
        )}

        {/* <ButtonLiner label={'Meus Grupos'} isOpen={openGroupInfo} handleOpen={() => setOpenGroupInfo(!openGroupInfo)} />
        {openGroupInfo && <GroupSetting />} */}
      </TopContainer>
    </PageContainer>
  );
};

export default Account;
