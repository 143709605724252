import { useEffect, useState } from 'react';
import { getCmsHtml } from '../service/cms/cms.service';

interface UseGetCmsContentProps {
  id: number;
}

interface UseGetCmsContentResponse {
  content: string;
  loading: boolean;
}

export const useGetCmsContent = ({ id }: UseGetCmsContentProps): UseGetCmsContentResponse => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGetContent = async () => {
    setLoading(true);
    const response = await getCmsHtml(id);
    setLoading(false);
    setContent(response);
  };

  useEffect(() => {
    handleGetContent();
  }, [id]);

  return { content, loading };
};
