import styled from 'styled-components';

type PotCardProps = {
  gradient: string;
  border: string;
  index: number;
  itemValue?: number;
  valueOfWidth?: number;
};

type DividerProps = {
  gradient: string;
};

export const PotCardContainerWrapper = styled.div`
  width: 100%;
  padding-bottom: 32px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
`;

export const PotCardContainer = styled.div<PotCardProps>`
  width: 100%;
  border: 2px solid ${({ border }) => border};
  border-radius: 20px;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${({ gradient }) => `linear-gradient(270deg, #1c1c27 35.45%, ${gradient} 334.4%),
    linear-gradient(0deg, ${gradient}33, ${gradient}33)`};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  z-index: 3;
  position: relative;
`;

export const CardSampleForStack = styled.div<PotCardProps>`
  height: ${({ valueOfWidth }) => `calc(100% + ${valueOfWidth}px)`};
  width: ${({ valueOfWidth }) => `calc(100% - ${valueOfWidth}px)`};
  margin: 0 auto;
  position: absolute;
  inset: 0;
  z-index: ${({ index }) => index};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border: 2px solid ${({ border }) => border};
  border-radius: 20px;
  background: ${({ gradient }) => `linear-gradient(270deg, #1c1c27 35.45%, ${gradient} 334.4%),
    linear-gradient(0deg, ${gradient}33, ${gradient}33)`};
`;

export const PotCardTopInfo = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 5px;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: 65% auto;
  }
`;

export const CardBottomInformation = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  @media (max-width: 650px) {
    grid-template-columns: 100%;
    justify-content: center;
  }
`;

export const Divider = styled.div<DividerProps>`
  height: 1px;
  width: 100%;
  background: ${({ gradient }) => gradient};
  margin-top: 16px;
`;

interface PotBetStatusProps {
  bgColor?: string;
}

export const RepeatedBetNumber = styled.div`
  display: flex;
  width: 30px;
  padding: 5.625px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #ff8e00;
  margin-right: 6px;

  p {
    color: #1c1c27;
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const PotBetStatus = styled.div<PotBetStatusProps>`
  background: ${({ bgColor }) => bgColor};
  width: auto;
  max-width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Outfit';
  line-height: 18px;
  color: #202030;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 650px) {
    margin: auto;
  }
`;

export const PotQtyPeopleBet = styled.div`
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 31px;
  background: #232434;
  max-width: fit-content;

  p {
    color: #c4c4c4;
    font-family: Outfit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.14px;
  }
`;

export const PotBetCoins = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  h4 {
    font-family: Outfit;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #fff;
  }

  @media (max-width: 650px) {
    padding: 0;
    gap: 4px;

    h4 {
      font-size: 1rem;
    }
  }
`;

export const PotCardHeaderInfo = styled.div`
  width: 100%;

  .info-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .pot-bet-description {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #c4c4c4;
  }

  .timer-label {
    display: none;
  }

  .timer-value {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #ffffff;
    font-size: 1rem;

    @media (max-width: 500px) {
      font-size: 0.875rem;
    }
  }
`;

export const PotCardChampionship = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 30px;
    color: #ffffff;
    height: auto;
    @media (max-width: 600px) {
      font-size: 1.125rem;
    }
  }

  @media (max-width: 500px) {
    font-size: 1.125rem;
  }
`;

export const PotCardBetTitle = styled.p`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #c4c4c4;

  @media (max-width: 500px) {
    font-size: 0.875rem;
  }
`;

export const PotCardPrice = styled(PotCardChampionship)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  img {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }

  .pot-bet-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
`;

export const PotCardPriceLabel = styled(PotCardBetTitle)``;

export const PotCardLabel = styled(PotCardBetTitle)`
  color: #ffffff;
`;

export const PotCardTime = styled(PotCardChampionship)`
  font-size: 1rem;

  @media (max-width: 500px) {
    font-size: 0.875rem;
  }
`;

export const PotCardBetPrice = styled(PotCardChampionship)`
  font-size: 1rem;

  @media (max-width: 500px) {
    font-size: 0.875rem;
  }
`;

export const PotCardButton = styled.button`
  width: auto;
  height: auto;
  border: none;
  outline: none;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ff8e00;

  img {
    margin-left: 10px;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }
`;
