import { PixDataType } from '../../typescript/pix-withdraw';
import { validateCPF } from '../../utils/types';

export const validateEmptyField = (data: PixDataType) => {
  const entries = Object.entries(data);

  for (const ar of entries) {
    if (ar[1] === '' || ar[1] <= 0 || ar[1] === null) {
      return ar[0];
    }
  }
};

export const validateEachField = (data: PixDataType) => {
  if (data.pixType === 3) {
    if (data.pixKey.length !== 14 && data.pixKey.length !== 18) {
      return { field: 'pixKey', msg: 'CPF ou CNPJ inválido' };
    }
  }

  if (data.fullName.length < 5) {
    return { field: 'fullName', msg: 'Nome completo muito curto' };
  }

  if (data.cpf.length !== 14 || validateCPF(data.cpf)) {
    return { field: 'cpf', msg: 'CPF invalido' };
  }

  return { field: null, msg: '' };
};
