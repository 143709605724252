import { ModalWarningSameContainer, ModalWarningSamePredictOverlay } from './styles';
import { MdClose } from 'react-icons/md';
import { ReactComponent as QuestionMark } from '../../utils/icons/icon-question.svg';
import Button from '../Button/Button';

interface ModalWarningSamePredictProps {
    handleCancel: ()=> void
    handleConfirm: ()=> void
}


const ModalWarningSamePredict = ({ handleCancel, handleConfirm  }: ModalWarningSamePredictProps) => {
  return (
    <ModalWarningSamePredictOverlay>
      <ModalWarningSameContainer>
        <button className="btn-close-modal" onClick={handleCancel}>
          <MdClose size={18} />
        </button>
        <QuestionMark />
        <p className="text-warning">Você já deu um palpite exatamente igual, deseja repetir?</p>
        <div className="btn-group">
          <Button hierarchy="secondary" title="Cancelar" moreClasses='h-[50px] text-white  border-white' label="Cancelar" onClick={handleCancel} />
          <Button hierarchy="primary"  moreClasses='h-[50px]' title="Continuar" label="Continuar" onClick={handleConfirm} />
        </div>
      </ModalWarningSameContainer>
    </ModalWarningSamePredictOverlay>
  );
};

export default ModalWarningSamePredict;
