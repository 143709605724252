import { TextField } from '@mui/material';
import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { useUserAuth } from '../../hooks/user-auth';
import { createGroup } from '../../service/group/group';
import { RootState } from '../../store';
import { ReactComponent as ImageIcon } from '../../utils/icons/image.svg';
import { normalizeName } from '../../utils/normalize-name';
import { ModalSessionExpired } from '../ModalCoins';
import { ErrorAlert, SuccessAlert } from './../BasicAlerts';
import {
  CreateGroupButton,
  CreateGroupContainer,
  CreateGroupDropZone,
  CreateGroupHeader,
  CreateGroupHeaderAvatar,
  CreateGroupInput,
  CreateGroupTitle,
  RulesCreateGroup,
  RulesGroupTitle,
} from './styles';

interface ErrorMsgType {
  field: 'name' | 'avatar' | null;
  msg: string;
}

interface GroupType {
  name: string;
  avatar: File | null;
}

interface CreateGroupProps {
  handleBack?: () => void;
  hasBtnBack?: boolean;
  handleCloseModal?: () => void;
  rulesGroup: boolean;
  isPage?: boolean;
}

const errorMsgDefaultState = {
  field: null,
  msg: '',
};

const CreateGroup = ({
  handleBack = () => null,
  hasBtnBack,
  rulesGroup,
  isPage = false,
  handleCloseModal = () => null,
}: CreateGroupProps) => {
  const userInfo = useSelector((state: RootState) => state.user);
  const logged = userInfo.token;
  const [errorMsg, setErrorMsg] = useState<ErrorMsgType>(errorMsgDefaultState);
  const [previewAvatar, setPreviewAvatar] = useState('');
  const [group, setGroup] = useState<GroupType>({
    name: '',
    avatar: null,
  });
  const [loading, setLoading] = useState(false);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const [responseStatus, setResponseStatus] = useState<'success' | 'error' | 'session' | ''>('');
  const navigate = useNavigate();
  const { isUserSessionFinished } = useUserAuth({});
  const hidePageContent = isMobile && responseStatus === 'session';

  const onDrop = (acceptedFiles: File[]) => {
    setErrorMsg(errorMsgDefaultState);

    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];

    if (file) {
      setGroup({
        ...group,
        avatar: file,
      });
      setPreviewAvatar(URL.createObjectURL(file));
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const error = fileRejections[0].errors[0];

    if (error.code === 'file-too-large') {
      setErrorMsg({
        field: 'avatar',
        msg: 'Arquivo muito grande, tamanho dever ser no max: 5MB',
      });
    }

    if (error.code === 'file-invalid-type') {
      setErrorMsg({
        field: 'avatar',
        msg: 'Tipo do Arquivo invalido, tipos de arquivos validos: .jpg/.jpeg/.png',
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 5000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDropRejected,
  });

  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    if (target.name === 'name') {
      if (target.value.length >= 25) {
        return;
      }
    }

    setGroup({
      ...group,
      [target.name]: target.value,
    });

    setErrorMsg(errorMsgDefaultState);
  };

  // 1. 4 caracteres minimum
  // 2. allow 2 letters minimum
  // 3. allow numbers
  // 4. space between words
  // 5. first and last is letter or number
  const regexGroup = /^(?=(?:\D*\d){0,2}\D*$)[a-zA-Z\d][a-zA-Z\d ]{2,}[a-zA-Z\d]$/;

  const validateGroupData = (): boolean => {
    if (!regexGroup.test(group.name)) {
      setErrorMsg({
        field: 'name',
        msg: 'Nome do grupo é obrigatório',
      });

      return false;
    }

    if (group.avatar === null) {
      setErrorMsg({
        field: 'avatar',
        msg: 'Avatar do grupo é obrigatório',
      });

      return false;
    }

    return true;
  };

  const handleSaveGroup = async () => {
    if (validateGroupData()) {
      setLoading(true);

      if (await isUserSessionFinished()) {
        setResponseStatus('session');
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', group.avatar as File);
      formData.append('name', group.name);
      formData.append('user_id', userInfo.userInfo.id + '');
      formData.append('balance', '0');
      formData.append('percent', '10');

      const response = await createGroup(formData);

      setLoading(false);

      if (response.status === 200) {
        const name = response.data?.data?.name || 'group-not-found-404';

        if (isPage) {
          navigate(`/${normalizeName(name)}`);
        } else {
          handleCloseModal();
          navigate(`/${normalizeName(name)}`);
        }
      }

      const errorResponse = response.response.data;

      if (errorResponse.status === 400) {
        setErrorMsg({ field: 'name', msg: errorResponse.data });
      }

      if (errorResponse.status === 401) {
        navigate('/');
      }
    }
    setLoading(false);
  };

  const closeModal = () => {
    if (isPage) {
      return handleBack();
    }

    return handleCloseModal();
  };

  return (
    <>
      {responseStatus === 'error' && (
        <ErrorAlert
          onClose={() => setResponseStatus('')}
          message="Erro ao criar grupo, tente novamente"
          title="Falhou na criação do grupo"
        />
      )}

      <ModalSessionExpired
        handleClose={() => setResponseStatus('')}
        handleConfirm={() => {
          setResponseStatus('');
          window.location.reload();
          navigate('/login');
        }}
        open={responseStatus === 'session'}
      />

      {responseStatus === 'success' && (
        <SuccessAlert message="O grupo foi criado com sucesso" title="Grupo criado" onClose={handleBack} />
      )}

      {!hidePageContent && (
        <CreateGroupContainer aria-disabled>
          {rulesGroup && (
            <RulesCreateGroup>
              <RulesGroupTitle>Mostre quem manda</RulesGroupTitle>
              <p>
                <span>Sabe aquele amigo que adora contar vantagem?</span>
                <span> Chegou a hora de pôr as cartas na mesa! Além de fazer uma graninha,</span>
                <span> cada grupo tem seu próprio ranking. Você fica sabendo quem joga mais</span>
                <span>e o mais importante - quem é melhor de palpite.</span>
                <span> E aí, você confia no seu jogo?</span>
              </p>
            </RulesCreateGroup>
          )}
          {!rulesGroup && <CreateGroupTitle>Criar um grupo</CreateGroupTitle>}
          <CreateGroupHeader>
            <CreateGroupHeaderAvatar>
              {previewAvatar ? <img src={previewAvatar} alt="" /> : <ImageIcon />}
            </CreateGroupHeaderAvatar>
            <input {...getInputProps()} />
            <CreateGroupDropZone {...getRootProps()}>Fazer upload de avatar</CreateGroupDropZone>
          </CreateGroupHeader>
          <p className="avatar-error-msg">{errorMsg.field === 'avatar' && errorMsg.msg}</p>

          <CreateGroupInput>
            <TextField
              name="name"
              value={group.name}
              label="Nome do grupo"
              placeholder="Nome do grupo"
              className="ipt"
              error={errorMsg.field === 'name'}
              helperText={errorMsg.field === 'name' ? errorMsg.msg : ''}
              onChange={handleChange}
            />
          </CreateGroupInput>
          <div className="button-div-group">
            {hasBtnBack && (
              <CreateGroupButton disabled={loading} isOutlined onClick={closeModal}>
                Voltar
              </CreateGroupButton>
            )}
            {!logged && (
              <CreateGroupButton disabled={loading} onClick={() => navigate('/login')}>
                {loading ? 'criando...' : 'Criar Grupo'}
              </CreateGroupButton>
            )}

            {logged && (
              <CreateGroupButton disabled={loading} onClick={handleSaveGroup}>
                {loading ? 'criando...' : 'Criar Grupo'}
              </CreateGroupButton>
            )}
          </div>
        </CreateGroupContainer>
      )}
    </>
  );
};

export default CreateGroup;
