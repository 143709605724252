import { useEffect, useState } from 'react';
import { Match } from '../../typescript/match';
import Button from '../Button/Button';
import { SelectorTeam } from './SelectorTeam';

interface IMatchSelectorProps {
  changeValue: (matchId: number, team: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  item: Match;
  isTargetPot?: boolean;
  isLeadingAtTheEnd?: boolean;
}

const MatchSelector = ({
  item,
  changeValue,
  defaultValue = '',
  isTargetPot = false,
  isLeadingAtTheEnd = false,
  disabled = false,
}: IMatchSelectorProps) => {
  const [selected, setSelected] = useState<string>('');

  const select = (value: string) => {
    if (disabled) return;
    if (selected === value) {
      setSelected('');
      changeValue(item.id, value);
    } else {
      setSelected(value);
    }

    changeValue(item.id, value);
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const inputsStyle =
    'p-2 w-[284px] focus:outline-none focus:ring-0 focus:border-[#FF8E00] focus:border-transparent h-[39px] max-w-[47px] bg-transparent h-[56px] text-white text-[20px] font-[400] w-full p-2 border-2 border-[#4B4C66] rounded-lg';

  return (
    <div className="w-full flex gap-2 sm:gap-4 md:gap-2 lg:gap-4 xl:gap-2 2xl:gap-4">
      <SelectorTeam
        disabled={disabled || isTargetPot}
        handleSelect={() => (!isTargetPot ? select(item.team1) : '')}
        name={item.team1}
        isLeftImage={isLeadingAtTheEnd}
        selected={selected === item.team1}
        teamImage={item.imageTeam1?.image}
        fullWidth
      />

      {isTargetPot && (
        <>
          <input min="0" style={{ textAlign: 'center' }} className={inputsStyle} />
          <input min="0" style={{ textAlign: 'center' }} className={inputsStyle} />
        </>
      )}

      {item.draw && !isTargetPot && !isLeadingAtTheEnd && (
        <Button
          style={{
            background: disabled && selected !== item.team1 && selected !== item.team2 ? '#4B4C66' : '',
            border: '2px solid #4B4C66',
          }}
          moreClasses="h-100 text-white text-[14px] font-[400] rounded-lg min-h-[56px] w-full sm:w-fit"
          onClick={() => select('draw')}
          hierarchy={selected === 'draw' ? 'primary' : 'secondary'}
          label="Empate"
          size="sm"
        />
      )}

      <SelectorTeam
        handleSelect={() => (!isTargetPot ? select(item.team2) : '')}
        name={item.team2}
        isLeftImage
        disabled={disabled || isTargetPot}
        selected={selected === item.team2}
        teamImage={item.imageTeam2?.image}
        fullWidth
      />
    </div>
  );
};

export default MatchSelector;
