export const cmsPagesEnum = {
  MAIN_BANNER_TYPEID: 1,
  PRIVACY_BANNER_TYPEID: 2,
  ABOUT_US_TYPEID: 3,
  LOGIN_PAGE_TYPEID: 4,
  POT_BANNER_TYPEID: 5,
  BANNER_CATEGORY_TYPEID: 6,
  RULES_CONTENT_TYPEID: 7,
  ABOUT_PAGE_TYPEID: 8,
  GAINS_PAGE_TYPEID: 9,
  FAQ_PAGE_TYPEID: 10,
  RESPONSIBLE_GAME_PAGE_TYPEID: 11,
  TERMNS_AND_CONDITIONS_PAGE_TYPEID: 12,
  POLICY_AND_PRIVACY_PAGE_TYPEID: 13,
  ICONE_HOT_POT: 14,
  MAIN_BANNER_MINI_TYPEID: 15,
  GROUP_BANNER_TYPEID: 17,
};
