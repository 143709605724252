import { createContext, Dispatch, SetStateAction, ReactNode, useState, useEffect } from 'react';

interface ChildrenProps {
  children: ReactNode;
}

interface Context {
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

const initialContext: Context = {
  openMenu: false,
  setOpenMenu: () => false,
};

export const NavBarMenuContext = createContext<Context>(initialContext);

export const NavBarMenuProvider = ({ children }: ChildrenProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return <NavBarMenuContext.Provider value={{ openMenu, setOpenMenu }}>{children}</NavBarMenuContext.Provider>;
};
