export const pixTypeEnum = [
  {
    value: 1,
    label: 'Email'
  },
  {
    value: 2,
    label: 'Telefone'
  },
  {
    value: 3,
    label: 'CPF/CNPJ'
  },
  {
    value: 4,
    label: 'Aleatório'
  }
];
