import { useState } from 'react';
import { BallonCoinsPot } from '../../components/Ballon';
import { PotBetCardData } from '../../components/Pot/PotBetCardData';
import { potHandleIconIdentifier, PotHandleIconIdentifierType } from '../../components/PotCard/helpers';
import {
  PotBetCoins,
  PotCardBetTitle,
  PotCardChampionship,
  PotCardPriceLabel,
  PotQtyPeopleBet,
} from '../../components/PotCard/styles';
import { Game } from '../../typescript/match';
import { CoinsIcon } from '../../utils/icons/CoinsIcon';
import { ReactComponent as ExpandMoreIcon } from '../../utils/icons/expand_more.svg';
import { ReactComponent as InfoIcon } from '../../utils/icons/info2.svg';
import { formatDay, formatValue } from '../../utils/types';

import { ItemPotBetContainer, ItemPotBetHeaderInfoContainer } from './styles';

interface ItemPotBetProps {
  pot: Game;
  index: number;
}

export const ItemPotBet = ({ pot, index }: ItemPotBetProps) => {
  const [infoBallonCoins, setInfoBallonCoins] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { valueAccumulated, minimumValue, createdAt, sameBets, minimumBet } = pot;

  const { colors, icon, legend } = potHandleIconIdentifier(pot) as PotHandleIconIdentifierType;

  const quantityPeoplesSameBet = sameBets ?? 0;
  const normalizeQuantityPeoplesSameBet = quantityPeoplesSameBet.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
  const isMultiplePeopleSameBet = quantityPeoplesSameBet > 1;

  return (
    <ItemPotBetContainer>
      <ItemPotBetHeaderInfoContainer>
        <div className="flex flex-row justify-between gap-y-2 gap-x-10 mb-2">
          <div>
            <PotCardChampionship>
              <h2>Palpite {index}</h2>
            </PotCardChampionship>
            <PotCardBetTitle>Feito em {formatDay(createdAt)}</PotCardBetTitle>
          </div>

          {!!isMultiplePeopleSameBet && (
            <PotQtyPeopleBet>
              <p>
                <b>
                  {normalizeQuantityPeoplesSameBet} {isMultiplePeopleSameBet ? 'palpites ' : 'palpite '}
                </b>{' '}
                {isMultiplePeopleSameBet ? 'iguais' : 'igual'} ao seu
              </p>
            </PotQtyPeopleBet>
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-between">
          <PotBetCoins>
            <CoinsIcon bg={colors.coin} color={'#202030'} />

            <h4 className={legend === 'Pot Cancelado' ? 'line-through' : ''}>
              {minimumBet} {minimumBet > 1 ? 'fichas' : 'ficha'}
            </h4>

            <button className="relative ml-2 cursor-pointer" onClick={() => setInfoBallonCoins(!infoBallonCoins)}>
              <InfoIcon />
              {infoBallonCoins && <BallonCoinsPot />}
            </button>
          </PotBetCoins>

          <div className="info-row flex flex-col items-end justify-center">
            <div className="ml-5 flex flex-row items-center gap-2">
              R$ {valueAccumulated >= minimumValue ? formatValue(valueAccumulated) : formatValue(minimumValue)}{' '}
              <div className="pot-bet-icon">{icon}</div>
            </div>
            <PotCardPriceLabel>Prêmio atual</PotCardPriceLabel>
          </div>

          <div
            className={`flex items-center justify-center sm:ml-5 pointer transition-all transform ${
              expanded && 'rotate-180'
            }`}
          >
            <ExpandMoreIcon onClick={() => setExpanded(!expanded)} />
          </div>
        </div>
      </ItemPotBetHeaderInfoContainer>

      {expanded && (
        <div className="w-full">
          <PotBetCardData potInfo={pot} isAlreadyBet={true} fullWidth />
        </div>
      )}
    </ItemPotBetContainer>
  );
};
