import styled from '@emotion/styled';

interface ModalPixContainerProps {
  open?: boolean;
}

export const ModalPixOverlayContainer = styled.div<ModalPixContainerProps>`
  width: 100%;
  height: ${({ open }) => (open ? '100%' : '300px')};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 60;
  left: 0;
  max-width: ${({ open }) => (open ? '100%' : '400px')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  background-color: #00000090;

  @media (max-width: 600px) {
    position: ${({ open }) => (open ? 'static' : 'fixed')};
    background-color: transparent;
    height: auto;
  }
`;

export const ModalPixContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 50 / var(--tw-bg-opacity));
  max-width: 574px;
  position: relative;
  border-radius: 20px;

  @media (max-width: 600px) {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
    border-radius: 0;
    background-color: transparent;

    .ipt {
      margin-bottom: 10px;
    }

    .ipt-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
