import ArrowRight from '../../utils/icons/arrowR.svg';
import { ReactComponent as InfoIcon } from '../../utils/icons/info2.svg';
import {
  CardBottomInformation,
  CardSampleForStack,
  Divider,
  PotBetCoins,
  PotBetStatus,
  PotCardBetTitle,
  PotCardButton,
  PotCardChampionship,
  PotCardContainer,
  PotCardContainerWrapper,
  PotCardHeaderInfo,
  PotCardPrice,
  PotCardPriceLabel,
  PotCardTopInfo,
  PotQtyPeopleBet,
} from './styles';

import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Game } from '../../typescript/match';
import { formatValue } from '../../utils/types';

import { Skeleton } from '@mui/material';
import { useUserContext } from '../../common/context/UserContext';
import { getPotBetById } from '../../service/pots/pots.service';
import { CoinsIcon } from '../../utils/icons/CoinsIcon';
import { sportOptions } from '../../utils/sportOptions';
import { BallonCoinsPot } from '../Ballon';
import { PotHandleIconIdentifierType, potHandleIconIdentifier, potIdentifier } from './helpers';

interface PotCardProps {
  pot: Game;
}

const potIdentifiedStyleInitial = {
  icon: <></>,
  legend: '',
  colors: {
    border: '',
    coin: '',
    button: {
      bg: '',
    },
  },
};

const PotCard = ({ pot }: any) => {

  const [infoBallonCoins, setInfoBallonCoins] = useState(false);
  const [isJustOne, setIsJustOne] = useState<boolean | undefined>(undefined);
  const [potIdentifiedStyle, setPotIdentifiedStyle] = useState<PotHandleIconIdentifierType>(potIdentifiedStyleInitial);
  const { userLogged } = useUserContext();
  const { championship, name, valueAccumulated, sameBets, minimumValue, minimumBet, sports } = pot;

  const navigate = useNavigate();

  const goToPotPage = () => {
    navigate(`/pot-bet/${userLogged?.login}/${pot.bets[0]}`);
  };

  const sport = sportOptions.find((sport) => sport.name === sports);

  const quantityPeoplesSameBet = sameBets ?? 0;
  const isMultiplePeopleSameBet = quantityPeoplesSameBet > 1;
  const normalizeQuantityPeoplesSameBet = quantityPeoplesSameBet.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
  const showAccumulatedOrMinimalValue =
    valueAccumulated >= minimumValue ? formatValue(valueAccumulated) : formatValue(minimumValue);

  useMemo(async () => {
    if (!pot.bets[0]) {
      return undefined;
    }

    const response = await getPotBetById(Number(pot.bets[0]));

    const hasBetCorrect = response.data.betsSamePot.filter(
      (item: any) => potHandleIconIdentifier(item)?.legend === 'Você ganhou'
    );

    if (hasBetCorrect.length) {
      setPotIdentifiedStyle(potIdentifier(hasBetCorrect[0]));
    }

    if (!hasBetCorrect.length) {
      setPotIdentifiedStyle(potIdentifier(response.data.bet));
    }

    if (response.data !== 'Não foi encontrado nenhum POT!') {
      const pots = response.data.betsSamePot;

      const allData = [...pots.map((item: Game) => ({ ...item, ...item.anotherBet }))];

      if (allData.length > 1) {
        return setIsJustOne(false);
      }
      return setIsJustOne(true);
    }
  }, []);

  return (
    <PotCardContainerWrapper>
      <div className="relative w-full flex min-h-[180px]">
        {isJustOne === undefined ? (
          <Skeleton
            variant="rounded"
            animation={'pulse'}
            style={{
              background: '#4B4C66',
              width: '100%',
              borderRadius: '20px',
              height: '250px',
            }}
          />
        ) : (
          <>
            <PotCardContainer
              index={0}
              gradient={potIdentifiedStyle.colors.coin}
              border={potIdentifiedStyle.colors.border}
            >
              <PotCardHeaderInfo>
                <PotCardTopInfo>
                  <div className="">
                    <PotCardChampionship>
                      {!!sport && <sport.icon className="fill-text-white w-[24px] h-[24px]" />}
                      <h2 className="truncate mb-2">{championship}</h2>
                    </PotCardChampionship>

                    <PotCardBetTitle>{name}</PotCardBetTitle>
                  </div>

                  <div className="flex flex-col gap-2 items-end">
                    <PotCardPrice>
                      R$ {showAccumulatedOrMinimalValue} <div className="pot-bet-icon">{potIdentifiedStyle.icon}</div>
                    </PotCardPrice>

                    <PotCardPriceLabel>Prêmio atual</PotCardPriceLabel>
                  </div>
                </PotCardTopInfo>
              </PotCardHeaderInfo>

              {isJustOne && (
                <div className="grid grid-cols-2 mt-3 w-full">
                  <PotBetCoins>
                    <CoinsIcon bg={potIdentifiedStyle.colors.coin} color={'#202030'} />

                    <h4 className={potIdentifiedStyle.legend === 'Pot Cancelado' ? 'line-through' : ''}>
                      {minimumBet} {minimumBet > 1 ? 'fichas' : 'ficha'}
                    </h4>

                    <button
                      className="ml-2 relative flex items-center"
                      onClick={() => setInfoBallonCoins((prevState) => !prevState)}
                    >
                      <InfoIcon />
                      {infoBallonCoins && <BallonCoinsPot />}
                    </button>
                  </PotBetCoins>

                  {!!quantityPeoplesSameBet && (
                    <div className="flex items-center justify-end">
                      <PotQtyPeopleBet>
                        <p className="text-4xl">
                          <strong>
                            {normalizeQuantityPeoplesSameBet} {isMultiplePeopleSameBet ? 'palpites' : 'palpite'}
                          </strong>{' '}
                          {isMultiplePeopleSameBet ? 'iguais' : 'igual'} ao seu
                        </p>
                      </PotQtyPeopleBet>
                    </div>
                  )}
                </div>
              )}

              <Divider gradient={potIdentifiedStyle.colors.border} />
              <CardBottomInformation>
                <PotBetStatus bgColor={potIdentifiedStyle.colors.button.bg}>{potIdentifiedStyle.legend}</PotBetStatus>

                <PotCardButton onClick={goToPotPage}>
                  VER DETALHES <img src={ArrowRight} alt="" />
                </PotCardButton>
              </CardBottomInformation>
            </PotCardContainer>

            {!isJustOne && (
              <>
                <CardSampleForStack
                  valueOfWidth={16}
                  index={2}
                  gradient={potIdentifiedStyle.colors.coin}
                  border={potIdentifiedStyle.colors.border}
                />
                <CardSampleForStack
                  valueOfWidth={32}
                  index={1}
                  gradient={potIdentifiedStyle.colors.coin}
                  border={potIdentifiedStyle.colors.border}
                />
              </>
            )}
          </>
        )}
      </div>
    </PotCardContainerWrapper>
  );
};

export default PotCard;
