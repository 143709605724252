import PageContainer from '../../layouts/PageContainer/PageContainer';
import {
  AboutGroupBanner,
  AboutGroupContainer,
  AboutGroupCreateGroupSection,
  AboutGroupCreateGroupSectionTextContainer,
  BannerButtonCreate,
  CreateGroupContainer,
} from './styles';

import AboutInstructions from '../../components/AboutInstructions';
import CreateGroup from '../../components/CreateGroup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getWindowSize } from '../../utils/windowSizeFunction';
import { getGrouByUser } from '../../service/group/group';
import { UserGroup } from '../../typescript/group';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import GroupInfo from '../../components/GroupInfo';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { getCmsHtml } from '../../service/cms/cms.service';
import { HomeBanner } from '../../components/Home/styles';

const AboutGroupPage = () => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [userGroup, setUserGroup] = useState<UserGroup | null>(null);
  const [bannerContent, setbannerContent] = useState('');

  const user = useSelector((state: RootState) => state.user);

  const handleGetUserGroup = async (): Promise<void> => {
    const response = await getGrouByUser(user.userInfo.id);

    if (response.status === 200) {
      setUserGroup(response.data);
    }
  };

  const { GROUP_BANNER_TYPEID } = cmsPagesEnum;

  useEffect(() => {
    getCmsHtml(GROUP_BANNER_TYPEID).then((res) => {
      setbannerContent(res);
    });
  }, []);

  useEffect(() => {
    handleGetUserGroup();
  }, []);
  return (
    <PageContainer>
      <AboutGroupContainer>
        <HomeBanner>
          <div
            className="banner-category"
            dangerouslySetInnerHTML={{
              __html: bannerContent,
            }}
          />
        </HomeBanner>
        <AboutInstructions hideButton={userGroup !== null} />

        <AboutGroupCreateGroupSection id="create-group-section">
          <AboutGroupCreateGroupSectionTextContainer>
            <h1>Mostre quem manda</h1>
            <p>
              <span>Sabe aquele amigo que adora contar vantagem?</span>
              <span>
                Chegou a hora de pôr as cartas na mesa! Além de fazer uma graninha, cada grupo tem seu próprio ranking.
                Você fica sabendo quem joga mais e o mais importante - quem é melhor de palpite.
              </span>
              <span>E aí, você confia no seu jogo?</span>
            </p>
            <div>
              <h1 className="text-legend">5% do valor das fichas dos seus amigos e 10% do valor do palpite ganhador!</h1>
              <p className="text-legend text-legend-small">Consulte as regras do grupo</p>
            </div>
          </AboutGroupCreateGroupSectionTextContainer>

          {!userGroup && (
            <CreateGroupContainer>
              <CreateGroup
                rulesGroup={false}
                hasBtnBack={windowSize.innerWidth < 1024}
                handleBack={() => navigate(-1)}
              />
            </CreateGroupContainer>
          )}

          {!!userGroup && (
            <CreateGroupContainer>
              <GroupInfo group={userGroup as UserGroup} />
            </CreateGroupContainer>
          )}
        </AboutGroupCreateGroupSection>
      </AboutGroupContainer>
    </PageContainer>
  );
};

export default AboutGroupPage;
