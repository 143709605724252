import { ReactComponent as Checked } from '../../utils/icons/checked.svg';
import { SimpleFeedbackTextContainer, BodyText } from './styles';

interface SimpleFeedbackTextProps {
  text: string;
  style?: React.CSSProperties | undefined
  isError?:boolean
}

export const SimpleFeedbackText = ({ text , style, isError}: SimpleFeedbackTextProps) => {
  return (
    <SimpleFeedbackTextContainer style={style} className="simple-feedback-text">
      <div className="content-container">
       {isError ? <div/> :<Checked />}
        <BodyText isError={isError}>{text}</BodyText>
      </div>
    </SimpleFeedbackTextContainer>
  );
};
