import Countdown from 'react-countdown';
import moment from "moment";
import 'moment-timezone';
import {getCurrentDate, getCurrentTimestamp} from "../../../utils/timestampDisplay";

interface DateProps {
  finalAt: Date;
  stylePot?: boolean;
}

const formatTimeLeft = ({ days, hours, minutes }: { days: number; hours: number; minutes: number }) => {
  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days} dia${days > 1 ? 's' : ''} `;
  }

  if (hours > 0) {
    formattedTime += `${hours} hora${hours > 1 ? 's' : ''} `;
  }

  if (minutes > 0 && days === 0) {
    formattedTime += `${minutes} minuto${minutes > 1 ? 's' : ''}`;
  }

  return formattedTime;
};

const timer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <div>
        <p className="text-xs text-right">Encerrado</p>
      </div>
    );
  } else {
    return (
      <div>
        <p className="text-xs text-right">Encerra em</p>
        <p className="text-sm font-medium">{formatTimeLeft({ days, hours, minutes })}</p>
      </div>
    );
  }
};

const timer2 = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <div>
        <p className="text-xs text-left text-text-gray">Encerrado</p>
      </div>
    );
  } else {
    return (
      <div>
        <p className="timer-label text-[14px] font-normal text-left leading-[18px] ">Encerra em:</p>
        <p className="timer-value text-[16px] font-semibold leading-[17px]">
          {formatTimeLeft({ days, hours, minutes })}
        </p>
      </div>
    );
  }
};

const timer3 = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <div>
        <p className="text-xs text-left text-text-gray">Encerrado</p>
      </div>
    );
  } else {
    return (
      <div>
        <p className="timer-label text-xs font-normal text-left leading-[15px]">Encerra em:</p>
        <p className="timer-value text-xs font-semibold leading-[15px]">{formatTimeLeft({ days, hours, minutes })}</p>
      </div>
    );
  }
};

export const CountdownTimer = ({ finalAt }: DateProps) => <Countdown date={finalAt} renderer={timer} />;

export const CountdownTimer2 = ({ finalAt, stylePot }: DateProps) => <Countdown date={finalAt} renderer={timer2} />;

export const CountdownTimer3 = ({ finalAt, stylePot }: DateProps) => <Countdown date={finalAt} renderer={timer3} />;
