import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Button from '../Button/Button';
import { useNavigate } from 'react-router';
import { ReactComponent as BoxCoins } from '../../utils/icons/boxCoins.svg';
import { ReactComponent as Checked } from '../../utils/icons/checked.svg';
import { ReactComponent as Info } from '../../utils/icons/error-info.svg';

import { useGetWindowSize } from '../../hooks/useGetWindowSize';

interface IModalAlertProps {
  openAlert: boolean;
  setOpenAlert: Function;
  title1?: string;
  title2?: string;
  text1?: string;
  text2?: string;
  title3?: string;
  modalIcon?: 'error' | 'success' | 'card-info';
  btnText?: string;
  handleConfirm?: ()=> void
}

const ModalCoins = ({
  setOpenAlert,
  openAlert,
  modalIcon = 'error',
  text1,
  text2,
  title1,
  title2,
  btnText,
  title3,
  handleConfirm = undefined
}: IModalAlertProps) => {
  const navigate = useNavigate();
  const windowSize = useGetWindowSize();

  function sendNavigate() {
    if(handleConfirm){
      handleConfirm()
    }else{
       navigate('/coins');
    setOpenAlert(false);
    }
   
  }

  const isMobile = windowSize.width <= 600;

  return (
    <div
      className={
        openAlert
          ? `block w-[100%]  h-[100vh]  ${isMobile ? '' : 'bg-[#00000090]'}  z-9900 ${
              isMobile ? 'static' : 'fixed top-0'
            } flex flex-col justify-center items-center z-[30]   ${isMobile ? '' : 'left-0'}`
          : `hidden`
      }
    >
      <div
        className={`w-full flex flex-col justify-center items-center ${
          isMobile ? '' : 'bg-[#222232]'
        }  max-w-[560px] h-[389px] relative rounded-[20px]`}
      >
        {!isMobile && (
          <button className="text-white p-2 absolute right-3 top-2" onClick={() => setOpenAlert(false)}>
            <MdClose size={18} />
          </button>
        )}

        <div>{modalIcon === 'error' && <BoxCoins />}</div>
        <div>{modalIcon === 'success' && <Checked />}</div>
        <div>{modalIcon === 'card-info' && <Info />}</div>

        <div>
          <h2 className="mt-[16px] text-white text-center text-[24px] font-semibold">
            {title1 || ' Você não tem fichas suficientes'}
          </h2>
          {title2 && <h2 className="text-white text-center text-[24px] font-semibold">{title2}</h2>}
          {title3 && <h2 className="text-white text-center text-[24px] font-semibold">{title3}</h2>}

        </div>
        <div className="mt-[8px] w-full flex flex-col items-center justify-center ">
          <p className="text-white text-[16px] text-center font-light w-[80%]">
            {text1 || ' Deseja adicionar fichas à sua carteira para '}
          </p>
          <p className=" text-white text-center text-[16px] font-light w-[80%] ">{text2 || 'Continue jogando'}</p>
        </div>
        <div className={`mt-[16px] flex ${isMobile && 'flex-col items-center'}  justify-center w-full`}>
          <Button
            hierarchy="primary"
            label={btnText || 'Comprar fichas'}
            size="md"
            moreClasses=" mt-5 mb-3 text-[15px] text-[#1C1C27]  font-bold w-[196px] h-[49px]"
            onClick={() => {
              if (modalIcon === 'success') {
                setOpenAlert(false);
              } else {
                sendNavigate();
              }
            }}
          />
          {isMobile && (
            <Button
              hierarchy="tertiary"
              label="voltar"
              size="md"
              moreClasses="mt-5 mb-3 text-[15px] text-[#FFFFFF]  font-bold w-[196px] h-[49px]"
              onClick={() => setOpenAlert(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const ModalPaymentInfo = ModalCoins;

interface ModalSessionExpiredProps {
  open: boolean;
   handleConfirm: ()=> void
   handleClose: ()=> void
}

export const ModalSessionExpired = ({open, handleConfirm, handleClose}: ModalSessionExpiredProps)=> {
  return (
    <ModalCoins
    openAlert={open}
    title1="Sessão Expirada"
    title2="A sua sessão expirou,"
    title3="faça o login novamente."
    text1=" "
    text2=" "
    btnText="login"
    modalIcon="card-info"
    handleConfirm={handleConfirm}
    setOpenAlert={handleClose}
  />
  )
}

export default ModalCoins;
