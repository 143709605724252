import PageContainer from '../../layouts/PageContainer/PageContainer';
import ModalCoins from '../../components/ModalCoins';
import { useNavigate } from 'react-router-dom';

const BetSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <ModalCoins
        openAlert={true}
        title1="Parabéns!"
        title2="Seu palpite foi feito com sucesso!"
        text1="Agora é só ficar de olho no resultado e comemorar."
        text2="Boa sorte!"
        btnText="Ok, entendi"
        modalIcon="success"
        setOpenAlert={(state: boolean) => navigate(-1)}
      />
    </PageContainer>
  );
};

export default BetSuccessPage;
