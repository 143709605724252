import { BallonArea, BallonAreaMoney, BallonContainer, BallonContainerMoney, BallonContainerPot } from './styles';

interface IBallonProps {
  openBallon?: boolean;
}

const BallonCoinsPot = ({ openBallon }: IBallonProps) => {
  return (
    <BallonContainerPot>
      <BallonArea>
        <p>Esse é o número de fichas necessárias para jogar nesse pot.</p>
      </BallonArea>
    </BallonContainerPot>
  );
};

const BallonCoins = ({ openBallon }: IBallonProps) => {
  return (
    <BallonContainer>
      <BallonArea>
        <p>Essas são suas fichas disponíveis para palpitar nos bolões Money Pot.</p>
      </BallonArea>
    </BallonContainer>
  );
};

const BallonMoney = ({ openBallon }: IBallonProps) => {
  return (
    <BallonContainerMoney>
      <BallonAreaMoney>
        <p>Esse é seu saldo em dinheiro. Você poderá usá-lo para saque ou na compra de novas fichas.</p>
      </BallonAreaMoney>
    </BallonContainerMoney>
  );
};

export { BallonCoins, BallonMoney, BallonCoinsPot };
