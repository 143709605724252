import { useEffect, useState } from 'react';
import { userTransactionsHistory } from '../../service/user/user.service';
import { formatValue } from '../../utils/types';
import { TableContainer, TransactionHistoryContainer, TransactionHistoryItem } from './styles';
import { ReactComponent as TrophIcon } from '../../utils/icons/troph-icon.svg';
import { ReactComponent as TransactionIcon } from '../../utils/icons/transaction-icon.svg';
import { Transaction } from '../../typescript/transaction';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

export const GroupTransaction = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setLoading] = useState(false);
  const totalItems = transactions.length;
  const [limit, setLimit] = useState(10);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;

  const filteredTransactions = isMobile ? transactions.slice(0, limit) : transactions


  const handlePagination = () => {
    if (limit === totalItems) {
      return null;
    }

    const sum = limit + 10;

    if (sum <= totalItems) {
      setLimit(sum);
    } else {
      setLimit(totalItems);
    }
  };

  const handleGetTransactions = async () => {
    setLoading(true);
    const transactions: Transaction[] = await userTransactionsHistory('user');

    const filteredTransaction = transactions.filter(
      (item) => item.status === 'Comissão do grupo' || item.status === 'Comissão do grupo por palpite'
    );

    setTransactions(filteredTransaction);
    setLoading(false);
  };

  useEffect(() => {
    handleGetTransactions();
  }, []); 

  return (
    <TransactionHistoryContainer>
      <h2>Histórico de transações</h2>
      <div className="table-container">
        {isLoading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {filteredTransactions.map((item) => {
              const isPotGain = item.status === 'Comissão do grupo por palpite';

              return (
                <TransactionHistoryItem key={item.id}>
                  <div className="item-inner-info">
                    <div className="transaction-icon-type">{isPotGain ? <TrophIcon /> : <TransactionIcon />}</div>
                    <p className="transaction-date">{item.dateTransaction.replace(/-/g, '/')} </p>
                    <span className="dot-s">•</span>
                    <p className={`transaction-description ${isPotGain ? 'text-bold' : ''}`}>{item.description}</p>
                  </div>

                  <p className={`transaction-value ${isPotGain ? 'text-bold' : ''}`}>
                    + R${formatValue(Number(item.value))}
                  </p>
                </TransactionHistoryItem>
              );
            })}
          </>
        )}
      </div>
      {limit !== totalItems && <>{isMobile && <button onClick={handlePagination}>Ver mais</button>}</>}
    </TransactionHistoryContainer>
  );
};
