import styled from '@emotion/styled';

export const CoinsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 22px;
  width: auto;
  height: 52px;
  background: #28293d;
  border-radius: 10px;
  column-gap: 20px;

  div {
    font-size: 20px;
    display: flex;
    align-items: center;

    p {
      margin-left: 6px;
    }
  }

  @media (max-width: 750px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 420px) {
    padding: 11px;
  }

  .dollar-sign-icon {
    color: #ff8e00;
    margin-right: 5px;

    @media (max-width: 350px) {
      margin-right: 0;
    }
  }

  .currency-l {
    font-weight: 300;
    margin-right: 5px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #ffffff;

    @media (max-width: 350px) {
      font-size: 12px;
    }
  }
`;

export const PotContainer = styled.div`
  position: relative;
`;

export const PotInfoContainer = styled.div`
  width: 100%;

  @media (min-width: 650px) {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const PrizeText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #c4c4c4;
  @media (max-width: 640px) {
    text-align: right;
    min-width: 95px;
  }
`;

export const PotAccumulatedPrice = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 25px;
  }

  img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
`;

export const PotChampionShipName = styled(PotAccumulatedPrice)`
  cursor: pointer;
  flex-wrap: wrap;
  word-wrap: break-word;
  line-height: 28px;
`;

interface ButtpCpyContainerProps {
  copied?: boolean;
}

export const ButtonCpyContainer = styled.div<ButtpCpyContainerProps>`
  color: ${({ copied }) => (copied ? ' #00DC6E' : ' #fff')};

  svg {
    fill: ${({ copied }) => (copied ? '#00DC6E' : '#fff')};
  }
`;
