import { BetsListPaginationContainer, PaginationButton, PaginationPageList, PaginationPageListItem } from './styles';
import ArrowRight from '../../utils/icons/arrowRight.svg';

interface PaginationProps {
  handlePrevPage: () => void;
  handleNextPage: () => void;
  pages: number[];
  activePage: number;
  handleActivePage: (page: number) => void;
}

const Pagination = ({ activePage, handleNextPage, handlePrevPage, pages, handleActivePage }: PaginationProps) => (
  <BetsListPaginationContainer>
    <PaginationButton onClick={handlePrevPage}>
      <img className="icon-left" src={ArrowRight} alt="prev" />
    </PaginationButton>
    <PaginationPageList>
      {pages.map((page) => (
        <PaginationPageListItem onClick={() => handleActivePage(page)} key={page} active={page === activePage}>
          {page}
        </PaginationPageListItem>
      ))}
    </PaginationPageList>
    <PaginationButton onClick={handleNextPage}>
      <img src={ArrowRight} alt="next" />
    </PaginationButton>
  </BetsListPaginationContainer>
);
export default Pagination;
