import styled from '@emotion/styled';
import {
  CreateGroupContainer,
  CreateGroupTitle,
  CreateGroupButton,
  CreateGroupInput,
  CreateGroupHeaderAvatar,
} from './../CreateGroup/styles';

export const ParticipateGroupContainer = styled(CreateGroupContainer)`
  height: 670px;

  @media (max-width: 750px) {
    padding: 15px;
    height: auto;
  }
`;

export const ParticipateGroupTitle = styled(CreateGroupTitle)`
  margin-bottom: 35px;
`;

export const ParticipateGroupSearchField = styled(CreateGroupInput)`
  .MuiInputBase-root,
  input {
    height: 40px;
  }
`;

export const ParticipateGroupList = styled.ul`
  height: 440px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  list-style: none;
  overflow-x: hidden;

  @media (max-width: 750px) {
    height: auto;
    overflow-x: hidden;
    padding-right: 0;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #4b4c66;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
`;

export const ParticipateGroupItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .info-s {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ParticipateGroupItemAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background: #4b4c66;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ParticipateGroupItemName = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
  margin-left: 8px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 433px) {
    width: 140px;
  }

  @media (max-width: 353px) {
    width: 100px;
  }

  @media (max-width: 314px) {
    width: 80px;
  }
`;

export const ParticipateGroupItemButton = styled.button`
  width: 97px;
  height: 34px;
  font-size: 14px;
  margin-top: 0px;
  color: #000;
  background: #ff8e00;
  border-radius: 100px;
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ParticipateGroupButton = styled(CreateGroupButton)`
  @media (max-width: 750px) {
    background: ${({ isOutlined }) => (isOutlined ? '#ff8e00' : '#ff8e00')};
    width: 70%;
    border: none;
    color: #1c1c27;
    margin-top: 50px;
  }
`;
