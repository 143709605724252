import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePotListing } from '../../hooks/usePotListing';
import { Game } from '../../typescript/match';
import { normalizePotUrl } from '../../utils/normalize-name';
import CardImg from '../CardImg/CardImg';
import { isToday, isBefore, parseISO, isSameDay } from 'date-fns';
import CryptoJS from "crypto-js";
import moment from 'moment';
import {getCurrentTimestamp} from "../../utils/timestampDisplay";

const HotPots = () => {
  const [potList, setPotList] = useState<Game[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const isHotListAvailable = !!potList && potList.length > 0;

  function gotToPot(potInfo: Game) {
    // const championShip = normalizePotUrl(potInfo.championship);
    // const title = normalizePotUrl(potInfo.name);
    // const newUrl = `/pot/${championShip}/${title}`;

    const potId = potInfo.id.toString(); // Convertendo para string

    const encryptionKey = 'secrethero99';
    const b64 = CryptoJS.AES.encrypt(potId, encryptionKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);

    const newUrl  = `/pot/${eHex}`;

    navigate(newUrl);
  }

  useMemo(async () => {
    setIsLoading(true);
    const result = await usePotListing();

    setPotList(result);
    setIsLoading(false);
  }, []);

  const showImageByPotType = (pot: Game): { img1: string; img2: string } => {
    if (pot.bettypes) {
      if (pot.bettypes.includes('onevsone') && pot.match[0] && pot.match[0].imageTeam1) {
        // Se sim, retornar um objeto com as imagens das equipes
        return {
          img1: pot.match[0].imageTeam1.image,
          img2: pot.match[0].imageTeam2.image
        };
      }
      if (pot.bettypes.includes('onevsmany')) {
        return { img1: '', img2: '' };
      }
      if (pot.bettypes.includes('pointspread')) {
        return {
          img1: pot.pointspread?.pointSpread[0].team1.image as string,
          img2: pot.pointspread?.pointSpread[0].team2.image as string,
        };
      }
      return { img1: '', img2: '' };
    }

    return { img1: pot.match[0].imageTeam1.image, img2: pot.match[0].imageTeam2.image };
  };

  if (!isHotListAvailable && !isLoading) return null;

  const today = getCurrentTimestamp();


  return (
    <div className="flex justify-between md:flex-nowrap flex-wrap">
      {isLoading ? (
        <div className="flex-1">
          <div className="hidden justify-between md:flex">
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                flex: 1,
                marginRight: '1em',
                borderRadius: '20px',
                marginBottom: '1.5rem',
                height: '225px',
              }}
            />
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                flex: 1,
                marginRight: '1em',
                borderRadius: '20px',
                marginBottom: '1.5rem',
                height: '225px',
              }}
            />
            <Skeleton
              variant="rounded"
              style={{ background: '#4B4C66', flex: 1, borderRadius: '20px', marginBottom: '1.5rem', height: '225px' }}
            />
          </div>
          <div className="flex flex-col md:hidden">
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                width: '100%',
                marginRight: '1em',
                borderRadius: '20px',
                marginBottom: '1.5rem',
                height: '120px',
              }}
            />
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                width: '100%',
                marginRight: '1em',
                borderRadius: '20px',
                marginBottom: '1.5rem',
                height: '120px',
              }}
            />
            <Skeleton
              variant="rounded"
              style={{
                background: '#4B4C66',
                width: '100%',
                borderRadius: '20px',
                marginBottom: '1.5rem',
                height: '120px',
              }}
            />
          </div>
        </div>
      ) : (
        potList
        .filter((pot) => {
          const potDate = moment(pot.dateInitialBet, 'YYYY-MM-DD');
          return potDate.valueOf() <= today;
        })
          .slice(0, 3)
          .map((pot) => (
            <CardImg
              key={pot.id}
              openMenu={() => gotToPot(pot)}
              colorSchema={'light'}
              championship={pot.championship}
              title={'Ganhe até:'}
              timeLeft={pot.dateFinalBet}
              image={showImageByPotType(pot).img1}
              imagetwo={showImageByPotType(pot).img2}
              accumulated={pot.valueAccumulated}
              value={pot.minimumValue}
              buttonText={'Jogue agora'}
            />
          ))
      )}
    </div>
  );
};

export default HotPots;
