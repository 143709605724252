import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineContentCopy } from 'react-icons/md';
import { RiFacebookBoxLine, RiTwitterLine, RiWhatsappLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { ErrorAlert } from '../../components/BasicAlerts';
import Button from '../../components/Button/Button';
import { useUserAuth } from '../../hooks/user-auth';
import PageContainer from '../../layouts/PageContainer/PageContainer';
import { updateGroup } from '../../service/group/group';
import { ReactComponent as CopyIcon } from '../../utils/icons/copy.svg';
import { ReactComponent as EditIcon } from '../../utils/icons/edit.svg';
import { ReactComponent as MoneyBag } from '../../utils/icons/moneyBag.svg';
import { normalizeName } from '../../utils/normalize-name';
import { shareLinkByClipBoard } from '../../utils/share';
import { formatValue } from '../../utils/types';
import { GroupTransaction } from './group-transaction';
import { GroupUsers } from './group-users';
import {
  AlignContainer,
  BalanceGroupContainer,
  ImageContainer,
  MainContainer,
  ModalShareContainer,
  NoMembersInfoContainer,
  ShareGroupContainer,
  ShareLinkContainer,
} from './styles';
import { useGetGroupUsers } from './use-get-group-users';

enum NetworkName {
  Whatsapp = 'Whatsapp',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

type GroupEditableType = {
  file: string;
  name: string;
};

const NETWORKS = [
  {
    title: NetworkName.Whatsapp,
    icon: RiWhatsappLine,
  },

  {
    title: NetworkName.Facebook,
    icon: RiFacebookBoxLine,
  },

  {
    title: NetworkName.Twitter,
    icon: RiTwitterLine,
  },
];

export default function Group({ groupDetails }: any) {
  const navigate = useNavigate();
  const { updateAppState } = useUserContext();
  const [message, setMessage] = useState('');
  const [statusRequest, setStatusRequest] = useState<'error' | 'success' | 'warn' | 'session' | ''>('');
  const [fileAvatar, setFileAvatar] = useState<File | null>(null);
  const [groupEditable, setGroupEditable] = useState<GroupEditableType>({
    file: groupDetails.file,
    name: groupDetails.name,
  });
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const { isUserSessionFinished } = useUserAuth({ isPrivatePage: true });
  const { groupUsers } = useGetGroupUsers({ groupId: Number(groupDetails.id) });
  const isGroupWithoutUsers = groupUsers.length === 0;
  const [invalidCharacter, setInvalidCharacter] = useState<number>(0);

  const handleCleanStatusRequest = () => {
    setStatusRequest('');
    setMessage('');
  };

  const handleUpdateGroup = async (name: string, file: File) => {
    if (await isUserSessionFinished()) {
      return;
    }

    if (groupDetails.id) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      const response = await updateGroup(groupDetails.id, formData);

      if (response.status === 400) {
        setStatusRequest('error');

        return setMessage(response.data);
      }

      return navigate(`/${normalizeName(name)}`);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];

    if (file) {
      return setFileAvatar(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 5000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  });

  async function sendNameUpdate() {
    //final sanitize to remove spaces and underscores at beginning and ends (typing needs it)
    const extremeSanitizationRegex =
      /^\s+|\s+$|^_+|_+$|[^A-Za-z0-9_\s]|(\s{2,})|(_)_+|(?=.*[A-Za-z].*[A-Za-z])()(?![A-Za-z0-9]+(?:_[A-Za-z0-9]+)*(?: [A-Za-z0-9]+)*$)/g;
    const updateName = groupEditable.name.replace(extremeSanitizationRegex, '');
    setGroupEditable({
      ...groupEditable,
      name: updateName,
    });
    const avatar = fileAvatar as File;

    // At least two letters in the string
    // Accepts capital or non-capital letters and numbers
    // Allows underscores
    // Only one space in between letters and numbers only
    // End of the string
    const regexGroup = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z0-9]+(?:_[A-Za-z0-9]+)*(?: [A-Za-z0-9]+)*$/;

    if (regexGroup.test(updateName)) {
      await handleUpdateGroup(updateName, avatar);
    } else {
      setGroupEditable({
        file: groupDetails.file,
        name: groupDetails.name,
      });
    }
    setIsEditing(false);
    updateAppState('group info updated');
  }

  const sharePot = () => {
    const url = groupDetails.invite;
    shareLinkByClipBoard(url);
    setLinkCopied(true);
  };

  const shareLinkToNetworks = ({ network }: any) => {
    if (window) {
      const url = groupDetails.invite;

      if (network === 'Facebook') {
        const link = `https://www.facebook.com/sharer.php?u=${url}`;
        return link;
      }

      if (network === 'Twitter') {
        const link = `https://twitter.com/intent/tweet?url=${url}&text=Fa%C3%A7a%20j%C3%A1%20o%20seu%20palpite%20na%20MoneyPot&hashtags=moneypot,game,money`;
        return link;
      }

      if (network === 'Whatsapp') {
        const link = `https://api.whatsapp.com/send?text=${url}`;
        return link;
      }
    }

    return '';
  };

  const handleChangeGroupName = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const replaceWithSpacesAtTheEndAllowed =
      /[^A-Za-z0-9_\s]|(\s{2,})|(_)_+|(?=.*[A-Za-z].*[A-Za-z])()(?![A-Za-z0-9]+(?:_[A-Za-z0-9]+)*(?: [A-Za-z0-9]+)*$)/g;
    //clean up string not allowing forbidden characters, allows numbers and letters,
    //spaces (only in between characters, not begin or end with spaces), same rule to spaces applies to underscore
    const newText = target.value.replace(replaceWithSpacesAtTheEndAllowed, '');

    if (newText !== target.value) {
      window.clearTimeout(invalidCharacter);
      const setTimeoutId = window.setTimeout(() => {
        setInvalidCharacter(0);
      }, 3000);
      setInvalidCharacter(setTimeoutId);
    } else {
      //In case the next character is valid reset
      window.clearTimeout(invalidCharacter);
      setInvalidCharacter(0);
    }

    setGroupEditable({
      ...groupEditable,
      name: newText,
    });
  };

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  }, [linkCopied]);

  useEffect(() => {
    //CLOSE SHARE OPTION WHEN CLICK OUTSIDE
    function closeOptions(event: Event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as HTMLDivElement)) {
        setShowShareOptions(false);
      }
    }

    document.addEventListener('mousedown', closeOptions);
    return () => document.removeEventListener('mousedown', closeOptions);
  }, [popoverRef]);

  useEffect(() => {
    if (fileAvatar) {
      setGroupEditable({
        ...groupEditable,
        file: URL.createObjectURL(fileAvatar),
      });
      handleUpdateGroup(groupDetails.name, fileAvatar);
    }
  }, [fileAvatar]);

  return (
    <PageContainer>
      {statusRequest === 'error' && (
        <ErrorAlert title={'Error'} message={message} onClose={handleCleanStatusRequest} autoClose />
      )}

      <MainContainer>
        <ShareGroupContainer>
          <ShareLinkContainer copied={linkCopied}>
            <ImageContainer>
              <img src={groupDetails?.avatar} alt="" />
              <input {...getInputProps()} />
              <button {...getRootProps()}>
                <EditIcon />
              </button>
            </ImageContainer>
            <div>
              <div className="container-title">
                <h2 className="text-name">{!isEditing && groupDetails.name}</h2>

                {/* DISABLE FEATURE */}
                {isEditing && (
                  <input
                    className="ipt-change-group-name text-name"
                    maxLength={20}
                    value={groupEditable.name}
                    onChange={handleChangeGroupName}
                    style={{ border: invalidCharacter > 0 ? '1px solid red' : '1px solid green', borderRadius: '10px' }}
                  />
                )}

                {/* DISABLE FEATURE */}
                {!isEditing && (
                  <button onClick={() => setIsEditing(true)}>
                    <EditIcon className="w-6 h-6 relative bottom-0 left-0" />
                  </button>
                )}
                {isEditing && <button onClick={sendNameUpdate}>Salvar</button>}
              </div>
              {isEditing && invalidCharacter > 0 && (
                <span className="block text-xs text-state-fail w-full text-left">
                  Você tentou inserir caractère/combinação inválido(a)!
                </span>
              )}
            </div>

            <div className="container-invite">
              <p>{groupDetails.invite.replace('https://', '')}</p>
              <button>
                <CopyIcon onClick={sharePot} />
              </button>
            </div>
          </ShareLinkContainer>
          <div className="max-w-[334px] md:max-w-[147px] w-[90%] relative">
            <Button
              hierarchy="primary"
              label="Convidar"
              size="lg"
              moreClasses="flex justify-center max-w-[334px] md:max-w-[147px] w-[90%] h-[52px]"
              onClick={() => setShowShareOptions(!showShareOptions)}
            />
            {showShareOptions && (
              <ModalShareContainer ref={popoverRef}>
                <button type="button" onClick={() => sharePot()} className="link-Container">
                  <MdOutlineContentCopy />
                  Copiar link
                </button>
                {NETWORKS.map((item) => (
                  <a
                    key={item.title}
                    target="blank"
                    rel="noopener noreferrer"
                    href={shareLinkToNetworks({ network: item.title })}
                    className="link-Container"
                  >
                    <item.icon />
                    {item.title}
                  </a>
                ))}
              </ModalShareContainer>
            )}
          </div>
        </ShareGroupContainer>

        {!isGroupWithoutUsers && (
          <AlignContainer>
            <BalanceGroupContainer>
              <h2>Ganhos acumulados</h2>
              <div>
                <span>R$ {formatValue(groupDetails.balance)}</span>
                <Link
                  to="/profile/withdraw"
                  className={window.innerWidth < 600 ? 'w-[75px]' : 'w-[224px] max-w-[100%]'}
                >
                  <Button
                    hierarchy="primary"
                    label="Sacar"
                    size="lg"
                    disabled={groupDetails.balance === '0'}
                    moreClasses={window.innerWidth < 600 ? 'w-[75px]' : 'w-[224px] max-w-[100%]'}
                  />
                </Link>
              </div>
            </BalanceGroupContainer>
            <GroupUsers groupId={Number(groupDetails.id)} />
            <GroupTransaction />
          </AlignContainer>
        )}

        {isGroupWithoutUsers && (
          <NoMembersInfoContainer>
            <MoneyBag />
            <h2>Você ainda não possui usuários no seu grupo!</h2>
            <p>Chame a galera, divulgue seu link pra seguidores, amigos, família e comece a lucrar!</p>
          </NoMembersInfoContainer>
        )}
      </MainContainer>
    </PageContainer>
  );
}
