import { useEffect, useState } from 'react';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { userTransactionsHistory } from '../../service/user/user.service';
import { Transaction } from '../../typescript/transaction';
import { ButtonSeeMore, TransactionContainer, TransactionItem } from './styles';

export const CoinsTransactions = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [limit, setLimit] = useState(5);
  const [isLoading, setLoading] = useState(false);

  const windowsSize = useGetWindowSize();
  const isMobileTypeDevice = windowsSize.width < 700;
  const filteredCoins = isMobileTypeDevice ? transactions.slice(0, limit) : transactions;
  const totalItems = transactions.length;
  const isThereNoMoTransactions = transactions.length > limit;
  const isShowButton = isMobileTypeDevice && transactions.length > 4 && isThereNoMoTransactions;

  const handlePagination = () => {
    if (limit === totalItems) {
      return null;
    }

    const sum = limit + 5;

    if (sum <= totalItems) {
      setLimit(sum);
    } else {
      setLimit(totalItems);
    }
  };

  const handleGetTransactions = async () => {
    try {
      setLoading(true);
      const response = await userTransactionsHistory('coin');
      setTransactions(response.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTransactions();
  }, []);

  return (
    <>
      <TransactionContainer>
        {isLoading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {filteredCoins?.map((item) => (
              <>
                <TransactionItem>
                  <p className="statusMessage">
                    {item.status === 'Devolução de fichas' ? item.description : item.status}
                  </p>

                  <p>
                    {item.status === 'Devolução de fichas' ? '' : '-'}
                    {Math.trunc(Number(item.value))} {Number(item.value) === 1 ? 'ficha' : 'fichas'}
                  </p>
                </TransactionItem>
              </>
            ))}
          </>
        )}
      </TransactionContainer>
      {isShowButton && <ButtonSeeMore onClick={handlePagination}>ver mais</ButtonSeeMore>}
    </>
  );
};
