export class SessionControl {
  //Token expiration time in minutes
  private static EXPIRATION_TIME = 120;

  //NAME KEYS [PROBABLY SHOULDN'T TOUCH IT]
  private static WELCOME_TO_GROUP_KEY = 'WELCOME_TO_GROUP';
  private static POT_CHOICES_KEY = 'POT_CHOICES';
  private static GROUP_TOKEN_KEY = 'GROUP_TOKEN';
  private static MONEY_POT_TOKEN_KEY = '@MoneyPot:token';
  private static MONEY_POT_EXPIRATION_KEY = '@MoneyPot:expiration';
  private static MONEY_POT_PARTNER_KEY = '@MoneyPot:partner';
  private static GROUP_NAME_KEY = 'groupName';

  static setToken(token: string) {
    localStorage.setItem(SessionControl.MONEY_POT_TOKEN_KEY, token);
    SessionControl.setExpirationDate();
  }

  static clearSession() {
    localStorage.clear();
  }

  static getToken() {
    const expired = SessionControl.checkIfTokenIsExpired();

    if (!expired) {
      return localStorage.getItem(SessionControl.MONEY_POT_TOKEN_KEY);
    } else {
      SessionControl.clearSession();
      return null;
    }
  }

  private static checkIfTokenIsExpired(): boolean {
    const expirationDate = localStorage.getItem(SessionControl.MONEY_POT_EXPIRATION_KEY);

    if (expirationDate) {
      const parsedExpirationDate = new Date(JSON.parse(expirationDate));
      const currentDate = new Date();

      //checks if token has expired
      if (currentDate < parsedExpirationDate) {
        //if not renew it, because there is still activity happening
        SessionControl.setExpirationDate();
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  private static setExpirationDate() {
    const currentDate = new Date();

    //sets expiration date as 2 hours (120 minutes)
    const expirationDate = new Date(currentDate.getTime() + SessionControl.EXPIRATION_TIME * 60000);

    localStorage.setItem(SessionControl.MONEY_POT_EXPIRATION_KEY, JSON.stringify(expirationDate));
  }

  static getPartner(): string | undefined {
    const partnerToken = localStorage.getItem(SessionControl.MONEY_POT_PARTNER_KEY)
      ? localStorage.getItem(SessionControl.MONEY_POT_PARTNER_KEY)?.replaceAll('"', '')
      : '';

    return partnerToken;
  }

  static clearPartner(): void {
    return localStorage.removeItem(SessionControl.MONEY_POT_PARTNER_KEY);
  }

  static setGroupName(path: string): void {
    return localStorage.setItem(SessionControl.GROUP_NAME_KEY, path);
  }

  static getGroupName(): string | null {
    const path = localStorage.getItem(SessionControl.GROUP_NAME_KEY);
    if (path) {
      localStorage.removeItem(SessionControl.GROUP_NAME_KEY);
    }
    return path;
  }

  static getPotChoices(): string | null {
    return localStorage.getItem(SessionControl.POT_CHOICES_KEY);
  }

  static setPotChoices(potChoices: string): void {
    return localStorage.setItem(SessionControl.POT_CHOICES_KEY, potChoices);
  }

  static clearPotChoices(): void {
    return localStorage.removeItem(SessionControl.POT_CHOICES_KEY);
  }

  static setGroupToken(token: string): void {
    return localStorage.setItem(SessionControl.GROUP_TOKEN_KEY, JSON.stringify(token));
  }

  static getGroupToken(): string | null {
    return localStorage.getItem(SessionControl.GROUP_TOKEN_KEY);
  }

  static clearGroupToken(): void {
    return localStorage.removeItem(SessionControl.GROUP_TOKEN_KEY);
  }

  static getPot(potName: string): string | null {
    return localStorage.getItem(potName);
  }

  static setPot(potName: string, potData: string): void {
    return localStorage.setItem(potName, potData);
  }

  static removePot(potName: string): void {
    return localStorage.removeItem(potName);
  }

  static setWelcomeToGroup(userId: number, welcome: boolean): void {
    return localStorage.setItem(SessionControl.WELCOME_TO_GROUP_KEY + userId, '' + welcome);
  }
  static getWelcomeToGroup(userId: number): string | null {
    return localStorage.getItem(SessionControl.WELCOME_TO_GROUP_KEY + userId);
  }
}
