import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlignContainer = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 50% 47%;
  grid-template-rows: auto auto auto;
  grid-gap: 32px;

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ShareGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin: 56px 0 40px 0;
  width: 100%;
  height: auto;
  background: #28293d;
  border-radius: 20px;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    width: 100%;
    height: auto;
  }
`;

interface ShareLinkContainerProps {
  copied?: boolean;
}

export const ShareLinkContainer = styled.div<ShareLinkContainerProps>`
  display: grid;
  flex-direction: row;
  grid-template-columns: 80px 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 32px;
  position: relative;
  padding: 0;
  width: 100%;
  height: auto;

  @media (max-width: 282px) {
    grid-template-columns: 50px 1fr;
  }

  .container-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-left: 8px;
    }
  }

  .text-name {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 454px) {
      width: 230px;
    }

    @media (max-width: 411px) {
      width: 200px;
    }

    @media (max-width: 382px) {
      width: 150px;
    }

    @media (max-width: 329px) {
      width: 120px;
    }

    @media (max-width: 305px) {
      width: 100px;
    }
  }

  h2 {
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ipt-change-group-name {
    border: none;
    outline: none;
    background: none;
  }

  .container-invite {
    display: flex;
    flex-direction: row;
    grid-row: 2/2;
    grid-column: 2/2;
    align-items: center;

    p {
      margin: 0;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    max-width: 366px;
    width: 100%;
    height: auto;
    padding: 8px;
    border: ${({ copied }) => (copied ? '1px solid #00DC6E; ' : ' 1px solid #4b4c66;')};
    border-radius: 8px;
    background: #28293d;
    font-family: 'Outfit';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: ${({ copied }) => (copied ? ' #00DC6E' : ' #c4c4c4')};

    button {
      margin-left: 8px;

      svg {
        g path {
          fill: ${({ copied }) => (copied ? '#00DC6E' : '#C4C4C4')};
        }
      }
    }
  }

  @media (max-width: 760px) {
    margin: 0;
    grid-column-gap: 4px;

    h2 {
      font-size: 36px;
    }

    .container-invite {
      display: grid;
      grid-template-columns: 90% 10%;
      height: auto;
      grid-column: 1/3;
      margin-top: 18px;

      p {
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        margin-left: 8px;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  img {
    width: 78px;
    height: 78px;
    border-radius: 9999px;
  }

  button {
    position: absolute;
    margin-top: 50px;
    margin-left: 55px;
    top: 0;
    right: 0;
  }

  @media (max-width: 760px) {
    width: 60px;
    height: 60px;

    img {
      width: 60px;
      height: 60px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 38px;
      margin-left: 45px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 282px) {
    width: 40px;
    height: 40px;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

export const BalanceGroupContainer = styled.div`
  width: 100%;
  height: 146px;
  background: #28293d;
  border-radius: 20px;
  padding: 24px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-family: 'Outfit';
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
  }

  span {
    font-family: 'League Gothic';
    font-weight: 400;
    font-style: normal;
    font-size: 44px;
  }
`;

export const TransactionHistoryContainer = styled.div`
  width: 100%;
  background: green;
  display: flex;
  flex-direction: column;
  grid-row: 2/2;
  padding: 24px 32px;
  width: 100%;
  height: 434px;
  background: #28293d;
  border-radius: 20px;

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 34px;
    border: 1px solid #ffffff;
    border-radius: 100px;
  }

  @media (max-width: 600px) {
    height: auto;
  }

  @media (max-width: 750px) {
    margin-bottom: 24px;
  }

  .table-container {
    width: 100%;
    height: 324px;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 600px) {
      height: auto;
    }

    ::-webkit-scrollbar {
      width: 4px;
      background-color: #4b4c66;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }
  }
`;

export const TransactionHistoryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  .item-inner-info {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;

    @media (max-width: 1200px) {
      font-size: 13px;
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .transaction-icon-type {
    margin-right: 10px;
  }

  .dot-s {
    margin-right: 8px;
    margin-left: 8px;
  }

  .transaction-description {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .transaction-value {
    justify-self: flex-end;
    width: 100px;
    text-align: right;
    padding-right: 5px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  position: relative;

  .table-header {
    width: 100%;
  }

  .users-table-list {
    height: 450px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
      height: auto;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 234px;
      background-color: #4b4c66;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }
  }

  .table-list-item,
  .table-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0px;
  }

  span {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 24px;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-right: 44px;
    color: #ff8e00;
  }

  @media (max-width: 600px) {
    height: auto;
    overflow-y: hidden;

    span {
      margin-right: 0px;
    }

    p {
      margin-right: 0px;
    }
  }
`;

export const NewUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row: 1/3;
  grid-column: 2/2;
  padding: 24px 32px;
  margin-bottom: 30px;

  width: 100%;
  height: 622px;

  background: #28293d;
  border-radius: 20px;

  .boxContainer {
    height: 496px;
  }

  .box {
    margin-right: 90px;
  }

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 600px) {
    height: auto;

    .boxContainer {
      height: auto;
    }

    .box {
      margin-right: 0px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 34px;
      border: 1px solid #ffffff;
      border-radius: 100px;
    }
  }
`;

export const SeparationLine = styled.div`
  /* max-width: 451px; */
  width: 100%;
  background: #4b4c66;
  border: 1px solid #4b4c66;
  margin-bottom: 24px;
`;

export const RankingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  margin: 0px 0 40px 0;

  width: 100%;
  height: 746px;

  background: #28293d;
  border-radius: 20px;
`;

export const ModalShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 147px;
  height: auto;
  background: #4b4c66;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 2px 0px 16px rgba(0, 0, 0, 0.25);

  position: absolute;
  top: 50;
  right: 0;
  left: 10;

  svg {
    width: 20px;
    height: 30px;
    color: white;
    margin-right: 0.4em;
  }

  .link-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    height: 40px;

    font-size: 14px;
    cursor: pointer;

    border-bottom: 1px solid #1c1c27;
  }
`;

export const NoMembersInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }

  svg {
    margin: 24px 0px;

    @media (max-width: 500px) {
      margin: 0px;
    }
  }

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 500px) {
      margin-top: 16px;
      font-size: 20px;
    }
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 30px; */
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 600px) {
      width: 90%;
    }
  }
`;
