import { GROUP_TOKEN_KEY } from '../../constants/local-storage-keys';
import { useGroupOwnershipCheck } from '../../hooks/useGroupOwnershipCheck';
import { SessionControl } from '../../session/SessionControl';
import { UserType } from '../../typescript/user';
import api from '../api';

export const createGroup = async (data: FormData) => {
  try {
    const result = await api.post(
      `/accounts-users/group`,
      data,

      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    return result;
  } catch (error: any) {
    return error;
  }
};

export const getGrouByUser = async (id: number) => {
  return await api
    .get(`/accounts-users/group-by-user/${id}`, {})
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

interface UnJoinGroupProps {
  user_id: number;
  group_id: number;
}

export const unJoinGroup = async ({ user_id, group_id }: UnJoinGroupProps) => {
  try {
    const response = await api.post(`/accounts-users/unjoin`, {
      user_id,
      group_id,
    });

    return response;
  } catch (error) {
    return 'error';
  }
};

export const deleteGroup = async (group_id: number) => {
  try {
    const response = await api.delete(`/accounts-users/group/${group_id}`);
    return response;
  } catch (error) {
    return 'error';
  }
};

interface ParticipateGroupProps {
  user_id: number;
  group_id?: number;
  token?: string;
}

export const participateGroup = async ({ user_id, group_id, token = '' }: ParticipateGroupProps) => {
  if (token.substring(0, 1) === '"') {
    token = token.substring(1, token.length - 1);
  }

  return await api
    .post(`/accounts-users/join-group`, {
      user_id,
      group_id,
      token,
    })
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const checkGroupTokenAndParticipate = async (user: UserType) => {
  if (user.userGroup.length === 0) {
    const data = SessionControl.getGroupToken();

    if (data) {
      const groupToken = JSON.parse(data);
      const isCurrentUserGroupOwner = await useGroupOwnershipCheck(user.id, groupToken);

      if (!isCurrentUserGroupOwner) {
        return await participateGroup({ user_id: user?.id, token: groupToken });
      }
    }
  }

  SessionControl.clearGroupToken();
};

export const getGroups = async () => {
  return await api
    .get(`/accounts-users/group`)
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const getGroup = async () => {
  return await api
    .get(`/accounts-users/group`)
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const updateGroup = async (id: number, data: FormData) => {
  return await api
    .patch(`/accounts-users/group/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const getUsersByGroup = async (id: number) => {
  return await api
    .get(`/accounts-users/users-by-group/${id}`, {})
    .then((response) => response.data)
    .catch((response) => response.response.data);
};

export const getParticipateByGroup = async (id: number) => {
  return await api
    .get(`/accounts-users/group/${id}`)
    .then((response) => response.data)
    .catch((response) => response.response.data);
};
