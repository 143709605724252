import { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavBarMenuContext } from '../../common/context/NavbarContext';
import { useUserContext } from '../../common/context/UserContext';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import Button from '../Button/Button';
import MenuOpenIcon from '../MenuOpenIcon/MenuOpenIcon';
import TopUserInfo from '../TopUserInfo/TopUserInfo';
import {
  AlignContainer,
  ButtonNavBar,
  HeaderButtonContainer,
  HeaderContainer,
  HeaderMainInfo,
  HeaderUserInfo,
  MainHeaderContainer,
  NavBarOffContainer,
} from './styles';

const Header = () => {
  const { userLogged } = useUserContext();
  const { setOpenMenu } = useContext(NavBarMenuContext);
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const logged = !!userLogged;

  const divClassName = 'relative w-[100%]  h-[54px] flex flex-row items-top justify-between lg:justify-end ';

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    let prevScrollpos = window.pageYOffset;

    window.onscroll = function () {
      if (isMobile) {
        const currentScrollPos = window.pageYOffset;
        const headerElement = (document as unknown as any).getElementById('header');
        headerElement.style.position = 'fixed';

        if (currentScrollPos < 3) {
          headerElement.style.top = '0';
          headerElement.style.position = 'static';
        } else {
          if (prevScrollpos > currentScrollPos) {
            headerElement.style.top = '0';
          } else {
            headerElement.style.top = '-150px';
          }
        }

        prevScrollpos = currentScrollPos;
      }
    };

    return () => {
      prevScrollpos = window.pageYOffset;
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const headerElement = (document as unknown as any).getElementById('header');
    headerElement.style.position = 'static';
  }, [location]);

  return (
    <MainHeaderContainer id="header">
      {windowSize.width <= 1024 ? (
        <HeaderContainer>
          {logged ? (
            <AlignContainer>
              <div>
                <Link to="/">
                  <img className="mt-8 ml-6" src="/logo-rodape.svg" alt="logomarca moneypot" />
                </Link>
              </div>

              <HeaderMainInfo>
                <ButtonNavBar>
                  <MenuOpenIcon setMenuOpen={() => setOpenMenu(true)} />
                </ButtonNavBar>

                <HeaderUserInfo>{!!userLogged && <TopUserInfo />}</HeaderUserInfo>
              </HeaderMainInfo>
            </AlignContainer>
          ) : (
            <NavBarOffContainer>
              <ButtonNavBar className="navOff">
                <MenuOpenIcon setMenuOpen={() => setOpenMenu(true)} />
              </ButtonNavBar>

              <div>
                <Link to="/">
                  <img src="/logo-rodape.svg" alt="moneypot" />
                </Link>
              </div>

              <HeaderButtonContainer>
                <Button
                  moreClasses="font-semibold max-w-[74px] w-full h-[34px]"
                  hierarchy="primary"
                  label="Entrar"
                  size="sm"
                  onClick={() => navigate('/login')}
                />
              </HeaderButtonContainer>
            </NavBarOffContainer>
          )}
          <div style={{ width: '54px' }} />
        </HeaderContainer>
      ) : logged ? (
        <div className={divClassName}>
          <TopUserInfo />
        </div>
      ) : (
        <div className={`${divClassName} justify-end`}>
          {windowSize.width <= 1024 && (
            <div className="mt-3">
              <Button
                hierarchy="tertiary"
                moreClasses="w-auto mr-3"
                label="Criar conta"
                size="md"
                onClick={() => navigate('/register')}
              />
              <Button
                hierarchy="primary"
                moreClasses="w-auto px-3 font-bold"
                label="Entrar"
                size="md"
                onClick={() => navigate('/login')}
              />
            </div>
          )}
        </div>
      )}
    </MainHeaderContainer>
  );
};

export default Header;
