import { createContext, Dispatch, SetStateAction, ReactNode, useState, useEffect } from 'react';

interface ChildrenProps {
  children: ReactNode;
}

interface Context {
  loginMenuOpen: boolean;
  setLoginMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const initialContext: Context = {
  loginMenuOpen: false,
  setLoginMenuOpen: () => false,
};

export const LoginOpenMenuContext = createContext<Context>(initialContext);

export const LoginOpenMenuProvider = ({ children }: ChildrenProps) => {
  const [loginMenuOpen, setLoginMenuOpen] = useState<boolean>(false);

  return (
    <LoginOpenMenuContext.Provider value={{ loginMenuOpen, setLoginMenuOpen }}>
      {children}
    </LoginOpenMenuContext.Provider>
  );
};
