import styled from '@emotion/styled';

export const SimpleFeedbackTextContainer = styled.div`
  width: 320px;
  max-width: 100%;
  height: auto;

  .content-container {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 30px;
    height: 30px;
    transform: scale(0.9);
  }

  
`;

interface BodyTextProps {
  isError?:boolean
}

export const BodyText = styled.p<BodyTextProps>`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color:${({isError})=> isError ? "red": "#00dc6e"} ;
  width: 90%;
`;
