
import React from 'react';
import { Skeleton } from '@mui/material';

const SkeletonLoader = () => {
  return (
    <div>
      <Skeleton variant="text" width={200} height={24} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={80} style={{ marginBottom: 10 }} />
      ))}
    </div>
  );
};

export default SkeletonLoader;
