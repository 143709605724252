import PageContainer from '../../layouts/PageContainer/PageContainer';
import { useState, useEffect } from 'react';
import { getCmsHtml } from '../../service/cms/cms.service';
import { getPublicPotBetById} from '../../service/pots/pots.service';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import Pot from '../../components/Pot/Pot';
import ModalRules from '../../components/ModalRules';
import ModalCoins from '../../components/ModalCoins';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useParams, useNavigate } from 'react-router-dom';
import { PotBanner } from './styles';
import { Game } from '../../typescript/match';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';

import CryptoJS from 'crypto-js';

const SinglePotPage = () => {
  const [bannerContent, setbannerContent] = useState('');
  const [bannerPot, setBannerPot] = useState('');

  const [potInfo, setPotInfo] = useState<Game | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModalRules, setOpenModalRules] = useState(false);
  const { token } = useSelector((state: RootState) => state.user);
  const windowSize = useGetWindowSize();
  const navigate = useNavigate();
  const [parsed] = useState('');

  const { id } = useParams();

  const { MAIN_BANNER_MINI_TYPEID, POT_BANNER_TYPEID } = cmsPagesEnum;

  const normalizePotUrl = (url: any) => {
    return url.replace(/-/g, " ");
  };

  const decryptPotId = (encryptedPotId: any) => {
    const encryptionKey = 'secrethero99';
    const reb64 = CryptoJS.enc.Hex.parse(encryptedPotId);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, encryptionKey);
    const plain = decrypt.toString(CryptoJS.enc.Utf8);
    return  plain;
  };

  const handleGetPotById = async () => {

    const decryptedId = decryptPotId(id);

    const normalizedId = normalizePotUrl(decryptedId);

    if (normalizedId) {

      const response = await getPublicPotBetById(normalizedId);

      if (response.data !== 'Não foi encontrado nenhum POT!') {
        setPotInfo(response.data);
      } else {
        navigate('/pageNotFound');
      }
    }
  };

  useEffect(() => {
    getCmsHtml(MAIN_BANNER_MINI_TYPEID).then((res) => {
      setbannerContent(res);
    });

    getCmsHtml(POT_BANNER_TYPEID).then((res) => {
      setBannerPot(res);
    });
  }, []);

  useEffect(() => {
    handleGetPotById();
  }, [id]);

  const openModalAtMobile = windowSize.width <= 600 && openAlert;

  return (
    <PageContainer className={`realtive `}>
      {openModalAtMobile && (
        <div className={`flex flex-cols items-center h-[500px]  w-[100%]`}>
          <ModalCoins setOpenAlert={setOpenAlert} openAlert={openAlert} />
        </div>
      )}

      {!openModalAtMobile && <ModalCoins setOpenAlert={setOpenAlert} openAlert={openAlert} />}

      <ModalRules openRules={openModalRules} setOpenRules={setOpenModalRules} />

      {!openModalAtMobile && (
        <>
          <div
            className={` ${
              windowSize.width <= 800
                ? 'grid grid-rows-[auto_auto] gap-y-5'
                : 'grid grid-cols-[64%_35%] gap-5 md:h-[200px] h-[108px]'
            }  relative w-full max-w-[100%]  overflow-hidden rounded-[20px] md:mt-[4rem] md:mb-[2rem]`}
          >
            <PotBanner
              dangerouslySetInnerHTML={{
                __html: bannerPot,
              }}
            ></PotBanner>

            <PotBanner
              dangerouslySetInnerHTML={{
                __html: bannerContent,
              }}
            ></PotBanner>
          </div>

          <div className="w-full">
            {potInfo !== null && (
              <Pot
                setOpenRules={setOpenModalRules}
                openRules={false}
                setOpenModal={(value: boolean) => setOpenAlert(value)}
                openModal={openAlert}
                potInfo={potInfo}
                fullWidth
                isAlreadyBet={false}
                parsed={parsed}
              />
            )}
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default SinglePotPage;
