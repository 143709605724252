import { useEffect, useState } from 'react';
import { useSearchParams, useParams, useLocation, useNavigate } from 'react-router-dom';
import CategorySelector from '../../components/CategorySelector/CategorySelector';
import Pot from '../../components/Pot/Pot';
import PageContainer from '../../layouts/PageContainer/PageContainer';

import { getPots } from '../../service/pots/pots.service';
import { BannerCategory, ChampionshipName, IconCategoryContainer } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ModalRules from '../../components/ModalRules';
import ModalCoins from '../../components/ModalCoins';
import { cmsPagesEnum } from '../../utils/cmsPagesEnum';
import { getCmsHtml } from '../../service/cms/cms.service';
import { useGetGameMatches, isExpired } from '../../hooks/useGetGameMatches';
import { Game } from '../../typescript/match';
import moment from 'moment';

interface ChampionshipGame {
  name: string;
  games: Game[];
}

const Category = () => {
  const [searchParams] = useSearchParams();
  const { sport } = useParams()
  const sportString = searchParams.get('sport') || '';
  const [bannerCategory, setbannerCategory] = useState('');
  const { token } = useSelector((state: RootState) => state.user);
  const [openModalRules, setOpenModalRules] = useState(false);
  const [openModalBuyCoins, setOpenModalBuyCoins] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(sport as string);
  const [potList, setPotList] = useState<Game[]>([]);
  const { categoriesAvailable } = useGetGameMatches();
  const location = useLocation()
  const navigate = useNavigate()

  const { BANNER_CATEGORY_TYPEID } = cmsPagesEnum;

  const getPotsBySports = async () => {

    const res = await getPots(`?sports=${selectedCategory}&status=Em Andamento`);

    const resultFiltred = res.data.data.filter((pot: Game) => !isExpired(pot.dateFinalBet) && moment(pot.dateInitialBet) <= moment());

    setPotList(resultFiltred);
  };

  const getPotsByChampionship = (): ChampionshipGame[] => {
    const myPots = [...potList];

    let championShipGames: ChampionshipGame[] = [];

    myPots.forEach((pot) => {
      if (championShipGames.length === 0) {
        championShipGames.push({
          name: pot.championship || 'Campeonato',
          games: [pot],
        });
      } else {
        const championShipIndex = championShipGames.findIndex((ch) => ch.name === pot.championship);

        if (championShipIndex >= 0) {
          const championShipGamesCpy = [...championShipGames];
          const oldChampionShip = championShipGamesCpy[championShipIndex];

          const games = [...oldChampionShip.games, pot];

          championShipGamesCpy[championShipIndex] = {
            ...oldChampionShip,
            games: games,
          };

          championShipGames = championShipGamesCpy;
        } else {
          championShipGames.push({
            name: pot.championship || 'Campeonato',
            games: [pot],
          });
        }
      }
    });

    return championShipGames;
  };

  const handleChangeParamsByCategory = () => {
    navigate(`/category/${selectedCategory}`)
  };

  useEffect(() => {
    getPotsBySports();
    handleChangeParamsByCategory();
  }, [selectedCategory, sportString, token]);

  useEffect(()=> {

    const categoryFromUrl = location.pathname.replace("/category/","")


    if(categoryFromUrl){
     setSelectedCategory(categoryFromUrl)
    }
    
  }, [location])

  useEffect(() => {
    getCmsHtml(BANNER_CATEGORY_TYPEID).then((res) => {
      setbannerCategory(res);
    });
  }, []);

  return (
    <div className="relative">
      <ModalCoins setOpenAlert={setOpenModalBuyCoins} openAlert={openModalBuyCoins} />

      <ModalRules openRules={openModalRules} setOpenRules={setOpenModalRules} />

      <PageContainer>
        <IconCategoryContainer>
          <CategorySelector
            categoriesAvailable={categoriesAvailable}
            selected={selectedCategory}
            setCategory={setSelectedCategory}
          />
        </IconCategoryContainer>

        <BannerCategory>
          <div
            className="banner-category"
            dangerouslySetInnerHTML={{
              __html: bannerCategory,
            }}
          />
        </BannerCategory>

        <div className="">
          {getPotsByChampionship().map((championship: ChampionshipGame) => (
            <div key={championship.name}>
              <ChampionshipName>{championship.name}</ChampionshipName>
              <>
                {championship.games.map((pot: Game) => (
                  <Pot
                    fullWidth
                    isAtCategoryPage
                    openRules={openModalRules}
                    setOpenRules={setOpenModalRules}
                    openModal={false}
                    setOpenModal={() => null}
                    potInfo={pot}
                    isAlreadyBet={false}
                    key={pot.id}
                  />
                ))}
              </>
            </div>
          ))}
        </div>
      </PageContainer>
    </div>
  );
};

export default Category;
