import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../common/context/UserContext';
import { GROUP_TOKEN_KEY } from '../../constants/local-storage-keys';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { checkGroupTokenAndParticipate, getGrouByUser, getGroups } from '../../service/group/group';
import { getUserInfo } from '../../service/user/user.service';
import { RootState } from '../../store';
import { UserGroup } from '../../typescript/group';
import { normalizeName } from '../../utils/normalize-name';
import SkeletonLoader from '../SkelentonGroup/SkeletonGroup';
import {
  ParticipateGroupButton,
  ParticipateGroupContainer,
  ParticipateGroupItem,
  ParticipateGroupItemAvatar,
  ParticipateGroupItemButton,
  ParticipateGroupItemName,
  ParticipateGroupList,
  ParticipateGroupSearchField,
  ParticipateGroupTitle,
} from './styles';
import { SessionControl } from '../../session/SessionControl';

interface ParticipateGroupProps {
  handleBack: () => void;
}

const ParticipateGroup = ({ handleBack }: ParticipateGroupProps) => {
  const user = useSelector((state: RootState) => state.user);

  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const windowSize = useGetWindowSize();
  const isMobile = windowSize.width <= 600;
  const [loadingButtonParticipate, setLoadingButtonParticipate] = useState<number | null>(null);
  const { updateAppState } = useUserContext();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  };

  const handleParticipeAtGroup = async (id: number, name: string) => {
    const userInfo = await getUserInfo();
    if (userInfo.id) {
      setLoadingButtonParticipate(id);

      updateAppState('group info updated');
      SessionControl.setGroupToken(name);
      await checkGroupTokenAndParticipate(userInfo);
      SessionControl.clearGroupToken();
      navigate(`/${normalizeName(name)}`);
      setLoadingButtonParticipate(null);
      if (!isMobile) {
        handleBack();
      }
    }
  };

  const handleGetGroups = async () => {
    setLoading(true);
    const response = await getGroups();
    const groupInfo = await getGrouByUser(user.userInfo.id);

    let result: UserGroup[] = response.data;

    if (groupInfo?.status === 200) {
      const group = groupInfo.data;

      if (group.id) {
        result = result.filter((g: UserGroup) => g.id !== group.id);
      }
    }

    setGroups(result);
    setLoading(false);
  };

  useEffect(() => {
    handleGetGroups();
  }, [search]);

  const filterGroup = (data: UserGroup[], query: string): UserGroup[] => {
    return data.filter((item) => {
      const lowerCaseQuery = query.toLowerCase();
      const nameIncludes = item.name?.toLowerCase().includes(lowerCaseQuery);
      return nameIncludes;
    });
  };

  const filterGroupInfo = useMemo(() => {
    return filterGroup(groups, search);
  }, [groups, search]);

  return (
    <ParticipateGroupContainer>
      <ParticipateGroupTitle>Participe de um grupo!</ParticipateGroupTitle>
      <ParticipateGroupSearchField>
        <TextField
          name="search"
          type="search"
          value={search}
          placeholder="Buscar grupo"
          className="ipt"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
      </ParticipateGroupSearchField>
      <ParticipateGroupList style={{ paddingRight: groups?.length > 8 ? '15px' : 0 }}>
        {loading && <SkeletonLoader />}

        <>
          {filterGroupInfo.map((item) => (
            <ParticipateGroupItem key={item.id}>
              <div className="info-s">
                <ParticipateGroupItemAvatar>
                  <img src={item.urlFile} alt="Imagem de Perfil do Grupo" />
                </ParticipateGroupItemAvatar>
                <ParticipateGroupItemName>{item.name}</ParticipateGroupItemName>
              </div>
              <ParticipateGroupItemButton
                disabled={loadingButtonParticipate !== null}
                onClick={() => handleParticipeAtGroup(item.id, item.name)}
              >
                {loadingButtonParticipate === item.id ? 'carregando...' : 'Participar'}
              </ParticipateGroupItemButton>
            </ParticipateGroupItem>
          ))}
        </>
      </ParticipateGroupList>

      <ParticipateGroupButton isOutlined onClick={handleBack}>
        Voltar
      </ParticipateGroupButton>
    </ParticipateGroupContainer>
  );
};

export default ParticipateGroup;
