import { HTMLAttributes } from 'react';
import { GrInstagram, GrYoutube } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { handleBackToTop } from '../../utils/window-func';
import { contactList, linksList } from './footer-constants';
import { FooterContainer, FooterMenu, FooterMenuItem, FooterLogo, FooterMenuTitle, FooterContent } from './styles';

interface IFooterProps extends HTMLAttributes<HTMLDivElement> {
  logoUrl: string;
}

const Footer = ({ logoUrl }: IFooterProps) => {
  const backToTop = () => {
    handleBackToTop()
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterLogo>
          <Link to="/">
            <img src="/logo-rodape.svg" alt="moneypot" />{' '}
          </Link>
        </FooterLogo>

        <FooterMenu className="about-menu">
          <FooterMenuTitle>Sobre</FooterMenuTitle>
          {linksList.map((item) => (
            <FooterMenuItem key={item.name}>
              {item.linkUrl === '' && item.name}
              {!!item.linkUrl && (
                <Link to={`${item.linkUrl}`} onClick={backToTop}>
                  {item.name}
                </Link>
              )}
            </FooterMenuItem>
          ))}
        </FooterMenu>

        <FooterMenu className="contact-menu">
          <FooterMenuTitle>Contato</FooterMenuTitle>
          <FooterMenuItem>
            <a href="mailto:suporte@moneypot.bet">suporte@moneypot.bet</a>
          </FooterMenuItem>
          {contactList.map((item) => (
            <FooterMenuItem key={item.name}>
              {item.linkUrl === '' && item.name}
              {!!item.linkUrl && (
                <Link to={`${item.linkUrl}`} onClick={backToTop}>
                  {item.name}
                </Link>
              )}
            </FooterMenuItem>
          ))}
        </FooterMenu>

        <FooterMenu className="networks-menu">
          <FooterMenuTitle className="mb-no-title">Redes socias</FooterMenuTitle>
          <div className="networks-div">
            <a href="https://www.instagram.com/moneypotbrasil/" target="_blank" rel="noreferrer">
              <GrInstagram color="#C4C4C4" className="w-[18px] h-full" />
            </a>
            <a href="https://www.youtube.com/@moneypotbrasil3417" target="_blank" rel="noreferrer">
              <GrYoutube color="#C4C4C4" className="w-[20px] h-full" />
            </a>
          </div>
        </FooterMenu>

        <FooterMenu className="security-menu">
          <FooterMenuTitle className="mb-no-title">Segurança</FooterMenuTitle>
          <div className="security-div">
            <img src="/images/rodape_ssl.png" alt="rodape SSL" className="mb-[12px] " />

            <img src="/images/rodape_pagSeguro.png" alt="rodape pag seguro" />
          </div>
        </FooterMenu>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
