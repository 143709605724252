import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUserContext } from '../../common/context/UserContext';
import { WithdrawButton, WithdrawNotes } from '../../pages/Withdraw/styles';
import { PixDataType } from '../../typescript/pix-withdraw';
import { pixTypeEnum } from '../../utils/pixTypeEnum';
import { formatCPF, formatCPForCNPJ, formatMoney, formatPhoneNumber } from '../../utils/types';
import { PixWithdrawContainer, PixWithdrawFormContainer, TextFieldContainer, TotalCredit } from './styles';
import { validateEachField, validateEmptyField } from './validation';

interface ErrorMsgType {
  field: keyof PixDataType | null;
  msg: string;
}

const errorMsgDefaultState = {
  field: null,
  msg: '',
};

const defaultState = {
  value: '',
  pixType: '',
  pixKey: '',
  fullName: '',
  cpf: '',
};

interface PixWithdrawProps {
  totalCredit: number | string;
  handleWithdraw: (data: PixDataType) => Promise<any>;
}

const PixWithdraw = ({ handleWithdraw, totalCredit }: PixWithdrawProps) => {
  const { userLogged } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<ErrorMsgType>(errorMsgDefaultState);
  const [pixData, setPixData] = useState<PixDataType>(defaultState);
  const [pixTypeFormatted, setPixTypeFormatted] = useState(pixData.pixKey);

  const handleChange = (event: React.ChangeEvent | SelectChangeEvent<string | null>) => {
    const target = event.target as HTMLInputElement;

    setPixData({
      ...pixData,
      [target.name]: target.value,
    });

    setErrorMsg(errorMsgDefaultState);
  };

  const handleAskWithdraw = async () => {
    const result = validateEmptyField(pixData);

    if (result) {
      return setErrorMsg({
        field: result as keyof PixDataType | null,
        msg: 'Campo obrigatório',
      });
    }

    const validationResult = validateEachField(pixData);

    if (validationResult.field === null) {
      setLoading(true);
      const response = await handleWithdraw(pixData);
      if (response === 'success') {
        setPixData(defaultState);
        setLoading(false);
      }
      setLoading(false);
    } else {
      setErrorMsg({
        field: validationResult.field as keyof PixDataType | null,
        msg: validationResult.msg,
      });
    }
  };

  useEffect(() => {
    if (userLogged) {
      setPixData({
        ...pixData,
        pixKey: userLogged.pixKey,
        pixType: userLogged.pixType,
      });
    }
  }, [userLogged]);

  useEffect(() => {
    if (pixData.pixType === 2) {
      return setPixTypeFormatted(formatPhoneNumber(pixData.pixKey));
    }
    if (pixData.pixType === 3) {
      return setPixTypeFormatted(formatCPForCNPJ(pixData.pixKey));
    }

    return setPixTypeFormatted(pixData.pixKey);
  }, [pixData]);

  return (
    <PixWithdrawContainer>
      <PixWithdrawFormContainer>
        <TextFieldContainer className=" ipt-value text-field-container">
          <TextField
            label="Valor"
            placeholder="0"
            name="value"
            id="text-value"
            value={formatMoney(pixData.value)}
            className="ipt "
            error={errorMsg.field === 'value'}
            helperText={errorMsg.field === 'value' && errorMsg.msg}
            onChange={handleChange}
          />
        </TextFieldContainer>
        <TotalCredit>
          <h1>SALDO MONEYPOT</h1>
          <p>Total: R$ {totalCredit}</p>
        </TotalCredit>
        <TextFieldContainer className="text-field-container">
          <TextField
            className="ipt"
            name="pixType"
            id="outlined-helperText"
            select
            label="Tipo Pix"
            defaultValue={pixData.pixType}
            value={pixData.pixType ?? ''}
            error={errorMsg.field === 'pixType'}
            helperText={errorMsg.field === 'pixType' && errorMsg.msg}
            onChange={handleChange}
          >
            {pixTypeEnum.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TextFieldContainer>
        <TextFieldContainer className="text-field-container">
          <TextField
            name="pixKey"
            value={pixTypeFormatted}
            label="Chave Pix"
            placeholder="Chave Pix"
            className="ipt"
            error={errorMsg.field === 'pixKey'}
            helperText={errorMsg.field === 'pixKey' && errorMsg.msg}
            onChange={handleChange}
          />
        </TextFieldContainer>
        <TextFieldContainer className="text-field-container text-field-name">
          <TextField
            label="Nome Completo"
            name="fullName"
            value={pixData.fullName}
            placeholder="Nome Completo"
            className="ipt"
            error={errorMsg.field === 'fullName'}
            helperText={errorMsg.field === 'fullName' ? errorMsg.msg : ''}
            onChange={handleChange}
          />
        </TextFieldContainer>
        <TextFieldContainer className="text-field-container text-field-cpf">
          <TextField
            label="CPF do responsável"
            value={formatCPF(pixData.cpf)}
            name="cpf"
            placeholder="CPF do responsável"
            className="ipt"
            error={errorMsg.field === 'cpf'}
            helperText={errorMsg.field === 'cpf' && errorMsg.msg}
            onChange={handleChange}
          />
        </TextFieldContainer>
      </PixWithdrawFormContainer>

      <WithdrawNotes>
        <h1>Importante!</h1>
        <p>
          Partiu conferir se está tudo certinho? Leia seus dados e se certifique de que está tudo nos conformes.
          Queremos que sua recompensa chegue até você sem perrengue.
        </p>
      </WithdrawNotes>

      <WithdrawButton disabled={loading} onClick={handleAskWithdraw}>
        {loading ? 'carregando...' : 'Sacar'}
      </WithdrawButton>
    </PixWithdrawContainer>
  );
};

export default PixWithdraw;
