import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserInfo } from '../service/user/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { slice } from './../../store/reducers/user/userSlice';
import { RootState } from '../store';
import { firebaseAuth } from '../service/firebase/firebase';
import { slice } from '../store/reducers/user/userSlice';
import { toast } from 'react-toastify';

interface UserAuthProps {
  isPrivatePage?: boolean;
  redirectTo?: string;
}

interface UserAuthPropsResponse {
  isUserSessionFinished: () => Promise<boolean>;
}

export const useUserAuth = ({ isPrivatePage, redirectTo = '/' }: UserAuthProps): UserAuthPropsResponse => {
  const { token } = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOutUser = () => {
    dispatch(slice.actions.logout());
    firebaseAuth.signOut();
    if (isPrivatePage) {
      navigate(redirectTo);
    }
    window.location.reload();

  };

  const isUserSessionFinished = async (): Promise<boolean> => {
    try {
      const response = await getUserInfo();
      const result = response.statusCode === 401;

      if (result) {
        signOutUser();
        return result;
      } else {
        return result;
      }
    } catch (error) {
      return true;
    }
  };

  const handleGetUser = async () => {
    if (token) {
      const result = await isUserSessionFinished();
      if (result) {
        signOutUser();
      }
    } else {
      if (isPrivatePage) {
        navigate(redirectTo);
      }
    }
  };

  useEffect(() => {
    handleGetUser();
  }, [pathname, token]);

  return { isUserSessionFinished };
};
