export const IconSmile = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 105C84.8528 105 105 84.8528 105 60C105 35.1472 84.8528 15 60 15C35.1472 15 15 35.1472 15 60C15 84.8528 35.1472 105 60 105Z"
        stroke="#00DC6E"
        strokeWidth="7.88096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1248 55.3125C45.7136 55.3125 47.8123 53.2138 47.8123 50.625C47.8123 48.0362 45.7136 45.9375 43.1248 45.9375C40.5359 45.9375 38.4373 48.0362 38.4373 50.625C38.4373 53.2138 40.5359 55.3125 43.1248 55.3125Z"
        fill="#00DC6E"
      />
      <path
        d="M71.2502 50.625H82.5002"
        stroke="#00DC6E"
        strokeWidth="7.88096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.4998 71.25C77.5174 74.6653 74.6727 77.5001 71.2506 79.4706C67.8284 81.4411 63.9487 82.4782 59.9998 82.4782C56.0508 82.4782 52.1711 81.4411 48.7489 79.4706C45.3268 77.5001 42.4821 74.6653 40.4998 71.25"
        stroke="#00DC6E"
        strokeWidth="7.88096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
