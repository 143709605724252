import styled from 'styled-components';

export const CMSModalContent = styled.div`
  max-width: 836px;
  width: 100%;
  height: 594px;
  background: #28293d;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  padding: 51px 96px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  }

  svg {
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  h4 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ff8e00;
  }

  p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-bottom: 16px;
  }
`;
