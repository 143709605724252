import api from '../api';

export const getPots = async (filter?: string) => {
  return await api
    .get(`public/pots/category${filter ? `${filter}` : ''}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getPotId = async (id: number) => {
  return await api
    .get(`public/pots/${id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getPotByTitle = async (title: string) => {

  const serializedTitle = encodeURIComponent(title) 
  
  return await api
    .get(`public/pots/single-pot/${serializedTitle}`, {
      
    })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getPublicPotBetById = async (id: number) => {

  return await api
      .get(`public/pots/one-pot/${id}`, {

      })
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        return response.response.data;
      });
};

export const getPotBetById = async (id: number) => {

  return await api
    .get(`accounts-users/pot-bet?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const changePotStatus = async (id: number) => {
  return await api
    .put(
      `accounts-users/pots/${id}`,
      {
        status: 'Aguardando Finalizacao',
      },
      {}
    )
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getPublicPots = async (query?: string) => {
  return await api
    .get(`/public/pots/${query}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};

export const getPublicBanner = async (query: string) => {
  return await api
    .get(`public/banner-public${'?' + query}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};
